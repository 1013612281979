import React from "react";
import "../../sass/Molecules.scss";
import { SolidButton3 } from "../atoms/Button";

export const Banner = ({
  title,
  text,
  action,
  actionText,
  color,
  extraContent,
  id,
  className,
}) => {
  return (
    <div
      id={id}
      className={`p-7 flex items-center bg-${color}-0 outline-b-2 outline-${color}-1 ${className}`}
    >
      <div className="flex-col flex-1">
        <span className={`font-md weight-600 no-wrap text-${color}-2`}>
          {title}
        </span>
        <span className={`font-sm weight-300 text-${color}-2`}>{text}</span>
      </div>

      {action && (
        <SolidButton3 onClick={action} color={color}>
          {actionText ? actionText : "Submit"}
        </SolidButton3>
      )}
      {extraContent}
    </div>
  );
};

export function BannerV2({ color, children, solid }) {
  return (
    <div
      className={`bg-${
        solid ? "base-2" : `${color}-0`
      } mb-4 rounded-5 p-5 ring-2 ring-offset-4 ring-${color}-1 animation-from-top`}
    >
      {children}
    </div>
  );
}
