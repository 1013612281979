import React from "react";

export function SmallLivePill({ className }) {
  return (
    <div
      className={`w-min px-3 rounded-4 bg-base-3 flex-row flex-center space-x-4 ${className}`}
    >
      <i
        className="bx bxs-circle bx-flashing text-success-1"
        style={{ fontSize: "6px" }}
      ></i>
      <span className="weight-400 text-success-1 font-xxs">Live</span>
    </div>
  );
}

export function LivePill({ className }) {
  return (
    <div
      className={`rounded-8 px-4 py-3 bg-base-2 flex-row flex-center space-x-4 h-min w-min ${className}`}
    >
      <i className="bx bxs-circle bx-flashing text-success-1 font-xxs"></i>
      <span className="weight-400 text-success-1 font-xs">Live</span>
    </div>
  );
}

export function SmallStatusPill({ className, color, text, hideDot }) {
  return (
    <div
      className={`w-min px-3 rounded-4 bg-base-3 flex-row flex-center space-x-4 ${className}`}
    >
      {!hideDot && (
        <i
          className={`bx bxs-circle ${
            text === "Live" && "bx-flashing"
          } text-${color}-1`}
          style={{ fontSize: "6px" }}
        />
      )}
      <span className={`weight-400 text-${color}-1 font-xxs`}>{text}</span>
    </div>
  );
}
