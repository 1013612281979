import React, { useCallback, useContext } from "react";
import {
  loginWallet,
  registerWallet,
  signMessage,
} from "../../services/wallet/LoginService";
import { Singletons } from "../../services/Singletons";
import { useCommonWallet } from "../../services/wallet/UseCommonWallet";
import { useDispatch } from "react-redux";
import bs58 from "bs58";

export interface WalletAuthContextProps {
  onConnect: (deviceId?: string) => void;
}

export const WalletAuthContext = React.createContext<WalletAuthContextProps>({
  onConnect(): void {},
});

export default function WalletAuthContextProvider({
  children,
  onSuccess,
}: {
  children: JSX.Element;
  onSuccess: () => void;
}) {
  const wallet = useCommonWallet();
  const dispatch = useDispatch();

  function handleError(e: unknown) {
    console.error(e);
    // @ts-ignore
    Singletons.toastService.error("Error", e.message ?? "Error");
  }

  const handleAuth = useCallback(
    async (publicKey: string, signedMessage: Uint8Array, deviceId: string) => {
      try {
        await loginWallet(
          publicKey,
          bs58.encode(signedMessage),
          dispatch,
          deviceId
        );
      } catch (e) {
        // @ts-ignore
        if (e?.response?.status === 404) {
          await registerWallet(
            publicKey,
            bs58.encode(signedMessage),
            dispatch,
            deviceId,
            null,
            null
          );
        } else handleError(e);
      }
    },
    [dispatch]
  );

  const onConnect = useCallback(
    async (deviceId) => {
      if (wallet.disconnecting || !wallet.connected) return;

      if (!wallet.signMessage)
        throw new Error(
          "Wallet does not support message signing, please use a different wallet provider"
        );

      try {
        const signedMessage = await signMessage(wallet);
        await handleAuth(wallet.publicKey?.toString(), signedMessage, deviceId);
        onSuccess();
      } catch (e) {
        handleError(e);
      }
    },
    [handleAuth, onSuccess, wallet]
  );

  return (
    <WalletAuthContext.Provider value={{ onConnect }}>
      {children}
    </WalletAuthContext.Provider>
  );
}

export const useWalletAuthContext = () => useContext(WalletAuthContext);
