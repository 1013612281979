import { useMediaQuery } from "@mui/material";
import { TransactionActionTypes } from "common/src/redux/reducers/Transaction";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, matchPath, useLocation } from "react-router-dom";
import { default as logoImg } from "../../../assets/LogoAlt.svg";
import TransactionModal from "../../modals/TransactionModal";
import { LoggedInHeader } from "./LoggedInHeader";
import { NotLoggedInHeader } from "./NotLoggedInHeader";
import TermsAcceptSheet from "../../modals/TermsAcceptSheet";
import { useUser } from "common/src/hooks/useUser";

export default function HeaderDesktop() {
  const location = useLocation();
  const user = useUser();
  const dispatch = useDispatch();

  const isLanding = matchPath(location.pathname, {
    path: "",
    exact: true,
    strict: false,
  });

  const [showHeader, setShowHeader] = useState(false);

  function handleScroll() {
    let scrollTop = window.scrollY;
    if (scrollTop > 30) {
      setShowHeader(true);
    } else if (scrollTop <= 30) {
      setShowHeader(false);
    }
  }

  useEffect(() => {
    dispatch({
      type: TransactionActionTypes.NO_OP,
    });
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const screenIsSmall = useMediaQuery("(max-width:1100px)");

  if (!isLanding) {
    return null;
  }
  return (
    <div className="">
      <TermsAcceptSheet />
      <nav
        className={`l-0 r-0 t-0  fixed z-12 ${
          showHeader && "glass shadow-3"
        } flex-col transition-long`}
      >
        <div className={"flex-row items-center"}>
          <div className={`relative w-full flex-row items-center pb-4 pt-4`}>
            <Link to="/" className="decoration-none">
              <div className="items-center flex mr-8 ml-5">
                <img src={logoImg} alt="logo" height="40" />
                {!screenIsSmall && (
                  <span className="font-md weight-300 text-neutral-2 letter-spacing-lg flex line-height-md">
                    PHANTASIA
                  </span>
                )}
              </div>
            </Link>

            {!user._id ? (
              <NotLoggedInHeader />
            ) : (
              <LoggedInHeader screenIsSmall={screenIsSmall} />
            )}
          </div>
        </div>
      </nav>
      <TransactionModal />
    </div>
  );
}
