import { combineReducers } from "redux";
import Chat from "./Chat";
import Modal from "./Modal";
import Roster from "./Roster";
import Transaction from "./Transaction";
import User from "./User";
import Wallet from "./Wallet";
import LoginUrl from "./LoginUrl";
import AuthMethod from "./AuthMethod";

export default combineReducers({
  User,
  Wallet,
  Transaction,
  Modal,
  Roster,
  Chat,
  LoginUrl,
  AuthMethod,
});
