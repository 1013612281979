import { Modal } from "@mui/material";
import { GradientButton3 } from "../atoms/Button";
import { HeaderContext } from "common/src/context/HeaderContext";
import React, { useContext, useState } from "react";
import { Singletons } from "common/src/services/Singletons";
import Switch from "../atoms/Switch";
import { HorizontalDivider } from "../atoms/Divider";
import { Input } from "../atoms/Input";
import { checkExistingUserInfo } from "common/src/services/UserService";
import { useUser } from "common/src/hooks/useUser";
import { addUserReferral } from "common/src/services/AuthService";
import { UserTypes } from "common/src/redux/actions/types";
import { useDispatch } from "react-redux";

export default function TermsAcceptSheet() {
  const { SET_USER } = UserTypes;

  const { termsAccepted, userTermsAccepted } = useContext(HeaderContext);

  const [termsAccept, setTermsAccept] = useState(false);
  const [username, setUsername] = useState("");
  const [referral, setReferral] = useState("");
  const [formError, setFormError] = useState(null);

  const user = useUser();
  const dispatch = useDispatch();

  async function handleSubmit() {
    if (!username) {
      setFormError(1);
      Singletons.toastService.error("No username", "Please add a username");
      return;
    }
    if (!termsAccept) {
      setFormError(2);
      Singletons.toastService.error(
        "Accept terms",
        "Please accept terms and conditions"
      );
      return;
    }

    const isUnique = await checkExistingUserInfo(username);
    if (!isUnique) {
      setFormError(1);
      Singletons.toastService.error("Change username", "Username is taken...");
      return;
    }
    await userTermsAccepted(username);
    if (referral) {
      try {
        const res = await addUserReferral(referral);
        if (res) {
          dispatch({
            type: SET_USER,
            payload: { ...user, referral: res.data },
          });
          Singletons.toastService.success("Success", "Referral added!");
        }
      } catch (e) {
        console.error(e.response.data.msg);
        Singletons.toastService.error("Error", e.response.data.msg);
      }
    }
  }

  return (
    <Modal
      open={!termsAccepted}
      className="flex flex-col flex-center outline-none modal"
      onClose={() => {}}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <div
        className={`max-h-9/10 h-min p-8 max-w-30 w-1/2 flex-col h-full animation-from-bottom bg-base-1 outline-1 rounded-8 overflow-hidden outline-none`}
      >
        <div className="flex-col space-y-7">
          <span className={"text-neutral-2 font-xl weight-500"}>
            Welcome to Phantasia!
          </span>
          <HorizontalDivider />
          <div className={"flex-col mb-5"}>
            <Input
              showError={formError === 1}
              onFocus={() => {
                if (formError) setFormError(false);
              }}
              id="username-input"
              value={username}
              label={"Username"}
              placeholder={"Your in-game name - make it stand out!"}
              icon={<i className="bx bx-user-circle text-neutral-2" />}
              onChange={(e) => setUsername(e.target.value.trim())}
              classes={"bg-base-2"}
            />
            <div className={"flex-row items-baseline space-x-3 mt-4 ml-5"}>
              <span className={"text-neutral-1 weight-300 font-xs"}>
                Or use a random one, like
              </span>
              <span
                onClick={() => setUsername(user.username)}
                className={
                  "text-primary-1 cursor-pointer hover:text-neutral-2 weight-400 font-sm"
                }
              >
                {user.username}
              </span>
            </div>
          </div>
          <Input
            label={"Referral Code (optional)"}
            id="referral-input"
            value={referral}
            placeholder={"ULTIMATE-PHAN-73"}
            icon={<i className="bx bx-barcode text-neutral-2 mb-5" />}
            onChange={(e) => setReferral(e.target.value.trim().toUpperCase())}
            classes={"bg-base-2 mb-5"}
          />
          <div className={"flex-row items-center space-x-7"}>
            <span className="font-xs text-neutral-2 weight-300 flex-1">
              I have read and agree to Phantasia's new{" "}
              <a
                className="text-primary-1 weight-500 font-sm"
                href="https://tosandprivacy.s3.amazonaws.com/Online+Terms+and+Conditions.pdf"
                target="_blank"
                rel="noreferrer"
              >
                Terms and Conditions
              </a>{" "}
              and{" "}
              <a
                className="text-primary-1 weight-500 font-sm"
                href="https://tosandprivacy.s3.amazonaws.com/Online+Privacy+Policy.pdf"
                target="_blank"
                rel="noreferrer"
              >
                Privacy Policy
              </a>{" "}
              and understand that I am agreeing to binding arbitration and to
              waive certain class action rights consistent with their provisions
            </span>
            <Switch
              className={""}
              value={termsAccept}
              setValue={() => setTermsAccept(!termsAccept)}
            />
          </div>
          <GradientButton3
            id="accept-terms"
            className={"w-full"}
            onClick={handleSubmit}
            color="primary"
          >
            Let's go!
          </GradientButton3>
        </div>
      </div>
    </Modal>
  );
}
