import { League, PropBonusType, PropKey } from "@phantasia/model-interfaces";
import React, {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { getBoardForLeague } from "../../services/PicksService";
import {
  GameWithTeams,
  PopulatedPickProp,
  PopulatedSlipLeg,
} from "../../services/dtos/ContestDtos";

interface PicksBoardContextProps {
  filteredPicks: PopulatedPickProp[] | null;
  selectedLeague: League;
  setSelectedLeague: (league: League) => void;
  selectedGame: GameWithTeams | null;
  setSelectedGame: (game: GameWithTeams | null) => void;
  gameFilters: GameWithTeams[];
  propKeyFilters: PropKey[];
  selectedPropKey: PropKey | null;
  setSelectedPropKey: (key: PropKey | null) => void;
  searchQuery: string;
  setSearchQuery: (query: string) => void;
  refresh: () => void;
  boostFilter: boolean;
  setBoostFilter: (boost: boolean) => void;
  findChangedOrRemovedProps: (currentSlip: PopulatedSlipLeg[]) => {
    changed: PopulatedPickProp[];
    removed: PopulatedPickProp[];
  };
  findPropToAdd: (propId: string) => PopulatedPickProp | undefined;
}

export const PicksBoardContext = React.createContext<PicksBoardContextProps>({
  findPropToAdd(propId: string): PopulatedPickProp | undefined {
    return undefined;
  },
  findChangedOrRemovedProps(): {
    changed: PopulatedPickProp[];
    removed: PopulatedPickProp[];
  } {
    return { changed: [], removed: [] };
  },
  filteredPicks: [],
  selectedLeague: League.NFL,
  setSelectedLeague: () => {},
  selectedGame: null,
  setSelectedGame: () => {},
  gameFilters: [],
  propKeyFilters: [],
  selectedPropKey: null,
  setSelectedPropKey: () => {},
  searchQuery: "",
  setSearchQuery: () => {},
  refresh: () => {},
  boostFilter: true,
  setBoostFilter: () => {},
});

export function PicksBoardContextProvider({
  children,
}: {
  children: ReactNode;
}) {
  const [availablePicks, setAvailablePicks] = useState<
    PopulatedPickProp[] | null
  >(null);
  const [selectedLeague, setSelectedLeague] = useState<League>(League.NFL);
  const [gameFilters, setGameFilters] = useState<GameWithTeams[]>([]);
  const [selectedGame, setSelectedGame] = useState<GameWithTeams | null>(null);
  const [selectedPropKey, setSelectedPropKey] = useState<PropKey | null>(null);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [boostFilter, setBoostFilter] = useState<boolean>(true);

  const refresh = useCallback(() => {
    setSelectedGame(null);
    getBoardForLeague(selectedLeague).then((res) => {
      setAvailablePicks(res.data.props);

      setGameFilters(res.data.games);
    });
  }, [selectedLeague]);

  useEffect(() => {
    refresh();
  }, [refresh, selectedLeague]);

  const filteredPicks = useMemo(() => {
    if (!availablePicks || availablePicks.length === 0) return availablePicks;
    let tempPicks = [...availablePicks];
    if (searchQuery) {
      return tempPicks.filter((pick) =>
        pick.player.name.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }
    if (selectedGame) {
      setSearchQuery("");
      tempPicks = tempPicks.filter(
        (pick) => pick.game._id === selectedGame._id
      );
    }
    if (selectedPropKey && !boostFilter) {
      setSearchQuery("");
      if (selectedPropKey === PropKey.FANTASY_POINTS) {
        return tempPicks.filter(
          (pick) =>
            // @ts-ignore
            pick.prop_key === selectedPropKey && pick.player.position !== "PK"
        );
      } else
        return tempPicks.filter((pick) => pick.prop_key === selectedPropKey);
    }
    if (boostFilter) {
      setSearchQuery("");
      return tempPicks.filter(
        (pick) => pick.bonus_type === PropBonusType.Bonus
      );
    }
    return [];
  }, [availablePicks, selectedGame, selectedPropKey, searchQuery, boostFilter]);

  const propKeyFilters = useMemo(() => {
    if (!availablePicks || availablePicks.length === 0) return [];
    const keys = availablePicks.map((prop) => {
      return prop.prop_key;
    });
    return [...Array.from(new Set(keys))];
  }, [availablePicks]);

  // useEffect(() => {
  //   if (propKeyFilters.length > 1 && !selectedPropKey)
  //     setSelectedPropKey(propKeyFilters[0]);
  //   if (selectedPropKey && !propKeyFilters.includes(selectedPropKey)) {
  //     setSelectedPropKey(propKeyFilters[0]);
  //   }
  // }, [propKeyFilters, selectedPropKey]);

  const findChangedOrRemovedProps = useCallback(
    (currentSlip: PopulatedSlipLeg[]) => {
      if (!availablePicks) return { changed: [], removed: [] };

      /* Props where value has changed */
      const changed = availablePicks.filter((pick) =>
        currentSlip.find(
          (p) =>
            p.prop._id.toString() === pick._id.toString() &&
            p.value !== pick.value
        )
      );

      /* Props that are now removed */
      const removed = currentSlip
        .map((leg) => leg.prop)
        .filter(
          (p) =>
            p.league === selectedLeague &&
            !availablePicks.find(
              (pick) => pick._id.toString() === p._id.toString()
            )
        );
      return { changed, removed };
    },
    [availablePicks, selectedLeague]
  );

  const findPropToAdd = useCallback(
    (propId: string) => {
      if (!availablePicks) return;
      return availablePicks.find((p) => p._id === propId);
    },
    [availablePicks]
  );

  return (
    <PicksBoardContext.Provider
      value={{
        filteredPicks,
        propKeyFilters,
        selectedPropKey,
        setSelectedPropKey,
        selectedGame,
        setSelectedGame,
        gameFilters,
        setSelectedLeague,
        selectedLeague,
        searchQuery,
        setSearchQuery,
        refresh,
        boostFilter,
        setBoostFilter,
        findChangedOrRemovedProps,
        findPropToAdd,
      }}
    >
      {children}
    </PicksBoardContext.Provider>
  );
}

export const usePicksBoard = () => useContext(PicksBoardContext);
