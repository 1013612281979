import { NavDrawer } from "../../components/layout/header/NavDrawer";
import { Link, useLocation } from "react-router-dom";
import { ROUTES } from "../../Routes";
import { LivePicksPage } from "./LivePicksPage";
import { PicksResultsPage } from "./PicksResultsPage";
import { PicksBoardPage } from "./PicksBoardPage";
import { PicksIntroModal } from "../../components/modals/PicksIntroModal";

export function PicksPage() {
  const location = useLocation();

  const isLive = location.pathname === ROUTES.PICKS_LIVE;
  const isResults = location.pathname === ROUTES.PICKS_RESULTS;

  let barStyle = "0%";

  if (isLive) barStyle = "33.33%";
  if (isResults) barStyle = "66.66%";

  const tabs = [
    {
      active: !isLive && !isResults,
      path: ROUTES.PICKS_BOARD,
      text: "Board",
    },
    {
      active: isLive,
      path: ROUTES.PICKS_LIVE,
      text: "Live Entries",
    },
    {
      active: isResults,
      path: ROUTES.PICKS_RESULTS,
      text: "Results",
    },
  ];

  return (
    <div className="h-screen w-screen flex-row space-x-8 bg-base-1">
      <PicksIntroModal />
      <NavDrawer />
      <div className={"flex-col flex-1 outline-l-1 pl-1 h-screen relative"}>
        <div className={"px-8 py-4 bg-primary-1 flex-row items-center"}>
          <span className={"white weight-500 font-md mr-3"}>
            Phantasia Picks is in Beta
          </span>
          <span className={"white weight-400 font-md opacity-90 mr-5"}>
            - please share all feedback with the team
          </span>
          {/*<SupportTextButton className={undefined} />*/}
          <div className={"flex-1"} />
        </div>
        <div className={"bg-base-1 outline-b-1 flex-row relative"}>
          {tabs.map((tab, index) => (
            <TabButton {...tab} key={index} />
          ))}

          <div
            style={{
              left: barStyle,
            }}
            className={`h-2 w-1/3 flex flex-center absolute transition-long b-0 z-11`}
          >
            <div className={"rounded-full w-16 h-2 bg-primary-1"} />
          </div>
        </div>
        {isLive ? (
          <LivePicksPage />
        ) : isResults ? (
          <PicksResultsPage />
        ) : (
          <PicksBoardPage />
        )}
      </div>
    </div>
  );
}

function TabButton({
  active,
  path,
  text,
}: {
  active: boolean;
  path: string;
  text: string;
}) {
  return (
    <Link
      className={`rounded-5 cursor-pointer p-6 hover:bg-base-2 flex-1 transition flex flex-center decoration-none`}
      to={{
        pathname: path,
        state: {},
      }}
    >
      <span
        className={`${
          active ? "text-neutral-2 weight-500" : "text-neutral-1 weight-400"
        } font-lg transition`}
      >
        {text}
      </span>
    </Link>
  );
}
