export const REDIRECT_SEARCH_PARAM = "redirect";

export function getRedirectPath(): string | null {
  const params = new URLSearchParams(window.location.search);
  return params.get(REDIRECT_SEARCH_PARAM);
}

export function doRedirectOrHome(history: { push: (path: string) => void }) {
  const redirectPath = getRedirectPath();
  if (redirectPath) {
    history.push(redirectPath);
  } else {
    history.push("/lobby");
  }
}
