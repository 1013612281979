/* eslint-disable no-restricted-globals */
import { SwipeableDrawer } from "@mui/material";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import logo from "../../assets/LogoAlt.svg";
import { LinkButton3, TextButton2 } from "../atoms/Button";

export function MobileAdModal({ toggleBottomSheet, bottomSheetAnchor }) {
  /**
   * Determine the mobile operating system.
   * This function returns one of 'iOS', 'Android', 'Windows Phone', or 'unknown'.
   *
   * @returns {String}
   */

  const location = useLocation();
  const pathname = location.pathname;

  const routesToHideModal = [
    "/",
    "/waitlist",
    "/shop",
    "/login",
    "/aaron-jones-nft",
    "/leagues",
    "/withdraw",
  ];

  useEffect(() => {
    if (pathname)
      if (!routesToHideModal.includes(pathname)) {
        if (getMobilePlatformFromUserAgent() === "ios")
          window.location.replace(getMobileOperatingSystem());
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <>
      {routesToHideModal.includes(pathname) ? (
        <></>
      ) : (
        <SwipeableDrawer
          anchor={"bottom"}
          open={bottomSheetAnchor ?? false}
          onClose={toggleBottomSheet(false)}
          onOpen={() => toggleBottomSheet(true)}
        >
          <div className="relative h-31 bg-base-1 flex-col flex-center px-6 max-w-full">
            <div className="rounded-11 p-8 flex-col bg-base-2 flex-center mt-15">
              <span className="text-neutral-2 weight-300 font-sm">
                For the best experience...
              </span>
              <span className="text-neutral-2 weight-500 font-lg text-center mt-5">
                Download the Phantasia Mobile app!
              </span>
              <div className="rounded-6 p-5 flex flex-center bg-base-1 mt-6">
                <img src={logo} className="h-11 w-11" alt="logo" />
              </div>
              <LinkButton3
                link={getMobileOperatingSystem()}
                color="primary"
                className={"w-full mt-5 rounded-3 rounded-bottom-8"}
              >
                Download Now
              </LinkButton3>
            </div>
            <div className="flex-grow" />
            <TextButton2 className={"mb-11"} onClick={toggleBottomSheet(false)}>
              No, I want to use my browser
            </TextButton2>
          </div>
        </SwipeableDrawer>
      )}
    </>
  );
}

export function getMobileOperatingSystem() {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  if (/android/i.test(userAgent)) {
    return "https://play.google.com/store/apps/details?id=com.phantasia";
  }

  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "https://apps.apple.com/us/app/phantasia-sports/id1608431296";
  }

  return "unknown";
}

export function getMobilePlatformFromUserAgent() {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  if (/android/i.test(userAgent)) {
    return "android";
  }

  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "ios";
  }

  return null;
}
