import { WalletTypes } from "./types";
import "react-native-get-random-values";
import { Singletons } from "../../services/Singletons";
import { AccountInfo, PublicKey } from "@solana/web3.js";
import { Dispatch } from "redux";
import { ACCOUNT_SIZE } from "@solana/spl-token";
import BN from "bn.js";
import { getCoinflowApi } from "../../util/api";
import {
  ConnectionService,
  findAssociatedTokenAddress,
  Pubkeys,
} from "@phantasia/model-interfaces";

export async function loadBalance(
  wallet: PublicKey,
  dispatch: Dispatch
): Promise<void> {
  if (!wallet) return;

  await Promise.all([
    setTokenBalances(wallet, dispatch),
    setSolBalance(wallet, dispatch),
  ]);
}

async function setSolBalance(walletPubkey: PublicKey, dispatch: Dispatch) {
  try {
    const solBalance = await ConnectionService.getConnection().getBalance(
      walletPubkey
    );
    dispatch({ type: WalletTypes.SET_SOL_BALANCE, payload: solBalance });
  } catch (e) {
    dispatch({ type: WalletTypes.SET_SOL_BALANCE, payload: 0 });
    console.error(e);
  }
}

async function setTokenBalances(walletPubkey: PublicKey, dispatch: Dispatch) {
  try {
    const tokenBalances = await getTokenBalances(walletPubkey);
    dispatch({
      type: WalletTypes.SET_FANT_BALANCE,
      payload: tokenBalances.fant,
    });
    dispatch({
      type: WalletTypes.SET_USDC_BALANCE,
      payload: tokenBalances.usdc,
    });
    dispatch({
      type: WalletTypes.SET_SFANT_BALANCE,
      payload: tokenBalances.sFant,
    });
    dispatch({
      type: WalletTypes.SET_CREDIT_BALANCE,
      payload: tokenBalances.fanUsd,
    });
  } catch (err) {
    dispatch({ type: WalletTypes.SET_FANT_BALANCE, payload: 0 });
    dispatch({ type: WalletTypes.SET_USDC_BALANCE, payload: 0 });
    dispatch({ type: WalletTypes.SET_SFANT_BALANCE, payload: 0 });
    // dispatch({ type: WalletTypes.SET_CREDIT_BALANCE, payload: 0 });
    Singletons.toastService.error(
      "Network Error",
      "You're internet seems to be weak. We can't get your wallet info."
    );
    console.error(err);
  }
}

async function getTokenBalances(walletPubkey: PublicKey) {
  const { data } = await getCoinflowApi().get(
    `https://api.coinflow.cash/api/customer/balances`
  );

  const atas = await getTokenAccountPubkeys(walletPubkey, [
    Pubkeys.getFantMint(),
    Pubkeys.getUsdcMint(),
    Pubkeys.getSfantMint(),
  ]);

  const connection = ConnectionService.getConnection();
  const tokenInfo = await connection.getMultipleAccountsInfo(atas);

  const amounts = tokenInfo.map(parseTokenAccountAmount);
  return {
    fant: amounts[0],
    usdc: amounts[1],
    sFant: amounts[2],
    fanUsd: data.credits.cents,
  };
}

async function getTokenAccountPubkeys(
  walletPubkey: PublicKey,
  mints: PublicKey[]
): Promise<PublicKey[]> {
  return Promise.all(
    mints.map((mint) => findAssociatedTokenAddress(walletPubkey, mint))
  );
}

function parseTokenAccountAmount(account: AccountInfo<Buffer> | null): number {
  try {
    if (!account) return 0;
    if (account.data.length !== ACCOUNT_SIZE) return 0;
    const slice = account.data.slice(64, 64 + 8);
    const rawAmount = Number(new BN(slice.reverse()));
    const uiAmount = rawAmount / Math.pow(10, 6);
    return Number(Math.floor(uiAmount * 100) / 100);
  } catch (e) {
    console.error("Error parsing token account amount", e);
    return 0;
  }
}
