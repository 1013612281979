import { ReactNode } from "react";
import { Tooltip } from "@mui/material";

export function CustomMenuItem({
  onClick,
  children,
}: {
  onClick: () => void;
  children: ReactNode;
}) {
  return (
    <div
      className="weight-300 hover-down hover:bg-base-2 rounded-5 cursor-pointer text-neutral-2 hover-dark font-md flex-row space-x-5 items-center p-5 w-full transition"
      onClick={() => {
        if (onClick) onClick();
      }}
    >
      {children}
    </div>
  );
}

export function CustomNavDrawerItem({
  onClick,
  children,
  icon,
  active,
  small,
  extraContent,
}: {
  onClick: () => void;
  children: ReactNode;
  icon: string;
  active?: boolean;
  small?: boolean;
  extraContent?: ReactNode;
}) {
  if (small) {
    return (
      <div
        onClick={() => {
          if (onClick) onClick();
        }}
        className={`flex flex-center h-10 min-w-10 mb-4 rounded-4 ${
          active ? "bg-primary-0" : null
        } flex-row items-center  space-x-5 w-full cursor-pointer py-6`}
      >
        <Tooltip title={children} placement="right">
          <span>
            <i
              className={`bx ${icon} ${
                active ? "text-primary-1" : "text-neutral-2"
              } hover:text-primary-1`}
            />
          </span>
        </Tooltip>
      </div>
    );
  }
  return (
    <div
      onClick={() => {
        if (onClick) onClick();
      }}
      className={`flex-row items-center group space-x-5 w-full cursor-pointer py-6 relative`}
    >
      <i
        className={`bx ${icon} ${
          active ? "text-primary-1" : "text-neutral-2"
        } group-hover:text-primary-1`}
      />

      <span
        className={`flex-1  font-md ${
          active ? "text-primary-1 weight-500" : "text-neutral-2 weight-400"
        } group-hover:text-primary-1 `}
      >
        {children}
      </span>
      {extraContent}
      {active ? (
        <div
          className={
            "bg-gradient-primary h-4 w-4 rounded-full absolute-middle-horizontal l--10"
          }
        />
      ) : null}
    </div>
  );
}
