import React from "react";

export function PageHeader({ children, className }) {
  return (
    <h1
      className={`letter-spacing-md font-lg weight-400 text-neutral-2 animation-reveal no-wrap ${className}`}
    >
      {children}
    </h1>
  );
}

export function Header4({ children, className }) {
  return (
    <span
      className={`font-sm letter-spacing-sm weight-300 text-neutral-1  ${className}`}
    >
      {children}
    </span>
  );
}

export function IconHeader1({ className, secondary, icon, children }) {
  return (
    <div className={`flex-row items-center space-x-4 ${className}`}>
      <i className={`bx ${icon} text-neutral-${secondary ? "1" : "2"}`} />
      <span
        className={`weight-300 letter-spacing-md uppercase text-neutral-${
          secondary ? "1" : "2"
        } font-xs`}
      >
        {children}
      </span>
    </div>
  );
}
