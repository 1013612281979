import { Modal } from "@mui/material";
import { OutlineButton3 } from "../atoms/Button";
import creditsLogo from "../../assets/fant-credit-logo.png";
import fantLogo from "../../assets/LogoMain.png";
import { PrizeTypes } from "../../pages/DailySpin";

export function RewardModal({ open, onClose, prize }) {
  if (!prize) return null;
  return (
    <Modal
      open={open}
      className="flex flex-col flex-center outline-none modal"
      onClose={() => onClose()}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <div className="outline-none flex-center bg-base-1 p-12 flex-col animation-from-bottom max-h-50-pct max-w-25-pct over-raised scroll-y rounded-6 max-h-95-pct">
        {prize && prize.value > 0 ? (
          <>
            <div className="flex-col space-y-4 flex-center">
              <div
                className={
                  "bg-success-0 rounded-full w-14 h-14 ring-success-1 ring-2 flex flex-center"
                }
              >
                <i className="bx bx-gift text-success-1 bx-sm" />
              </div>
              <span className="font-xl weight-500 text-neutral-2">
                Congratulations, you won a prize!
              </span>
            </div>
            <div className="flex-row outline-1 rounded-8 w-full h-15 flex-center my-6">
              {prize && prize.type === PrizeTypes.CREDITS ? (
                <img src={creditsLogo} className="h-10 w-10" alt="pattern44" />
              ) : prize && prize.type === PrizeTypes.FANT ? (
                <img src={fantLogo} className="h-10 w-10" alt="pattern44" />
              ) : null}
              <span className="font-3xl text-neutral-2 letter-spacing-sm weight-500 ml-3">
                {!prize ? "?" : prize.label}
              </span>
            </div>
            <div className="font-md weight-300 text-neutral-1 mt-5 max-w-30 text-center">
              You won {prize.label}! Your prize should be reflected in your
              balances shortly. Try again tomorrow for more prizes!
            </div>
          </>
        ) : (
          <>
            <div className="flex-col space-y-7 flex-center">
              <div
                className={
                  "bg-danger-0 rounded-full w-14 h-14 ring-danger-1 ring-2 flex flex-center"
                }
              >
                <i className="bx bx-x-circle text-danger-1 bx-sm" />
              </div>

              <span className="font-xl weight-500 text-neutral-2 mb-1">
                No prize today...
              </span>
            </div>
            <div className="font-md weight-200 text-neutral-1 mt-3">
              Try again tomorrow for more prizes!
            </div>
          </>
        )}
        <OutlineButton3
          className="mt-10 w-20 rounded-full bg-base-1"
          color="neutral"
          onClick={() => onClose()}
        >
          Close
        </OutlineButton3>
      </div>
    </Modal>
  );
}
