import { Link, useHistory, useLocation } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import TermsAcceptSheet from "../../modals/TermsAcceptSheet";
import { default as logoImg } from "../../../assets/LogoAlt.svg";
import TransactionModal from "../../modals/TransactionModal";
import { ReactNode, useEffect, useMemo, useState } from "react";
import { CustomNavDrawerItem } from "../../atoms/CustomMenuItem";
import { DesktopHeaderMenu } from "./DesktopHeaderMenu";
import { HorizontalDivider, HorizontalDividerInDiv } from "../../atoms/Divider";
import { ROUTES } from "../../../Routes";
import Wallet from "../../wallet/Wallet";
import { DepositMenu } from "../../payments/DepositMenu";
import { NotificationsMenu } from "./NotificationsMenu";
import { useUser } from "common/src/hooks/useUser";
import HeaderContextProvider from "common/src/context/HeaderContext";
import moment from "moment";

export function NavDrawer({
  children = <></>,
  className,
}: {
  children?: ReactNode;
  className?: string;
}) {
  const screenIsSmall = useMediaQuery("(max-width:1280px)");

  if (screenIsSmall) {
    return (
      <div className={"relative h-screen w-14 min-w-14"}>
        <div
          className={`fixed l-0 b-0 t-0 w-14 min-w-14 flex-col group bg-base-1 items-center py-8 space-y-6 ${className}`}
        >
          <HeaderContextProvider>
            <TermsAcceptSheet />
          </HeaderContextProvider>
          <TransactionModal />
          <img src={logoImg} alt="logo" height="40" />
          <NotificationsMenu />
          <WheelSpinButton />
          <DesktopHeaderMenu screenIsSmall={true} />
          <HorizontalDividerInDiv className={"w-full h-min max-h-1 px-5"} />
          <NavLinks screenIsSmall={true} />
          <div className={"flex-1"} />
          <DepositMenu small={true} />
        </div>
      </div>
    );
  }

  return <FullNavMenu className={className}>{children}</FullNavMenu>;
}

function WheelSpinButton() {
  const user = useUser();
  const history = useHistory();
  const canSpin = useMemo(() => {
    if (!user.spinTimestamp) {
      return true;
    }
    const lastSpin = new Date(user.spinTimestamp);
    const nextSpin = moment.utc().subtract(24, "hours").toDate();
    return nextSpin >= lastSpin;
  }, [user]);
  return (
    <div
      className="relative flex flex-center w-10 h-10 rounded-full bg-base-2 cursor-pointer hover-up transition"
      onClick={() => {
        history.push(ROUTES.DAILY_SPIN);
      }}
    >
      {canSpin && (
        <div className=" absolute t-1 r-1 bg-success-1 ring-4 ring-base-1 flex flex-center w-5 h-5 rounded-full" />
      )}
      <i className="bx bx-gift text-neutral-2" />
    </div>
  );
}

function FullNavMenu({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) {
  return (
    <div className={`relative h-screen min-w-26 w-26 `}>
      <div
        className={`bg-base-1 h-screen max-h-screen flex-col fixed l-0 b-0 t-0 min-w-26 w-26 ${className}`}
      >
        <HeaderContextProvider>
          <TermsAcceptSheet />
        </HeaderContextProvider>
        <nav className={`z-12 flex-col transition-long flex-1 overflow-auto`}>
          <div
            className={`relative w-full flex-row items-center space-x-3 pt-4 pl-6 pr-8 pt-8`}
          >
            <Link to="/" className="decoration-none flex-1">
              <div className="items-center flex mr-8">
                <img src={logoImg} alt="logo" height="40" />

                <span className="font-md weight-400 text-neutral-2 letter-spacing-lg flex line-height-md">
                  PHANTASIA
                </span>
              </div>
            </Link>
            <NotificationsMenu />
            <WheelSpinButton />
          </div>
          <div className={`p-8 flex-1 flex-col overflow-auto`}>
            <DesktopHeaderMenu screenIsSmall={undefined} />
            <HorizontalDivider className={"mt-6 mb-3 max-h-1"} />
            <div className={"flex-col  flex-1"}>
              <NavLinks />
              {children}
            </div>

            <div className={"flex-row space-x-4"}>
              <Wallet small />
              <DepositMenu small={undefined} />
            </div>
          </div>
        </nav>

        <TransactionModal />
      </div>
    </div>
  );
}

function NavLinks({ screenIsSmall }: { screenIsSmall?: boolean }) {
  const [active, setActive] = useState<string>("");

  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (
      location.pathname.includes(ROUTES.PICKS_RESULTS) ||
      location.pathname.includes(ROUTES.PICKS_LIVE) ||
      location.pathname.includes(ROUTES.PICKS_BOARD)
    )
      setActive(ROUTES.PICKS_BOARD);

    if (location.pathname.includes(ROUTES.STAKING)) setActive(ROUTES.STAKING);
  }, [location.pathname]);

  return (
    <div className={"relative flex-col"}>
      <CustomNavDrawerItem
        small={screenIsSmall}
        active={active === ROUTES.PICKS_BOARD}
        icon={"bx-collection"}
        extraContent={
          <span
            className={
              "bg-gradient-primary rounded-full px-4 py-1 white font-xs"
            }
          >
            New
          </span>
        }
        onClick={() => history.push(ROUTES.PICKS_BOARD)}
      >
        Phantasia Picks
      </CustomNavDrawerItem>

      <CustomNavDrawerItem
        small={screenIsSmall}
        active={active === ROUTES.STAKING}
        icon={"bx-lock-alt"}
        onClick={() => history.push(ROUTES.STAKING)}
      >
        Staking
      </CustomNavDrawerItem>
    </div>
  );
}
