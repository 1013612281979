/* eslint-disable import/first */
import { initializeConnections } from "common/src";

const connectionString = initializeConnections(
  process.env.REACT_APP_SOLANA_NET,
  process.env.REACT_APP_RPC_URL
);

import React from "react";
import App from "./App.jsx";
import { Magic } from "magic-sdk";
import { SolanaExtension } from "@magic-ext/solana";
import { Singletons } from "common/src/services/Singletons";
import { toastError, toastSuccess } from "./components/toast/ToastService";
import { store } from "./store";

const magic = new Magic("pk_live_19EA833CD6BEB4E4", {
  extensions: [
    new SolanaExtension({
      rpcUrl: connectionString,
    }),
  ],
});

const toastService = {
  error: toastError,
  success: toastSuccess,
};

const apiUrl = process.env.REACT_APP_API_URL;
Singletons.set({ magic, toastService, store, apiUrl });

import { createRoot } from "react-dom/client";
const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<App tab="home" />);
