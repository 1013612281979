import React from "react";

export const Option = ({ value, label, selected }) => {
  return (
    <option
      className={` ${
        selected === value ? " font-md select-option-selected" : "select-option"
      }`}
      value={value}
    >
      {label}
    </option>
  );
};
