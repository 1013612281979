import { SlipLegWithPerformanceAndProjection } from "common/src/services/dtos/ContestDtos";

import { FormattedSmallPlayerGame } from "../board/BoardMyPicksSection";
import { gameIsFinal, getPropKeyShortName } from "common/src/util/helpers";
import {
  PlayerGamePerformance,
  PropBonusType,
  PropDecision,
} from "@phantasia/model-interfaces";
import {
  getFinalPropResult,
  getPropPerformanceStatus,
} from "common/src/services/PlayerPropsService";
import ProgressBar from "../../atoms/ProgressBar";
import { PickResultIcon, PicksDecisionIndicator } from "./SlipListItem";
import { getPlayerStatHighlights } from "common/src/services/GameScoreService";
import { PicksBonusArrow } from "../utils/PicksBonusArrow";

export function SlipLegListItem({
  leg,
  loading,
  multiplier,
  minimize,
}: {
  leg: SlipLegWithPerformanceAndProjection;
  loading?: boolean;
  multiplier?: number;
  minimize?: boolean;
}) {
  const { prop, result, prop_decision, performance, value, bonus_type } = leg;
  const { player, game, prop_key } = prop;

  if (minimize) {
    return (
      <div className={"flex-col overflow-visible"}>
        <div className={"flex-row items-center"}>
          <div
            className={
              "w-10 h-10 rounded-full flex flex-center bg-base-4 relative"
            }
          >
            <img
              src={player.photo}
              alt={"player"}
              className={"contain h-full rounded-full"}
            />
            {multiplier ? (
              <div
                className={
                  "absolute flex flex-center b--4 rounded-full bg-base-2 ring-2 ring-base-1 px-4 h-6"
                }
              >
                <span className={"text-primary-1 font-xs weight-600"}>
                  {multiplier}x
                </span>
              </div>
            ) : null}
          </div>
          <div className={"flex-col items-end  flex-1"}>
            <span className={"font-lg weight-600 text-neutral-2"}>{value}</span>
            <span className={"font-xs weight-300 text-neutral-1"}>
              {getPropKeyShortName(prop_key)}
            </span>
          </div>
          <div className={"flex-col items-center"}>
            <PicksDecisionIndicator
              decision={prop_decision}
              rotate={prop_decision === PropDecision.UNDER}
              result={result}
            />
          </div>
        </div>
        <div className={"flex-row space-x-6 py-6 overflow-visible"}>
          <div className={"flex-col flex-1 overflow-visible"}>
            <div className={"flex-row items-center space-x-4"}>
              <span className={"font-sm weight-500 text-neutral-2"}>
                {player.name}
              </span>
              <PickResultIcon result={result} returnNull />
            </div>
            <span className={"font-sm weight-400 text-neutral-1"}>
              {`${player.position}  •  ${player.team}`}
            </span>
            <FormattedSmallPlayerGame game={game} player={player} />

            <span className={"font-xs weight-300 text-neutral-1"}>
              {getPlayerStatHighlights(
                performance,
                player.position,
                prop.league
              )}
            </span>

            <ProgressBars leg={leg} loading={loading} />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={"flex-col overflow-visible"}>
      <div className={"flex-row space-x-6 py-6 overflow-visible"}>
        <div
          className={
            "w-11 h-11 rounded-full flex flex-center bg-base-4 relative"
          }
        >
          <img
            src={player.photo}
            alt={"player"}
            className={"contain h-full rounded-full"}
          />
          {bonus_type === PropBonusType.Bonus && !multiplier ? (
            <div
              className={
                "absolute flex flex-center r--3 b--3 rounded-full bg-warning-0 ring-4 ring-base-1 w-7 h-7"
              }
            >
              <PicksBonusArrow fillColor={"#ecc440"} className={`w-5 h-5`} />
            </div>
          ) : null}
          {multiplier ? (
            <div
              className={
                "absolute flex flex-center b--4 rounded-full bg-base-2 ring-2 ring-base-1 px-4 h-6"
              }
            >
              <span className={"text-primary-1 font-xs weight-600"}>
                {multiplier}x
              </span>
            </div>
          ) : null}
        </div>
        <div className={"flex-col flex-1 overflow-visible"}>
          <div className={"flex-row items-center space-x-4"}>
            <span className={"font-md weight-500 text-neutral-2"}>
              {player.name}
            </span>
            <PickResultIcon result={result} returnNull />
          </div>
          <div className={"flex-row items-baseline space-x-3"}>
            <span className={"font-sm weight-400 text-neutral-1"}>
              {`${player.position}  •  ${player.team}`}
            </span>
            <FormattedSmallPlayerGame game={game} player={player} />
          </div>
          <span className={"font-xs weight-300 text-neutral-1"}>
            {getPlayerStatHighlights(performance, player.position, prop.league)}
          </span>

          <ProgressBars leg={leg} loading={loading} />
        </div>
        <div className={"rounded-6 pt-8 flex-row items-center space-x-4"}>
          <div className={"flex-col items-end w-13"}>
            <span className={"font-xl weight-500 text-neutral-2"}>{value}</span>
            <span className={"font-xs weight-300 text-neutral-1"}>
              {getPropKeyShortName(prop_key)}
            </span>
          </div>
          <div className={"flex-col items-center"}>
            <PicksDecisionIndicator
              decision={prop_decision}
              rotate={prop_decision === PropDecision.UNDER}
              result={result}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

function ProgressBars({
  leg,
  loading,
}: {
  leg: SlipLegWithPerformanceAndProjection;
  loading?: boolean;
}) {
  if (loading)
    return (
      <div className={"flex-row items-center space-x-3 min-h-9 max-h-9 mt-3"}>
        <i className="bx bx-loader bx-spin text-neutral-1" />
        <span className={"font-xs weight-300 text-neutral-1"}>Loading...</span>
      </div>
    );

  const propStatusValue = getPropPerformanceStatus(
    leg.performance as PlayerGamePerformance<string>,
    leg.prop.prop_key,
    leg.prop.league,
    leg.prop.player.position
  );

  const { prop, value, prop_decision, performance } = leg;
  const { prop_key, player, game } = prop;

  const color = !gameIsFinal(game)
    ? "primary"
    : getFinalPropResult(
        performance as PlayerGamePerformance<string>,
        prop_key,
        player.position,
        prop_decision,
        prop.league,
        value,
        propStatusValue
      )
    ? "success"
    : "danger";

  return (
    <div
      className={
        "justify-center flex-col relative overflow-visible min-h-9 max-h-9 mt-3 animation-from-bottom"
      }
    >
      <span
        style={{ left: `${Math.min((propStatusValue / value) * 100, 100)}%` }}
        className={` bg-${color}-0 text-neutral-2 ring-4 ring-base-1 font-xs weight-500 absolute rounded-full ml--5 min-w-8 w-9 h-8 flex flex-center z-9`}
      >
        {propStatusValue}
      </span>

      <ProgressBar
        height={"4px"}
        value={(propStatusValue / value) * 100}
        color={color}
      />
    </div>
  );
}
