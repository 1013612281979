import { Game, League } from "@phantasia/model-interfaces";
import {
  createCsgoPerformances,
  createLolPerformances,
  getPropsToGrade,
  PropToGrade,
} from "common/src/services/PicksService";
import { useEffect, useState } from "react";
import { Loader } from "../../components/molecules/Loader";
import { GradientButton2, SolidButton1 } from "../../components/atoms/Button";
import { Singletons } from "common/src/services/Singletons";
import { HorizontalDivider } from "../../components/atoms/Divider";
import { copyLink } from "common/src/util/copyLink";
import { toastSuccess } from "../../components/toast/ToastService";

export function PropGrader({
  setShowGrader,
}: {
  setShowGrader: (show: boolean) => void;
}) {
  const [props, setProps] = useState<PropToGrade[] | null>(null);
  const [league, setLeague] = useState<League>(League.CSGO);
  const [games, setGames] = useState<Game[] | null>(null);
  useEffect(() => {
    getPropsToGrade(league).then((res) => {
      if (res) setProps(res.data);
      const propGames = res.data.map((e) => e.game);
      const unique: Game[] = [];
      for (const game of propGames) {
        if (!unique.some((e) => e._id === game._id)) {
          unique.push(game);
        }
      }
      setGames(unique);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [league]);

  const graderLeagues = [
    { text: League.CSGO, value: League.CSGO },
    { text: League.LOL, value: League.LOL },
  ];

  const createPerformances = (league: League, gameId: string) => {
    if (league === League.CSGO)
      createCsgoPerformances(gameId).then((res) => {
        if (res) Singletons.toastService.success("Success!", "Graded CSGO");
      });
    else if (league === League.LOL)
      createLolPerformances(gameId).then((res) => {
        if (res) Singletons.toastService.success("Success!", "Graded CSGO");
      });
  };

  const copy = (id: string) => {
    copyLink(id, true).then(() =>
      toastSuccess("Success", "Referral Link Copied")
    );
  };

  if (!props || !games) return <Loader />;

  let barStyle = "0%";

  if (league === League.LOL) barStyle = "50%";

  return (
    <div className={"flex-col flex-1 outline-l-1 pl-1 h-screen relative"}>
      <div
        className={
          "px-8 h-12 items-center glass outline-b-1 flex-row absolute t-0 r-0 l-0 z-10"
        }
      >
        <div className={"flex-col flex-1"}>
          <span className={"font-lg weight-500 text-neutral-2 flex-1"}>
            Picks Admin Dashboard
          </span>
        </div>
        <div className={"bg-base-1 h-12 outline-b-1 flex-row relative mr-8"}>
          {graderLeagues.map((tab, index) => (
            <TabButton
              text={tab.text}
              active={league === tab.value}
              onClick={() => setLeague(tab.value)}
              key={index}
            />
          ))}

          <div
            style={{
              left: barStyle,
            }}
            className={`h-3 w-1/2 flex flex-center absolute transition-long b-0 z-11`}
          >
            <div
              className={"rounded-t-l-6 rounded-t-r-6 w-16 h-3 bg-primary-1"}
            />
          </div>
        </div>
        <GradientButton2
          color={"primary"}
          onClick={() => setShowGrader(!setShowGrader)}
          className={undefined}
          textStyles={undefined}
          contentRight={undefined}
          contentLeft={undefined}
          disabled={undefined}
          submit={undefined}
        >
          Dashboard
        </GradientButton2>
      </div>

      <div className={"flex-col max-h-full py-15 px-8 overflow-auto"}>
        {games.map((game, index) => (
          <div key={index}>
            {index !== 0 && <HorizontalDivider className={undefined} />}
            <div className="flex-row space-x-5 font-md items-center py-6 text-neutral-1">
              <div className={"flex-col w-27 space-y-3"}>
                <span className="text-neutral-2 font-lg weight-500 mb-3">
                  {game.home_team + " vs " + game.away_team}
                </span>

                <span
                  onClick={() => copy(`ObjectId('${game._id.toString()}')`)}
                  className="font-sm cursor-pointer weight-300 text-neutral-1 hover-success transition w-min no-wrap"
                >
                  Mongo ID - {game._id}
                </span>
                <span
                  onClick={() => copy(game.game_ID.toString())}
                  className="font-sm cursor-pointer weight-300 text-neutral-1 hover-success transition w-min no-wrap"
                >
                  OddsJam ID - {game.game_ID}
                </span>
              </div>
              <SolidButton1
                onClick={() => {
                  createPerformances(league, game._id.toString());
                }}
                className={" py-7 px-8 bg-base-1 font-xl"}
                textStyles={"font-xs"}
                contentRight={undefined}
                contentLeft={undefined}
                disabled={undefined}
                submit={undefined}
                color={undefined}
                id={undefined}
              >
                Create Performances
              </SolidButton1>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

function TabButton({
  active,
  onClick,
  text,
}: {
  active: boolean;
  onClick: () => void;
  text: string;
}) {
  return (
    <div
      className={`rounded-5 cursor-pointer w-14 p-6 hover:bg-base-2  transition flex flex-center decoration-none`}
      onClick={onClick}
    >
      <span
        className={`${
          active ? "text-neutral-2 weight-500" : "text-neutral-1 weight-400"
        } font-sm transition no-wrap`}
      >
        {text}
      </span>
    </div>
  );
}
