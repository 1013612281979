import React, { useCallback, useEffect } from "react";
import { useLocalStorage } from "./useLocalStorage";
import { PhantasiaTheme, PhantasiaThemes } from "common/src/util/themes";

interface ThemeContextProps {
  theme: string;
  setTheme: (theme: string) => void;
}

export const ThemeContext = React.createContext<ThemeContextProps>({
  setTheme: () => {},
  theme: "DarkTheme",
});

type Props = {
  children: JSX.Element;
};

export function ThemeContextProvider({ children }: Props): JSX.Element {
  const [theme, setTheme] = useLocalStorage<string>("theme", "DarkTheme");

  const setThemeVariables = useCallback((themeVars: PhantasiaTheme[]) => {
    themeVars.forEach((color: PhantasiaTheme) => {
      document.documentElement.style.setProperty(color.property, color.value);
    });
  }, []);

  useEffect(() => {
    const themeColors = PhantasiaThemes[theme];
    if (themeColors) {
      setThemeVariables(themeColors);
    }
  }, [setThemeVariables, theme]);

  return (
    <ThemeContext.Provider value={{ setTheme, theme }}>
      {children}
    </ThemeContext.Provider>
  );
}
