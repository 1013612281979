import { Modal } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useCreditsBalance } from "common/src/hooks/useBalance";
import { CoinflowPurchase } from "@coinflowlabs/react";
import { useCommonWallet } from "common/src/services/wallet/UseCommonWallet";
import { useConnection, useSolanaNet } from "common/src/hooks/useConnection";
import { Singletons } from "common/src/services/Singletons";
import Wallet from "../../wallet/Wallet";
import { useDispatch } from "react-redux";
import { loadBalance } from "common/src/redux/actions/WalletAction";
import { useUser } from "common/src/hooks/useUser";
import { UserTypes } from "common/src/redux/actions/types";
import { getPersonaCustomerInfo } from "common/src/services/PersonaService";

export function DepositModal({ open, onClose, onDeposit }) {
  const credits = useCreditsBalance();
  const wallet = useCommonWallet();
  const connection = useConnection();
  const net = useSolanaNet();
  const dispatch = useDispatch();
  const user = useUser();
  // const isEligibleForBonus = !user.hasDeposited;
  const purchaseInfo = {
    productName: "Phantasia Credits",
    productType: "inGameProduct",
    quantity: 1,
  };

  const [persona, setPersona] = useState(undefined);

  useEffect(() => {
    if (open) {
      getPersonaCustomerInfo().then((res) => setPersona(res));
    }
  }, [open]);

  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        className="flex flex-col flex-center modal outline-none"
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div className="animation-from-bottom bg-base-1 flex-row w-screen h-screen">
          <div
            className={
              "w-1/3 animation-from-left flex-col justify-center px-8 flex-center delay-3 bg-base-2"
            }
          >
            <div className={"m-auto flex-col"}>
              <div
                onClick={() => onClose()}
                className={
                  "flex-row items-center space-x-4 hover-left transition cursor-pointer"
                }
              >
                <i className="bx bx-left-arrow-alt text-neutral-1" />
                <span className={"text-neutral-1 font-xl weight-200"}>
                  Credit Deposit
                </span>
              </div>

              <span
                style={{ fontSize: "calc(4vw + 16px)" }}
                className={"weight-300 letter-spacing-sm text-neutral-2 mt-6"}
              >
                ${(credits / 100).toFixed(2)}
              </span>
              <div className={"flex-row items-center mb-8"}>
                <span
                  className={
                    "font-lg flex-1 weight-200 letter-spacing-sm text-neutral-1 mr-13"
                  }
                >
                  Credit balance
                </span>
                <Wallet />
              </div>
            </div>
          </div>
          <div className={"flex-1 animation-fade"}>
            <div className={"m-auto h-full max-w-30"}>
              <CoinflowPurchase
                blockchain={"solana"}
                wallet={wallet}
                customerInfo={persona}
                email={user.email}
                merchantId={"phantasia"}
                chargebackProtectionData={[purchaseInfo]}
                env={net === "mainnet" ? "prod" : "sandbox"}
                connection={connection}
                webhookInfo={{ name: "phantasia", isDeposit: true }}
                onSuccess={() => {
                  Singletons.toastService.success(
                    "Successs",
                    "Deposit successful!"
                  );
                  if (onDeposit) onDeposit();
                  if (!user.hasDeposited) {
                    dispatch({
                      type: UserTypes.SET_USER,
                      payload: { ...user, hasDeposited: true },
                    });
                  }
                  setTimeout(() => {
                    onClose();
                    loadBalance(wallet.publicKey, dispatch).catch((e) =>
                      console.error(e)
                    );
                  }, 1000);
                }}
              />
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
