import React, {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  PopulatedSlip,
  SlipWithPerformanceAndProjection,
} from "../../services/dtos/ContestDtos";
import { getLiveSlipsForUser } from "../../services/PicksService";

interface LiveSlipsContextProps {
  liveSlips: PopulatedSlip[] | null;
  selectedSlip: SlipWithPerformanceAndProjection | PopulatedSlip | null;
  setSelectedSlip: (
    slip: SlipWithPerformanceAndProjection | PopulatedSlip | null
  ) => void;
  refresh: () => void;
}

export const LiveSlipsContext = React.createContext<LiveSlipsContextProps>({
  liveSlips: null,
  selectedSlip: null,
  setSelectedSlip: () => {},
  refresh: () => {},
});

export function LiveSlipsContextProvider({
  children,
}: {
  children: ReactNode;
}) {
  const [liveSlips, setLiveSlips] = useState<PopulatedSlip[] | null>(null);
  const [selectedSlip, setSelectedSlip] = useState<
    SlipWithPerformanceAndProjection | PopulatedSlip | null
  >(null);

  const refresh = useCallback(() => {
    getLiveSlipsForUser().then((res) => {
      setLiveSlips(res.data.slips);
      setSelectedSlip(res.data.selected);
    });
  }, []);

  useEffect(() => {
    refresh();
  }, [refresh]);

  return (
    <LiveSlipsContext.Provider
      value={{ liveSlips, selectedSlip, setSelectedSlip, refresh }}
    >
      {children}
    </LiveSlipsContext.Provider>
  );
}

export const useLiveSlips = () => useContext(LiveSlipsContext);
