import React from "react";
import "../../sass/base.scss";
import { getExplorerTxUrl } from "common/src/util/explorer";

export function ExplorerLink(props) {
  return (
    <a
      target="_blank"
      href={getExplorerTxUrl(props.transactionSignature)}
      rel="noreferrer"
      className={!props.className ? "text-success-1" : props.className}
    >
      View transaction
    </a>
  );
}
