import React from "react";
import { CoinflowPurchaseHistory } from "@coinflowlabs/react";
import { useConnection, useSolanaNet } from "common/src/hooks/useConnection";
import { useCommonWallet } from "common/src/services/wallet/UseCommonWallet";

export function PaymentHistoryComponent() {
  const wallet = useCommonWallet();
  const connection = useConnection();

  const net = useSolanaNet();

  return (
    <div className="flex-1 justify-center">
      <CoinflowPurchaseHistory
        blockchain={"solana"}
        wallet={wallet}
        merchantId={"phantasia"}
        connection={connection}
        env={net === "mainnet" ? "prod" : "staging"}
      />
    </div>
  );
}
