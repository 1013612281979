import React from "react";
import pattern44 from "../../assets/patterns/pattern_44.svg";
import { ROUTES } from "../../Routes";
import { GradientButton3 } from "../../components/atoms/Button";
import { useHistory } from "react-router-dom";

export function WaysToPlaySection({ isMobile, noBg }) {
  const history = useHistory();
  const valueProps = [
    {
      icon: "bxs-bolt",
      title: "The Fastest Payouts",
      desc: "Transfer winnings to your bank account in under 10 minutes - no joke. The fastest payouts in fantasy sports.",
    },
    {
      icon: "bx-support",
      title: "Elite Customer Service",
      desc: "Talk directly to lead engineers for resolve issues and provide feedback. Phantasia is built for users & by users.",
    },
    {
      icon: "bxs-discount",
      title: "Save Money on Fees",
      desc: "Save 10+% on management fees when you use Phantasia vs. leading platforms. You'll never find lower fees for daily fantasy.",
    },
  ];

  return (
    <div className="w-full max-w-full space-y-12 flex-col flex-center relative z-11 py-15 overflow-hidden">
      {!noBg && (
        <div className="absolute opacity-25 r-0 l-0 t--28 z-6 overflow-hidden flex-col items-center">
          <img
            src={pattern44}
            className="opacity-8 h-full cover min-h-35 min-w-35 z-8"
            alt="pattern44"
          />
        </div>
      )}
      <h1
        style={{ fontSize: "calc(20px + 1.1vw)" }}
        className={`no-wrap text-neutral-2 weight-500 mt-0 flex-row`}
      >
        Why{" "}
        <strong className="ml-5 text-gradient-light weight-500">
          Phantasia?
        </strong>
      </h1>
      {/*<span className="font-lg text-center text-neutral-2 opacity-75 line-height-lg weight-300 max-w-31 mb-13 px-7">*/}
      {/*  Phantasia utilizes smart contract technology to allow complete ownership*/}
      {/*  of funds and instant payouts to your bank. Phantasia can never touch*/}
      {/*  your money.*/}
      {/*</span>*/}
      <div
        className={`max-w-35 w-full flex-center flex-${
          isMobile ? "col space-y-6 items-center " : "row space-x-8"
        } relative z-9`}
      >
        {valueProps.map((item, index) => (
          <div
            key={index}
            className={`flex-col space-y-5 p-8 rounded-8 outline-1 bg-gradient-base ${
              isMobile ? "w-9/10" : "flex-1"
            } relative `}
          >
            <div
              className={
                "bg-primary-gradient rounded-full h-12 w-12 flex flex-center"
              }
            >
              <i className={`bx ${item.icon} text-neutral-2 bx-sm`} />
            </div>
            <span className="font-xl text-neutral-2 weight-400">
              {item.title}
            </span>
            <span className="font-lg text-neutral-2 opacity-75 line-height-lg weight-300">
              {item.desc}
            </span>
          </div>
        ))}
      </div>
      <GradientButton3
        onClick={() => history.push(ROUTES.PICKS)}
        className={"py-9 mt-12 px-12 rounded-full"}
      >
        Play Fantasy Now
      </GradientButton3>
    </div>
  );
}
