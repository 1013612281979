import { PickResult, Slip } from "@phantasia/model-interfaces";
import { getExplorerTxUrl } from "common/src/util/explorer";

export function SlipResultIndicator({
  slip,
  large,
}: {
  slip: Slip<string>;
  large?: boolean;
}) {
  if (!slip.result) return null;

  if (slip.result === PickResult.LOSS) {
    return (
      <div
        className={
          "bg-danger-0 h-11 rounded-t-r-5 rounded-t-l-5 flex flex-center"
        }
      >
        <span className={"font-md weight-400 text-danger-2 letter-spacing-md"}>
          LOSS
        </span>
      </div>
    );
  }
  if (slip.result === PickResult.WIN && slip.payout_transaction_signature) {
    const winnings = slip.usd_won ?? slip.payout;
    return (
      <div
        className={
          "bg-success-0 h-11 rounded-t-r-5 rounded-t-l-5 flex flex-center space-x-5"
        }
      >
        <i className="bx bx-check-double text-success-2" />
        <span className={"font-md weight-400 text-success-2 letter-spacing-md"}>
          WIN +${winnings.toFixed(2)}
        </span>

        <a
          target="_blank"
          href={getExplorerTxUrl(slip?.payout_transaction_signature)}
          rel="noreferrer"
          className={
            "outline-1 decoration-none w-9 h-9 group rounded-full flex flex-center hover:bg-success-1 hover-down transition"
          }
        >
          <i className="bx bx-receipt text-success-1 group-hover:text-success-2 transition" />
        </a>
      </div>
    );
  }

  if (slip.result === PickResult.DNP) {
    return (
      <div
        className={
          "bg-base-3 h-11 rounded-t-r-5 rounded-t-l-5 flex flex-center space-x-5"
        }
      >
        <i className="bx bx-transfer-alt text-neutral-2" />
        <span className={"font-md weight-400 text-neutral-2 letter-spacing-md"}>
          REFUNDED +${slip.entry_amount.toFixed(2)}
        </span>
      </div>
    );
  }

  return null;
}
