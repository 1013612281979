import { useSlipBuilder } from "common/src/context/picks/SlipBuilderContext";
import { PicksArrow } from "../utils/PicksArrow";
import { IconHeader1 } from "../../atoms/PageHeader";
import { SolidButton2 } from "../../atoms/Button";
import { SlipPaymentForm } from "./SlipPaymentForm";
import { GameWithTeams } from "common/src/services/dtos/ContestDtos";
import { Player } from "@phantasia/model-interfaces";
import { getGameOpponentAndHomeTeam } from "common/src/services/PicksService";
import moment from "moment/moment";
import { BoardMyPicksList } from "../lists/BoardMyPicksList";
import { PicksLineChangeModal } from "../../modals/PicksLineChangeModal";

export function BoardMyPicksSection() {
  const { clearSlip } = useSlipBuilder();
  return (
    <div className={"flex-col h-full max-h-full relative overflow-auto"}>
      <div className={"flex-row p-6 glass sticky t-0 z-10 ring-b-1"}>
        <IconHeader1
          icon={"bx-collection"}
          className={"flex-1"}
          secondary={undefined}
        >
          My Picks
        </IconHeader1>

        <SolidButton2
          onClick={() => clearSlip()}
          className={undefined}
          textStyles={undefined}
          contentRight={undefined}
          contentLeft={undefined}
          disabled={undefined}
          submit={undefined}
          color={undefined}
        >
          Clear
        </SolidButton2>
        {/*<SupportInlineButton className={"ml-5"} />*/}
      </div>

      <EmptyState />

      <BoardMyPicksList />
      <SlipPaymentForm />
      <PicksLineChangeModal />
    </div>
  );
}

function EmptyState() {
  const { currentSlip } = useSlipBuilder();

  if (currentSlip.length > 0) {
    return null;
  }

  return (
    <div className={"mx-8 flex-col items-center"}>
      <div className={"flex-row items-center space-x-5 mt-8"}>
        <PicksArrow className={`w-14 h-14 fill-primary opacity-75`} />
        <PicksArrow className={`w-14 h-14 bx-rotate-180 fill-primary mt-8`} />
      </div>
      <span className={"font-lg weight-500 text-neutral-2"}>
        Make your picks!
      </span>
      <span className={"font-md text-center weight-300 text-neutral-1"}>
        Click on the Higher or Lower buttons to make picks. Choose 2+ players to
        complete your entry.
      </span>
    </div>
  );
}

export function FormattedSmallPlayerGame({
  game,
  player,
}: {
  game: GameWithTeams;
  player: Player<string>;
}) {
  if (!player || !player.team_ref) return null;

  const { opponent, isHomeTeam } = getGameOpponentAndHomeTeam(game, player);

  if (!opponent) return null;

  return (
    <span className={"font-xs flex weight-300 text-neutral-1"}>
      {`${isHomeTeam ? "vs." : "@"} ${opponent.name} • ${moment(
        game.date
      ).format("ddd h:mm a")}`}
    </span>
  );
}
