import { SlipPerformanceTracker } from "./SlipPerformanceTracker";
import { SlipList } from "../lists/SlipList";
import { useSettledSlips } from "common/src/context/picks/SettledSlipsContext";
import { Loader } from "../../molecules/Loader";
import { useEffect, useRef } from "react";

export function SettledPicksComponent() {
  const {
    settledSlips,
    selectedSlip,
    setSelectedSlip,
    winnings,
    loadingSlips,
    loadNextSet,
    refresh,
  } = useSettledSlips();

  const observerTarget = useRef(null);

  useEffect(() => {
    const current = observerTarget.current;
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) loadNextSet();
      },
      { threshold: 1 }
    );

    if (observerTarget.current) observer.observe(observerTarget.current);

    return () => {
      if (current) observer.unobserve(current);
    };
  }, [loadNextSet, observerTarget]);

  return (
    <div className={"flex-1 overflow-auto"}>
      <div className={"grid grid-cols-2 h-full overflow-auto"}>
        <div className={"p-8 ring-r-1 max-h-full h-full overflow-auto"}>
          <div
            className={
              "rounded-8 bg-base-2 p-7 flex-row items-center space-x-8 mb-8"
            }
          >
            <div className={"flex-col flex-1"}>
              <span className={"weight-300 text-neutral-1 font-xs"}>
                Winnings
              </span>
              <span className={"weight-500 text-neutral-2 font-xl"}>
                ${winnings.winnings.toFixed(2)}
              </span>
            </div>
            <div className={"flex-col flex-1"}>
              <span className={"weight-300 text-neutral-1 font-xs"}>wins</span>
              <span className={"weight-500 text-neutral-2 font-xl"}>
                {winnings.winAmount}
              </span>
            </div>

            <div className={"flex-col flex-1"}>
              <span className={"weight-300 text-neutral-1 font-xs"}>
                refunds
              </span>
              <span className={"weight-500 text-neutral-2 font-xl"}>
                {winnings.refunds}
              </span>
            </div>
            <div className={"flex-col flex-1"}>
              <span className={"weight-300 text-neutral-1 font-xs"}>
                Total entries
              </span>
              <span className={"weight-500 text-neutral-2 font-xl"}>
                {winnings.count}
              </span>
            </div>
          </div>
          <SlipList
            selectedSlipId={selectedSlip?._id ?? "1"}
            slips={settledSlips}
            setSelectedSlip={setSelectedSlip}
          />
          <div ref={observerTarget} className={"flex-center flex"}>
            {loadingSlips ? <Loader /> : null}
          </div>
        </div>
        <div className={"ring-r-1 max-h-full h-full overflow-auto space-y-12"}>
          <SlipPerformanceTracker
            slips={settledSlips}
            selectedSlip={selectedSlip}
            refresh={refresh}
          />
        </div>
      </div>
    </div>
  );
}
