import { Tooltip } from "@mui/material";
import React from "react";

export function CheckingLocation() {
  return (
    <div className="flex-col bg-base-1 rounded-9 p-11 animation-from-bottom w-11/12 max-w-31 overflow-auto max-h-9/10 flex flex-center">
      <div className="w-full flex-col flex-center">
        <div className="bg-primary-0 outline-primary-1 outline-1 min-w-15 rounded-full min-h-15 flex flex-center">
          <i className="bx bx-current-location bx-flashing text-primary-2 bx-md" />
        </div>
        <h2 className="weight-700 text-neutral-2">Verifying Location</h2>
        <span className="font-md text-neutral-1 weight-400">
          This can take a few seconds
        </span>
      </div>
    </div>
  );
}

export function AllowLocationAccess() {
  return (
    <div className="flex-col bg-base-1 rounded-9 p-11 animation-from-bottom w-11/12 max-w-31 overflow-auto max-h-9/10 flex flex-center">
      <div className="w-full flex-col flex-center">
        <div className="bg-danger-0 outline-danger min-w-15 rounded-full min-h-15 flex flex-center">
          <i className="bx bx-location-plus text-danger-2 bx-md" />
        </div>
        <h1 className="weight-700 text-neutral-2">
          Please Allow Location Access
        </h1>
        <span className="font-md text-neutral-1 weight-300 text-center w-3/4">
          You need to allow access to your browser location before we can
          continue. Look for a popup window somewhere near the top of your
          browser and click 'allow'.
        </span>
      </div>
    </div>
  );
}

export function AllowLocationAccessOnboarding() {
  return (
    <div className="flex-col glass-2 rounded-9 p-9 animation-from-bottom delay-5 w-11/12 max-w-31 overflow-auto max-h-9/10 flex flex-center">
      <div className="w-full flex-col flex-center">
        <i className="bx bx-loader-circle bx-spin bx-sm text-primary-1 mb-4" />

        <h3 className="weight-500 text-neutral-2 mt-0">
          Please Allow Location Access
        </h3>
        <span className="font-md text-primary-1 weight-300 text-center w-3/4 mb-8">
          You need to allow access to your browser location before we can
          continue. Look for a popup window somewhere near the top of your
          browser and click 'allow'.
        </span>
        <WhyLocationTooltip />
      </div>
    </div>
  );
}

function WhyLocationTooltip() {
  return (
    <div>
      <Tooltip
        title={
          "Phantasia needs to know your location in order to determine if you are allowed to participate in paid-entry contests. Certain jurisdictions are not allowed to participate on the Phantasia user interface."
        }
        placement="bottom"
      >
        <span className="font-xs weight-300 text-neutral-2 flex">
          Why does Phantasia need my location?
        </span>
      </Tooltip>{" "}
    </div>
  );
}
