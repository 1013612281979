import { shortenPublicKey } from "common/src/util/helpers";
import { useCommonWallet } from "common/src/services/wallet/UseCommonWallet";
import { useUser } from "common/src/hooks/useUser";
import { useHistory } from "react-router-dom";
import { useBalance } from "common/src/hooks/useBalance";
import { HorizontalDividerInDiv } from "../../atoms/Divider";
import { Singletons } from "common/src/services/Singletons";
import { DepositModal } from "../../modals/purchase/DepositModal";
import { KycContextProvider } from "common/src/context/KycContext";
import { KycModal } from "../../modals/kyc/KycModal";
import { ReactNode, useContext, useState } from "react";
import {
  WalletPageContext,
  WalletPageContextProvider,
} from "common/src/context/WalletPageContext";
import usdcLogo from "../../../assets/USD-Coin-icon.svg";
import creditsLogo from "../../../assets/fant-credit-logo.png";

import { getWalletsDollarValue } from "common/src/services/SendService";
import fantLogo from "../../../assets/LogoAlt.svg";
import { Tooltip } from "@mui/material";
import { useDispatch } from "react-redux";
import { SolidButton1 } from "../../atoms/Button";
import { getAndSetUser } from "common/src/services/UserService";

type ActionButtonProps = {
  action: () => void;
  icon: string;
  text: string;
};

export function WalletHomeSummary() {
  const wallet = useCommonWallet();
  const user = useUser();
  const history = useHistory();
  const { usdc, credits } = useBalance();
  const [paymentModalOpen, setPaymentModalOpen] = useState(false);
  const [kycOpen, setKycOpen] = useState(false);

  async function copyPublicKey() {
    if (!wallet) return;
    if ("clipboard" in navigator) {
      await navigator.clipboard.writeText(wallet.publicKey.toString());
    } else {
      document.execCommand("copy", true, wallet.publicKey.toString());
    }
    Singletons.toastService.success(
      "Copied!",
      "Wallet key copied to clipboard"
    );
  }

  function handleDepositOpen() {
    if (user.passedKyc) {
      setPaymentModalOpen(true);
    } else {
      setKycOpen(true);
    }
  }

  const actionButtons: ActionButtonProps[] = [
    {
      action: () => handleDepositOpen(),
      icon: "bx-plus",
      text: "Deposit",
    },
    {
      action: () => history.push("/withdraw"),
      icon: "bx-money-withdraw",
      text: "Withdraw",
    },
  ];

  return (
    <div className="flex-col min-w-26 w-26 max-w-26 flex-center w-full relative overflow-hidden">
      <div className={"absolute t-0 l-0 r-0"}>
        <BgImg />
      </div>
      <div className="relative pt-9">
        <ProfileImg />
      </div>
      <div className={"flex-row space-x-3 align-center mt-4"}>
        <span className="font-md text-neutral-2 weight-400 ">
          {user.username}
        </span>
        {user.passedKyc && (
          <i className="bx bxs-badge-check text-gradient-light" />
        )}
      </div>

      <div className="flex-row items-center space-x-6 mt-1">
        {wallet?.wallet?.icon && (
          <img className="w-5 h-5" src={wallet?.wallet?.icon} alt="w" />
        )}
        <span className="font-xs text-neutral-1 weight-300">
          {shortenPublicKey(wallet?.publicKey?.toString())}
        </span>
        <i
          onClick={() => copyPublicKey()}
          className={
            "bx bx-copy transition cursor-pointer text-neutral-1 hover:text-primary-1"
          }
        />
      </div>

      <FreePlayTicket />

      <HorizontalDividerInDiv className={"w-full my-5 mx-5"} />
      <div className={"flex-row space-x-5 w-full px-5"}>
        <div className={"flex-col items-center flex-1"}>
          <span className="font-2xl text-neutral-2 weight-500">
            ${(credits / 100 + usdc).toFixed(2)}
          </span>

          <span className="font-sm text-neutral-1 weight-300">
            ${usdc.toFixed(2)} withdrawable balance
          </span>
        </div>
      </div>

      <div className={"flex-row w-full p-5 space-x-5"}>
        {actionButtons.map((btn, index) => (
          <ActionButton {...btn} key={index} />
        ))}
      </div>
      <WalletPageContextProvider>
        <DashboardBalances />
      </WalletPageContextProvider>

      <DepositModal
        open={paymentModalOpen}
        onClose={() => {
          setPaymentModalOpen(false);
        }}
        onDeposit={() => {}}
      />
      <KycContextProvider
        onSuccess={() => {
          setKycOpen(false);
          setPaymentModalOpen(true);
        }}
      >
        <KycModal
          onCatchExpansion={() => {
            setKycOpen(false);
            setPaymentModalOpen(true);
          }}
          open={kycOpen}
          onClose={() => {
            setKycOpen(false);
          }}
          message={""}
        />
      </KycContextProvider>
    </div>
  );
}

function TicketDivider() {
  return (
    <div className={"flex-row w-full items-center relative h-8 min-h-8"}>
      <div
        className={
          "min-w-7 rounded-full h-7 absolute-middle-horizontal l--5 bg-base-1 ring-r-1 ring-base-3"
        }
      />
      <div className={"flex-row items-center space-x-6 w-full"}>
        {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(() => (
          <div className={"bg-base-0 h-1 flex-1"} />
        ))}
      </div>
      <div
        className={
          "min-w-7 rounded-full h-7 absolute-middle-horizontal r--5 bg-base-1 ring-l-1 ring-base-3"
        }
      />
    </div>
  );
}

export function FreePlayTicket() {
  const [kycOpen, setKycOpen] = useState<boolean>(false);
  const user = useUser();
  const dispatch = useDispatch();

  if (user.passedKyc || !user.referral) {
    return null;
  }

  return (
    <div
      className={`bg-base-3 rounded-6 flex-col hover-up hover:bg-base-2 transition mt-6 w-11/12`}
    >
      <div className={"px-5 pt-5 flex-col flex-center"}>
        <span className={"text-neutral-2 weight-700 font-lg letter-spacing-sm"}>
          FREE PLAY
        </span>
        <span className={"text-neutral-2 weight-300 font-xs "}>
          Verify identity to redeem
        </span>
      </div>
      <TicketDivider />
      <div className={"px-5 pb-5"}>
        <SolidButton1
          className={"rounded-4 flex-1 w-full"}
          textStyles={""}
          contentRight={undefined}
          contentLeft={undefined}
          disabled={undefined}
          submit={undefined}
          color={undefined}
          onClick={() => setKycOpen(true)}
          id={undefined}
        >
          Redeem
        </SolidButton1>
      </div>
      <KycContextProvider
        onSuccess={() => {
          setKycOpen(false);
          setTimeout(() => {
            getAndSetUser(dispatch);
          }, 1000);
        }}
      >
        <KycModal
          message={`Verify your identity to redeem your free play from Phantasia`}
          open={kycOpen}
          onClose={() => {
            setKycOpen(false);
          }}
          onCatchExpansion={() => {}}
        />
      </KycContextProvider>
    </div>
  );
}

function ActionButton({ action, icon, text }: ActionButtonProps) {
  return (
    <div
      onClick={action}
      className={
        "space-y-1 p-5 outline-1 cursor-pointer transition flex-col flex-center p-5 flex-1 rounded-5 hover:bg-primary-1 hover:outline-primary-1"
      }
    >
      <i className={`bx ${icon} text-neutral-2`} />
      <span className={"weight-400 font-sm text-neutral-2"}>{text}</span>
    </div>
  );
}

function ProfileImg() {
  return (
    <div className="flex flex-center min-w-13 min-h-13 max-w-13 max-h-13 rounded-full overflow-hidden bg-base-2 hover-opaque transition ring-4 ring-base-1">
      <i className="bx bx-user text-neutral-1 z-10 bx-sm" />
    </div>
  );
}

function BgImg() {
  return <div className="flex flex-center w-full bg-base-2 h-13" />;
}

function DashboardBalances() {
  const { usdc, fant, credits } = useBalance();

  const { priceData } = useContext(WalletPageContext);

  return (
    <div className="flex-col w-full px-5">
      <BalanceDisplaySmall
        ticker="USDC"
        name={"Withdrawable USD"}
        balance={usdc}
        image={<img className="w-6 h-6" src={usdcLogo} alt="usdc" />}
        data={usdc}
        tooltip={"Withdrawable winnings "}
      />
      <BalanceDisplaySmall
        ticker="Phantasia Credits"
        name={"Credits"}
        balance={credits / 100}
        image={<img className="w-6 h-6" src={creditsLogo} alt="credits" />}
        data={credits / 100}
        tooltip={"Non-withdrawable credits. Must be spent in-game."}
      />
      <BalanceDisplaySmall
        ticker="FANT"
        name={"Phantasia"}
        balance={fant}
        image={<img className="w-8 h-8" src={fantLogo} alt="fant" />}
        data={getWalletsDollarValue(priceData, fant, 0, 0)}
        tooltip={
          "Phantasia's native cryptocurrency. Used to buy NFTs and stake to avoid fees & earn money. Earned in free DFS contests"
        }
      />
    </div>
  );
}

function BalanceDisplaySmall({
  image,
  name,
  ticker,
  balance,
  data,
  tooltip,
}: {
  image: ReactNode;
  name: string;
  ticker: string;
  balance: number;
  data: number;
  tooltip: string;
}) {
  return (
    <div className="flex-row my-3 w-full items-center space-x-6">
      <div className="flex-row space-x-4 items-center flex-1">
        <div className="flex flex-center h-9 w-9">{image}</div>
        <div className="flex-col flex-1 mr-5">
          <div className="flex-row space-x-4">
            <span className="font-sm weight-400 text-neutral-2">{name}</span>
            <span className="flex">
              <Tooltip title={tooltip} placement="right">
                <i className="bx bx-info-circle font-xxs text-neutral-1 flex" />
              </Tooltip>
            </span>
          </div>

          <span className="font-xs weight-300 text-neutral-1">{ticker}</span>
        </div>
      </div>
      <div className="flex-col items-end">
        <span className={`font-md weight-400 text-neutral-2 no-wrap`}>
          ${data.toFixed(2)}
        </span>
        <span className={`font-xs weight-300 text-neutral-1 no-wrap`}>
          {balance} {ticker}
        </span>
      </div>
    </div>
  );
}
