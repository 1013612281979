import {
  Game,
  GameStatus,
  League,
  NflPositions,
  PropKey,
} from "@phantasia/model-interfaces";
import { PublicKey } from "@solana/web3.js";
import {
  EPL_STAT_ABBREVIATIONS,
  MLB_STAT_ABBREVIATIONS,
  NBA_STAT_ABBREVIATIONS,
  NFL_STAT_ABBREVIATIONS,
  NFL_STAT_ABBREVIATIONS_DST,
  NHL_STAT_ABBREVIATIONS,
} from "./constants";
import { ALLOW_PAID_CONTEST_ON_ANDROID } from "./AndroidPaidContestAllow";

export function lamportsToSol(lamports: number): number {
  return Math.round(lamports * 0.000000001 * 1000000) / 1000000;
}

export function shortenPublicKey(pubkey: PublicKey | string): string {
  if (!pubkey) return "";

  if (pubkey instanceof PublicKey) pubkey = pubkey.toString();

  return (
    pubkey.substring(0, 4) +
    "..." +
    pubkey.substring(pubkey.length - 4, pubkey.length)
  );
}

export function stringToPubkey(str: string) {
  return new PublicKey(str);
}

export function getStatAbbreviations(league: League, isDST: boolean): Object {
  return isDST
    ? NFL_STAT_ABBREVIATIONS_DST
    : league === League.NFL
    ? NFL_STAT_ABBREVIATIONS
    : league === League.NBA
    ? NBA_STAT_ABBREVIATIONS
    : league === League.MLB
    ? MLB_STAT_ABBREVIATIONS
    : league === League.NHL
    ? NHL_STAT_ABBREVIATIONS
    : EPL_STAT_ABBREVIATIONS;
}

export function playerGameScheduled(game: Game<string>) {
  if (!game) return false;
  return game.status === GameStatus.SCHEDULED;
}

export function playerGameInProgress(game: Game<string>) {
  if (!game) return false;
  return game.status === GameStatus.IN_PROGRESS;
}

export function playerGameFinal(game: Game<string>) {
  if (!game) return false;
  return (
    game.status === GameStatus.FINAL || game.status === GameStatus.FINAL_OT
  );
}

export function playerGamePostponed(game: Game<string>) {
  if (!game) return false;
  return (
    game.status === GameStatus.POSTPONED || game.status === GameStatus.FORFEIT
  );
}

export function gameIsFinal(game: Game<string>) {
  if (!game) return false;
  return (
    game.status === GameStatus.FINAL || game.status === GameStatus.FINAL_OT
  );
}

export function getPropKeyShortName(
  prop_key: PropKey,
  position?: NflPositions
) {
  switch (prop_key) {
    case PropKey.FANTASY_POINTS:
      return "Fan Pts";
    case PropKey.FANTASY_POINTS_PITCHER:
      return "P Fan Pts";
    case PropKey.POINTS_ASSISTS_REBOUNDS:
      return "P+R+A";
    case PropKey.POINTS_ASSISTS:
      return "Pts+Ast";
    case PropKey.POINTS_REBOUNDS:
      return "Pts+Reb";
    case PropKey.BLOCKS:
      return "Blocks";
    case PropKey.ASSISTS:
      return "Assists";
    case PropKey.STEALS:
      return "Steals";
    case PropKey.THREE_POINTERS:
      return "3-Pts";
    case PropKey.HITS:
      return "Hits";
    case PropKey.BASES:
      return "Bases";
    case PropKey.REBOUNDS:
      return "Rebounds";
    case PropKey.POINTS:
      return "Points";
    case PropKey.STRIKEOUTS:
      return "Pitching Ks";
    case PropKey.EARNED_RUNS:
      return "ERA";
    case PropKey.WALKS_ALLOWED:
      return "BB Alwd";
    case PropKey.HITS_ALLOWED:
      return "Hits Alwd";
    case PropKey.PITCHING_TOTAL_BASES:
      return "Base Alwd";
    case PropKey.GOALIE_SAVES:
      return "Saves";
    case PropKey.GOALS_AGAINST:
      return "Goals Alwd";
    case PropKey.SHOTS_ON_GOAL:
      return "SoG";
    case PropKey.FACEOFFS_WON:
      return "Faceoffs";
    case PropKey.MINUTES_ON_ICE:
      return "Minutes";
    case PropKey.GOALS:
      return "Goals";
    case PropKey.INNINGS_PITCHED:
      return "Innings";
    case PropKey.PITCHING_OUTS:
      return "Outs";
    case PropKey.BATTING_RUNS:
      return "Runs";
    case PropKey.BATTING_HOME_RUNS:
      return "HRs";
    case PropKey.BATTING_RBI:
      return "RBIs";
    case PropKey.STOLEN_BASES:
      return "SB";
    case PropKey.HITS_RUNS_RBI:
      return "Hit+R+RBI";
    case PropKey.BATTING_WALKS:
      return "BB";
    case PropKey.BATTING_STRIKEOUTS:
      return "Batting Ks";
    case PropKey.ASSISTS_REBOUNDS:
      return "Reb+Ast";
    case PropKey.TURNOVERS:
      return "TOs";
    case PropKey.STEALS_BLOCKS:
      return "Stl+Blk";
    case PropKey.STEALS_REBOUNDS:
      return "Stl+Reb";
    case PropKey.FREE_THROWS:
      return "FT Made";
    case PropKey.HEADSHOTS_1_2:
      return "Map 1+2 HS";
    case PropKey.HEADSHOTS_3:
      return "Map 3 HS";
    case PropKey.KILLS_1:
      return "Map 1 Kills";
    case PropKey.KILLS_1_2:
      return "Map 1+2 Kills";
    case PropKey.KILLS_2:
      return "Map 2 Kills";
    case PropKey.KILLS_3:
      return "Map 3 Kills";
    case PropKey.KILLS_4:
      return "Map 4 Kills";
    case PropKey.KILLS_5:
      return "Map 5 Kills";
    case PropKey.KILL_1_2_3:
      return "Map 1+2+3 Ks";
    case PropKey.PASSING_YARDS:
      return "Pass Yards";
    case PropKey.PASSING_COMPLETIONS:
      return "Pass Cmp";
    case PropKey.RECEIVING_YARDS:
      return "Rec Yards";
    case PropKey.RUSHING_YARDS:
      return "Rush Yards";
    case PropKey.RECEPTIONS:
      return "Receptions";
    case PropKey.RUSHING_RECEIVING_YARDS:
      return "Rush+Rec Yards";
    case PropKey.PASSING_TD:
      return "Pass TD";
    case PropKey.LONGEST_RUSH:
      return "Long Rush";
    case PropKey.RECEIVING_TD:
      return "Rec TDs";
    case PropKey.INTERCEPTIONS_THROWN:
      return "INT Thrown";
    case PropKey.RUSHING_TD:
      return "Rush TD";
    case PropKey.TOTAL_TD:
      return "Total TD";
    case PropKey.PASSING_ATTEMPTS:
      return "Pass Att";
    case PropKey.RUSHING_ATTEMPTS:
      return "Rush Atts";
    case PropKey.TACKLES_ASSISTS:
      return "Tkl+Ast";
    case PropKey.PASSING_RUSHING_YARDS:
      return "Pass+Rush Yds";
    case PropKey.TACKLES:
      return "Tackles";
    case PropKey.INTERCEPTIONS:
      return "Def INT";
    case PropKey.LONGEST_RECEPTION:
      return "Long Catch";
    case PropKey.LONGEST_PASS:
      return "Long Pass";
    case PropKey.FG_MADE:
      return "FG Made";
    case PropKey.KICKING_POINTS:
      return "Kick Pts";
    case PropKey.TACKLE_FOR_LOSS:
      return "TFL";
    case PropKey.XP_MADE:
      return "XP Made";
    case PropKey.PUNTS:
      return "Punts";
    default:
      return prop_key;
  }
}

export function getPropKeyUiName(prop_key: PropKey, position?: NflPositions) {
  switch (prop_key) {
    case PropKey.FANTASY_POINTS:
      return "Fantasy Pts";
    case PropKey.FANTASY_POINTS_PITCHER:
      return "Pitcher Fantasy Pts";
    case PropKey.POINTS_ASSISTS_REBOUNDS:
      return "Pts+Reb+Ast";
    case PropKey.POINTS_ASSISTS:
      return "Pts+Ast";
    case PropKey.POINTS_REBOUNDS:
      return "Pts+Reb";
    case PropKey.BLOCKS:
      return "Blocks";
    case PropKey.ASSISTS:
      return "Assists";
    case PropKey.STEALS:
      return "Steals";
    case PropKey.THREE_POINTERS:
      return "3-Pts Made";
    case PropKey.HITS:
      return "Hits";
    case PropKey.BASES:
      return "Total Bases";
    case PropKey.REBOUNDS:
      return "Rebounds";
    case PropKey.POINTS:
      return "Points";
    case PropKey.STRIKEOUTS:
      return "Pitching Ks";
    case PropKey.EARNED_RUNS:
      return "Runs Allowed";
    case PropKey.WALKS_ALLOWED:
      return "BB Allowed";
    case PropKey.HITS_ALLOWED:
      return "Hits Allowed";
    case PropKey.PITCHING_TOTAL_BASES:
      return "Bases Allowed";
    case PropKey.GOALIE_SAVES:
      return "Saves";
    case PropKey.GOALS_AGAINST:
      return "Goals Allowed";
    case PropKey.SHOTS_ON_GOAL:
      return "Shots on Goal";
    case PropKey.FACEOFFS_WON:
      return "Faceoffs Won";
    case PropKey.MINUTES_ON_ICE:
      return "Minutes on Ice";
    case PropKey.GOALS:
      return "Goals";
    case PropKey.INNINGS_PITCHED:
      return "Innings Pitched";
    case PropKey.PITCHING_OUTS:
      return "Pitching Outs";
    case PropKey.BATTING_RUNS:
      return "Runs";
    case PropKey.BATTING_HOME_RUNS:
      return "Home Runs";
    case PropKey.BATTING_RBI:
      return "RBI";
    case PropKey.STOLEN_BASES:
      return "Stolen Bases";
    case PropKey.HITS_RUNS_RBI:
      return "Hits+Runs+RBIs";
    case PropKey.BATTING_WALKS:
      return "Walks";
    case PropKey.BATTING_STRIKEOUTS:
      return "Batter Ks";
    case PropKey.ASSISTS_REBOUNDS:
      return "Reb+Ast";
    case PropKey.TURNOVERS:
      return "Turnovers";
    case PropKey.STEALS_BLOCKS:
      return "Steals+Blk";
    case PropKey.STEALS_REBOUNDS:
      return "Steals+Reb";
    case PropKey.FREE_THROWS:
      return "Free Throws Made";
    case PropKey.HEADSHOTS_1_2:
      return "Map 1+2 Headshots";
    case PropKey.HEADSHOTS_3:
      return "Map 3 Headshots";
    case PropKey.KILLS_1:
      return "Map 1 Kills";
    case PropKey.KILLS_1_2:
      return "Map 1+2 Kills";
    case PropKey.KILLS_2:
      return "Map 2 Kills";
    case PropKey.KILLS_3:
      return "Map 3 Kills";
    case PropKey.KILLS_4:
      return "Map 4 Kills";
    case PropKey.KILLS_5:
      return "Map 5 Kills";
    case PropKey.KILL_1_2_3:
      return "Map 1+2+3 Kills";
    case PropKey.PASSING_YARDS:
      return "Passing yards";
    case PropKey.PASSING_COMPLETIONS:
      return "Complete Passes";
    case PropKey.RECEIVING_YARDS:
      return "Rec Yards";
    case PropKey.RUSHING_ATTEMPTS:
      return "Rush Attempts";
    case PropKey.RUSHING_YARDS:
      return "Rush Yards";
    case PropKey.RECEPTIONS:
      return "Receptions";
    case PropKey.RUSHING_RECEIVING_YARDS:
      return "Rush + Rec Yards";
    case PropKey.PASSING_TD:
      return "Passing TDs";
    case PropKey.LONGEST_RUSH:
      return "Longest Rush";
    case PropKey.RECEIVING_TD:
      return "Receiving TDs";
    case PropKey.INTERCEPTIONS_THROWN:
      return "Interceptions Thrown";
    case PropKey.RUSHING_TD:
      return "Rushing TDs";
    case PropKey.TOTAL_TD:
      return "Total TDs";
    case PropKey.PASSING_ATTEMPTS:
      return "Passing Attempts";
    case PropKey.TACKLES_ASSISTS:
      return "Tackles + Assists";
    case PropKey.PASSING_RUSHING_YARDS:
      return "Pass + Rush Yards";
    case PropKey.TACKLES:
      return "Tackles";
    case PropKey.INTERCEPTIONS:
      return "Interceptions";
    case PropKey.LONGEST_RECEPTION:
      return "Longest Catch";
    case PropKey.LONGEST_PASS:
      return "Longest Pass";
    case PropKey.FG_MADE:
      return "FG Made";
    case PropKey.KICKING_POINTS:
      return "Kicking Points";
    case PropKey.TACKLE_FOR_LOSS:
      return "Tackle For Loss";
    case PropKey.XP_MADE:
      return "XP Made";
    case PropKey.PUNTS:
      return "Punts";
    default:
      return prop_key;
  }
}

export function allowPaidContestsOnMobile(platform: string): boolean {
  if (platform === "ios") {
    return true;
  }
  if (platform === "android") {
    return ALLOW_PAID_CONTEST_ON_ANDROID;
  }
  return true;
}
