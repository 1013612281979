export function PicksArrow({ className }: { className?: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="100"
      className={`${className}`}
      viewBox="0 0 20 17.5"
      stroke="none"
      strokeWidth="2"
    >
      <circle cx="10" cy="2.5" r="1" />

      <circle cx="10" cy="5" r="1" />
      <circle cx="7.5" cy="5" r="1" />
      <circle cx="12.5" cy="5" r="1" />

      <circle cx="5" cy="7.5" r="1" />
      <circle cx="7.5" cy="7.5" r="1" />
      <circle cx="10" cy="7.5" r="1" />
      <circle cx="12.5" cy="7.5" r="1" />
      <circle cx="15" cy="7.5" r="1" />

      <circle cx="15" cy="10" r="1" />
      <circle cx="17.5" cy="10" r="1" />
      <circle cx="12.5" cy="10" r="1" />
      <circle cx="10" cy="10" r="1" />
      <circle cx="7.5" cy="10" r="1" />
      <circle cx="5" cy="10" r="1" />
      <circle cx="2.5" cy="10" r="1" />

      <circle cx="15" cy="12.5" r="1" />
      <circle cx="17.5" cy="12.5" r="1" />
      <circle cx="12.5" cy="12.5" r="1" />
      <circle cx="10" cy="12.5" r="1" />
      <circle cx="7.5" cy="12.5" r="1" />
      <circle cx="5" cy="12.5" r="1" />
      <circle cx="2.5" cy="12.5" r="1" />

      <circle cx="15" cy="15" r="1" />
      <circle cx="17.5" cy="15" r="1" />
      <circle cx="12.5" cy="15" r="1" />
      <circle cx="10" cy="15" r="1" />
      <circle cx="7.5" cy="15" r="1" />
      <circle cx="5" cy="15" r="1" />
      <circle cx="2.5" cy="15" r="1" />
    </svg>
  );
}
