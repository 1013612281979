import {
  MAX_ENTRY_AMOUNT,
  MAX_SLIP_LEGS,
  useSlipBuilder,
} from "common/src/context/picks/SlipBuilderContext";
import { useBalance } from "common/src/hooks/useBalance";
import { GradientButton3, SolidButton2 } from "../../atoms/Button";
import { Input } from "../../atoms/Input";
import {
  calculatePayoutForEntry,
  getBoostedIncrease,
  getPicksMultiplier,
} from "common/src/services/PicksService";
import { PicksPurchaseModal } from "../../modals/PicksPurchaseModal";
import { useUser } from "common/src/hooks/useUser";
import { KycModal } from "../../modals/kyc/KycModal";
import { KycContextProvider } from "common/src/context/KycContext";
import { useState } from "react";
import {
  getCountryAndState,
  isExpansionRegion,
} from "common/src/services/GeolocationService";
import Switch from "../../atoms/Switch";
import { Tooltip } from "@mui/material";
import { Singletons } from "common/src/services/Singletons";
import { HorizontalDivider } from "../../atoms/Divider";
import Logo from "../../../assets/LogoMain.png";
import { FANT_BOOST_COST } from "common/src/util/constants";
import { PicksBonusArrow } from "../utils/PicksBonusArrow";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../../../Routes";

export function SlipPaymentForm() {
  const {
    entryAmount,
    setEntryAmount,
    currentSlip,
    submitSlip,
    buttonLoading,
    maxPayout,
    insurance,
    setInsurance,
    boost,
    setBoost,
    setButtonLoading,
    setTransaction,
    submitFreePlay,
  } = useSlipBuilder();

  const user = useUser();
  const history = useHistory();

  const { fant } = useBalance();
  const [kycOpen, setKycOpen] = useState<boolean>(false);

  const entryNotAllowed =
    maxPayout <
    Number(
      calculatePayoutForEntry(
        Number(entryAmount),
        currentSlip,
        insurance ? 2 : 1,
        boost
      )
    );

  const maxEntryExceeded = entryAmount > MAX_ENTRY_AMOUNT;

  const boostedIncrease = getBoostedIncrease(
    currentSlip.map((leg) => leg.bonus_type),
    insurance,
    boost
  );

  async function handleSubmit() {
    if (!user.passedKyc) {
      setButtonLoading(true);
      const expansion = await checkExpansion();
      if (expansion) {
        submitSlip(setTransaction, true);
        return;
      } else {
        setKycOpen(true);
        setButtonLoading(false);
        return;
      }
    }
    if (currentSlip.length && user.freePlays && user.freePlays > 0)
      return submitFreePlay(freePlaySuccess);
    submitSlip(setTransaction);
  }

  function freePlaySuccess() {
    Singletons.toastService.success("Entry Submitted!", "");
    history.push(ROUTES.PICKS_LIVE);
  }

  function closeKyc() {
    setKycOpen(false);
    if (currentSlip.length && user.freePlays && user.freePlays > 0)
      return submitFreePlay(freePlaySuccess);
    submitSlip(setTransaction);
  }

  async function checkExpansion() {
    const { country, state } = await getCountryAndState();
    console.log(country, state);
    return isExpansionRegion(country);
  }

  return (
    <div className={"ring-t-1 p-8 flex-col"}>
      <KycContextProvider onSuccess={closeKyc}>
        <KycModal
          message={`You'll need to verify yourself to play Phantasia picks. It shouldn't take more than 30 seconds!`}
          open={kycOpen}
          onClose={() => {
            setKycOpen(false);
          }}
          onCatchExpansion={closeKyc}
        />
      </KycContextProvider>
      <div className={"bg-base-2 rounded-5 p-5"}>
        <div className={"flex-row mb-4"}>
          <span className={"text-neutral-2 weight-400 font-md flex-1"}>
            Entry amount
          </span>
          <div className={"flex-1"} />
          <Balances />
        </div>
        <div className={"flex-col"}>
          <div className={"flex-row flex-1 outline-1 rounded-full p-1"}>
            <SolidButton2
              color={entryAmount === 1 ? "primary" : "base"}
              className={"rounded-full w-12"}
              textStyles={undefined}
              contentRight={undefined}
              contentLeft={undefined}
              disabled={
                currentSlip.length < 2 ||
                (user.freePlays &&
                  user.freePlays > 0 &&
                  currentSlip.length === MAX_SLIP_LEGS)
              }
              submit={undefined}
              onClick={() => setEntryAmount(1)}
            >
              $1
            </SolidButton2>
            <SolidButton2
              color={entryAmount === 5 ? "primary" : "base"}
              className={"rounded-full w-12"}
              textStyles={undefined}
              contentRight={undefined}
              contentLeft={undefined}
              disabled={
                currentSlip.length < 2 ||
                (user.freePlays &&
                  user.freePlays > 0 &&
                  currentSlip.length === MAX_SLIP_LEGS)
              }
              submit={undefined}
              onClick={() => setEntryAmount(5)}
            >
              $5
            </SolidButton2>
            <SolidButton2
              color={entryAmount === 10 ? "primary" : "base"}
              className={"rounded-full w-12"}
              textStyles={undefined}
              contentRight={undefined}
              contentLeft={undefined}
              disabled={
                currentSlip.length < 2 ||
                (user.freePlays &&
                  user.freePlays > 0 &&
                  currentSlip.length === MAX_SLIP_LEGS)
              }
              submit={undefined}
              onClick={() => setEntryAmount(10)}
            >
              $10
            </SolidButton2>
            {user.freePlays &&
            user.freePlays > 0 &&
            currentSlip.length === MAX_SLIP_LEGS ? (
              <Input
                disabled
                classes={"bg-base-3 rounded-full"}
                value={"Free Play!"}
                size={"sm"}
                placeholder={""}
                icon={<i className={"bx bx-dollar text-neutral-1"} />}
                onChange={(e) => {}}
              />
            ) : (
              <Input
                disabled={currentSlip.length < 2}
                classes={" bg-base-3 rounded-full"}
                value={entryAmount}
                size={"sm"}
                placeholder={"Entry"}
                icon={<i className={"bx bx-dollar text-neutral-1"} />}
                onChange={(e) => setEntryAmount(e.target.value)}
              />
            )}
          </div>

          <div className={"flex-row flex-1 mt-5 items-baseline"}>
            <span
              className={"text-neutral-2 weight-500 font-md flex-1 flex-row"}
            >
              To Win (
              {getPicksMultiplier(currentSlip, insurance, boost).toFixed(2)}
              x){" "}
              {boost ? <i className="bx bx-rocket white font-md ml-3" /> : null}
            </span>

            <div className={`flex-row space-x-4`}>
              {boostedIncrease ? (
                <div
                  className={
                    "rounded-full p-3 px-4 space-x-3 flex-row items-center bg-warning-0"
                  }
                >
                  <PicksBonusArrow
                    fillColor={"#ecc440"}
                    className={`w-5 h-5`}
                  />
                  <span className={"text-warning-1 font-xs weight-400"}>
                    {boostedIncrease}%
                  </span>
                </div>
              ) : null}
              <span
                className={`${
                  entryNotAllowed ? "text-danger-2" : "text-primary-2"
                }  weight-600 font-xl flex-row`}
              >
                $
                {calculatePayoutForEntry(
                  entryAmount,
                  currentSlip,
                  insurance ? 2 : 1,
                  boost
                )}
              </span>
            </div>
          </div>
        </div>
      </div>
      {currentSlip.length === MAX_SLIP_LEGS &&
      user.freePlays &&
      user.freePlays > 0 ? (
        <div className={"p-5 mt-4 rounded-5 flex flex-center bg-base-2"}>
          <span className={"font-sm weight-500 text-neutral-2"}>
            Free play applied
          </span>
        </div>
      ) : (
        <div className={"flex-row items-center space-x-4 py-4 mt-4"}>
          <div
            className={`h-8 w-8 rounded-full bg-gradient-success flex-center flex transition-long`}
          >
            <i className="bx bxs-check-shield white" />
          </div>
          <span className={"font-sm weight-400 text-neutral-2"}>
            Insurance?
          </span>
          <Tooltip
            title={"Get 1 pick wrong and receive your entry back (1x)"}
            placement="top"
          >
            <span>
              <i className="bx bx-info-circle text-neutral-1" />
            </span>
          </Tooltip>
          <div className={"flex-1"} />
          <Switch
            className={""}
            value={insurance}
            setValue={() => {
              if (currentSlip.length <= 2)
                Singletons.toastService.error(
                  "Under 3 picks",
                  "Insurance requires at least 3 picks"
                );
              else setInsurance(!insurance);
            }}
          />
        </div>
      )}
      <HorizontalDivider className={undefined} />
      <div className={"flex-row items-center space-x-4 py-4"}>
        <div
          className={`h-8 w-8 rounded-full bg-gradient-primary flex-center flex transition-long`}
        >
          <i className="bx bx-rocket white" />
        </div>
        <span className={"font-sm weight-400 text-neutral-2"}>
          Buy a boost?
        </span>
        <Tooltip
          title={`Trade ${FANT_BOOST_COST} FANT for an extra .1x on your multiplier`}
          placement="top"
        >
          <span>
            <i className="bx bx-info-circle text-neutral-1" />
          </span>
        </Tooltip>
        <div className={"flex-1"} />
        <div
          className={"flex-row items-center p-3 px-4 bg-base-2 rounded-full"}
        >
          <img src={Logo} className={"h-7 w-7 contain"} alt={"fant"} />
          <span className={"font-sm weight-500 text-neutral-2"}>
            {fant.toFixed(2)}
          </span>
        </div>
        <Switch
          className={""}
          value={boost}
          setValue={() => {
            if (fant < FANT_BOOST_COST)
              Singletons.toastService.error(
                "Not enough FANT",
                "A boost costs 50 FANT. Balance low."
              );
            else setBoost(!boost);
          }}
        />
      </div>
      {maxEntryExceeded ? (
        <span className={"text-danger-1 weight-300 font-sm my-5 text-center"}>
          Max entry of $50 during beta
        </span>
      ) : entryNotAllowed ? (
        <span className={"text-danger-1 weight-300 font-sm my-5 text-center"}>
          Max daily payout per user of ${user.maxPayoutCap ?? "1,200"} during
          Beta
        </span>
      ) : (
        <span className={"text-neutral-2 weight-300 font-sm my-5 text-center"}>
          {insurance
            ? "Miss 1 and still get your money back"
            : "Get all picks correct to win!"}
        </span>
      )}
      <GradientButton3
        onClick={handleSubmit}
        color={"primary"}
        id={undefined}
        className={"rounded-full min-h-12 mr-14"}
        textStyles={undefined}
        contentRight={undefined}
        contentLeft={
          buttonLoading ? (
            <i className="bx bx-loader bx-spin text-neutral-1" />
          ) : null
        }
        disabled={buttonLoading || entryNotAllowed}
        submit={undefined}
      >
        {buttonLoading ? "Loading..." : "Place Entry"}
      </GradientButton3>
      <PicksPurchaseModal />
    </div>
  );
}

function Balances() {
  const { credits, usdc } = useBalance();

  return (
    <div className={"flex-row items-baseline space-x-3"}>
      <span className={"weight-500 text-neutral-2 font-lg"}>
        ${(credits / 100 + usdc).toFixed(2)}
      </span>
      <span className={"weight-300 text-neutral-1 font-xs"}>USD Balance</span>
    </div>
  );
}
