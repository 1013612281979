import { useMediaQuery } from "@mui/material";
import { DISCORD_LINK, DOCUMENTATION_LINK } from "common/src/util/constants";
import React from "react";
import { Link } from "react-router-dom";
import solana_img from "../../../assets/built-img.png";
import logo_on_dark from "../../../assets/logo-with-words-dark-bg.png";
import { ROUTES } from "../../../Routes";

export default function Footer() {
  const isMobile = useMediaQuery("(max-width:768px)");

  return (
    <div
      className={`w-full max-w-35 m-auto py-9  ${
        isMobile ? "w-full flex-col px-5" : "grid grid-cols-5 gap-8"
      } `}
    >
      <div
        className={`flex-col ${
          isMobile ? "w-full" : "col-span-2"
        }  justify-start`}
      >
        <img src={logo_on_dark} className="w-17 contain" alt="Phantasia" />

        <span className="font-sm text-neutral-1 weight-400 mt-6 line-height-lg">
          Phantasia Sports is not in any way affiliated with any sports team or
          sports league, nor is it affiliated with any player, players
          association, or collection of players. The use of sports players’
          names in conjunction with their statistics in our contests is intended
          for informational purposes only and should not be construed as an
          endorsement of our contests.
        </span>
        <div className="flex-row space-x-4 flex items-center mt-5 mb-8">
          <i className="bx bx-copyright text-neutral-1"></i>
          <span className="font-sm text-neutral-1 weight-400 ">
            2022 Phantasia Sports
          </span>
        </div>
        <div className="space-x-6 flex-row items-center mb-8">
          <span className="text-neutral-2 font-md weight-400 no-wrap">
            Built on Solana
          </span>
          <img
            src={solana_img}
            className="min-w-6 min-h-6 max-h-6 max-w-6"
            alt="Solana"
          />
        </div>
        <div className="flex-row items-center space-x-6 ">
          <span className="flex flex-center">
            <a
              className="flex flex-center decoration-none"
              href="https://twitter.com/PhantasiaSports"
            >
              <i className="bx bxl-twitter bx-sm text-neutral-1 hover-dark transition"></i>
            </a>
          </span>
          <span className="flex flex-center ">
            <a className="flex flex-center decoration-none" href={DISCORD_LINK}>
              <i className="bx bxl-discord-alt bx-sm text-neutral-1 hover-dark transition"></i>
            </a>
          </span>
        </div>
        <span className="font-sm text-neutral-1 weight-400 mt-6 line-height-sm">
          Website operated by Phantasia Labs
        </span>
        <span className="font-sm text-neutral-1 weight-400 mt-6 line-height-sm">
          Customer Service Address: 1 E Erie St Suite 525-2049
        </span>
        <span className="font-sm text-neutral-1 weight-400 mt-6 line-height-sm">
          Customer Service Phone #: (224) 599-4799
        </span>
      </div>

      <div className={`flex-col justify-start`}>
        <span className="font-lg text-neutral-2 weight-500 mb-9">Product</span>
        <span className="mb-8">
          <Link
            className="hover-dark font-sm text-neutral-1 transition decoration-none"
            to={ROUTES.PICKS}
          >
            Picks
          </Link>
        </span>
      </div>
      <div className={`flex-col justify-start`}>
        <span className="font-lg text-neutral-2 weight-500 mb-9">
          Documentation & Legal
        </span>
        <span className=" mb-8">
          <a
            className="hover-dark font-md text-neutral-1 transition decoration-none "
            href="https://tosandprivacy.s3.amazonaws.com/Online+Terms+and+Conditions.pdf"
            target="_blank"
            rel="noreferrer"
          >
            Terms
          </a>
        </span>
        <span className=" mb-8">
          <a
            className="hover-dark font-md text-neutral-1 transition decoration-none "
            href="https://tosandprivacy.s3.amazonaws.com/Online+Privacy+Policy.pdf"
            target="_blank"
            rel="noreferrer"
          >
            Privacy
          </a>
        </span>
        <span className=" mb-8">
          <a
            className="hover-dark font-md text-neutral-1 transition decoration-none "
            href={DOCUMENTATION_LINK}
            target="_blank"
            rel="noreferrer"
          >
            Documentation
          </a>
        </span>
      </div>

      <div className={`flex-col justify-start`}>
        <span className="font-lg text-neutral-2 weight-500 mb-9">
          Community
        </span>
        <span className=" mb-8">
          <a
            className="hover-dark font-md text-neutral-1 transition decoration-none"
            href="https://twitter.com/PhantasiaSports"
            target="_blank"
            rel="noreferrer"
          >
            Twitter
          </a>
        </span>
        <span className=" mb-8">
          <a
            className="hover-dark font-md text-neutral-1 transition decoration-none"
            href={DISCORD_LINK}
            target="_blank"
            rel="noreferrer"
          >
            Discord
          </a>
        </span>
        <span className=" mb-8">
          <a
            className="hover-dark font-md text-neutral-1 transition decoration-none"
            href="https://medium.com/@phantasia_sports"
            target="_blank"
            rel="noreferrer"
          >
            Medium
          </a>
        </span>
      </div>
    </div>
  );
}
