import { ChatTypes } from "../actions/types";

const { SET_CHAT_VIEW, CLEAR, SET_SUPPORT_CHANNEL } = ChatTypes;

const initialState = {
  lastViewedMessages: {},
  supportChannel: null,
};

const Chat = (state = initialState, action) => {
  switch (action.type) {
    case SET_CHAT_VIEW: {
      const lastViewedMessages = state.lastViewedMessages;
      const channelId = action.payload.channelId;
      lastViewedMessages[channelId] = action.payload.messageId;
      return {
        ...state,
        lastViewedMessages,
      };
    }
    case CLEAR: {
      return {
        ...state,
        lastViewedMessages: {},
      };
    }
    case SET_SUPPORT_CHANNEL: {
      return { ...state, supportChannel: action.payload };
    }
    default: {
      return state;
    }
  }
};

export default Chat;
