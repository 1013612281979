import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useUser } from "common/src/hooks/useUser";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { UserTypes } from "common/src/redux/actions/types";
import { Singletons } from "common/src/services/Singletons";
import { produceDailyRewardJob } from "common/src/services/UserService";
import { GradientButton3 } from "../components/atoms/Button";
import { RewardModal } from "../components/modals/RewardModal";
import creditsLogo from "../assets/fant-credit-logo.png";
import logo from "../assets/LogoMain.png";
import fantLogo from "../assets/LogoMain.png";
import { NavDrawer } from "../components/layout/header/NavDrawer";
import { HorizontalDivider } from "../components/atoms/Divider";

export const PrizeTypes = {
  NOTHING: "nothing",
  FANT: "fant",
  CREDITS: "credits",
};

export default function DailySpinPage() {
  const history = useHistory();
  const [canSpin, setCanSpin] = useState(true);
  const user = useUser();

  const dispatch = useDispatch();

  const [prize, setPrize] = useState(null);
  const [started, setStarted] = useState(false);
  const [position, setPosition] = useState(-16);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (!user.spinTimestamp) {
      setCanSpin(true);
      return;
    }
    const lastSpin = new Date(user.spinTimestamp);
    const nextSpin = moment.utc().subtract(24, "hours").toDate();
    if (nextSpin >= lastSpin) {
      setCanSpin(true);
    } else {
      setCanSpin(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const prizes = useMemo(() => {
    return [
      { type: PrizeTypes.FANT, label: "50 FANT", value: 50 },
      { type: PrizeTypes.CREDITS, label: "$0.50", value: 0.5 },
      { type: PrizeTypes.FANT, label: "5 FANT", value: 5 },
      { type: PrizeTypes.CREDITS, label: "$1", value: 1 },
      { type: PrizeTypes.CREDITS, label: "$5", value: 5 },
      { type: PrizeTypes.CREDITS, label: "$2", value: 2 },
      { type: PrizeTypes.FANT, label: "20 FANT", value: 20 },
      { type: PrizeTypes.CREDITS, label: "$3", value: 3 },
      { type: PrizeTypes.FANT, label: "1 FANT", value: 1 },
      { type: PrizeTypes.FANT, label: "10 FANT", value: 10 },
      { type: PrizeTypes.NOTHING, label: "$0", value: 0 },
      { type: PrizeTypes.FANT, label: "50 FANT", value: 50 },
      { type: PrizeTypes.CREDITS, label: "$0.50", value: 0.5 },
      { type: PrizeTypes.FANT, label: "5 FANT", value: 5 },
      { type: PrizeTypes.CREDITS, label: "$1", value: 1 },
      { type: PrizeTypes.CREDITS, label: "$5", value: 5 },
      { type: PrizeTypes.CREDITS, label: "$2", value: 2 },
      { type: PrizeTypes.FANT, label: "20 FANT", value: 20 },
      { type: PrizeTypes.CREDITS, label: "$3", value: 3 },
      { type: PrizeTypes.FANT, label: "1 FANT", value: 1 },
      { type: PrizeTypes.FANT, label: "10 FANT", value: 10 },
      { type: PrizeTypes.NOTHING, label: "$0", value: 0 },
      { type: PrizeTypes.FANT, label: "50 FANT", value: 50, usable: true },
      { type: PrizeTypes.CREDITS, label: "$0.50", value: 0.5, usable: true },
      { type: PrizeTypes.FANT, label: "5 FANT", value: 5, usable: true },
      { type: PrizeTypes.CREDITS, label: "$1", value: 1, usable: true },
      { type: PrizeTypes.CREDITS, label: "$5", value: 5, usable: true },
      { type: PrizeTypes.CREDITS, label: "$2", value: 2, usable: true },
      { type: PrizeTypes.FANT, label: "20 FANT", value: 20, usable: true },
      { type: PrizeTypes.CREDITS, label: "$3", value: 3, usable: true },
      { type: PrizeTypes.FANT, label: "1 FANT", value: 1, usable: true },
      { type: PrizeTypes.FANT, label: "10 FANT", value: 10, usable: true },
      { type: PrizeTypes.NOTHING, label: "$0", value: 0, usable: true },
    ];
  }, []);

  const finalizePrize = useCallback(
    (timestamp, reward) => {
      setPrize(reward);
      setTimeout(() => {
        setOpen(true);
        setCanSpin(false);
      }, 500);

      dispatch({
        type: UserTypes.UPDATE_SPIN_TIMESTAMP,
        payload: timestamp,
      });
    },
    [dispatch]
  );

  const sendPrize = useCallback(async () => {
    try {
      const res = await produceDailyRewardJob();
      if (!res) {
        Singletons.toastService.error("Error", "Something went wrong...");
      }
      return res;
    } catch (e) {
      console.log(e);
      Singletons.toastService.error("Error", e.msg);
    }
  }, []);

  const start = useCallback(async () => {
    if (started) {
      return;
    }
    try {
      const res = await sendPrize();
      if (!res) {
        return;
      }
      const { timestamp, reward } = res;

      const spinValue = prizes.findIndex(
        (p) => p.value === reward.value && p.type === reward.type && p.usable
      );

      setPosition(-16 - 164 * (spinValue - 1));

      setTimeout(() => finalizePrize(timestamp, reward), 5000);
    } catch (e) {
      console.error(e);
      Singletons.toastService.error("Error", "Something went wrong...");
    }
  }, [finalizePrize, prizes, sendPrize, started]);

  return (
    <>
      <RewardModal
        open={open}
        onClose={() => {
          setOpen(false);
          history.push("/home");
        }}
        prize={prize}
      />
      <div className={"flex-row"}>
        <NavDrawer />
        <div className="flex-col flex-1 h-screen bg-base-1 outline-l-1">
          <div
            className={
              "px-8 h-14 items-center bg-base-1 outline-b-1 flex-row z-10 space-x-5 w-full outline-l-1"
            }
          >
            <span className={"font-lg weight-500 text-neutral-2 flex-1"}>
              Phantasia Daily Spin
            </span>
          </div>
          <div className="flex-col text-neutral-2 flex-center flex-1 p-8">
            <div className={"w-30 flex-col flex-center"}>
              <span className="font-2xl weight-500">Phantasia Daily Spin!</span>

              <span className="font-md weight-300 text-neutral-1 text-center mt-3 mb-7">
                Spin the wheel every 24 hours for a shot at a random prize. Earn
                real dollars you can use for fantasy sports contests!
              </span>
              <div className={"flex-row space-x-8 items-center w-full"}>
                <HorizontalDivider className={"bg-neutral-2 flex-1"} />
                <i className={"bx bxs-down-arrow text-neutral-2"} />
                <HorizontalDivider className={"bg-neutral-2 flex-1"} />
              </div>
              <div
                className={
                  "bg-base-2 rounded-10 p-10 overflow-hidden flex-row w-full max-w-30 my-7"
                }
              >
                <div
                  style={{
                    transform: `translateX(${position}px)`,
                    transitionDuration: "5000ms",
                    transitionTimingFunction: "cubic-bezier(0, 0, .24, 1)",
                  }}
                  className={"flex-row space-x-7 transition"}
                >
                  {prizes.map((p, index) => (
                    <div
                      key={index}
                      className={`h-18 w-18 min-w-18 max-w-18 rounded-6 flex-col flex-center raised-lg bg-base-3 space-y-5`}
                    >
                      {p.type === PrizeTypes.CREDITS ? (
                        <img
                          src={creditsLogo}
                          className="h-10 w-10"
                          alt="pattern44"
                        />
                      ) : p && p.type === PrizeTypes.FANT ? (
                        <img
                          src={fantLogo}
                          className="h-10 w-10"
                          alt="pattern44"
                        />
                      ) : (
                        <i className={"bx bx-block text-danger-1 bx-sm"} />
                      )}
                      <span className="font-xl letter-spacing-sm weight-500 ml-3">
                        {p.label}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
              {canSpin ? (
                <GradientButton3
                  className="mt-6 mb-4 w-full rounded-8 min-h-12"
                  color="primary"
                  disabled={Boolean(prize) || !canSpin}
                  onClick={() => {
                    start().catch((e) => console.error(e));
                    setStarted(true);
                  }}
                >
                  Try my luck!
                </GradientButton3>
              ) : (
                <>
                  <span className="mb-3 font-md weight-300 mb-5 text-neutral-1 text-center">
                    Come back soon... Your next chance will be available in:
                  </span>

                  <ContestTimer
                    contest={{
                      start_date: moment(user.spinTimestamp).add("1", "days"),
                    }}
                    style3
                  />
                </>
              )}
              <img src={logo} className="mt-6 h-12 w-12" alt="pattern44" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export function ContestTimer({ contest, style2, style3, style4 }) {
  const start_date = new Date(contest.start_date);

  const calculate_time = () => {
    const now = moment.utc().toDate();
    const difference = start_date - now;

    let timeLeft = {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    };
    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }
    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculate_time());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculate_time());
    }, 1000);
    return () => clearTimeout(timer);
  });

  const timerComponents = [];
  Object.keys(timeLeft).forEach((interval) => {
    if (interval === "hours") {
      timerComponents.push(
        <span>
          {timeLeft[interval]}
          {":"}
        </span>
      );
    }
    if (interval === "minutes") {
      timerComponents.push(
        <span>
          {timeLeft[interval].toString().padStart(2, "0")}
          {":"}
        </span>
      );
    }
    if (interval === "seconds") {
      timerComponents.push(
        <span>{timeLeft[interval].toString().padStart(2, "00")}</span>
      );
    }
  });

  function formatTimeLeft(time) {
    let string = "";
    if (time.days) {
      string += `${time.days}d `;
    }
    string += `${formatNumberTwoDigits(time.hours)}:${formatNumberTwoDigits(
      time.minutes
    )}:${formatNumberTwoDigits(time.seconds)}`;
    return string;
  }

  function formatNumberTwoDigits(number) {
    return number.toLocaleString("en-US", {
      minimumIntegerDigits: 2,
      useGrouping: false,
    });
  }

  if (style2) {
    return (
      <div className={"flex-col rounded-5 px-5 py-4 bg-base-2 items-end"}>
        <span className={"font-xs weight-300 text-neutral-1 flex"}>
          Starting in
        </span>
        <span className={"font-md weight-500 text-neutral-2"}>
          {formatTimeLeft(timeLeft)}
        </span>
      </div>
    );
  }
  const timeValues = ["Days", "Hours", "Mins", "Secs"];

  if (style3) {
    return (
      <div className="flex-row">
        {[
          timeLeft.days,
          timeLeft.hours,
          timeLeft.minutes,
          timeLeft.seconds,
        ].map((time, index) => (
          <div key={index} className="flex-1">
            <div
              className={`${index !== 0 ? "ml-5" : ""} ${
                index !== 3 ? "mr-5" : ""
              } h-10 flex-col flex-center`}
            >
              <span className="text-neutral-1 weight-300 font-md">
                {timeValues[index]}
              </span>
              <span className="text-neutral-2 font-2xl weight-500">
                {time.toString().length > 1 ? time : "0" + time}
              </span>
            </div>
          </div>
        ))}
      </div>
    );
  }

  if (style4) {
    return (
      <div className="flex-row">
        {[timeLeft.hours, timeLeft.minutes, timeLeft.seconds].map(
          (time, index) => (
            <div key={index} className="">
              <span className="text-neutral-2 font-md weight-400">
                {time.toString().length > 1 ? time : "0" + time}
                {index < 2 ? `: ` : ""}
              </span>
            </div>
          )
        )}
      </div>
    );
  }

  return (
    !(
      timeLeft.days === 0 &&
      timeLeft.hours === 0 &&
      timeLeft.minutes === 0 &&
      timeLeft.seconds === 0
    ) && (
      <div className="mobile-hide flex-row space-x-4 items-center mt-4">
        <i className="bx bx-timer text-neutral-1"></i>
        <span className="text-neutral-1 font-sm weight-300 no-wrap">{`Starts in ${
          (timeLeft.hours.toString().length > 1
            ? timeLeft.hours
            : "0" + timeLeft.hours) +
          ":" +
          (timeLeft.minutes.toString().length > 1
            ? timeLeft.minutes
            : "0" + timeLeft.minutes) +
          ":" +
          (timeLeft.seconds.toString().length > 1
            ? timeLeft.seconds
            : "0" + timeLeft.seconds)
        }
        `}</span>
      </div>
    )
  );
}
