import { useContext, useMemo } from "react";
import { GeolocationContext } from "common/src/context/GeolocationContext";
import LogoAlt from "../../assets/LogoAlt.svg";

export function IPLocationWrapper({
  children,
  countryBlacklist,
  stateBlackList,
}) {
  const { p2eAccessAllowed, country, state } = useContext(GeolocationContext);
  console.log({ country, state });

  return useMemo(() => {
    const allowed = p2eAccessAllowed(countryBlacklist, stateBlackList);
    if (allowed) return children;
    else return <IpAccessDenied />;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    p2eAccessAllowed,
    countryBlacklist,
    stateBlackList,
    children,
    country,
    state,
  ]);
}

function IpAccessDenied() {
  return (
    <div className="flex flex-center flex-col">
      <div className="">
        <img src={LogoAlt} alt="Logo" className="auth-logo" />
      </div>
      <h2 className="text-neutral-2 mb-5">Access Denied</h2>
      <div className="flex-col flex-center">
        <span className="text-neutral-1 font-md weight-300 w-1/2 ">
          Due to regulations, this website is not available to residents of
          Belarus, the Central African Republic, the Democratic Republic of
          Congo, the Democratic People's Republic of Korea, Cuba, Iran, Libya,
          Somalia, Sudan, South Sudan, Syria, Thailand, Yemen, Zimbabwe, select
          regions within the US (Washington, New York) and any other
          jurisdiction in which accessing or using this website is prohibited.
        </span>
      </div>
    </div>
  );
}
