import { LoginUrlTypes } from "../actions/types";

const { SET_LOGIN_URL, CLEAR_LOGIN_URL } = LoginUrlTypes;

const initialState = {
  onLoginUrl: "",
};

const LoginUrl = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOGIN_URL: {
      const onLoginUrl = action.payload;
      return {
        ...state,
        onLoginUrl,
      };
    }
    case CLEAR_LOGIN_URL: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default LoginUrl;
