import { Modal } from "@mui/material";
import {
  ChargebackProtectionItem,
  CoinflowPurchase,
} from "@coinflowlabs/react";
import { useCommonWallet } from "common/src/services/wallet/UseCommonWallet";
import { useConnection, useSolanaNet } from "common/src/hooks/useConnection";
import { useSlipBuilder } from "common/src/context/picks/SlipBuilderContext";
import { useEffect, useState } from "react";
import { LiveLegSummary, SlipListItem } from "../picks/list-items/SlipListItem";
import {
  calculatePayoutForEntry,
  getPicksMultiplier,
  getSlipFromNewEntryLegs,
} from "common/src/services/PicksService";
import { HorizontalDivider } from "../atoms/Divider";
import { Loader } from "../molecules/Loader";
import { Transaction } from "@solana/web3.js";
import { PopulatedSlipLeg } from "common/src/services/dtos/ContestDtos";
import { ExplorerLink } from "../transaction/ExplorerLink";
import { GradientButton3, SolidButton3 } from "../atoms/Button";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../../Routes";
import { useUser } from "common/src/hooks/useUser";
import { loadBalance } from "common/src/redux/actions/WalletAction";
import { useDispatch } from "react-redux";
import { getPersonaCustomerInfo } from "common/src/services/PersonaService";

export function PicksPurchaseModal() {
  const { transaction, clearSlip } = useSlipBuilder();
  const [successSignature, setSuccessSignature] = useState<string | null>(null);

  const [open, setOpen] = useState<boolean>(false);

  const [isExpired, setIsExpired] = useState(false);

  useEffect(() => {
    if (!open) return () => {};
    // Set a timeout for 5 minutes
    const timer = setTimeout(() => {
      setIsExpired(true);
    }, 5 * 60 * 1000); // 5 minutes in milliseconds

    // Cleanup the timer on component unmount
    return () => clearTimeout(timer);
  }, [open]);

  useEffect(() => {
    if (transaction) setOpen(true);
    else setSuccessSignature(null);
  }, [transaction]);

  const [persona, setPersona] = useState(undefined);

  useEffect(() => {
    if (open) {
      getPersonaCustomerInfo().then((res) => setPersona(res));
    }
  }, [open]);

  if (!transaction) return null;

  return (
    <>
      <Modal
        open={open}
        onClose={() => {
          if (successSignature) {
            clearSlip();
          }
          setOpen(false);
        }}
        className="flex flex-col flex-center modal outline-none"
        BackdropProps={{
          timeout: 500,
        }}
      >
        <>
          {isExpired ? (
            <div
              className={
                "text-base-2 bg-base-2 rounded-5 p-12 flex flex-center"
              }
            >
              <span className={"text-neutral-2 font-md"}>
                This entry has expired. No payment was recorded
              </span>
            </div>
          ) : (
            <>
              <PrePayment
                persona={persona}
                setSuccessSignature={setSuccessSignature}
                successSignature={successSignature}
              />
              <PostPayment
                setOpen={setOpen}
                successSignature={successSignature}
              />
            </>
          )}
        </>
      </Modal>
    </>
  );
}

function PostPayment({
  successSignature,
  setOpen,
}: {
  successSignature: string | null;
  setOpen: (open: boolean) => void;
}) {
  const { currentSlip, entryAmount, clearSlip, insurance } = useSlipBuilder();
  const history = useHistory();
  const user = useUser();
  if (!successSignature) return null;

  const slip = getSlipFromNewEntryLegs(
    currentSlip,
    Number(entryAmount),
    insurance ? 2 : 1
  );

  return (
    <div className="relative bg-base-1 w-30 rounded-9 animation-from-bottom max-w-9/10 max-h-11/12 outline-none flex-col items-center overflow-auto p-7">
      <div
        className={
          "flex flex-center min-h-12 h-12 w-12 bg-primary-0 rounded-full mb-5"
        }
      >
        <div className={"flex flex-center h-8 w-8 bg-primary-1 rounded-full"}>
          <i className={"bx bx-check-double text-neutral-2"} />
        </div>
      </div>
      <span className={"text-neutral-2 weight-400 font-lg mb-3"}>
        Entry Submitted!
      </span>

      <span className="font-sm text-primary-1 mb-9">
        <ExplorerLink
          className={"text-primary-1"}
          transactionSignature={successSignature}
        />
      </span>

      <div className={"min-w-28"}>
        <SlipListItem
          slip={slip}
          selectedSlipId={""}
          setSelectedSlip={() => {}}
        />
      </div>
      <span className="font-lg weight-500 text-primary-1 mt-5">
        {user.code}
      </span>
      <span className="font-sm weight-300 text-neutral-1 mt-2">
        referral code
      </span>

      <div className={"flex-row space-x-8 w-full mt-8"}>
        <SolidButton3
          onClick={() => {
            setOpen(false);
            clearSlip();
          }}
          color={"base"}
          id={undefined}
          className={"flex-1"}
          textStyles={undefined}
          contentRight={undefined}
          contentLeft={undefined}
          disabled={undefined}
          submit={undefined}
        >
          Make more picks
        </SolidButton3>
        <GradientButton3
          onClick={() => {
            setOpen(false);
            clearSlip();
            history.push(ROUTES.PICKS_LIVE);
          }}
          color={"primary"}
          id={undefined}
          className={"flex-1"}
          textStyles={undefined}
          contentRight={undefined}
          contentLeft={undefined}
          disabled={undefined}
          submit={undefined}
        >
          View My Slips
        </GradientButton3>
      </div>
    </div>
  );
}

function PrePayment({
  successSignature,
  setSuccessSignature,
  persona,
}: {
  successSignature: string | null;
  setSuccessSignature: (sig: string | null) => void;
  persona: any;
}) {
  const { insurance, transaction, entryAmount, currentSlip, boost } =
    useSlipBuilder();
  if (!transaction || successSignature) return null;

  return (
    <div className="relative bg-base-0 w-33 rounded-9 animation-from-bottom max-w-9/10 max-h-9/10 h-9/10 outline-none flex-col overflow-hidden p-8">
      <div className={"grid-cols-2 grid gap-8 h-full w-full"}>
        <RosterContent
          insurance={insurance}
          currentSlip={currentSlip}
          entryAmount={entryAmount}
          boost={boost}
        />
        <PurchaseContent
          persona={persona}
          setSuccessSignature={setSuccessSignature}
          transaction={transaction}
          entryAmount={entryAmount}
        />
      </div>
    </div>
  );
}

function RosterContent({
  currentSlip,
  entryAmount,
  insurance,
  boost,
}: {
  currentSlip: PopulatedSlipLeg[];
  entryAmount: number;
  insurance: boolean;
  boost: boolean;
}) {
  return (
    <div className={"flex-col p-6"}>
      <span className={"text-neutral-2 weight-500 font-xl mb-8"}>My Picks</span>
      <div className={"flex-col space-y-7"}>
        {currentSlip.map((leg) => (
          <LiveLegSummary leg={leg} key={leg.prop._id} />
        ))}
      </div>
      <HorizontalDivider className={"max-h-1 mt-10 mb-8"} />
      <div className={"flex-row items-baseline"}>
        <span className={"text-neutral-2 weight-400 font-md flex-1"}>
          Potential Payout ({getPicksMultiplier(currentSlip, insurance, boost)}
          x):
        </span>
        <span className={"text-success-1 weight-600 font-2xl"}>
          $
          {calculatePayoutForEntry(
            entryAmount,
            currentSlip,
            insurance ? 2 : 1,
            boost
          )}
        </span>
      </div>
    </div>
  );
}

function PurchaseContent({
  transaction,
  entryAmount,
  setSuccessSignature,
  persona,
}: {
  transaction: Transaction;
  entryAmount: number;
  setSuccessSignature: (sig: string | null) => void;
  persona: any;
}) {
  const dispatch = useDispatch();
  const wallet = useCommonWallet();
  const connection = useConnection();
  const net = useSolanaNet();

  const purchaseInfo: ChargebackProtectionItem = {
    productName: "Phantasia Picks Entry",
    productType: "inGameProduct",
    quantity: 1,
  };

  return (
    <div
      className={
        "bg-base-1 ring-1 overflow-hidden relative rounded-6 flex flex-center"
      }
    >
      <div className={"z-10 h-full w-full"}>
        <CoinflowPurchase
          webhookInfo={{ name: "phantasia" }}
          blockchain={"solana"}
          customerInfo={persona}
          chargebackProtectionData={[purchaseInfo]}
          // @ts-ignore
          wallet={wallet}
          merchantId={"phantasia"}
          connection={connection}
          amount={entryAmount}
          env={net === "mainnet" ? "prod" : "staging"}
          onSuccess={(res) => {
            setTimeout(() => {
              // @ts-ignore
              setSuccessSignature(res);
              loadBalance(wallet.publicKey, dispatch);
            }, 1000);
          }}
          transaction={transaction}
        />
      </div>
      <div
        className={
          "absolute-middle-horizontal flex flex-center t-0 b-0 l-0 r-0 z-1"
        }
      >
        <Loader message={"Loading purchase..."} />
      </div>
    </div>
  );
}
