import rootReducer from "common/src/redux/reducers";
import storage from "redux-persist/lib/storage";
import { persistStore, persistReducer } from "redux-persist";
import { composeWithDevTools } from "redux-devtools-extension";
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { persistWhitelist } from "common/src/redux/persistWhitelist";

function getWebStore() {
  const initialState = {};

  const middleware = [thunk];
  const persistConfig = {
    key: "root",
    storage: storage,
    whitelist: persistWhitelist,
  };

  const persistedReducer = persistReducer(persistConfig, rootReducer);
  return createStore(
    persistedReducer,
    initialState,
    composeWithDevTools(applyMiddleware(...middleware))
  );
}

const store = getWebStore();
const persistor = persistStore(store);

export { store, persistor };
