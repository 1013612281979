export function PicksBonusArrow({
  className,
  fillColor,
}: {
  className?: string;
  fillColor?: string;
}) {
  return (
    <svg
      width="100"
      height="100"
      className={className}
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2407_1470)">
        <path
          d="M74.2609 26.1563L40 68.5H60L55.0766 95.5672C55.0672 95.6203 55.0696 95.6748 55.0836 95.7269C55.0976 95.779 55.1228 95.8273 55.1575 95.8686C55.1922 95.9099 55.2356 95.943 55.2845 95.9657C55.3334 95.9884 55.3867 96.0001 55.4406 96C55.498 95.9999 55.5546 95.9865 55.6058 95.9607C55.6571 95.935 55.7017 95.8976 55.7359 95.8516L90 53.5H70L74.9469 26.4297C74.9535 26.3758 74.9486 26.321 74.9325 26.2691C74.9164 26.2173 74.8894 26.1694 74.8533 26.1287C74.8172 26.0881 74.7729 26.0556 74.7233 26.0334C74.6737 26.0112 74.62 25.9998 74.5656 26C74.5061 26.0002 74.4475 26.0145 74.3946 26.0417C74.3416 26.0688 74.2958 26.1081 74.2609 26.1563Z"
          fill={fillColor}
        />
        <path
          d="M50 23.75C52.7614 23.75 55 21.5114 55 18.75C55 15.9886 52.7614 13.75 50 13.75C47.2386 13.75 45 15.9886 45 18.75C45 21.5114 47.2386 23.75 50 23.75Z"
          fill={fillColor}
        />
        <path
          d="M50 36.25C52.7614 36.25 55 34.0114 55 31.25C55 28.4886 52.7614 26.25 50 26.25C47.2386 26.25 45 28.4886 45 31.25C45 34.0114 47.2386 36.25 50 36.25Z"
          fill={fillColor}
        />
        <path
          d="M37.5 36.25C40.2614 36.25 42.5 34.0114 42.5 31.25C42.5 28.4886 40.2614 26.25 37.5 26.25C34.7386 26.25 32.5 28.4886 32.5 31.25C32.5 34.0114 34.7386 36.25 37.5 36.25Z"
          fill={fillColor}
        />
        <path
          d="M25 48.75C27.7614 48.75 30 46.5114 30 43.75C30 40.9886 27.7614 38.75 25 38.75C22.2386 38.75 20 40.9886 20 43.75C20 46.5114 22.2386 48.75 25 48.75Z"
          fill={fillColor}
        />
        <path
          d="M37.5 48.75C40.2614 48.75 42.5 46.5114 42.5 43.75C42.5 40.9886 40.2614 38.75 37.5 38.75C34.7386 38.75 32.5 40.9886 32.5 43.75C32.5 46.5114 34.7386 48.75 37.5 48.75Z"
          fill={fillColor}
        />
        <path
          d="M25 61.25C27.7614 61.25 30 59.0114 30 56.25C30 53.4886 27.7614 51.25 25 51.25C22.2386 51.25 20 53.4886 20 56.25C20 59.0114 22.2386 61.25 25 61.25Z"
          fill={fillColor}
        />
        <path
          d="M12.5 61.25C15.2614 61.25 17.5 59.0114 17.5 56.25C17.5 53.4886 15.2614 51.25 12.5 51.25C9.73858 51.25 7.5 53.4886 7.5 56.25C7.5 59.0114 9.73858 61.25 12.5 61.25Z"
          fill={fillColor}
        />
        <path
          d="M25 73.75C27.7614 73.75 30 71.5114 30 68.75C30 65.9886 27.7614 63.75 25 63.75C22.2386 63.75 20 65.9886 20 68.75C20 71.5114 22.2386 73.75 25 73.75Z"
          fill={fillColor}
        />
        <path
          d="M12.5 73.75C15.2614 73.75 17.5 71.5114 17.5 68.75C17.5 65.9886 15.2614 63.75 12.5 63.75C9.73858 63.75 7.5 65.9886 7.5 68.75C7.5 71.5114 9.73858 73.75 12.5 73.75Z"
          fill={fillColor}
        />
        <path
          d="M37.5 86.25C40.2614 86.25 42.5 84.0114 42.5 81.25C42.5 78.4886 40.2614 76.25 37.5 76.25C34.7386 76.25 32.5 78.4886 32.5 81.25C32.5 84.0114 34.7386 86.25 37.5 86.25Z"
          fill={fillColor}
        />
        <path
          d="M25 86.25C27.7614 86.25 30 84.0114 30 81.25C30 78.4886 27.7614 76.25 25 76.25C22.2386 76.25 20 78.4886 20 81.25C20 84.0114 22.2386 86.25 25 86.25Z"
          fill={fillColor}
        />
        <path
          d="M12.5 86.25C15.2614 86.25 17.5 84.0114 17.5 81.25C17.5 78.4886 15.2614 76.25 12.5 76.25C9.73858 76.25 7.5 78.4886 7.5 81.25C7.5 84.0114 9.73858 86.25 12.5 86.25Z"
          fill={fillColor}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M38.267 63.8085C36.3598 64.5132 35 66.3478 35 68.5C35 71.0006 36.8357 73.0724 39.233 73.4415C38.693 73.641 38.1092 73.75 37.5 73.75C34.7386 73.75 32.5 71.5114 32.5 68.75C32.5 65.9886 34.7386 63.75 37.5 63.75C37.7608 63.75 38.017 63.77 38.267 63.8085Z"
          fill={fillColor}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M54.8223 42.4242C54.2414 40.3062 52.3024 38.75 50 38.75C47.2386 38.75 45 40.9886 45 43.75C45 46.3985 47.0592 48.566 49.6638 48.7389L54.8223 42.4242Z"
          fill={fillColor}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M82.728 70.2473C83.3642 72.2774 85.2601 73.7503 87.5 73.7503C90.2615 73.7503 92.5 71.5117 92.5 68.7503C92.5 66.1377 90.4963 63.9932 87.9419 63.7695L82.728 70.2473Z"
          fill={fillColor}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M92.5 81.25C92.5 84.0114 90.2614 86.25 87.5 86.25C84.7386 86.25 82.5 84.0114 82.5 81.25C82.5 78.4886 84.7386 76.25 87.5 76.25C90.2614 76.25 92.5 78.4886 92.5 81.25Z"
          fill={fillColor}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M71.229 84.5329C72.1456 85.5847 73.4951 86.2494 74.9999 86.2494C77.7613 86.2494 79.9999 84.0109 79.9999 81.2494C79.9999 79.3587 78.9504 77.713 77.4021 76.8633L71.229 84.5329Z"
          fill={fillColor}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M60.2869 35.7348L66.4525 28.1873C65.5378 27.0086 64.1075 26.25 62.5 26.25C59.7386 26.25 57.5 28.4886 57.5 31.25C57.5 33.2168 58.6356 34.9184 60.2869 35.7348Z"
          fill={fillColor}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M42.2506 57.8137C42.4125 57.3218 42.5 56.7961 42.5 56.25C42.5 53.4886 40.2614 51.25 37.5 51.25C34.7386 51.25 32.5 53.4886 32.5 56.25C32.5 59.0114 34.7386 61.25 37.5 61.25C38.4029 61.25 39.2499 61.0107 39.981 60.592L42.2506 57.8137Z"
          fill={fillColor}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M75.7114 48.7007C78.1358 48.3553 79.9998 46.2708 79.9998 43.7509C79.9998 41.8989 78.9929 40.282 77.4966 39.418L75.7114 48.7007Z"
          fill={fillColor}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M53.2914 77.486C52.412 76.7164 51.2604 76.25 50 76.25C47.2386 76.25 45 78.4886 45 81.25C45 84.0114 47.2386 86.25 50 86.25C50.6316 86.25 51.2358 86.1329 51.7921 85.9192L53.2914 77.486Z"
          fill={fillColor}
        />
      </g>
      <defs>
        <clipPath id="clip0_2407_1470">
          <rect width="100" height="100" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
