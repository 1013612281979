import React from "react";
import {
  BalanceDisplayComponent,
  SendCryptoForm,
} from "./BalanceDisplayComponent";
import { IconHeader1 } from "../../atoms/PageHeader";
import { LinkButton1 } from "../../atoms/Button";
import { getExplorerAccountUrl } from "common/src/util/explorer";
import { useCommonWallet } from "common/src/services/wallet/UseCommonWallet";

export function WalletPageComponent() {
  const wallet = useCommonWallet();
  return (
    <div className="p-8 grid grid-cols-2 gap-8 max-h-screen overflow-visible">
      <div className="flex-col space-y-8 overflow-auto">
        <BalanceDisplayComponent />
        <div className={"bg-base-1 rounded-8 p-8"}>
          <div className="flex-row items-baseline">
            <IconHeader1 icon={"bx-send"} className={"flex-grow mb-5"}>
              Send Money
            </IconHeader1>
            <LinkButton1
              link={getExplorerAccountUrl(wallet.publicKey)}
              className={"bg-base-1 text-primary-1"}
              textStyles={"text-neutral-1"}
            >
              View Transactions
            </LinkButton1>
          </div>
          <SendCryptoForm wallet={wallet} />
        </div>
      </div>
    </div>
  );
}
