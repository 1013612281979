import { Loader } from "../../molecules/Loader";
import {
  League,
  MlbPositions,
  NflPositions,
  Player,
} from "@phantasia/model-interfaces";
import { Tooltip } from "@mui/material";
import { getStatAbbreviations } from "common/src/util/helpers";
import moment from "moment";
import { VerticalDivider } from "../../atoms/Divider";
import { isMlbPitcher } from "common/src/services/PicksService";
import { GameLog } from "common/src/util/JoinContestUtil";

export function GameLogComponent({
  playerGameLog,
  player,
  league,
}: {
  playerGameLog: GameLog[];
  player: Player<string>;
  league: League;
}) {
  if (!playerGameLog) return <Loader />;

  const abbreviated_stats = getStatAbbreviations(
    league,
    player.position === NflPositions.DST
  );

  function getOpponentString(log: GameLog) {
    return `${moment(log.game.date).format("M/D")} ${log.game.away_team} @ ${
      log.game.home_team
    }`;
  }

  return (
    <div className="flex-row bg-base-0">
      {playerGameLog && playerGameLog.length > 0 ? (
        <div
          className={"flex-row p-8 w-full max-w-full overflow-scroll relative"}
        >
          <div className={"flex-col space-y-8"}>
            {playerGameLog.map((log: GameLog, index) => (
              <div key={index} className={"flex-col bg-base-1 p-8 rounded-8"}>
                <div className={"flex-row items-baseline space-x-4 mb-3 "}>
                  <span className={"font-md weight-300 text-neutral-1 no-wrap"}>
                    {getOpponentString(log)}
                  </span>
                  <span className={"font-md weight-500 text-primary-1 no-wrap"}>
                    {league === League.NFL
                      ? log.fantasy_points_ppr
                      : log.fantasy_points}{" "}
                    FP
                  </span>
                </div>

                <div className={`flex-row space-x-8`}>
                  {Object.entries(log.player_performance_stats).map(
                    ([k, v]: [string, number], index: number) => (
                      <StatBox
                        k={k}
                        v={v}
                        index={index}
                        stats={abbreviated_stats}
                        player={player}
                        key={index}
                        league={league}
                      />
                    )
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className="flex-col px-6 py-11 w-full flex flex-center">
          <i className="bx bxs-folder-minus bx-md text-neutral-1 mb-5" />
          <span className=" font-sm weight-300 text-neutral-1 ">
            No Game Logs
          </span>
        </div>
      )}
    </div>
  );
}

function StatBox({
  index,
  player,
  stats,
  k,
  v,
  league,
}: {
  index: number;
  player: Player<string>;
  stats: Object;
  k: string;
  v: number;
  league: League;
}) {
  if (
    league === League.MLB &&
    ((isMlbPitcher(player.position as MlbPositions) && index < 15) ||
      (!isMlbPitcher(player.position as MlbPositions) && index > 15))
  )
    return null;

  function Divider() {
    if (league === League.MLB) {
      if (!isMlbPitcher(player.position as MlbPositions) && index === 0)
        return null;
      if (isMlbPitcher(player.position as MlbPositions) && index === 15)
        return null;
    } else if (index === 0) return null;

    return <VerticalDivider className={"h-9 mr-5"} />;
  }

  return (
    <div className={"flex-row items-center"}>
      <Divider />
      <div key={index} className={"flex-col"}>
        <span className={"font-md weight-500 text-neutral-2 no-wrap flex pt-3"}>
          {v ? Math.round(v * 10) / 10 : "0"}
        </span>
        <span>
          <Tooltip
            placement={"bottom"}
            title={k
              .replaceAll("_", " ")
              .replace(/^(.)|\s+(.)/g, (c) => c.toUpperCase())}
          >
            <span className={"text-neutral-1 weight-300 font-sm flex"}>
              {stats[k]}
            </span>
          </Tooltip>
        </span>
      </div>
    </div>
  );
}
