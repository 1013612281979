import { League } from "@phantasia/model-interfaces";

export const NEW_FEATURES = [
  {
    iconWeb: "bx-money-withdraw",
    icon: "🪪",
    title: "Phantasia Loyalty Program",
    text: "Earn deposit matches worth 5% of your entry amounts when you play on Phantasia. 10 entries = a deposit match on your next purchase.",
  },
  {
    iconWeb: "bx-sort",
    icon: "5️⃣",
    title: "5-Pick Entries for 22x",
    text: "Feeling lucky? Pick 5 boosted players and shoot for 22x payouts!!!",
  },
  {
    iconWeb: "bx-sort",
    icon: "💸",
    title: "Max Entry Raised",
    text: "We've raised the entry cap to $100. ",
  },
];

export const SUPPORT_CHAT_USERNAME = "Support Phanbot";
export const SUPPORT_CHAT_IMAGE =
  "https://arweave.net/G_HhIrjuHx54XUT0Xv4ELl9OToeLOMLBkOFDUoZKFh8";

export const PLAYER_INJURIES = {
  OUT: "Out",
  DOUBTFUL: "Doubtful",
  QUESTIONABLE: "Questionable",
  PROBABLE: "Probable",
};

export const GAME_STATUSES = {
  IN_PROGRESS: "InProgress",
  POSTPONED: "Postponed",
  SCHEDULED: "Scheduled",
  FINAL: "Final",
  FINAL_OT: "F/OT",
};

export const NFL_STAT_ABBREVIATIONS = {
  passing_attempts: "PSS ATT",
  passing_completions: "PSS CMP",
  passing_yards: "PSS YDS",
  passing_touchdowns: "PSS TD",
  passing_interceptions: "INT",
  rushing_attempts: "RSH ATT",
  rushing_yards: "RSH YDS",
  rushing_touchdowns: "RSH TD",
  receiving_targets: "TGTS",
  receptions: "REC",
  receiving_yards: "REC YDS",
  receiving_touchdowns: "REC TD",
  rushing_yards_per_attempt: "R YDS/A",
  fumbles: "FUM",
  fumbles_lost: "LOST",
  kick_return_touchdowns: "KR TD",
  kick_return_yards: "KR YD",
  kick_returns: "KR",
  punt_return_touchdowns: "PR TD",
  punt_return_yards: "PR YD",
  punt_returns: "PR",
  extra_points_attempted: "XP ATT",
  extra_points_made: "XP",
  field_goals_attempted: "FG ATT",
  field_goals_made: "FG",
  field_goals_made0to19: "0-19",
  field_goals_made20to29: "20-29",
  field_goals_made30to39: "30-39",
  field_goals_made40to49: "40-49",
  field_goals_made50_plus: "50+",
};

export const NBA_STAT_ABBREVIATIONS = {
  field_goals_made: "FGM",
  field_goals_attempted: "FGA",
  field_goals_percentage: "FG%",
  effective_field_goals_percentage: "eFG%",
  two_pointers_made: "2P",
  two_pointers_attempted: "2PA",
  two_pointers_percentage: "2P%",
  three_pointers_made: "3P",
  three_pointers_attempted: "3PA",
  three_pointers_percentage: "3P%",
  free_throws_made: "FT",
  free_throws_attempted: "FTA",
  free_throws_percentage: "FT%",
  offensive_rebounds: "ORB",
  defensive_rebounds: "DRB",
  rebounds: "TRB",
  assists: "AST",
  steals: "STL",
  blocked_shots: "BLK",
  turnovers: "TO",
  personal_fouls: "PF",
  points: "PTS",
  true_shooting_attempts: "TSA",
  true_shooting_percentage: "TS%",
};

export const NFL_STAT_ABBREVIATIONS_DST = {
  points_allowed: "PA",
  sacks: "SACK",
  sack_yards: "SY",
  fumbles_forced: "FF",
  fumbles_recovered: "FR",
  fumble_return_yards: "FRY",
  fumble_return_touchdowns: "FMTD",
  interceptions: "INT",
  interception_return_yards: "INTYDS",
  interception_return_touchdowns: "INTTD",
  punt_returns: "PR",
  punt_return_yards: "PRYD",
  punt_return_touchdowns: "PRTD",
  kick_returns: "KR",
  kick_return_yards: "KRYD",
  kick_return_touchdowns: "KRTD",
  safeties: "SFT",
  blocked_kicks: "BLK",
  defensive_touchdowns: "TD",
  touchdowns_scored: "DF TD",
};

export const NHL_STAT_ABBREVIATIONS = {
  minutes: "M",
  goals: "G",
  assists: "A",
  shots_on_goal: "SoG",
  blocks: "BLK",
  short_handed_goals: "SHG",
  shootout_goals: "SG",
  hat_tricks: "HT",
  power_play_goals: "PPG",
  points: "P",
  hits: "H",
  goaltending_wins: "W",
  goaltending_saves: "SV",
  goaltending_goals_against: "GA",
  goaltending_shutouts: "SH",
  goaltending_overtime_losses: "OTL",
};

export const EPL_STAT_ABBREVIATIONS = {
  minutes: "MIN",
  goals: "G",
  assists: "AST",
  shots: "SH",
  shots_on_goal: "SG",
  yellow_cards: "CrdY",
  red_cards: "CrdR",
  yellow_red_cards: "2CrY",
  crosses: "CRS",
  tackles_won: "TKLW",
  interceptions: "INT",
  own_goals: "OG",
  fouls: "F",
  fouled: "FD",
  offsides: "OFF",
  passes: "PSS",
  passes_completed: "PSC",
  last_man_tackle: "LMT",
  corners_won: "CW",
  blocked_shots: "B",
  touches: "TCH",
  defender_clean_sheets: "DCS",
  goalkeeper_saves: "SV",
  goalkeeper_goals_against: "GA",
  goalkeeper_single_goal_against: "SGA",
  goalkeeper_clean_sheets: "GKCS",
  goalkeeper_wins: "W",
  penalty_kick_goals: "PKG",
  penalty_kick_misses: "PKM",
  penalty_kick_saves: "PKSV",
  penalties_won: "PW",
  penalties_conceded: "PL",
  score: "SCR",
  opponent_score: "OPSC",
};

export const MLB_STAT_ABBREVIATIONS = {
  at_bats: "AB",
  runs: "R",
  hits: "H",
  singles: "1B",
  doubles: "2B",
  triples: "3B",
  home_runs: "HR",
  runs_batted_in: "RBI",
  batting_average: "BA",
  outs: "O",
  strikeouts: "K",
  walks: "BB",
  hit_by_pitch: "HBP",
  stolen_base: "SB",
  innings_pitched_decimal: "IPD",
  innings_pitched_full: "IP",
  innings_pitched_outs: "IPO",
  pitching_hits: "H",
  pitching_runs: "R",
  pitching_walks: "BB",
  pitching_strikeouts: "K",
  pitching_home_runs: "HR",
  pitches_thrown: "NP",
  grand_slams: "GS",
  total_bases: "TB",
  pitching_complete_games: "CG",
  pitching_shut_outs: "SHO",
  pitching_no_hitter: "NH",
  pitching_hit_by_pitch: "HBP",
  wins: "W",
  strike_outs: "K",
  stolen_bases: "SB",
  caught_stealing: "CS",
  pitching_earned_runs: "ER",
  pitches_thrown_strikes: "S",
  pitches_singles: "PS",
  pitches_doubles: "PD",
  pitches_triples: "PT",
  pitches_grand_slams: "PGS",
  pitching_no_hitters: "NH",
  pitching_perfect_games: "PG",
  pitching_total_bases: "TB",
};

export const NBA_IMPORTANT_STAT_VALUES = [
  "points",
  "rebounds",
  "assists",
  "steals",
  "blocked_shots",
];
export const EPL_D_IMPORTANT_STAT_VALUES = [
  "goals",
  "assists",
  "shots",
  "tackles_won",
  "interceptions",
];
export const EPL_A_IMPORTANT_STAT_VALUES = [
  "goals",
  "assists",
  "shots",
  "tackles_won",
  "shots_on_goal",
];
export const EPL_M_IMPORTANT_STAT_VALUES = [
  "goals",
  "assists",
  "shots",
  "tackles_won",
  "shots_on_goal",
];
export const EPL_GK_IMPORTANT_STAT_VALUES = [
  "goalkeeper_saves",
  "goalkeeper_goals_against",
  "goalkeeper_clean_sheets",
  "goalkeeper_wins",
  "penalty_kick_saves",
];
export const QB_IMPORTANT_STAT_VALUES = [
  "passing_yards",
  "passing_touchdowns",
  "passing_interceptions",
  "rushing_yards",
  "rushing_touchdowns",
];
export const RB_IMPORTANT_STAT_VALUES = [
  "rushing_attempts",
  "rushing_yards",
  "rushing_touchdowns",
  "receiving_yards",
  "receiving_touchdowns",
];
export const WR_IMPORTANT_STAT_VALUES = [
  "receiving_targets",
  "receptions",
  "receiving_yards",
  "receiving_touchdowns",
];
export const TE_IMPORTANT_STAT_VALUES = [
  "receiving_targets",
  "receptions",
  "receiving_yards",
  "receiving_touchdowns",
];
export const DST_IMPORTANT_STAT_VALUES = [
  "points_allowed",
  "sacks",
  "fumbles_forced",
  "interceptions",
];

export const K_IMPORTANT_STAT_VALUES = [
  "extra_points_made",
  "field_goals_made",
];

export const MLB_BATTING_IMPORTANT_STAT_VALUES = [
  "singles",
  "doubles",
  "triples",
  "home_runs",
  "runs_batted_in",
];
export const MLB_PITCHING_IMPORTANT_STAT_VALUES = [
  "wins",
  "innings_pitched_decimal",
  "pitching_hits",
  "pitching_runs",
  "pitching_strikeouts",
];

export const NHL_OFF_IMPORTANT_STAT_VALUES = [
  "goals",
  "assists",
  "shots_on_goal",
  "points",
  "blocks",
];

export const NHL_G_IMPORTANT_STAT_VALUES = [
  "goaltending_wins,",
  "goaltending_saves",
  "goaltending_goals_against",
  "goaltending_shutouts",
  "goaltending_overtime_losses",
];

export const NFL_TEAM_COLORS = {
  ATL: "#c5223b",
  CHI: "#e5612c",
  BAL: "#5539e8",
  CIN: "#FB4F14",
  CAR: "#26a9ef",
  BUF: "#2970e8",
  ARI: "#e72656",
  CLE: "#f85725",
  DAL: "#3973f5",
  DEN: "#ea6134",
  DET: "#2cbce8",
  GB: "#1c9f47",
  HOU: "#2183c7",
  IND: "#3486f1",
  JAX: "#D7A22A",
  KC: "#E31837",
  LAC: "#0080C6",
  LAR: "#216cef",
  LV: "#9a9a9a",
  MIA: "#17c6d3",
  MIN: "#822eec",
  NE: "#1a7fda",
  NO: "#e0be77",
  NYG: "#2c5ce5",
  NYJ: "#25b688",
  PHI: "#0aa0b0",
  PIT: "#e5b326",
  SEA: "#69BE28",
  SF: "#d71818",
  TB: "#D50A0A",
  TEN: "#4B92DB",
  WAS: "#d02f56",
  AFC: "#e3242b",
  NFC: "#3944bc",
  "Atlanta Falcons": "#c5223b",
  "Chicago Bears": "#e5612c",
  "Baltimore Ravens": "#5539e8",
  "Cincinnati Bengals": "#FB4F14",
  "Carolina Panthers": "#26a9ef",
  "Buffalo Bills": "#2970e8",
  "Arizona Cardinals": "#e72656",
  "Cleveland Browns": "#f85725",
  "Dallas Cowboys": "#3973f5",
  "Denver Broncos": "#ea6134",
  "Detroit Lions": "#2cbce8",
  "Green Bay Packers": "#1c9f47",
  "Houston Texans": "#2183c7",
  "Indianapolis Colts": "#3486f1",
  "Jacksonville Jaguars": "#D7A22A",
  "Kansas City Chiefs": "#E31837",
  "Los Angeles Chargers": "#0080C6",
  "Los Angeles Rams": "#216cef",
  "Las Vegas Raiders": "#9a9a9a",
  "Miami Dolphins": "#17c6d3",
  "Minnesota Vikings": "#822eec",
  "New England Patriots": "#1a7fda",
  "New Orleans Saints": "#e0be77",
  "New York Giants": "#2c5ce5",
  "New York Jets": "#25b688",
  "Philadelphia Eagles": "#0aa0b0",
  "Pittsburgh Steelers": "#e5b326",
  "Seattle Seahawks": "#69BE28",
  "San Francisco 49ers": "#d71818",
  "Tampa Bay Buccaneers": "#D50A0A",
  "Tennessee Titans": "#4B92DB",
  "Washington Football Team": "#d02f56",
};

export const NBA_TEAM_COLORS = {
  ATL: "#e14549",
  BOS: "#36ce74",
  BKN: "#a2a2a2",
  CHA: "#51a4e8",
  CHI: "#cc3457",
  CLE: "#da495f",
  DAL: "#449bd7",
  DEN: "#467cce",
  DET: "#d5354f",
  GS: "#2f64c9",
  HOU: "#d2345c",
  IND: "#3171bd",
  LAC: "#cc3c54",
  LAL: "#8444cc",
  MEM: "#3965c7",
  MIA: "#d33e3e",
  MIL: "#2daf5c",
  MIN: "#376eb7",
  NY: "#3483bb",
  NO: "#c5a73e",
  OKC: "#d5612f",
  ORL: "#3493d0",
  PHI: "#3e95d5",
  PHO: "#5c47d2",
  POR: "#E03A3E",
  SAC: "#8544c2",
  SA: "#b4b4b4",
  TOR: "#cc3b51",
  UTA: "#3e80d2",
  WAS: "#296dbe",
  "Atlanta Hawks": "#e14549",
  "Boston Celtics": "#36ce74",
  "Brooklyn Nets": "#a2a2a2",
  "Charlotte Hornets": "#51a4e8",
  "Chicago Bulls": "#cc3457",
  "Cleveland Cavaliers": "#da495f",
  "Dallas Mavericks": "#449bd7",
  "Denver Nuggets": "#467cce",
  "Detroit Pistons": "#d5354f",
  "Golden State Warriors": "#2f64c9",
  "Houston Rockets": "#d2345c",
  "Indiana Pacers": "#3171bd",
  "LA Clippers": "#cc3c54",
  "Los Angeles Lakers": "#8444cc",
  "Memphis Grizzlies": "#3965c7",
  "Miami Heat": "#d33e3e",
  "Milwaukee Bucks": "#2daf5c",
  "Minnesota Timberwolves": "#376eb7",
  "New York Knicks": "#3483bb",
  "New Orleans Pelicans": "#cbaa3e",
  "Oklahoma City Thunder": "#d5612f",
  "Orlando Magic": "#3493d0",
  "Philadelphia 76ers": "#3e95d5",
  "Phoenix Suns": "#5c47d2",
  "Portland Trail Blazers": "#E03A3E",
  "Sacramento Kings": "#8544c2",
  "San Antonio Spurs": "#b4b4b4",
  "Toronto Raptors": "#cc3b51",
  "Utah Jazz": "#3e80d2",
  "Washington Wizards": "#296dbe",
};

export const WNBA_TEAM_COLORS = {
  ATL: "#d7425a",
  CHI: "#eecd59",
  CONN: "#dc5067",
  DAL: "#4482cb",
  IND: "#d33950",
  LV: "#dc3f5f",
  LA: "#eebe46",
  MIN: "#2283ff",
  NY: "#6ECEB2",
  PHX: "#775bf3",
  SEA: "#FBE122",
  WAS: "#2880f3",
  "Atlanta Dream": "#d7425a",
  "Chicago Sky": "#eecd59",
  "Connecticut Sun": "#dc5067",
  "Dallas Wings": "#4482cb",
  "Indiana Fever": "#d33950",
  "Las Vegas Aces": "#dc3f5f",
  "Los Angeles Sparks": "#eebe46",
  "Minnesota Lynx": "#2283ff",
  "New York Liberty": "#6ECEB2",
  "Phoenix Mercury": "#775bf3",
  "Seattle Storm": "#FBE122",
  "Washington Mystics": "#2880f3",
};

export const CFB_TEAM_COLORS = {
  "Boston College": "#c72729",
  Clemson: "#F66733",
  Duke: "#275ade",
  "Florida State": "#c73a5d",
  "Georgia Tech": "#C99700",
  Louisville: "#da3030",
  "Miami Florida": "#F47321",
  "North Carolina": "#52a2da",
  "North Carolina State": "#e02e2e",
  Pittsburgh: "#145b9f",
  Syracuse: "#FF6600",
  Virginia: "#1770e5",
  "Virginia Tech": "#da2020",
  "Wake Forest": "#C6A664",
  Illinois: "#ef6c17",
  Indiana: "#d02139",
  Iowa: "#e0c611",
  Maryland: "#FF0000",
  Michigan: "#FFCB05",
  "Michigan State": "#178a72",
  Minnesota: "#b0092f",
  Nebraska: "#D00000",
  Northwestern: "#803ae1",
  "Ohio State": "#d32222",
  "Penn State": "#175bbd",
  Purdue: "#C99700",
  Wisconsin: "#C8102E",
  Baylor: "#1a8034",
  TCU: "#681fa6",
  Texas: "#d26c18",
  "Texas Tech": "#CC0000",
  "Iowa State": "#f35e73",
  Kansas: "#4084e3",
  "Kansas State": "#6e30c4",
  Oklahoma: "#c72b2c",
  "Oklahoma State": "#FF6600",
  "West Virginia": "#C6A664",
  Arizona: "#d31c3b",
  "Arizona State": "#d2275d",
  California: "#1065c0",
  Colorado: "#947e36",
  Oregon: "#1f835b",
  "Oregon State": "#FF6F00",
  Stanford: "#b72323",
  UCLA: "#2e629b",
  USC: "#cc1d1d",
  Utah: "#d32020",
  Washington: "#7750bb",
  "Washington State": "#d04a60",
  Alabama: "#d05050",
  Arkansas: "#9D2235",
  Auburn: "#FF6F00",
  Florida: "#FF8200",
  Georgia: "#DA291C",
  Kentucky: "#005DAA",
  LSU: "#6b2ac0",
  "Ole Miss": "#9D2235",
  "Mississippi State": "#af1818",
  Missouri: "#e3a20d",
  "South Carolina": "#c21f2c",
  Tennessee: "#FF8200",
  "Texas A&M": "#a80e0e",
  Vanderbilt: "#a68d44",
  "Notre Dame": "#1340ab",
  BYU: "#1a4bab",
  UCF: "#a88e4e",
  Houston: "#E31736",
  Cincinnati: "#EF3340",
  "Murray State": "#215cd2",
};

export const NHL_TEAM_COLORS = {
  BOS: "#deca4f",
  BUF: "#4981ee",
  DET: "#ee4657",
  MON: "#e7505d",
  OTT: "#e75353",
  TB: "#4d83ee",
  TOR: "#366bd3",
  FLA: "#e13d56",
  CAR: "#e74b4b",
  NJ: "#e83c50",
  NYI: "#3f89cb",
  NYR: "#3a6cd0",
  PHI: "#ea672f",
  PIT: "#e7c84e",
  WAS: "#e53e58",
  CBJ: "#3f82d9",
  CHI: "#e74959",
  DAL: "#39b48f",
  COL: "#d2476e",
  STL: "#3369c5",
  NAS: "#d9a63b",
  WPG: "#4793ef",
  MIN: "#36ab59",
  CGY: "#cb3a52",
  EDM: "#e17143",
  LA: "#c9c8c8",
  SJ: "#2fbec7",
  VAN: "#3785cc",
  ARI: "#be3550",
  ANA: "#e38246",
  VEG: "#d3b34a",
  SEA: "#3fd1d7",
  "Boston Bruins": "#deca4f",
  "Buffalo Sabres": "#4981ee",
  "Detroit Red Wings": "#ee4657",
  "Montreal Canadiens": "#e7505d",
  "Ottawa Senators": "#e75353",
  "Tampa Bay Lightning": "#4d83ee",
  "Toronto Maple Leafs": "#366bd3",
  "Florida Panthers": "#e13d56",
  "Carolina Hurricanes": "#e74b4b",
  "New Jersey Devils": "#e83c50",
  "New York Islanders": "#3f89cb",
  "New York Rangers": "#3a6cd0",
  "Philadelphia Flyers": "#ea672f",
  "Pittsburgh Penguins": "#e7c84e",
  "Washington Capitals": "#e53e58",
  "Columbus Blue Jackets": "#3f82d9",
  "Chicago Blackhawks": "#e74959",
  "Dallas Stars": "#39b48f",
  "Colorado Avalanche": "#d2476e",
  "St. Louis Blues": "#3369c5",
  "Nashville Predators": "#d9a63b",
  "Winnipeg Jets": "#4793ef",
  "Minnesota Wild": "#36ab59",
  "Calgary Flames": "#cb3a52",
  "Edmonton Oilers": "#e17143",
  "Los Angeles Kings": "#c9c8c8",
  "San Jose Sharks": "#2fbec7",
  "Vancouver Canucks": "#3785cc",
  "Arizona Coyotes": "#be3550",
  "Anaheim Ducks": "#e38246",
  "Vegas Golden Knights": "#d3b34a",
  "Seattle Kraken": "#3fd1d7",
};

export const EPL_TEAM_COLORS = {
  ARS: "#EF0107",
  BOR: "#DA291C",
  CFC: "#034694",
  CRY: "#1B458F",
  EVE: "#003399",
  LEI: "#003090",
  LIV: "#C8102E",
  MNC: "#6CABDD",
  AST: "#95BFE5",
  MNU: "#DA291C",
  NOR: "#FFF200",
  NEW: "#41B6E6",
  SOT: "#D71920",
  STO: "#E03A3E",
  SUN: "#eb172b",
  SWA: "#121212",
  TOT: "#132257",
  WAT: "#FBEE23",
  WBA: "#122F67",
  WHU: "#7A263A",
  BUR: "#6C1D45",
  HUL: "#f5971d",
  MID: "#e11b22",
  BHA: "#0057B8",
  HUD: "#0E63AD",
  CAR: "#0070B5",
  WOL: "#FDB913",
  SHE: "#EE2737",
  BRE: "#e30613",
  LEE: "#FFCD00",
  FUL: "#CC0000",
  NOT: "#EB0024",

  "Arsenal FC": "#EF0107",
  "AFC Bournemouth": "#DA291C",
  "Chelsea FC": "#034694",
  "Crystal Palace FC": "#1B458F",
  "Everton FC": "#003399",
  "Leicester City FC": "#003090",
  "Liverpool FC": "#C8102E",
  "Manchester City FC": "#6CABDD",
  "Aston Villa FC": "#95BFE5",
  "Manchester United FC": "#DA291C",
  "Norwich City FC": "#FFF200",
  "Newcastle United FC": "#41B6E6",
  "Southampton FC": "#D71920",
  "Stoke City FC": "#E03A3E",
  "Sunderland AFC": "#eb172b",
  "Swansea City AFC": "#121212",
  "Tottenham Hotspur FC": "#132257",
  "Watford FC": "#FBEE23",
  "West Bromwich Albion FC": "#122F67",
  "West Ham United FC": "#7A263A",
  "Burnley FC": "#6C1D45",
  "Hull City AFC": "#f5971d",
  "Middlesbrough FC": "#e11b22",
  "Brighton & Hove Albion FC": "#0057B8",
  "Huddersfield Town AFC": "#0E63AD",
  "Cardiff City FC": "#0070B5",
  "Wolverhampton Wanderers FC": "#FDB913",
  "Sheffield United FC": "#EE2737",
  "Brentford FC": "#e30613",
  "Leeds United FC": "#FFCD00",
  "Fulham FC": "#CC0000",
  "Nottingham Forest": "#EB0024",
  Nottingham: "#EB0024",
  "Nottingham Forest FC": "#EB0024",

  Arsenal: "#EF0107",
  Bournemouth: "#DA291C",
  Chelsea: "#034694",
  "Crystal Palace": "#1B458F",
  Everton: "#003399",
  "Leicester City": "#003090",
  Liverpool: "#C8102E",
  "Manchester City": "#6CABDD",
  "Man City": "#6CABDD",
  "Aston Villa": "#95BFE5",
  "Manchester United": "#DA291C",
  "Norwich City": "#FFF200",
  "Newcastle United": "#41B6E6",
  Southampton: "#D71920",
  "Stoke City": "#E03A3E",
  Sunderland: "#eb172b",
  "Swansea City": "#121212",
  "Tottenham Hotspur": "#132257",
  Watford: "#FBEE23",
  "West Bromwich Albion": "#122F67",
  "West Ham United": "#7A263A",
  Burnley: "#6C1D45",
  "Hull City": "#f5971d",
  Middlesbrough: "#e11b22",
  "Brighton & Hove Albion": "#0057B8",
  "Huddersfield Town": "#0E63AD",
  "Cardiff City": "#0070B5",
  Wolverhampton: "#FDB913",
  "Sheffield United": "#EE2737",
  Brentford: "#e30613",
  "Leeds United": "#FFCD00",
  Fulham: "#CC0000",
};

export const MLB_TEAM_COLORS = {
  ARI: "#d03952",
  ATL: "#cb234c",
  BAL: "#d3612c",
  BOS: "#d23d47",
  CHW: "#e1dede",
  CHC: "#2f67da",
  CIN: "#c42b43",
  CLE: "#E31937",
  COL: "#4e4ed3",
  DET: "#2468bb",
  HOU: "#ea7c35",
  KC: "#3179b9",
  LAA: "#bb233e",
  LAD: "#3275c2",
  MIA: "#e77625",
  MIL: "#2272d7",
  MIN: "#2b68ad",
  NYM: "#e55c21",
  NYY: "#ee4565",
  OAK: "#1a8f81",
  PHI: "#bb3242",
  PIT: "#FDB827",
  SD: "#b69d2f",
  SF: "#e35e2d",
  SEA: "#2372d3",
  STL: "#c7364f",
  TB: "#1774ee",
  TEX: "#e73342",
  TOR: "#2e7cd7",
  WSH: "#cb292d",
  "Arizona Diamondbacks": "#d03952",
  "Atlanta Braves": "#cb234c",
  "Baltimore Orioles": "#d3612c",
  "Boston Red Sox": "#d23d47",
  "Chicago White Sox": "#e1dede",
  "Chicago Cubs": "#2f67da",
  "Cincinnati Reds": "#c42b43",
  "Cleveland Guardians": "#E31937",
  "Colorado Rockies": "#4e4ed3",
  "Detroit Tigers": "#2468bb",
  "Houston Astros": "#ea7c35",
  "Kansas City Royals": "#3179b9",
  "Los Angeles Angels": "#bb233e",
  "Los Angeles Dodgers": "#3275c2",
  "Miami Marlins": "#e77625",
  "Milwaukee Brewers": "#2272d7",
  "Minnesota Twins": "#2b68ad",
  "New York Mets": "#e55c21",
  "New York Yankees": "#ee4565",
  "Oakland Athletics": "#1a8f81",
  "Philadelphia Phillies": "#bb3242",
  "Pittsburgh Pirates": "#FDB827",
  "San Diego Padres": "#b69d2f",
  "San Francisco Giants": "#e35e2d",
  "Seattle Mariners": "#2372d3",
  "St. Louis Cardinals": "#c7364f",
  "Tampa Bay Rays": "#1774ee",
  "Texas Rangers": "#e73342",
  "Toronto Blue Jays": "#2e7cd7",
  "Washington Nationals": "#cb292d",
};

export const LeagueFilterOptions = [
  { label: League.NFL, value: League.NFL, icon: "ball" },
  { label: League.CFB, value: League.CFB, icon: "ball" },
  { label: League.NBA, value: League.NBA, icon: "basketball" },
  { label: League.MLB, value: League.MLB, icon: "baseball" },
  { label: League.NHL, value: League.NHL, icon: "circle" },
  { label: League.WNBA, value: League.WNBA, icon: "basketball" },
  { label: League.CSGO, value: League.CSGO, icon: "crosshair" },
  { label: League.LOL, value: League.LOL, icon: "map-alt" },
];

export enum PlayerStatType {
  Projection,
  PickProp,
}

export const colorNeutral = {
  0: "#ffffff",
  50: "#f4f4f5",
  100: "#e4e4e7",
  200: "#d4d4d8",
  300: "#a1a1aa",
  400: "#71717a",
  500: "#52525b",
  600: "#3f3f46",
  700: "#26282c",
  800: "#1f2125",
  900: "#181a1c",
};

export const colorSuccess = {
  50: "#ebfcf5",
  100: "#d2fae7",
  200: "#a7f2d2",
  300: "#6fe8bc",
  400: "#35d49f",
  500: "#11ba87",
  600: "#05966d",
  700: "#04785b",
  800: "#065e48",
  900: "#064f3e",
};

export const colorPrimary = {
  50: "#e7e2fd",
  100: "#c0b6f7",
  200: "#a494f3",
  300: "#856eee",
  400: "#6547e9",
  500: "#502ee5",
  600: "#4529bf",
  700: "#3a2499",
  800: "#2f1f73",
  900: "#23194d",
};

export const colorWarning = {
  50: "#fefce8",
  100: "#fef9c3",
  200: "#fef08a",
  300: "#fde047",
  400: "#facc15",
  500: "#eab308",
  600: "#ca8a04",
  700: "#a16207",
  800: "#854d0e",
  900: "#713f12",
};

export const colorOrange = {
  50: "#e7e2fd",
  100: "#c0b6f7",
  200: "#a494f3",
  300: "#856eee",
  400: "#6547e9",
  500: "#ff9861",
  600: "#4529bf",
  700: "#3a2499",
  800: "#2f1f73",
  900: "#23194d",
};

export const colorDanger = {
  50: "#fbf1f3",
  100: "#faabbd",
  200: "#f8829b",
  300: "#f5587a",
  400: "#f32e59",
  500: "#c22547",
  600: "#921c35",
  700: "#611224",
  800: "#310912",
  900: "#180509",
};
export const colorTurquoise = {
  50: "#e0f9ff",
  100: "#c7f2ff",
  200: "#a4e5fc",
  300: "#81d0f7",
  400: "#63b7f2",
  500: "#4799e6",
  600: "#387fc9",
  700: "#2f69a3",
  800: "#2f5b82",
  900: "#2a4f70",
};

export const colorPink = {
  50: "#f9e0ff",
  100: "#f6c7ff",
  200: "#f1a4fc",
  300: "#ef81f7",
  400: "#f263f0",
  500: "#e647d8",
  600: "#c938b9",
  700: "#a32f98",
  800: "#822f7d",
  900: "#702a6b",
};

export const colorLime = {
  50: "#f7fee7",
  100: "#ecfccb",
  200: "#d9f99d",
  300: "#bef264",
  400: "#a3e635",
  500: "#84cc16",
  600: "#65a30d",
  700: "#4d7c0f",
  800: "#3f6212",
  900: "#365314",
};

export const COUNTRIES = [
  {
    label: "Afghanistan",
    value: "AF",
  },
  {
    label: "Albania",
    value: "AL",
  },
  {
    label: "Algeria",
    value: "DZ",
  },
  {
    label: "American Samoa",
    value: "AS",
  },
  {
    label: "Andorra",
    value: "AD",
  },
  {
    label: "Angola",
    value: "AO",
  },
  {
    label: "Anguilla",
    value: "AI",
  },
  {
    label: "Antarctica",
    value: "AQ",
  },
  {
    label: "Antigua and Barbuda",
    value: "AG",
  },
  {
    label: "Argentina",
    value: "AR",
  },
  {
    label: "Armenia",
    value: "AM",
  },
  {
    label: "Aruba",
    value: "AW",
  },
  {
    label: "Australia",
    value: "AU",
  },
  {
    label: "Austria",
    value: "AT",
  },
  {
    label: "Azerbaijan",
    value: "AZ",
  },
  {
    label: "Bahamas",
    value: "BS",
  },
  {
    label: "Bahrain",
    value: "BH",
  },
  {
    label: "Bangladesh",
    value: "BD",
  },
  {
    label: "Barbados",
    value: "BB",
  },
  {
    label: "Belarus",
    value: "BY",
  },
  {
    label: "Belgium",
    value: "BE",
  },
  {
    label: "Belize",
    value: "BZ",
  },
  {
    label: "Benin",
    value: "BJ",
  },
  {
    label: "Bermuda",
    value: "BM",
  },
  {
    label: "Bhutan",
    value: "BT",
  },
  {
    label: "Bolivia (Plurinational State of)",
    value: "BO",
  },
  {
    label: "Bonaire, Sint Eustatius and Saba",
    value: "BQ",
  },
  {
    label: "Bosnia and Herzegovina",
    value: "BA",
  },
  {
    label: "Botswana",
    value: "BW",
  },
  {
    label: "Bouvet Island",
    value: "BV",
  },
  {
    label: "Brazil",
    value: "BR",
  },
  {
    label: "British Indian Ocean Territory",
    value: "IO",
  },
  {
    label: "Brunei Darussalam",
    value: "BN",
  },
  {
    label: "Bulgaria",
    value: "BG",
  },
  {
    label: "Burkina Faso",
    value: "BF",
  },
  {
    label: "Burundi",
    value: "BI",
  },
  {
    label: "Cabo Verde",
    value: "CV",
  },
  {
    label: "Cambodia",
    value: "KH",
  },
  {
    label: "Cameroon",
    value: "CM",
  },
  {
    label: "Canada",
    value: "CA",
  },
  {
    label: "Cayman Islands",
    value: "KY",
  },
  {
    label: "Central African Republic",
    value: "CF",
  },
  {
    label: "Chad",
    value: "TD",
  },
  {
    label: "Chile",
    value: "CL",
  },
  {
    label: "China",
    value: "CN",
  },
  {
    label: "Christmas Island",
    value: "CX",
  },
  {
    label: "Cocos (Keeling) Islands",
    value: "CC",
  },
  {
    label: "Colombia",
    value: "CO",
  },
  {
    label: "Comoros",
    value: "KM",
  },
  {
    label: "Congo (the Democratic Republic of the)",
    value: "CD",
  },
  {
    label: "Congo",
    value: "CG",
  },
  {
    label: "Cook Islands",
    value: "CK",
  },
  {
    label: "Costa Rica",
    value: "CR",
  },
  {
    label: "Croatia",
    value: "HR",
  },
  {
    label: "Cuba",
    value: "CU",
  },
  {
    label: "Curaçao",
    value: "CW",
  },
  {
    label: "Cyprus",
    value: "CY",
  },
  {
    label: "Czechia",
    value: "CZ",
  },
  {
    label: "Côte d'Ivoire",
    value: "CI",
  },
  {
    label: "Denmark",
    value: "DK",
  },
  {
    label: "Djibouti",
    value: "DJ",
  },
  {
    label: "Dominica",
    value: "DM",
  },
  {
    label: "Dominican Republic",
    value: "DO",
  },
  {
    label: "Ecuador",
    value: "EC",
  },
  {
    label: "Egypt",
    value: "EG",
  },
  {
    label: "El Salvador",
    value: "SV",
  },
  {
    label: "Equatorial Guinea",
    value: "GQ",
  },
  {
    label: "Eritrea",
    value: "ER",
  },
  {
    label: "Estonia",
    value: "EE",
  },
  {
    label: "Eswatini",
    value: "SZ",
  },
  {
    label: "Ethiopia",
    value: "ET",
  },
  {
    label: "Falkland Islands [Malvinas]",
    value: "FK",
  },
  {
    label: "Faroe Islands",
    value: "FO",
  },
  {
    label: "Fiji",
    value: "FJ",
  },
  {
    label: "Finland",
    value: "FI",
  },
  {
    label: "France",
    value: "FR",
  },
  {
    label: "French Guiana",
    value: "GF",
  },
  {
    label: "French Polynesia",
    value: "PF",
  },
  {
    label: "French Southern Territories",
    value: "TF",
  },
  {
    label: "Gabon",
    value: "GA",
  },
  {
    label: "Gambia",
    value: "GM",
  },
  {
    label: "Georgia",
    value: "GE",
  },
  {
    label: "Germany",
    value: "DE",
  },
  {
    label: "Ghana",
    value: "GH",
  },
  {
    label: "Gibraltar",
    value: "GI",
  },
  {
    label: "Greece",
    value: "GR",
  },
  {
    label: "Greenland",
    value: "GL",
  },
  {
    label: "Grenada",
    value: "GD",
  },
  {
    label: "Guadeloupe",
    value: "GP",
  },
  {
    label: "Guam",
    value: "GU",
  },
  {
    label: "Guatemala",
    value: "GT",
  },
  {
    label: "Guernsey",
    value: "GG",
  },
  {
    label: "Guinea",
    value: "GN",
  },
  {
    label: "Guinea-Bissau",
    value: "GW",
  },
  {
    label: "Guyana",
    value: "GY",
  },
  {
    label: "Haiti",
    value: "HT",
  },
  {
    label: "Heard Island and McDonald Islands",
    value: "HM",
  },
  {
    label: "Holy See",
    value: "VA",
  },
  {
    label: "Honduras",
    value: "HN",
  },
  {
    label: "Hong Kong",
    value: "HK",
  },
  {
    label: "Hungary",
    value: "HU",
  },
  {
    label: "Iceland",
    value: "IS",
  },
  {
    label: "India",
    value: "IN",
  },
  {
    label: "Indonesia",
    value: "ID",
  },
  {
    label: "Iran (Islamic Republic of)",
    value: "IR",
  },
  {
    label: "Iraq",
    value: "IQ",
  },
  {
    label: "Ireland",
    value: "IE",
  },
  {
    label: "Isle of Man",
    value: "IM",
  },
  {
    label: "Israel",
    value: "IL",
  },
  {
    label: "Italy",
    value: "IT",
  },
  {
    label: "Jamaica",
    value: "JM",
  },
  {
    label: "Japan",
    value: "JP",
  },
  {
    label: "Jersey",
    value: "JE",
  },
  {
    label: "Jordan",
    value: "JO",
  },
  {
    label: "Kazakhstan",
    value: "KZ",
  },
  {
    label: "Kenya",
    value: "KE",
  },
  {
    label: "Kiribati",
    value: "KI",
  },
  {
    label: "Korea (the Democratic People's Republic of)",
    value: "KP",
  },
  {
    label: "Korea (the Republic of)",
    value: "KR",
  },
  {
    label: "Kuwait",
    value: "KW",
  },
  {
    label: "Kyrgyzstan",
    value: "KG",
  },
  {
    label: "Lao People's Democratic Republic",
    value: "LA",
  },
  {
    label: "Latvia",
    value: "LV",
  },
  {
    label: "Lebanon",
    value: "LB",
  },
  {
    label: "Lesotho",
    value: "LS",
  },
  {
    label: "Liberia",
    value: "LR",
  },
  {
    label: "Libya",
    value: "LY",
  },
  {
    label: "Liechtenstein",
    value: "LI",
  },
  {
    label: "Lithuania",
    value: "LT",
  },
  {
    label: "Luxembourg",
    value: "LU",
  },
  {
    label: "Macao",
    value: "MO",
  },
  {
    label: "Madagascar",
    value: "MG",
  },
  {
    label: "Malawi",
    value: "MW",
  },
  {
    label: "Malaysia",
    value: "MY",
  },
  {
    label: "Maldives",
    value: "MV",
  },
  {
    label: "Mali",
    value: "ML",
  },
  {
    label: "Malta",
    value: "MT",
  },
  {
    label: "Marshall Islands",
    value: "MH",
  },
  {
    label: "Martinique",
    value: "MQ",
  },
  {
    label: "Mauritania",
    value: "MR",
  },
  {
    label: "Mauritius",
    value: "MU",
  },
  {
    label: "Mayotte",
    value: "YT",
  },
  {
    label: "Mexico",
    value: "MX",
  },
  {
    label: "Micronesia (Federated States of)",
    value: "FM",
  },
  {
    label: "Moldova (the Republic of)",
    value: "MD",
  },
  {
    label: "Monaco",
    value: "MC",
  },
  {
    label: "Mongolia",
    value: "MN",
  },
  {
    label: "Montenegro",
    value: "ME",
  },
  {
    label: "Montserrat",
    value: "MS",
  },
  {
    label: "Morocco",
    value: "MA",
  },
  {
    label: "Mozambique",
    value: "MZ",
  },
  {
    label: "Myanmar",
    value: "MM",
  },
  {
    label: "Namibia",
    value: "NA",
  },
  {
    label: "Nauru",
    value: "NR",
  },
  {
    label: "Nepal",
    value: "NP",
  },
  {
    label: "Netherlands",
    value: "NL",
  },
  {
    label: "New Caledonia",
    value: "NC",
  },
  {
    label: "New Zealand",
    value: "NZ",
  },
  {
    label: "Nicaragua",
    value: "NI",
  },
  {
    label: "Niger",
    value: "NE",
  },
  {
    label: "Nigeria",
    value: "NG",
  },
  {
    label: "Niue",
    value: "NU",
  },
  {
    label: "Norfolk Island",
    value: "NF",
  },
  {
    label: "Northern Mariana Islands",
    value: "MP",
  },
  {
    label: "Norway",
    value: "NO",
  },
  {
    label: "Oman",
    value: "OM",
  },
  {
    label: "Pakistan",
    value: "PK",
  },
  {
    label: "Palau",
    value: "PW",
  },
  {
    label: "Palestine, State of",
    value: "PS",
  },
  {
    label: "Panama",
    value: "PA",
  },
  {
    label: "Papua New Guinea",
    value: "PG",
  },
  {
    label: "Paraguay",
    value: "PY",
  },
  {
    label: "Peru",
    value: "PE",
  },
  {
    label: "Philippines",
    value: "PH",
  },
  {
    label: "Pitcairn",
    value: "PN",
  },
  {
    label: "Poland",
    value: "PL",
  },
  {
    label: "Portugal",
    value: "PT",
  },
  {
    label: "Puerto Rico",
    value: "PR",
  },
  {
    label: "Qatar",
    value: "QA",
  },
  {
    label: "Republic of North Macedonia",
    value: "MK",
  },
  {
    label: "Romania",
    value: "RO",
  },
  {
    label: "Russian Federation",
    value: "RU",
  },
  {
    label: "Rwanda",
    value: "RW",
  },
  {
    label: "Réunion",
    value: "RE",
  },
  {
    label: "Saint Barthélemy",
    value: "BL",
  },
  {
    label: "Saint Helena, Ascension and Tristan da Cunha",
    value: "SH",
  },
  {
    label: "Saint Kitts and Nevis",
    value: "KN",
  },
  {
    label: "Saint Lucia",
    value: "LC",
  },
  {
    label: "Saint Martin (French part)",
    value: "MF",
  },
  {
    label: "Saint Pierre and Miquelon",
    value: "PM",
  },
  {
    label: "Saint Vincent and the Grenadines",
    value: "VC",
  },
  {
    label: "Samoa",
    value: "WS",
  },
  {
    label: "San Marino",
    value: "SM",
  },
  {
    label: "Sao Tome and Principe",
    value: "ST",
  },
  {
    label: "Saudi Arabia",
    value: "SA",
  },
  {
    label: "Senegal",
    value: "SN",
  },
  {
    label: "Serbia",
    value: "RS",
  },
  {
    label: "Seychelles",
    value: "SC",
  },
  {
    label: "Sierra Leone",
    value: "SL",
  },
  {
    label: "Singapore",
    value: "SG",
  },
  {
    label: "Sint Maarten (Dutch part)",
    value: "SX",
  },
  {
    label: "Slovakia",
    value: "SK",
  },
  {
    label: "Slovenia",
    value: "SI",
  },
  {
    label: "Solomon Islands",
    value: "SB",
  },
  {
    label: "Somalia",
    value: "SO",
  },
  {
    label: "South Africa",
    value: "ZA",
  },
  {
    label: "South Georgia and the South Sandwich Islands",
    value: "GS",
  },
  {
    label: "South Sudan",
    value: "SS",
  },
  {
    label: "Spain",
    value: "ES",
  },
  {
    label: "Sri Lanka",
    value: "LK",
  },
  {
    label: "Sudan",
    value: "SD",
  },
  {
    label: "Suriname",
    value: "SR",
  },
  {
    label: "Svalbard and Jan Mayen",
    value: "SJ",
  },
  {
    label: "Sweden",
    value: "SE",
  },
  {
    label: "Switzerland",
    value: "CH",
  },
  {
    label: "Syrian Arab Republic",
    value: "SY",
  },
  {
    label: "Taiwan (Province of China)",
    value: "TW",
  },
  {
    label: "Tajikistan",
    value: "TJ",
  },
  {
    label: "Tanzania, United Republic of",
    value: "TZ",
  },
  {
    label: "Thailand",
    value: "TH",
  },
  {
    label: "Timor-Leste",
    value: "TL",
  },
  {
    label: "Togo",
    value: "TG",
  },
  {
    label: "Tokelau",
    value: "TK",
  },
  {
    label: "Tonga",
    value: "TO",
  },
  {
    label: "Trinidad and Tobago",
    value: "TT",
  },
  {
    label: "Tunisia",
    value: "TN",
  },
  {
    label: "Turkey",
    value: "TR",
  },
  {
    label: "Turkmenistan",
    value: "TM",
  },
  {
    label: "Turks and Caicos Islands",
    value: "TC",
  },
  {
    label: "Tuvalu",
    value: "TV",
  },
  {
    label: "Uganda",
    value: "UG",
  },
  {
    label: "Ukraine",
    value: "UA",
  },
  {
    label: "United Arab Emirates",
    value: "AE",
  },
  {
    label: "United Kingdom of Great Britain and Northern Ireland",
    value: "GB",
  },
  {
    label: "United States Minor Outlying Islands",
    value: "UM",
  },
  {
    label: "United States of America",
    value: "US",
  },
  {
    label: "Uruguay",
    value: "UY",
  },
  {
    label: "Uzbekistan",
    value: "UZ",
  },
  {
    label: "Vanuatu",
    value: "VU",
  },
  {
    label: "Venezuela (Bolivarian Republic of)",
    value: "VE",
  },
  {
    label: "Viet Nam",
    value: "VN",
  },
  {
    label: "Virgin Islands (British)",
    value: "VG",
  },
  {
    label: "Virgin Islands (U.S.)",
    value: "VI",
  },
  {
    label: "Wallis and Futuna",
    value: "WF",
  },
  {
    label: "Western Sahara",
    value: "EH",
  },
  {
    label: "Yemen",
    value: "YE",
  },
  {
    label: "Zambia",
    value: "ZM",
  },
  {
    label: "Zimbabwe",
    value: "ZW",
  },
  {
    label: "Åland Islands",
    value: "AX",
  },
];

export const US_STATES = [
  { label: "--", value: "XX" },
  { label: "Alabama", value: "AL" },
  { label: "Alaska", value: "AK" },
  { label: "Arizona", value: "AZ" },
  { label: "Arkansas", value: "AR" },
  { label: "California", value: "CA" },
  { label: "Colorado", value: "CO" },
  { label: "Connecticut", value: "CT" },
  { label: "Delaware", value: "DE" },
  { label: "Washington DC", value: "DC" },
  { label: "Florida", value: "FL" },
  { label: "Georgia", value: "GA" },
  { label: "Hawaii", value: "HI" },
  { label: "Idaho", value: "ID" },
  { label: "Illinois", value: "IL" },
  { label: "Indiana", value: "IN" },
  { label: "Iowa", value: "IA" },
  { label: "Kansas", value: "KS" },
  { label: "Kentucky", value: "KY" },
  { label: "Louisiana", value: "LA" },
  { label: "Maine", value: "ME" },
  { label: "Maryland", value: "MD" },
  { label: "Massachusetts", value: "MA" },
  { label: "Michigan", value: "MI" },
  { label: "Minnesota", value: "MN" },
  { label: "Mississippi", value: "MS" },
  { label: "Missouri", value: "MO" },
  { label: "Montana", value: "MT" },
  { label: "Nebraska", value: "NE" },
  { label: "Nevada", value: "NV" },
  { label: "New Hampshire", value: "NH" },
  { label: "New Jersey", value: "NJ" },
  { label: "New Mexico", value: "NM" },
  { label: "New York", value: "NY" },
  { label: "North Carolina", value: "NC" },
  { label: "North Dakota", value: "ND" },
  { label: "Ohio", value: "OH" },
  { label: "Oklahoma", value: "OK" },
  { label: "Oregon", value: "OR" },
  { label: "Pennsylvania", value: "PA" },
  { label: "Rhode Island", value: "RI" },
  { label: "South Carolina", value: "SC" },
  { label: "South Dakota", value: "SD" },
  { label: "Tennessee", value: "TN" },
  { label: "Texas", value: "TX" },
  { label: "Utah", value: "UT" },
  { label: "Vermont", value: "VT" },
  { label: "Virginia", value: "VA" },
  { label: "Washington", value: "WA" },
  // { label: "West Virginia", value: "WV" },
  { label: "Wisconsin", value: "WI" },
  { label: "Wyoming", value: "WY" },
];

export const BALANCE_CURRENCIES = {
  SOL: "SOL",
  FANT: "FANT",
  USDC: "USDC",
};

export const DISCORD_LINK = "https://discord.gg/Tmc5tU3kGq";
export const MOONPAY_LINK_USDC =
  "https://buy.moonpay.com?defaultCurrencyCode=usdc_sol";

export const DOCUMENTATION_LINK =
  "https://docs.phantasia.app/phantasia-sports/";
export const FUND_WALLET_HELP_LINK =
  "https://docs.phantasia.app/phantasia-sports/";

export const COLOR_OPTIONS = {
  PRIMARY: "primary",
  SUCCESS: "success",
  DANGER: "danger",
  NEUTRAL: "neutral",
  OVER_RAISED: "over-raised",
};

export const FANT_BOOST_COST = 50;

export const TRANSACTION_WARNING_MESSAGE =
  "Your transaction never completed. This is normal and can happen often when the blockchain network is slow or congested. Please do not panic - just click retry below.";
export const TRANSACTION_DUPLICATE_MESSAGE =
  "You already have a lineup entered in this contest and the limit is 1 entry per user. Please go to the contest page to view or edit your lineup.";
export const TRANSACTION_LOW_SOL_MESSAGE =
  " You need some SOL in your wallet in order to pay transaction fees (very cheap! Roughly 1/20th of a US cent). To buy more, use your credit card on FTX Pay, Moonpay, or see documention for further options.";

export const PHANTASIA_PICKS_FAQ_RULES = [
  {
    q: "What happens if my pick is marked as DNP?",
    a:
      "A projection may be marked as a DNP (Did not play) for various reasons, such as a game cancellation or postponement, board error, or the player not playing a single second. In addition to these, Phantasia has the right to DNP, change, or remove any projection at its sole discretion. If a player is injured or ejected early in the game, their picks will still be valid. A DNP will cause your picks to 'downgrade' in the following fashion:\n\n" +
      "4-pick uninsured play -> 3-pick uninsured play\n" +
      "3-pick uninsured play -> 2-pick uninsured play\n" +
      "2-pick uninsured play -> refund\n\n" +
      "Or, with insurance:\n\n" +
      "4-pick insured play -> 3-pick insured play\n" +
      "3-pick insured play -> 2-pick uninsured play\n" +
      "2-pick uninsured play -> refund",
  },
  {
    q: "Does a DNP change payouts?",
    a: "Please see above rule for downgrade explanation",
  },
  {
    q: "Why was I refunded?",
    a:
      "If you were refunded, your entry had players who did not play and caused your entry to meet a refundable condition. Refundable conditions include:\n\n" +
      "All players in entry who are not marked as 'DNP' are on the same team\n\n" +
      "Less than 2 players in entry are not marked 'DNP'",
  },
  {
    q: "What happens if my pick is marked as a TIE?",
    a:
      "A projection may be marked as a TIE if your pick performs exactly the same as projected. Example: Player X is projected 5 points and you choose 'higher'. Player X scores exactly 5 points and is scored as a 'TIE'. In this case, your entry will be 'downgraded' in the following fashion:\n\n" +
      "4-pick uninsured play -> 3-pick uninsured play\n" +
      "3-pick uninsured play -> 2-pick uninsured play\n" +
      "2-pick uninsured play -> 1-TIE play\n" +
      "1-TIE play -> 2-TIE play\n\n" +
      "Or, with insurance:\n\n" +
      "4-pick insured play -> 3-pick insured play\n" +
      "3-pick insured play -> 2-pick uninsured play\n" +
      "2-pick uninsured play -> 1-TIE play\n" +
      "1-TIE play -> 2-TIE play",
  },
  {
    q: "What is a 'board error' and why does it count as DNP?",
    a: "A board error is an obvious mistake that was left on the board, like 'Tom Brady 10.5 TDs' for example. Projections determined as a board error can be marked DNP if the projection is determined extremely and obviously incorrect. ",
  },
  {
    q: "Does an injury mid game count as a DNP?",
    a: "If a player is injured or ejected early in the game, their picks will still be valid.",
  },
  {
    q: "Can I edit or cancel my entry once it's submitted?",
    a: "No, all entries are final once submitted, and no entries may be edited or canceled for any reason.",
  },
  {
    q: "Are there any restrictions on player selection?",
    a: "For the most part, you can select any combination of players in your entry, regardless of the sport. However, you must have picks from at least two different teams in your entry, and you cannot have the same player in your entry more than once.\n\n There are also some sport specific rules, like MLB entries cannot contain batters & opposing pitchers. Esports lineups cannot contain players on the same team.",
  },
  {
    q: "Does Phantasia Picks allow insured or sized down plays?",
    a: "Yes! With insurance, you can sacrifice a higher payout with the chance to earn your entry amount back in full if you choose one pick incorrectly (status of 'LOSS'). Insured plays pay 8x and 4x for 4-pick and 3-pick plays, respectively. If a DNP or TIE causes your slip to 'downgrade', insurance will not be valid if your entry has 2 or less picks with a status other than 'DNP' or 'TIE'",
  },
  {
    q: "What happens if my player doesn't play?",
    a: "If a player plays but exits early due to injury or otherwise, all picks will still be valid. The treatment of inactive players depends on the sport. For football, if a player is inactive, the pick will be graded as void. For basketball, any player who plays 0 minutes will be treated as an inactive player, and all picks will be considered void. For baseball, any player who doesn't receive a single at bat, plate appearance or throw a single pitch will be treated as an inactive player, and all picks of that player will be void. Additionally, Batters and Pitchers who do not start the game in the official lineup are eligible to be voided at the discretion of Phantasia.  For hockey, any skater who is not active for the game or a goalie who does not start will have their entries graded as void.  Please contact support for more questions.",
  },
  {
    q: "What happens in postponed or canceled games?",
    a: "If a game is postponed or canceled and the completion of that game does not occur on the originally scheduled calendar day, picks for the game will be graded as a DNP and the entry will be 'downgraded'",
  },
  {
    q: "What if the game stats or other in-app information are wrong?",
    a: "Game stats and other in-app information are for entertainment and informational purposes only, and they are provided by a third-party stat provider. Phantasia cannot guarantee 100% accuracy, so picks made as a result of incorrectly displayed game stats will not be marked as DNP. Other incorrect information on the projection, such as the opponent or game time, will be reviewed on a case-by-case basis. If you wish to request a refund due to incorrect information, you must do so before any game in your entry has started.",
  },
  {
    q: "Are there limits or minimums on entry amounts?",
    a: "The minimum entry amount is always $1. The maximum entry amount, at least while Phantasia Picks remains in Beta, will be determined by a maximum payout potential. This number varies user-by-user and can be changed any time by Phantasia. You also may not enter with 100% duplicate players.  This is subject to change during or post Phantasia Picks beta period.",
  },
];
