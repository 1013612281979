import { selectIsTransactionPending } from "../selectors/transaction";
import { useSelector } from "react-redux";

export const TransactionActionTypes = {
  SUCCESS: "TRANSACTION_SUCCESS",
  WARNING: "TRANSACTION_WARNING",
  DUPLICATE: "DUPLICATE_ENTRY",
  NOT_ENOUGH_SOL: "TRANSACTION_NOT_ENOUGH_SOL",
  FAILURE: "TRANSACTION_FAILURE",
  PENDING: "TRANSACTION_PENDING",
  NO_OP: "TRANSACTION_NOOP",
};

export const RunTransactionType = {
  SINGLE: "SINGLE",
  ASYNC: "ASYNC",
  SYNC: "SYNC",
};

export const useTxState = () => useSelector(selectIsTransactionPending);

const initialState = {
  pending: TransactionActionTypes.NO_OP,
  transactionRetryFunc: async () => {},
  transactionSignature: "",
  onSuccess: undefined,
  errorLog: null,
  type: RunTransactionType.SINGLE,
};

const Transaction = (state = initialState, action) => {
  switch (action.type) {
    case TransactionActionTypes.PENDING: {
      return {
        ...initialState,
        pending: TransactionActionTypes.PENDING,
        message: action.message,
      };
    }
    case TransactionActionTypes.SUCCESS: {
      return {
        ...initialState,
        pending: TransactionActionTypes.SUCCESS,
        transactionSignature: action.payload.transactionSignature,
      };
    }
    case TransactionActionTypes.FAILURE: {
      return {
        pending: TransactionActionTypes.FAILURE,
        transactionRetryFunc: action.payload.transactionRetryFunc,
        onSuccess: action.payload.onSuccess,
        errorLog: action.payload.errorLog,
        type: action.payload.type,
      };
    }
    case TransactionActionTypes.NO_OP: {
      return initialState;
    }
    case TransactionActionTypes.WARNING: {
      return {
        pending: TransactionActionTypes.WARNING,
        transactionRetryFunc: action.payload.transactionRetryFunc,
        onSuccess: action.payload.onSuccess,
        errorLog: action.payload.errorLog,
        type: action.payload.type,
      };
    }
    case TransactionActionTypes.DUPLICATE: {
      return {
        pending: TransactionActionTypes.DUPLICATE,
      };
    }
    case TransactionActionTypes.NOT_ENOUGH_SOL: {
      return {
        ...initialState,
        pending: TransactionActionTypes.NOT_ENOUGH_SOL,
        errorLog: action.payload.errorLog,
      };
    }
    default: {
      return state;
    }
  }
};

export default Transaction;
