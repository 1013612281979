import {
  EXTRA_COUNTRIES_FULL,
  isExpansionRegion,
  PAID_CONTEST_STATE_WHITELIST,
} from "common/src/services/GeolocationService";
import React, { useContext } from "react";
import { GradientButton3 } from "../../atoms/Button";
import { GeolocationContext } from "common/src/context/GeolocationContext";

export function RegionRestricted({ handleClose }) {
  const { country } = useContext(GeolocationContext);

  const isExpansionCountry = isExpansionRegion(country);
  const title = isExpansionCountry
    ? "Deposit Not Allowed"
    : "Region Restricted";

  const desc = isExpansionCountry
    ? "Your region is permitted for paid contests with entry in USDC or FANT only. Enter any contest without depositing."
    : "Unfortunately, paid Contests are not allowed in your region. Luckily, you can still play in Phantasia's free contests and win great prizes!";

  return (
    <div className="bg-base-1 flex-col animation-from-bottom p-8 rounded-9 w-11/12 max-w-30 flex-center text-center max-h-9/10 overflow-auto">
      <div className="w-full flex-col mt-8">
        <div className="w-full flex flex-center mb-5">
          <div className="bg-danger-0 outline-danger min-w-15 rounded-full min-h-15 flex flex-center">
            <i className="bx bx-block text-danger-2 bx-md" />
          </div>
        </div>
        <h2 className="text-neutral-2 mb-5 weight-500">{title}</h2>
        <span className="text-neutral-1 font-md weight-300 mb-9">{desc}</span>
        <span className="text-neutral-1 font-md weight-300 mb-4">
          US states allowing paid contests & credit deposits:
        </span>
        <div className="wrap space-evenly flex-row flex-center mb-9 mt-4">
          <span className="font-md text-neutral-2 weight-300">
            {Array.from(PAID_CONTEST_STATE_WHITELIST.entries()).map(
              (entry, index) => (
                <>{entry[0]}, </>
              )
            )}
          </span>
        </div>

        <span className="text-neutral-1 font-md weight-300 mb-4">
          Countries allowing paid contests
        </span>
        <div className="wrap space-evenly flex-row flex-center mb-11 mt-4">
          <span className="font-md text-neutral-2 weight-300">
            {[...EXTRA_COUNTRIES_FULL, "United States"].map((entry, index) => (
              <>{entry}, </>
            ))}
          </span>
        </div>

        <div>
          <GradientButton3
            onClick={handleClose}
            color="primary"
            className={"w-full"}
          >
            Take me home
          </GradientButton3>
        </div>
      </div>
    </div>
  );
}
