import WalletAuthContextProvider from "common/src/context/auth/WalletAuthContext";
import { P2E_COUNTRY_BLACKLIST } from "common/src/context/GeolocationContext";
import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import LoginForm from "../components/auth/LoginForm";
import { IPLocationWrapper } from "../components/geolocation/IPLocationWrapper";
import { ROUTES } from "../Routes";
import MagicContextProvider from "common/src/context/auth/MagicContext";

const Login = () => {
  const history = useHistory();

  const onSuccess = useCallback(() => {
    const params = new URLSearchParams(window.location.search);
    const redirectPath = params.get("redirect");

    if (redirectPath) history.push(redirectPath);
    else history.push(ROUTES.PICKS);
  }, [history]);

  return (
    <div className="min-h-screen flex w-screen">
      <IPLocationWrapper countryBlacklist={P2E_COUNTRY_BLACKLIST}>
        <WalletAuthContextProvider onSuccess={onSuccess}>
          <MagicContextProvider onSuccess={onSuccess}>
            <LoginForm />
          </MagicContextProvider>
        </WalletAuthContextProvider>
      </IPLocationWrapper>
    </div>
  );
};

export default Login;
