import {
  DfsRosterSlot,
  EplPositions,
  League,
  MlbPositions,
  NbaPositions,
  NflPositions,
  NhlPositions,
} from "@phantasia/model-interfaces";
import {
  CFB_TEAM_COLORS,
  colorLime,
  colorNeutral,
  colorOrange,
  colorPink,
  colorPrimary,
  colorSuccess,
  colorTurquoise,
  colorWarning,
  EPL_TEAM_COLORS,
  MLB_TEAM_COLORS,
  NBA_TEAM_COLORS,
  NFL_TEAM_COLORS,
  NHL_TEAM_COLORS,
  WNBA_TEAM_COLORS,
} from "./constants";

export function getInjuryColor(injury: string): string {
  switch (injury) {
    case "Probable":
      return "success";
    case "Questionable":
      return "warning";
    case "Doubtful":
      return "danger";
    case "Out":
      return "danger";
    default:
      return "";
  }
}

export function getTeamColorBackground(
  team: string,
  league: League
): { backgroundColor: string } {
  switch (league) {
    case League.NFL:
      return { backgroundColor: NFL_TEAM_COLORS[team] };
    case League.NBA:
      return { backgroundColor: NBA_TEAM_COLORS[team] };
    case League.EPL:
      return { backgroundColor: EPL_TEAM_COLORS[team] };
    case League.MLB:
      return { backgroundColor: MLB_TEAM_COLORS[team] };
    case League.NHL:
      return { backgroundColor: NHL_TEAM_COLORS[team] };
    default:
      return { backgroundColor: "#000000" };
  }
}

export function getTeamColorHex(team: string, league: League): string {
  switch (league) {
    case League.NFL:
      return NFL_TEAM_COLORS[team];
    case League.NBA:
      return NBA_TEAM_COLORS[team];
    case League.EPL:
      return EPL_TEAM_COLORS[team];
    case League.NHL:
      return NHL_TEAM_COLORS[team];
    case League.MLB:
      return MLB_TEAM_COLORS[team];
    case League.WNBA:
      return WNBA_TEAM_COLORS[team];
    case League.CFB:
      return CFB_TEAM_COLORS[team];
    case League.CSGO:
      return "#246cd5";
    case League.LOL:
      return "#246cd5";

    default:
      return "#000000";
  }
}

export function getPositionBackgroundColor(
  position:
    | NflPositions
    | EplPositions
    | NbaPositions
    | MlbPositions
    | NhlPositions
    | DfsRosterSlot
): { backgroundColor: string } {
  switch (position) {
    case NflPositions.QB:
      return { backgroundColor: colorPrimary[500] };
    case NflPositions.RB:
      return { backgroundColor: colorSuccess[500] };
    case NflPositions.WR:
      return { backgroundColor: colorOrange[500] };
    case NflPositions.TE:
      return { backgroundColor: colorWarning[500] };
    case NflPositions.K:
      return { backgroundColor: colorPink[500] };
    case "FLEX" as NflPositions:
      return { backgroundColor: colorLime[500] };
    case DfsRosterSlot.UTIL:
      return { backgroundColor: colorLime[500] };
    case DfsRosterSlot.G:
      return { backgroundColor: colorPrimary[500] };
    case DfsRosterSlot.F:
      return { backgroundColor: colorOrange[500] };
    case "FLX" as NflPositions:
      return { backgroundColor: colorLime[500] };
    case NflPositions.DST:
      return { backgroundColor: colorTurquoise[500] };
    case NbaPositions.PG:
      return { backgroundColor: colorPrimary[500] };
    case NbaPositions.SG:
      return { backgroundColor: colorSuccess[500] };
    case NbaPositions.SF:
      return { backgroundColor: colorOrange[500] };
    case NbaPositions.PF:
      return { backgroundColor: colorWarning[500] };
    case NbaPositions.C:
      return { backgroundColor: colorTurquoise[500] };
    case EplPositions.A:
      return { backgroundColor: colorPrimary[500] };
    case EplPositions.M:
      return { backgroundColor: colorSuccess[500] };
    case EplPositions.D:
      return { backgroundColor: colorOrange[500] };
    case EplPositions.GK:
      return { backgroundColor: colorTurquoise[500] };
    case NhlPositions.RW:
      return { backgroundColor: colorSuccess[500] };
    case NhlPositions.LW:
      return { backgroundColor: colorSuccess[500] };
    case NhlPositions.W:
      return { backgroundColor: colorSuccess[500] };
    case MlbPositions.P:
      return { backgroundColor: colorPrimary[500] };
    case MlbPositions.SP:
      return { backgroundColor: colorPrimary[500] };
    case MlbPositions.RP:
      return { backgroundColor: colorPrimary[500] };
    case MlbPositions.C:
      return { backgroundColor: colorTurquoise[500] };
    case MlbPositions.FB:
      return { backgroundColor: colorSuccess[500] };
    case MlbPositions.SB:
      return { backgroundColor: colorWarning[500] };
    case MlbPositions.TB:
      return { backgroundColor: colorOrange[500] };
    case MlbPositions.SS:
      return { backgroundColor: colorLime[500] };
    case MlbPositions.OF:
      return { backgroundColor: colorPink[500] };
    case MlbPositions.LF:
      return { backgroundColor: colorPink[500] };
    case MlbPositions.RF:
      return { backgroundColor: colorPink[500] };
    case MlbPositions.CF:
      return { backgroundColor: colorPink[500] };
    case MlbPositions.DH:
      return { backgroundColor: "#eab676" };
    case MlbPositions.PH:
      return { backgroundColor: "#eab676" };
    case "OF/H" as MlbPositions:
      return { backgroundColor: colorPink[500] };
    case "BN" as MlbPositions:
      return { backgroundColor: colorNeutral[100] };
    default:
      return { backgroundColor: colorNeutral[700] };
  }
}
