import React, { useEffect } from "react";

import { MAGIC_WALLET } from "common/src/services/wallet/magic";
import { useCommonWallet } from "common/src/services/wallet/UseCommonWallet";
import { MagicWallet } from "./MagicWallet";
import { MOCK_WALLET } from "common/src/services/wallet/mockWallet";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";

function Wallet(props) {
  const { onConnect, onDisconnect } = props;
  const wallet = useCommonWallet();

  useEffect(() => {
    if (wallet.name !== MAGIC_WALLET && wallet.name !== MOCK_WALLET)
      if (!wallet.connected && !wallet.connecting) {
        if (onDisconnect)
          try {
            onDisconnect();
          } catch (e) {
            console.error(e);
          }
      } else if (wallet.connected) if (onConnect) onConnect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wallet]);

  if (
    wallet.name === MAGIC_WALLET ||
    wallet.name === MOCK_WALLET ||
    props.copyable
  )
    return <MagicWallet publicKey={wallet.publicKey} />;

  return <WalletMultiButton className={props.small ? "small-trigger" : ""} />;
}

export default Wallet;
