import { WalletTypes } from "../actions/types";
const initialState = {
  fantBalance: 0,
  solBalance: 0,
  usdcBalance: 0,
  stakedFantBalance: 0,
  creditBalance: 0,
};

const Wallet = (state = initialState, action) => {
  switch (action.type) {
    case WalletTypes.SET_FANT_BALANCE:
      return {
        ...state,
        fantBalance: action.payload,
      };
    case WalletTypes.SET_SOL_BALANCE:
      return {
        ...state,
        solBalance: action.payload,
      };
    case WalletTypes.SET_USDC_BALANCE:
      return {
        ...state,
        usdcBalance: action.payload,
      };
    case WalletTypes.SET_SFANT_BALANCE:
      return {
        ...state,
        stakedFantBalance: action.payload,
      };
    case WalletTypes.SET_CREDIT_BALANCE:
      return {
        ...state,
        creditBalance: action.payload,
      };
    case WalletTypes.RESET_BALANCES:
      return initialState;
    default:
      return state;
  }
};

export default Wallet;
