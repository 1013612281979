import { Menu } from "@mui/material";
import React, { useState } from "react";
import { NotificationsList } from "../../notifications/NotificationsList";
import { VerticalScrollview } from "../../atoms/Scrollviews";
import { NotificationsHeader } from "../../notifications/NotificationsHeader";
import { useUser } from "common/src/hooks/useUser";

export function NotificationsMenu() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuOpen, setMenuOpen] = useState(Boolean(anchorEl));
  const user = useUser();

  const handleMenuToggle = (event) => {
    setAnchorEl(event.currentTarget);
    setMenuOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setMenuOpen(false);
  };

  return (
    <div>
      <div className="">
        <div
          id="notifications-menu"
          className="relative flex flex-center w-10 h-10 rounded-full bg-base-2 cursor-pointer hover-up transition"
          aria-controls={menuOpen ? "notifications-menu" : undefined}
          aria-expanded={menuOpen ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleMenuToggle}
        >
          {user.notifications &&
            user.notifications.filter((n) => !n.read).length > 0 && (
              <div className=" absolute t-1 r-1 bg-danger-1 ring-4 ring-base-1 flex flex-center w-5 h-5 rounded-full" />
            )}
          <i className="bx bx-bell text-neutral-2" />
        </div>
      </div>

      <Menu
        id="notifications-menu"
        style={{ padding: 0 }}
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <div className="flex-col shadow rounded-8 overflow-hidden max-h-30 max-w-28 w-28 bg-base-1">
          <VerticalScrollview
            className={"max-h-30 overflow-auto bg-base-1"}
            header={<NotificationsHeader />}
          >
            <NotificationsList />
          </VerticalScrollview>
        </div>
      </Menu>
      <></>
    </div>
  );
}
