import React from "react";
import {
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  RedditShareButton,
  FacebookShareButton,
} from "react-share";

export function TelegramButton({ url, title, hideText, small }) {
  return (
    <TelegramShareButton title={title} url={url}>
      <SocialButton
        small={small}
        icon="telegram"
        platform={hideText ? null : "Telegram"}
      />
    </TelegramShareButton>
  );
}

export function TwitterButton({ url, title, hideText, small }) {
  return (
    <TwitterShareButton
      url={url}
      title={title}
      hashtags={["Phantasia", "FutureOfFantasySports"]}
    >
      <SocialButton
        small={small}
        icon="twitter"
        platform={hideText ? null : "Twitter"}
      />
    </TwitterShareButton>
  );
}
export function WhatsappButton({ url, title, hideText, small }) {
  return (
    <WhatsappShareButton
      url={url}
      title={title}
      hashtags={["Phantasia", "FutureOfFantasySports"]}
    >
      <SocialButton
        small={small}
        icon="whatsapp"
        platform={hideText ? null : "Whatsapp"}
      />
    </WhatsappShareButton>
  );
}

export function RedditButton({ url, title, hideText, small }) {
  return (
    <RedditShareButton
      url={url}
      title={title}
      hashtags={["Phantasia", "FutureOfFantasySports"]}
    >
      <SocialButton
        small={small}
        icon="reddit"
        platform={hideText ? null : "Reddit"}
      />
    </RedditShareButton>
  );
}
export function FacebookButton({ url, title, hideText, small }) {
  return (
    <FacebookShareButton
      url={url}
      title={title}
      hashtags={["Phantasia", "FutureOfFantasySports"]}
    >
      <SocialButton
        small={small}
        icon="facebook"
        platform={hideText ? null : "Facebook"}
      />
    </FacebookShareButton>
  );
}

function SocialButton({ icon, platform, small }) {
  return (
    <div
      className={`flex space-x-6 hover:bg-base-2 flex-center h-9 min-w-9 rounded-5 flex-center transition w-full`}
    >
      <div className={`flex flex-center`}>
        <i className={`bx bxl-${icon} text-neutral-2`} />
      </div>
      {platform && (
        <span
          className={`font-${
            small ? "sm" : "md"
          } weight-400 text-neutral-2 mr-8`}
        >
          Share on {platform}
        </span>
      )}
    </div>
  );
}
