import { Menu } from "@mui/material";
import { Theme } from "common/src/context/HeaderContext";
import { SignOut } from "common/src/redux/actions/UserAction";
import { useCommonWallet } from "common/src/services/wallet/UseCommonWallet";
import { shortenPublicKey } from "common/src/util/helpers";
import React, { useContext, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { CustomMenuItem } from "../../atoms/CustomMenuItem";
import Switch from "../../atoms/Switch";
import { useUser } from "common/src/hooks/useUser";
import { useBalance } from "common/src/hooks/useBalance";
import { triggerEventRafClickDropDown } from "../../../GoogleAnalytics";
import { DepositModal } from "../../modals/purchase/DepositModal";
import { ThemeContext } from "../../../utils/ThemeContext";
import { ROUTES } from "../../../Routes";
import { WalletHomeSummary } from "./WalletHomeSummary";
import { HorizontalDividerInDiv } from "../../atoms/Divider";
import Wallet from "../../wallet/Wallet";

export function DesktopHeaderMenu({ screenIsSmall }) {
  const user = useUser();
  const profileSummary = useMemo(() => {
    return <WalletHomeSummary />;
  }, []);
  const { credits, usdc } = useBalance();
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuOpen, setMenuOpen] = useState(Boolean(anchorEl));
  const [paymentModalOpen, setPaymentModalOpen] = useState(false);

  const wallet = useCommonWallet();

  const handleMenuToggle = (event) => {
    setAnchorEl(event.currentTarget);
    setMenuOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setMenuOpen(false);
  };

  function LargeMenu() {
    return (
      <div
        id="user-menu"
        className="items-center flex space-x-5 flex-row w-full"
        aria-controls="basic-menu"
        aria-expanded={menuOpen ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleMenuToggle}
      >
        <div className="flex-row items-center w-full overflow-hidden rounded-15 cursor-pointer hover:bg-base-2 transition space-x-5">
          <div className="flex items-center relative">
            <div className="bg-base-2 min-h-11 max-h-11 w-11 rounded-full flex flex-center">
              <i className="bx bx-user text-neutral-1 bx-sm" />
            </div>
            {user.passedKyc && (
              <i
                className={
                  "bx bxs-badge-check text-gradient-light absolute bx-sm b--3 r--3"
                }
              />
            )}
          </div>

          <div className="flex-col flex-1">
            <span className="font-xs text-neutral-0 weight-300 no-wrap flex">
              {shortenPublicKey(user.walletPubkey)}
            </span>
            <span className="font-sm text-neutral-1 weight-300 no-wrap flex mb-2">
              {user.username}
            </span>
            <span
              id="fant-balance"
              className="font-md weight-500 text-neutral-2 no-wrap"
            >
              ${(usdc + credits / 100).toFixed(2)}
            </span>
          </div>
          <i className="bx bx-chevron-down bx-sm text-neutral-2 mr-3" />
        </div>
      </div>
    );
  }

  function SmallMenu() {
    return (
      <div
        id="user-menu"
        className="flex space-x-5 flex-row w-full"
        aria-controls="basic-menu"
        aria-expanded={menuOpen ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleMenuToggle}
      >
        <div className="flex-col items-center p-4 rounded-15 cursor-pointer hover:bg-base-2 transition space-y-5">
          <div className="flex-col items-center relative w-full">
            <div className="bg-base-2 min-h-9 max-h-9 w-9 rounded-full flex flex-center">
              <i className="bx bx-user text-neutral-1 bx-sm" />
            </div>

            {user.passedKyc && (
              <i
                className={
                  "bx bxs-badge-check text-gradient-light absolute rounded-full b--3 r--3"
                }
              />
            )}
          </div>

          <div className="flex-col items-center flex-1 w-full">
            <span className="font-xs text-neutral-0 weight-300 no-wrap flex">
              {shortenPublicKey(user.walletPubkey)}
            </span>
            <span
              id="fant-balance"
              className="font-sm weight-500 text-neutral-2 no-wrap"
            >
              ${(usdc + credits / 100).toFixed(2)}
            </span>
          </div>
          <i className="bx bx-menu bx-sm text-neutral-2 mr-3" />
        </div>
      </div>
    );
  }

  return (
    <div>
      <DepositModal
        open={paymentModalOpen}
        onClose={() => {
          setPaymentModalOpen(false);
        }}
      />
      <div className="flex-row items-center w-full"></div>
      {screenIsSmall ? <SmallMenu /> : <LargeMenu />}
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={menuOpen}
        // anchorOrigin={"top"}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <div className="flex-row  ring-1 ring-base-3 shadow-3 rounded-8 overflow-hidden">
          <div className="flex-col min-w-25">
            {profileSummary}
            <div className={"p-8"}>
              <Wallet />
            </div>
          </div>
          <MenuLinks
            handleClose={handleClose}
            screenIsSmall={screenIsSmall}
            wallet={wallet}
          />
        </div>
      </Menu>
      <></>
    </div>
  );
}

function MenuLinks({ handleClose, wallet }) {
  const { setTheme, theme } = useContext(ThemeContext);

  const dispatch = useDispatch();
  const history = useHistory();

  function logout() {
    handleClose();
  }

  const links = [
    {
      icon: "bx-user-plus",
      text: "Refer-A-Friend Dashboard",
      onClick: () => {
        triggerEventRafClickDropDown();
        history.push(ROUTES.RAF);
      },
    },
    {
      icon: "bx-money-withdraw",
      text: "Withdraw Winnings",
      onClick: () => {
        history.push(ROUTES.WITHDRAW);
      },
    },
    {
      icon: "bx-user",
      text: "Profile Fan Card",
      onClick: () => {
        history.push(ROUTES.FAN_CARD_BUILDER);
      },
    },
    {
      icon: "bx-wallet",
      text: "Wallet & Balances",
      onClick: () => {
        history.push(ROUTES.WALLET);
      },
    },
    {
      icon: "bx-id-card",
      text: "NFT Builder",
      onClick: () => {
        history.push(ROUTES.NFT_BUILDER);
      },
    },
    {
      icon: "bx-purchase-tag",
      text: "Deposit History",
      onClick: () => {
        history.push(ROUTES.PAYMENT_HISTORY);
      },
    },
    {
      icon: "bx-money-withdraw",
      text: "Withdraw History",
      onClick: () => {
        history.push(ROUTES.WITHDRAW_HISTORY);
      },
    },
    {
      icon: "bx-cog",
      text: "Settings",
      onClick: () => {
        history.push(ROUTES.SETTINGS);
      },
    },
    {
      icon: "bx-log-out-circle",
      text: "Sign out",
      onClick: () => {
        logout();
        if (wallet) wallet.disconnect();
        dispatch(SignOut(history));
        window.Intercom("shutdown");
      },
    },
  ];

  return (
    <div className="flex-col p-6 bg-base-1 outline-l-1">
      {links.map((link, index) => (
        <div className={""}>
          {index !== 0 && <HorizontalDividerInDiv className={"pl-9 ml-3"} />}
          <CustomMenuItem
            key={index}
            onClick={() => {
              link.onClick();
              handleClose();
            }}
          >
            <i className={`bx ${link.icon}`} />
            <span className="font-md text-neutral-2 hover-dark">
              {link.text}
            </span>
          </CustomMenuItem>
        </div>
      ))}

      <div className="flex-grow" />
      <div className="w-full  outline-1 rounded-4 px-5 py-4 mt-6 flex-row items-center">
        <span className="font-md weight-300 text-neutral-2 flex-1 mr-4">
          Theme
        </span>
        <i className="bx bx-sun text-neutral-2 mr-4" />
        <Switch
          value={theme === Theme.Dark}
          setValue={() => {
            if (theme === Theme.Dark) setTheme(Theme.Light);
            else setTheme(Theme.Dark);
          }}
        />
        <i className="bx bx-moon text-neutral-2 ml-4" />
      </div>
    </div>
  );
}
