import { Loader } from "../../molecules/Loader";
import moment from "moment/moment";
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import React from "react";
import { getPropPerformanceStatus } from "common/src/services/PlayerPropsService";

export function GameGraph({
  gameLog,
  teamColor,
  activeStat,
  league,
  position,
}) {
  if (!gameLog) return <Loader />;

  const graphData = [];

  gameLog.forEach((log) => {
    const stat = getPropPerformanceStatus(log, activeStat, league, position);

    if (!isNaN(stat))
      graphData.push({
        date: moment(log.game.date).format("MMM DD"),
        name: `${moment(log.game.date).format("MMM DD")} - ${
          log.game.away_team
        } @ ${log.game.home_team}`,
        points: stat,
      });
  });

  if (graphData.length === 0) return null;

  const CustomTooltip = ({ active, payload }) => {
    if (active) {
      return (
        <div className={"rounded-5 p-5 bg-base-3 flex-col"}>
          <span className={"text-neutral-1 uppercase weight-300 font-xs"}>
            {payload[0].payload.name}
          </span>
          <span className={"text-primary-2 weight-300 font-md"}>
            {payload[0].value} points
          </span>
        </div>
      );
    }
    return null;
  };
  return (
    <div className="flex flex-1 w-full min-h-25 pr-6 ml--6">
      <div className="flex flex-1 text-neutral-2  delay-4 mt-8">
        <ResponsiveContainer width="100%" height="95%" debounce={1}>
          <AreaChart data={graphData}>
            <defs>
              <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor={teamColor} stopOpacity={0.3} />
                <stop offset="95%" stopColor={teamColor} stopOpacity={0} />
              </linearGradient>
            </defs>

            <CartesianGrid
              stroke={"rgba(95,96,103,0.3)"}
              strokeDasharray="2 10"
            />
            <XAxis dataKey="date" axisLine={false} />
            <YAxis dataKey="points" axisLine={false} />
            <Tooltip content={<CustomTooltip />} />
            <Area
              type="monotone"
              dataKey="points"
              stroke={teamColor}
              fillOpacity={1}
              fill="url(#colorUv)"
              strokeWidth={3}
              animationDuration={500}
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}
