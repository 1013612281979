import React from "react";
import { CoinflowWithdrawHistory } from "@coinflowlabs/react";
import { useConnection, useSolanaNet } from "common/src/hooks/useConnection";
import { useCommonWallet } from "common/src/services/wallet/UseCommonWallet";

export function WithdrawHistoryPage() {
  const wallet = useCommonWallet();
  const connection = useConnection();

  const net = useSolanaNet();

  return (
    <div className="w-full flex-row justify-center space-x-11">
      <div className="relative w-full py-15 h-screen overflow-hidden">
        <div className="flex-col h-full items-center">
          <span className="text-neutral-2 font-xl weight-300 text-center mx-auto ">
            Withdraw History
          </span>
          <div className={"flex-1 w-full"}>
            <CoinflowWithdrawHistory
              blockchain={"solana"}
              wallet={wallet}
              merchantId={"phantasia"}
              connection={connection}
              env={net === "mainnet" ? "prod" : "staging"}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
