import React from "react";
import { COLOR_OPTIONS } from "common/src/util/constants";

function CustomButton({
  id,
  className,
  textStyles,
  contentRight,
  contentLeft,
  onClick,
  disabled,
  submit,
  children,
}) {
  return (
    <button
      id={id}
      type={submit ? "submit" : "button"}
      onClick={onClick}
      disabled={disabled && "disabled"}
      className={`${className} flex-row flex-center space-x-4 border-none hover-up cursor-pointer transition duration-200 ${
        disabled ? " disabled no-click" : ""
      }`}
    >
      {contentLeft}
      <span className={`${textStyles} no-wrap`}>{children}</span>
      {contentRight}
    </button>
  );
}

function Button1({
  className,
  textStyles,
  contentRight,
  contentLeft,
  onClick,
  disabled,
  submit,
  children,
  id,
}) {
  return (
    <CustomButton
      id={id}
      textStyles={`font-xs weight-400 letter-spacing-xs ${textStyles}`}
      className={`min-w-9 h-9 max-h-9 px-5 rounded-5 rounded-15 ${className}`}
      contentLeft={contentLeft}
      contentRight={contentRight}
      submit={submit}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </CustomButton>
  );
}
function Button2({
  className,
  textStyles,
  contentRight,
  contentLeft,
  onClick,
  disabled,
  submit,
  children,
}) {
  return (
    <CustomButton
      textStyles={`${textStyles} font-sm weight-400 letter-spacing-sm `}
      className={`min-h-10 max-h-10 h-10 px-6 rounded-15 ${className} `}
      contentLeft={contentLeft}
      contentRight={contentRight}
      submit={submit}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </CustomButton>
  );
}
function Button3({
  id,
  className,
  textStyles,
  contentRight,
  contentLeft,
  onClick,
  disabled,
  submit,
  children,
}) {
  return (
    <CustomButton
      id={id}
      textStyles={`font-md weight-400 uppercase letter-spacing-sm word-spacing-sm ${textStyles}`}
      className={`min-w-11 h-11 max-h-11 px-8 rounded-6 ${className} `}
      contentLeft={contentLeft}
      contentRight={contentRight}
      submit={submit}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </CustomButton>
  );
}

export function LinkButton1({
  className,
  textStyles,
  contentRight,
  contentLeft,
  disabled,
  children,
  link,
  color,
  onClick,
}) {
  return (
    <span>
      <a
        href={link}
        target="_blank"
        rel="noreferrer"
        className="decoration-none"
      >
        <SolidButton1
          className={className}
          textStyles={textStyles}
          contentRight={contentRight}
          contentLeft={contentLeft}
          disabled={disabled}
          color={color}
          onClick={onClick}
        >
          {children}
        </SolidButton1>
      </a>
    </span>
  );
}
export function LinkButton2({
  className,
  textStyles,
  contentRight,
  contentLeft,
  disabled,
  children,
  link,
  color,
  onClick,
}) {
  return (
    <span>
      <a
        href={link}
        target="_blank"
        rel="noreferrer"
        className="decoration-none"
      >
        <SolidButton2
          className={className}
          textStyles={textStyles}
          contentRight={contentRight}
          contentLeft={contentLeft}
          disabled={disabled}
          color={color}
          onClick={onClick}
        >
          {children}
        </SolidButton2>
      </a>
    </span>
  );
}
export function LinkButton3({
  className,
  textStyles,
  contentRight,
  contentLeft,
  disabled,
  children,
  link,
  color,
  onClick,
}) {
  return (
    <a
      href={link}
      target="_blank"
      rel="noreferrer"
      className={`${className} decoration-none`}
    >
      <SolidButton3
        className={`w-full ${className}`}
        textStyles={textStyles}
        contentRight={contentRight}
        contentLeft={contentLeft}
        disabled={disabled}
        color={color}
        onClick={onClick}
      >
        {children}
      </SolidButton3>
    </a>
  );
}

export function OutlineButton1({
  className,
  textStyles,
  contentRight,
  contentLeft,
  disabled,
  children,
  submit,
  color,
  onClick,
}) {
  return (
    <Button1
      textStyles={`${textStyles} ${
        colorIsNeutral(color) ? `text-neutral-2` : `text-${color}-2`
      }`}
      className={`outline-1 ${
        !colorIsNeutral(color)
          ? `outline-${color}-1 bg-${color}-0`
          : " bg-base-2"
      }  ${className}`}
      contentLeft={contentLeft}
      contentRight={contentRight}
      submit={submit}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </Button1>
  );
}

export function OutlineButton2({
  className,
  textStyles,
  contentRight,
  contentLeft,
  disabled,
  submit,
  children,
  color,
  onClick,
}) {
  return (
    <Button2
      textStyles={`${textStyles} ${
        colorIsNeutral(color) ? `text-neutral-2` : `text-${color}-2`
      }`}
      className={`outline-1 ${
        !colorIsNeutral(color)
          ? `outline-${color}-1 bg-${color}-0`
          : " bg-base-1"
      }  ${className}`}
      contentLeft={contentLeft}
      contentRight={contentRight}
      submit={submit}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </Button2>
  );
}

export function OutlineButton3({
  className,
  textStyles,
  contentRight,
  contentLeft,
  disabled,
  submit,
  children,
  color = "base",
  onClick,
}) {
  if (!color) color = "base";
  return (
    <Button3
      textStyles={`${textStyles} ${
        colorIsNeutral(color) ? `text-neutral-2` : `text-${color}-2`
      }`}
      className={`outline-1 ${
        !colorIsNeutral(color)
          ? `outline-${color}-1 bg-${color}-0`
          : " bg-base-2"
      }  ${className}`}
      contentLeft={contentLeft}
      contentRight={contentRight}
      submit={submit}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </Button3>
  );
}

export function SolidButton1({
  className,
  textStyles,
  contentRight,
  contentLeft,
  disabled,
  submit,
  children,
  color,
  onClick,
  id,
}) {
  return (
    <Button1
      id={id}
      textStyles={`${
        colorIsNeutral(color) ? "text-neutral-2" : "white"
      } ${textStyles}`}
      className={`${getSolidBackgroundColor(color)}  ${className}`}
      contentLeft={contentLeft}
      contentRight={contentRight}
      submit={submit}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </Button1>
  );
}
export function SolidButton2({
  className,
  textStyles,
  contentRight,
  contentLeft,
  disabled,
  submit,
  children,
  color,
  onClick,
}) {
  return (
    <Button2
      textStyles={`${
        colorIsNeutral(color) ? "text-neutral-2" : "white"
      } ${textStyles}`}
      className={`bg-base-2 ${getSolidBackgroundColor(color)}  ${className}`}
      contentLeft={contentLeft}
      contentRight={contentRight}
      submit={submit}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </Button2>
  );
}
export function SolidButton3({
  id,
  className,
  textStyles,
  contentRight,
  contentLeft,
  disabled,
  submit,
  children,
  color,
  onClick,
}) {
  return (
    <Button3
      id={id}
      textStyles={`${
        colorIsNeutral(color) ? "text-neutral-2" : "white"
      } ${textStyles}`}
      className={`${getSolidBackgroundColor(color)}  ${className}`}
      contentLeft={contentLeft}
      contentRight={contentRight}
      submit={submit}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </Button3>
  );
}

export function TextButton1({
  className,
  textStyles,
  contentRight,
  contentLeft,
  disabled,
  submit,
  children,
  color,
  onClick,
}) {
  return (
    <Button1
      textStyles={`${
        colorIsNeutral(color) ? "text-neutral-2" : `text-${color}-1`
      } ${textStyles}`}
      className={`bg-transparent  ${className}`}
      contentLeft={contentLeft}
      contentRight={contentRight}
      submit={submit}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </Button1>
  );
}
export function TextButton2({
  className,
  textStyles,
  contentRight,
  contentLeft,
  disabled,
  submit,
  children,
  color,
  onClick,
}) {
  return (
    <Button2
      textStyles={`${
        colorIsNeutral(color) ? "text-neutral-2" : `text-${color}-1`
      } ${textStyles}`}
      className={`bg-transparent  ${className}`}
      contentLeft={contentLeft}
      contentRight={contentRight}
      submit={submit}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </Button2>
  );
}
export function TextButton3({
  className,
  textStyles,
  contentRight,
  contentLeft,
  disabled,
  submit,
  children,
  color,
  onClick,
}) {
  return (
    <Button3
      textStyles={`${
        colorIsNeutral(color) ? "text-neutral-2" : `text-${color}-1`
      } ${textStyles}`}
      className={`bg-transparent ${className}`}
      contentLeft={contentLeft}
      contentRight={contentRight}
      submit={submit}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </Button3>
  );
}

export function GradientButton1({
  className,
  textStyles,
  contentRight,
  contentLeft,
  disabled,
  submit,
  children,
  onClick,
  color,
}) {
  return (
    <Button1
      textStyles={`
        white
       ${textStyles}`}
      className={` bg-gradient-${color ? color : "primary"}  ${className}`}
      contentLeft={contentLeft}
      contentRight={contentRight}
      submit={submit}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </Button1>
  );
}
export function GradientButton2({
  className,
  textStyles,
  contentRight,
  contentLeft,
  disabled,
  submit,
  children,
  onClick,
  color,
}) {
  return (
    <Button2
      textStyles={`
        white
       ${textStyles}`}
      className={` bg-gradient-${color ? color : "primary"}  ${className}`}
      contentLeft={contentLeft}
      contentRight={contentRight}
      submit={submit}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </Button2>
  );
}
export function GradientButton3({
  id,
  className,
  textStyles,
  contentRight,
  contentLeft,
  disabled,
  submit,
  children,
  onClick,
  color,
}) {
  return (
    <Button3
      id={id}
      textStyles={`
        white
       ${textStyles}`}
      className={`glow-${color ? color : "primary"} bg-gradient-${
        color ? color : "primary"
      }  ${className}`}
      contentLeft={contentLeft}
      contentRight={contentRight}
      submit={submit}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </Button3>
  );
}

function colorIsNeutral(color) {
  return (
    color === "neutral" || !color || color === "over-raised" || color === "base"
  );
}

function getSolidBackgroundColor(color) {
  if (!color || color === "") return "bg-base-2";
  return color === COLOR_OPTIONS.OVER_RAISED
    ? "bg-base-3"
    : colorIsNeutral(color)
    ? "bg-base-2"
    : `bg-${color}-1`;
}
