import React from "react";
import { Input } from "../../atoms/Input";
import Switch from "../../atoms/Switch";

export function ParticipantEmailForm({
  showError,
  value,
  setValue,
  emailOptOut,
  setEmailOptOut,
  inputStyle,
}) {
  return (
    <>
      <div className="flex-row items-baseline w-full space-x-5 mb-5">
        <span className="text-neutral-1 weight-300 letter-spacing-sm font-xs flex-1">
          EMAIL {setEmailOptOut && "(OPTIONAL)"}
        </span>
        <span className="text-neutral-1 weight-300 font-xs">
          Enter your email to receive notifications. We'll never spam you.
        </span>
      </div>

      <Input
        icon={<i className="bx bx-mail-send text-neutral-1 " />}
        classes={`${inputStyle} font-md`}
        showError={showError}
        size="md"
        type="email"
        onChange={setValue}
        placeholder={"johndoe@email.com"}
        value={value}
        inputMode="email"
      />

      {setEmailOptOut && (
        <div className="flex-row w-full space-x-5 items-center mt-5">
          <Switch
            value={emailOptOut}
            setValue={() => setEmailOptOut(!emailOptOut)}
          />
          <span className="text-neutral-1 weight-300 font-xs flex">
            I wish to opt out of email and push notifications. I acknowledge
            that by opting out, I risk missing important information related to
            contest results & resizing
          </span>
        </div>
      )}
    </>
  );
}
