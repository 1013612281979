export default function Switch({ value, setValue, className }) {
  return (
    <label className="switch">
      <input
        type="checkbox"
        checked={value}
        value={value}
        onChange={setValue}
      />
      <span className={`switch-slider ${className}`}></span>
    </label>
  );
}
