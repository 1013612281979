import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../Routes";

export default function LoginSuccessPage() {
  const history = useHistory();

  useEffect(() => {
    if (process.env.REACT_APP_SOLANA_NET === "mainnet") {
      window.gtag("event", "conversion", {
        send_to: "AW-10886219621/ZfTsCL7pqMIDEOWO-sYo",
        non_interaction: true,
      });
    }

    setTimeout(() => {
      history.push(ROUTES.PICKS);
    }, 500);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <div className="container-view"></div>;
}
