import React, { useContext } from "react";
import { useDispatch } from "react-redux";
import { SolidButton3 } from "../../atoms/Button";
import { KycContext } from "common/src/context/KycContext";
import { getAndSetUser } from "common/src/services/UserService";

export function DocV({ handleClose }) {
  const openUrl = () => {
    window.open(inquiryUrl, "_blank");
  };
  const dispatch = useDispatch();
  const { inquiryUrl } = useContext(KycContext);
  const refreshUser = async () => {
    getAndSetUser(dispatch);
    handleClose();
  };

  return (
    <div className="rounded-9 bg-base-1 p-9 flex-col flex-center space-y-8 animation-from-bottom w-11/12 max-w-31 h-full max-h-9/10">
      <div
        className={
          "bg-primary-0 rounded-full outline-primary flex flex-center w-14 h-14"
        }
      >
        <i className={"bx bx-md bx-help-circle white"} />
      </div>
      <span className={"text-neutral-2 font-lg weight-500"}>
        Person not found...
      </span>
      <span
        className={"text-neutral-1 font-md weight-300 max-w-29 text-center"}
      >
        We were unable to verify your identity. Please click the link and
        complete the form to finish verification. Once completed click the
        refresh button below.
      </span>
      <div
        onClick={openUrl}
        className={"flex-row space-x-8 items-center w-min"}
      >
        <div
          className={
            "transition cursor-pointer hover:bg-base-2 rounded-full flex flex-center px-6 outline-1 h-12"
          }
        >
          <span className={"text-neutral-2 font-lg weight-500"}>
            {inquiryUrl}
          </span>
        </div>
        <div
          className={
            "transition cursor-pointer hover:bg-base-2 rounded-full flex flex-center outline-1 h-12 w-12"
          }
        >
          <i className={"bx bx-sm bx-copy-alt white"} />
        </div>
      </div>
      <SolidButton3
        onClick={refreshUser}
        className={"w-full"}
        color={"success"}
      >
        Refresh
      </SolidButton3>
    </div>
  );
}
