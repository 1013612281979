import { getApi } from "../util/api";
import { Singletons } from "./Singletons";
import { UserTypes } from "../redux/actions/types";
import moment from "moment";
import { KycState } from "@phantasia/model-interfaces";
import { Dispatch } from "redux";

export const KycModalState = {
  ALLOW_LOCATION_ACCESS: "ALLOW_LOCATION_ACCESS",
  CHECKING_LOCATION: "CHECKING_LOCATION",
  KYC_FORM: "KYC_FORM",
  LOCATION_ACCESS_DENIED: "LOCATION_ACCESS_DENIED",
  REGION_RESTRICTED: "REGION_RESTRICTED",
  DOCV: "DOCV",
};

export interface KycData {
  firstName: string;
  surName: string;
  physicalAddress: string;
  city: string;
  country: string;
  state: string;
  zip: string;
  dob: string | Date;
  ssn?: string;
  email?: string;
  phone?: string;
}

export const KycFormErrors = {
  FIRST_NAME: "First name is required",
  SURNAME: "Last name is required",
  ADDRESS: "Address is required",
  CITY: "City is required",
  STATE: "State is required",
  COUNTRY: "City is required",
  ZIP: "Zip code is required",
  DOB: "Date of birth is required",
  EMAIL: "Email is required - or opt out",
  SSN: "SSN Is Not Valid",
  PHONE: "Phone number is required",
  NY_STATE: "Withdraws are not available in the State of New York",
};

export async function submitKycForm(
  formData: KycData,
  dispatch: Dispatch,
  setModalState: (state: string) => void,
  onSuccess: () => void,
  setErrorInput: (error: string) => void,
  setFailedCount: () => void,
  useSSN: boolean,
  setInquiryUrl: (id: string) => void
) {
  try {
    validate(formData, setErrorInput, setFailedCount, useSSN);
    const res = await getApi().post("/api/auth/kyc", {
      firstName: formData.firstName,
      surName: formData.surName,
      physicalAddress: formData.physicalAddress,
      city: formData.city,
      country: formData.country,
      state: formData.state,
      zip: formData.zip,
      dob: moment(formData.dob).format("YYYYMMDD"),
      ssn: formData.ssn,
      email: formData.email,
      phone: formData.phone,
    });

    if (res.status === 201) {
      const kycFail = await getApi().post("/api/auth/kyc-fail", {});
      setModalState(KycModalState.DOCV);
      setInquiryUrl(kycFail.data);
      return;
    } else if (res.status === 200) {
      dispatch({
        type: UserTypes.SET_USER,
        payload: {
          passedKyc: true,
          birthdate: moment(formData.dob).toISOString(),
          kycStatus: formData.ssn
            ? KycState.TAX_INFO_PROVIDED
            : KycState.ACCEPTED,
        },
      });
      onSuccess();
    }
    return;
  } catch (e: any) {
    setFailedCount();
    console.error(e);
    const displayMessage =
      e?.response?.data?.msg ?? e?.message ?? "Something went wrong";

    if (typeof displayMessage === "string" && displayMessage !== "") {
      setErrorInput(
        displayMessage === "" ? "Something went wrong" : displayMessage
      );
    }
    Singletons.toastService.error(
      "Error Identifying...",
      displayMessage === "" ? "Something went wrong" : displayMessage
    );
  }
}

export function validateName(
  formData: KycData,
  setErrorInput: (error: string) => void,
  setFailedCount: () => void
) {
  if (!formData.firstName) {
    setErrorInput(KycFormErrors.FIRST_NAME);
    setFailedCount();
    throw new Error(KycFormErrors.FIRST_NAME);
  }

  if (!formData.surName) {
    setErrorInput(KycFormErrors.SURNAME);
    setFailedCount();
    throw new Error(KycFormErrors.SURNAME);
  }
}

export function validateAddress(
  formData: KycData,
  setErrorInput: (error: string) => void,
  setFailedCount: () => void
) {
  if (!formData.physicalAddress) {
    setErrorInput(KycFormErrors.ADDRESS);
    setFailedCount();
    throw new Error(KycFormErrors.ADDRESS);
  }

  if (!formData.city) {
    setErrorInput(KycFormErrors.CITY);
    setFailedCount();
    throw new Error(KycFormErrors.CITY);
  }
}

export function validateCity(
  formData: KycData,
  setErrorInput: (error: string) => void,
  setFailedCount: () => void
) {
  if (
    !formData.state ||
    formData.state.length !== 2 ||
    formData.state === "XX"
  ) {
    setErrorInput(KycFormErrors.STATE);
    setFailedCount();
    throw new Error(KycFormErrors.STATE);
  }

  if (!formData.country) {
    setErrorInput(KycFormErrors.COUNTRY);
    setFailedCount();
    throw new Error(KycFormErrors.COUNTRY);
  }
}

export function validateZip(
  formData: KycData,
  setErrorInput: (error: string) => void,
  setFailedCount: () => void
) {
  if (!formData.zip || formData.zip?.toString()?.length !== 5) {
    setErrorInput(KycFormErrors.ZIP);
    setFailedCount();
    throw new Error(KycFormErrors.ZIP);
  }
}

export function validateDob(
  formData: KycData,
  setErrorInput: (error: string) => void,
  setFailedCount: () => void
) {
  if (!formData.dob) {
    setErrorInput(KycFormErrors.DOB);
    setFailedCount();
    throw new Error(KycFormErrors.DOB);
  }
}

export function validateState(
  formData: KycData,
  setErrorInput: (error: string) => void,
  setFailedCount: () => void
) {
  if (formData.state === "NY") {
    setFailedCount();
    setErrorInput(KycFormErrors.NY_STATE);
    throw new Error(KycFormErrors.NY_STATE);
  }
}

export function validateSsn(
  formData: KycData,
  setErrorInput: (error: string) => void,
  setFailedCount: () => void
) {
  if (
    !formData.ssn
    //   ||
    // formData.ssn?.length !== 4 ||
    // isNaN(Number(formData.ssn)) ||
    // Number(formData.ssn) > 9999 ||
    // Number(formData.ssn) < 1
  ) {
    setFailedCount();
    setErrorInput(KycFormErrors.SSN);
    throw new Error(KycFormErrors.SSN);
  }
}

export function validateEmail(
  formData: KycData,
  setErrorInput: (error: string) => void,
  setFailedCount: () => void
) {
  if (!formData.email) {
    setFailedCount();
    setErrorInput(KycFormErrors.EMAIL);
    throw new Error(KycFormErrors.EMAIL); // TODO regex match email
  }
}

export function validatePhone(
  formData: KycData,
  setErrorInput: (error: string) => void,
  setFailedCount: () => void
) {
  if (!formData.phone) {
    setFailedCount();
    setErrorInput(KycFormErrors.PHONE);
    throw new Error(KycFormErrors.PHONE); // TODO regex match phone number
  }
}

function validate(
  formData: KycData,
  setErrorInput: (error: string) => void,
  setFailedCount: () => void,
  useSSN: boolean
) {
  validateName(formData, setErrorInput, setFailedCount);
  validateAddress(formData, setErrorInput, setFailedCount);
  validateCity(formData, setErrorInput, setFailedCount);
  validateZip(formData, setErrorInput, setFailedCount);
  validateDob(formData, setErrorInput, setFailedCount);

  if (useSSN) {
    // validateSsn(formData, setErrorInput, setFailedCount);
    validateState(formData, setErrorInput, setFailedCount);
    validateEmail(formData, setErrorInput, setFailedCount);
    validatePhone(formData, setErrorInput, setFailedCount);
  }
}

interface DocVResult {
  verifyResult: {
    referenceId: string;
    documentVerification: {
      documentData: {
        dob: string;
      };
    };
  };
}

export async function onDocVSuccess(result: DocVResult, dispatch: Dispatch) {
  if (!result.verifyResult) {
    Singletons.toastService.error(
      "No Response from the Document Verification",
      "No Response from the Document Verification"
    );
    console.error("No Verify Result");
    return;
  }

  const { referenceId } = result.verifyResult;
  if (!referenceId) {
    console.error("ReferenceId or document data is not defined");
    Singletons.toastService.error(
      "Unable to determine document data",
      "Unable to determine document data"
    );
    return;
  }

  try {
    await getApi().post("/api/auth/docv", { referenceId });
    const res = await getApi().get("api/auth");
    const { data } = res;

    dispatch({
      type: UserTypes.SET_USER,
      payload: {
        passedKyc: true,
        birthdate: moment(data.birthdate).toISOString(),
      },
    });
    // eslint-disable-next-line
    location.reload();
  } catch (e: any) {
    console.error(e);
    const errorMsg = e?.response?.body?.msg ?? e.message;
    Singletons.toastService.error(errorMsg, errorMsg);
  }

  Singletons.toastService.success(
    "Verification Successful",
    "Verification Successful"
  );
}
