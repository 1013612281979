import { useSelector } from "react-redux";
import { selectUser } from "../redux/selectors/user";
import { User } from "@phantasia/model-interfaces";
import { ArweaveDto } from "../services/dtos/ArWeave";

export type NftMetadata = {
  mint: string;
  collection: { family: string; name: string };
  image: string;
  data?: ArweaveDto;
};

export type ReduxUser = User & {
  loginUsername: string;
  _id: string;
  nft_metadata: NftMetadata[];
  phantasia_nft_holder: boolean;
  authToken: string;
  birthdate: string;
  keypair: string;
  freePlays?: number;
};

export function useUser(): ReduxUser {
  return useSelector(selectUser);
}
