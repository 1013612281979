import { SwipeableDrawer } from "@mui/material";
import React from "react";
import Footer from "./Footer";

export function FooterBottomSheet({ toggleBottomSheet, bottomSheetAnchor }) {
  return (
    <SwipeableDrawer
      anchor={"bottom"}
      open={bottomSheetAnchor ?? false}
      onClose={toggleBottomSheet(false)}
      onOpen={() => toggleBottomSheet(true)}
    >
      <div className="relative">
        <div
          className="absolute r-5 t-5 bg-base-1 hover-up cursor-pointer transition duration-200 rounded-full flex flex-center h-11 w-11"
          onClick={toggleBottomSheet(false)}
        >
          <i className="bx bx-x text-neutral-2 bx-sm"></i>
        </div>
        <Footer />
      </div>
    </SwipeableDrawer>
  );
}
