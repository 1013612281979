import { useSelector } from "react-redux";
import { selectRecentAuthMethod } from "../selectors/authMethod";
import { UserTypes } from "../actions/types";

export const useRecentAuthMethod = () => useSelector(selectRecentAuthMethod);

export enum AuthMethods {
  PHONE = "phone",
  EMAIL = "email",
}

export type AuthMethodState = {
  method: AuthMethods | null;
  value: string;
};

const initialState = {
  method: null,
  value: "",
};

const AuthMethod = (state = initialState, action: any) => {
  switch (action.type) {
    case UserTypes.RECENT_AUTH_METHOD: {
      return action.payload;
    }
    default: {
      return state;
    }
  }
};

export default AuthMethod;
