import { League } from "@phantasia/model-interfaces";

export const FANTASY_SCORING_BY_SPORT = [
  {
    leagues: [League.NBA, League.WNBA],
    icon: "basketball-outline",
    name: "Basketball",
    title: "NBA & WNBA Fantasy Points",
    scoring: {
      Point: "+1",
      Rebound: "+1.2",
      Assist: "+1.5",
      Steal: "+3",
      Block: "+3",
      Turnover: "-1",
    },
  },
  {
    leagues: [League.NFL],
    icon: "american-football-outline",
    name: "Football",
    title: "NFL Fantasy Points",
    scoring: {
      "Passing TD": "+4",
      "Passing Yard": "+0.04",
      Interception: "-1",
      "Rushing TD": "+6",
      "Rushing Yard": "+0.1",
      "Receiving TD": "+6",
      "Receiving Yard": "+0.1",
      Reception: "+1",
      "Fumble Lost": "-1",
    },
  },
  {
    leagues: [League.MLB],
    icon: "baseball-outline",
    name: "Batters",
    title: "MLB Batter Fantasy Points",
    scoring: {
      Single: "+3",
      Double: "+5",
      Triple: "+8",
      "Home Run": "+10",
      "Run Batted In": "+2",
      "Run Scored": "+2",
      "Base on Balls (Walk)": "+2",
    },
  },
  {
    leagues: [League.MLB],
    icon: "baseball-outline",
    name: "Pitchers",
    title: "MLB Pitching Fantasy Points",
    scoring: {
      "Out Pitched": "+1",
      Strikeout: "+3",
      Win: "+6",
      "Earned Run Allowed": "-3",
      "Quality Start (18+ outs, < 4 ER)": "+4",
    },
  },
];
