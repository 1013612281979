import { usePicksBoard } from "common/src/context/picks/PicksBoardContext";
import { HorizontalScrollview } from "../../atoms/Scrollviews";
import { GAME_STATUSES } from "common/src/util/constants";
import Moment from "react-moment";
import { SmallLivePill } from "../../atoms/LivePill";

export function PicksGameFilters() {
  const { gameFilters, setSelectedGame, selectedGame, setSearchQuery } =
    usePicksBoard();

  return (
    <div className={"pr-1"}>
      <HorizontalScrollview className={"pl-8 mb-4 pr-2"}>
        {gameFilters.map((game, index) => (
          <>
            <div
              key={index}
              className={`${
                game.status !== GAME_STATUSES.SCHEDULED ? "no-click" : ""
              }  rounded-5 hover:bg-base-2 h-14 px-4 transition flex-row cursor-pointer items-center animation-from-right ${
                selectedGame && selectedGame._id === game._id
                  ? "outline-primary-1 bg-base-2 outline-2 "
                  : "outline-1"
              }`}
              onClick={() => {
                if (selectedGame && selectedGame._id === game._id)
                  setSelectedGame(null);
                else setSelectedGame(game);
                setSearchQuery("");
              }}
            >
              <div className="flex-col">
                <div className="space-x-5 flex-row items-center p-4 justify-start text-start">
                  <img
                    src={game.away_team_ID?.logo}
                    className={`w-6 contain`}
                    alt="logo"
                  />

                  <span className="text-neutral-2 weight-500 font-sm no-wrap w-full overflow-hidden ellipsis">
                    {game.away_team_ID?.name}
                  </span>
                </div>
                <div className="space-x-5 flex-row flex-center p-4 justify-start text-start">
                  <img
                    src={game.home_team_ID?.logo}
                    className={`w-6 contain`}
                    alt="logo"
                  />

                  <span className="text-neutral-2 weight-500 font-sm no-wrap w-full overflow-hidden ellipsis">
                    {game.home_team_ID?.name}
                  </span>
                </div>
              </div>
              <div className="flex-grow" />
              <div className="flex-center flex-col p-4 items-end">
                <span className="font-sm text-neutral-2 weight-300 no-wrap">
                  <Moment format="hh:mm A">{game.date}</Moment>
                </span>
                <span className="font-xs text-neutral-1 weight-300 no-wrap">
                  <Moment format="ddd MMM DD">{game.date}</Moment>
                </span>
                {game.status === GAME_STATUSES.IN_PROGRESS && (
                  <SmallLivePill className={"mt-3"} />
                )}
              </div>
            </div>
          </>
        ))}
      </HorizontalScrollview>
    </div>
  );
}
