import React, { useEffect, useCallback } from "react";
import { Route, useHistory, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { SignOut } from "common/src/redux/actions/UserAction";
import { useCommonWallet } from "common/src/services/wallet/UseCommonWallet";
import { isLoggedIn, MAGIC_WALLET } from "common/src/services/wallet/magic";
import { useUser } from "common/src/hooks/useUser";

const PrivateRoute = ({ component: Component, ...rest }) => {
  let history = useHistory();
  const location = useLocation();
  let dispatch = useDispatch();
  let wallet = useCommonWallet();
  const user = useUser();

  const signOut = useCallback(() => {
    wallet.disconnect();
    dispatch(SignOut(history, location.pathname));
  }, [dispatch, history, location.pathname, wallet]);

  useEffect(() => {
    if (
      !wallet ||
      !user ||
      !user.walletPubkey ||
      !user.authToken ||
      (wallet?.publicKey && user.walletPubkey !== wallet.publicKey.toString())
    ) {
      signOut();
      window.Intercom("shutdown");
    }
  }, [
    dispatch,
    history,
    location.pathname,
    signOut,
    user,
    wallet,
    wallet.connecting,
  ]);

  useEffect(() => {
    if (!user?.authToken) signOut();
  }, [dispatch, history, location.pathname, signOut, user]);

  useEffect(() => {
    if (wallet.name !== MAGIC_WALLET) return;
    isLoggedIn()
      .then((loggedIn) => {
        if (!loggedIn) {
          signOut();
        }
      })
      .catch((e) => console.error("IsLoggedIn error", e));
  }, [signOut, wallet.name]);

  return <Route {...rest} render={(props) => <Component {...props} />} />;
};

export default PrivateRoute;
