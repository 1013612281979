import { Modal } from "@mui/material";
import { retryTransaction } from "common/src/redux/actions/TransactionAction";
import {
  TransactionActionTypes,
  useTxState,
} from "common/src/redux/reducers/Transaction";
import { Singletons } from "common/src/services/Singletons";
import { useCommonWallet } from "common/src/services/wallet/UseCommonWallet";
import {
  FUND_WALLET_HELP_LINK,
  TRANSACTION_DUPLICATE_MESSAGE,
  TRANSACTION_LOW_SOL_MESSAGE,
  TRANSACTION_WARNING_MESSAGE,
} from "common/src/util/constants";
import { copyLink } from "common/src/util/copyLink";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import SOLcircle from "../../assets/solana-circle.svg";
import { GradientButton3, LinkButton3, SolidButton1 } from "../atoms/Button";
import { ExplorerLink } from "../transaction/ExplorerLink";
import Wallet from "../wallet/Wallet";
import { useHistory, useLocation } from "react-router-dom";

export default function TransactionModal() {
  const dispatch = useDispatch();
  const wallet = useCommonWallet();
  const transactionState = useTxState();

  function getModalContents() {
    switch (transactionState.pending) {
      case TransactionActionTypes.PENDING:
        return (
          <TransactionPendingContents message={transactionState.message} />
        );
      case TransactionActionTypes.SUCCESS:
        return (
          <TransactionSuccessContents
            transactionSignature={transactionState.transactionSignature}
          />
        );
      case TransactionActionTypes.DUPLICATE:
        return <TransactionDuplicateContents />;
      case TransactionActionTypes.FAILURE:
        return (
          <TransactionFailureContents errorLog={transactionState.errorLog} />
        );
      case TransactionActionTypes.WARNING:
        return <TransactionWarningContents />;
      case TransactionActionTypes.NOT_ENOUGH_SOL:
        return (
          <TransactionNotEnoughSolContents
            errorLog={transactionState.errorLog}
          />
        );
      default:
        return null;
    }
  }

  function getModalAction() {
    if (transactionState.pending === TransactionActionTypes.SUCCESS) {
      return (
        <GradientButton3
          color="success"
          onClick={() =>
            dispatch({
              type: TransactionActionTypes.NO_OP,
            })
          }
        >
          Close
        </GradientButton3>
      );
    }
    if (transactionState.pending === TransactionActionTypes.FAILURE) {
      return (
        <GradientButton3
          color="danger"
          onClick={() => dispatch(retryTransaction(transactionState, wallet))}
        >
          Retry Transaction
        </GradientButton3>
      );
    }
    if (transactionState.pending === TransactionActionTypes.WARNING) {
      return (
        <GradientButton3
          label="Retry Transaction"
          color="primary"
          onClick={() => dispatch(retryTransaction(transactionState, wallet))}
        >
          Retry Transaction
        </GradientButton3>
      );
    }

    if (transactionState.pending === TransactionActionTypes.NOT_ENOUGH_SOL) {
      return (
        <div className="flex-col">
          <div className="w-full mb-6">
            <Wallet />
          </div>
          <div className="flex-row space-x-6">
            <LinkButton3
              className={"flex-1"}
              color="neutral"
              link={FUND_WALLET_HELP_LINK}
              contentLeft={
                <i className="bx bxs-file-doc text-neutral-2 font-lg" />
              }
            >
              Other Options
            </LinkButton3>
          </div>
        </div>
      );
    }

    return null;
  }

  return (
    <Modal
      open={Boolean(transactionState.pending !== TransactionActionTypes.NO_OP)}
      className="flex flex-col flex-center outline-none modal z-11"
      onBackdropClick={() => {}}
      onClose={() =>
        dispatch({
          type: TransactionActionTypes.NO_OP,
        })
      }
      BackdropProps={{
        timeout: 500,
      }}
    >
      <div
        className="flex-col animation-from-bottom bg-base-1 p-8 rounded-9 outline-none w-full"
        style={{ maxWidth: "600px" }}
      >
        {getModalContents()}

        {getModalAction()}
      </div>
    </Modal>
  );
}

function TransactionPendingContents({ message }) {
  return (
    <div className="rounded-4 p-8 flex-row space-x-6 bg-base-2 flex-center">
      <i className="bx bx-loader-circle bx-spin bx-lg text-neutral-2" />
      <div className="flex-col">
        <span className="text-lg text-neutral-2 weight-600">
          {message ? message : "Transaction pending..."}
        </span>
        <span className="text-md text-danger-1 weight-400">
          Do <b>NOT</b> refresh your browser!!
        </span>
      </div>
    </div>
  );
}

function TransactionSuccessContents({ transactionSignature }) {
  return (
    <div className="rounded-4 p-8 flex-col flex-center">
      <div className="outline-success-1 outline-1 w-15 h-15 rounded-full bg-success-0 flex flex-center mb-4">
        <i
          id="tx-successful"
          className="bx bx-check-double bx-flashing bx-md text-success-1"
        />
      </div>
      <h3 className="text-neutral-2 weight-500">Transaction Successful!</h3>

      {transactionSignature && (
        <span className="font-lg text-success-1">
          <ExplorerLink transactionSignature={transactionSignature} />
        </span>
      )}
    </div>
  );
}

function TransactionDuplicateContents() {
  const location = useLocation();
  const id = location.pathname.split("/")[3];
  const dispatch = useDispatch();
  const history = useHistory();

  return (
    <div className="rounded-2 p-5 flex-col flex-center">
      <div className="outline-2 outline-primary-1 rounded-full bg-primary-0 flex flex-center mb-2">
        <i className="bx bxs-duplicate bx-lg text-primary-2" />
      </div>
      <h3 className="text-neutral-2 weight-500">Duplicate Entry</h3>
      <span className="text-neutral-1 text-center mb-6 font-md weight-300 line-height-lg">
        {TRANSACTION_DUPLICATE_MESSAGE}
      </span>
      <GradientButton3
        color="primary"
        onClick={() => {
          dispatch({
            type: TransactionActionTypes.NO_OP,
          });
          history.push(`/contest/${id}`);
        }}
      >
        View my contest
      </GradientButton3>
    </div>
  );
}

function TransactionWarningContents() {
  return (
    <div className="rounded-4 p-8 flex-col flex-center">
      <div className="outline-primary-1 outline-1 w-15 h-15 rounded-full bg-primary-0 flex flex-center mb-4">
        <i className="bx bx-refresh bx-tada bx-md text-primary-1" />
      </div>
      <h3 className="text-neutral-2 weight-500">Please Retry Transaction</h3>
      <span className="text-neutral-1 font-md weight-300 line-height-lg">
        {TRANSACTION_WARNING_MESSAGE}
      </span>
    </div>
  );
}

function TransactionNotEnoughSolContents({ errorLog }) {
  return (
    <div className="rounded-4 p-8 flex-col flex-center">
      <div className="outline-warning-1 outline-1 w-15 h-15 rounded-full bg-warning-0 flex flex-center mb-4">
        <i className="bx bx-wallet bx-tada bx-md text-warning-1" />
      </div>
      <div className="flex space-x-5 items-center mb-8 mt-6">
        <h3 className="text-neutral-2 weight-500 m-0">You Need More SOL</h3>
        <img
          className="h-9 w-9 rounded-full cover"
          src={SOLcircle}
          alt="solana"
        />
      </div>
      <span className="text-neutral-1 font-sm weight-300 line-height-lg mb-5">
        {TRANSACTION_LOW_SOL_MESSAGE}
      </span>
      <span className="text-neutral-2 font-md weight-300 line-height-lg mb-5">
        {errorLog}
      </span>
    </div>
  );
}

function TransactionFailureContents({ errorLog }) {
  const [viewLogs, setViewLogs] = useState(true);
  return (
    <div className="rounded-4 p-8 flex-col flex-center">
      <div className="outline-danger w-15 h-15 rounded-full bg-danger-0 flex flex-center mb-4">
        <i className="bx bx-error bx-flashing bx-md text-danger-1" />
      </div>
      <h3 className="text-neutral-2 weight-500">Transaction Failed</h3>
      <div className="flex-row space-x-5 w-full">
        <SolidButton1
          className="w-1/2 "
          contentLeft={
            <i
              className={`bx bx-chevron-${
                viewLogs ? "up" : "down"
              } text-neutral-2`}
            />
          }
          color={"neutral"}
          onClick={() => setViewLogs(!viewLogs)}
        >
          {`${viewLogs ? "Hide" : "View"} error logs`}
        </SolidButton1>
        <SolidButton1
          className="w-1/2 "
          contentLeft={<i className="bx bx-copy text-neutral-2" />}
          color={"neutral"}
          onClick={() =>
            copyLink(errorLog, true).then(() =>
              Singletons.toastService.success(
                "Error copied!",
                "Error copied! Please send to developers on Discord."
              )
            )
          }
        >
          Copy error
        </SolidButton1>
      </div>

      {viewLogs && (
        <div
          className="animation-grow-y transition duration-200 mt-5 overflow-auto  outline-1 w-full p-5 rounded-5"
          style={{ maxHeight: 150 }}
        >
          {typeof errorLog === "object" ? (
            <span className="font-sm text-neutral-2 ">
              {JSON.stringify(errorLog)}
            </span>
          ) : (
            <span className="font-sm text-neutral-2 ">
              {errorLog ? errorLog : "no logs provided"}
            </span>
          )}
        </div>
      )}
    </div>
  );
}
