import { UserTypes, WalletTypes } from "./types";
import { logoutUser } from "../../services/wallet/magic";
import { Singletons } from "../../services/Singletons";
import { REDIRECT_SEARCH_PARAM } from "../../services/RedirectService";
import { getApi } from "../../util/api";

const { SIGN_OUT } = UserTypes;
const { RESET_BALANCES } = WalletTypes;

export const SignOut = (history, redirectPath) => async (dispatch) => {
  try {
    const user = Singletons.store.getState().User;
    if (user.authToken && user.authToken.includes("=")) {
      await logoutUser().catch((e) => console.error(e));
    }

    dispatch({
      type: SIGN_OUT,
    });
    dispatch({ type: RESET_BALANCES });

    if (redirectPath) {
      history.push(`/login?${REDIRECT_SEARCH_PARAM}=${redirectPath}`);
    } else history.push("/login");
  } catch (error) {
    console.error(error);
  }
};

export const clearDeviceId = async () => {
  await getApi().post("api/auth/clearid");
};
