import { UserTypes } from "../actions/types";
import moment from "moment";

const {
  SET_USER,
  SET_FANCARD,
  LOGIN_SUCCESS,
  REGISTER_SUCCESS,
  SIGN_OUT,
  SET_NFT_METADATA,
  SET_PHANTASIA_NFT_HOLDER,
  SET_AUTH_TOKEN,
  SET_KEYPAIR,
  UPDATE_CARDS,
  UPDATE_EMAIL,
  UPDATE_NOTIFICATION_PREFERENCES,
  UPDATE_PAYMENTS,
  UPDATE_NOTIFICATIONS,
  UPDATE_SEEN_NEW,
  UPDATE_SPIN_TIMESTAMP,
} = UserTypes;

const initialState = {
  username: "",
  loginUsername: "",
  acceptedTerms: false,
  _id: "",
  isAdmin: false,
  fanCard: {},
  nft_metadata: [],
  phantasia_nft_holder: false,
  walletPubkey: "",
  authToken: "", // This can either be the wallet signed message or the magic token
  passedKyc: false,
  kycStatus: "",
  birthdate: "",
  keypair: "",
  cards: [],
  payments: [],
  eligible_for_bonus: false,
  notifications: [],
  update_seen: true,
  referenceId: "",
};

const User = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER: {
      return {
        ...state,
        ...action.payload,
        notifications: sortNotifications(action.payload.notifications),
      };
    }
    case SET_FANCARD: {
      return {
        ...state,
        fanCard: action.payload,
      };
    }
    case SET_NFT_METADATA: {
      return {
        ...state,
        nft_metadata: action.payload,
      };
    }
    case SET_PHANTASIA_NFT_HOLDER: {
      return {
        ...state,
        phantasia_nft_holder: action.payload,
      };
    }

    case LOGIN_SUCCESS:
    case REGISTER_SUCCESS: {
      return {
        ...state,
        username:
          action.payload.user.username ||
          action.payload.user.email ||
          action.payload.user.loginUsername,
        loginUsername:
          action.payload.user.username ||
          action.payload.user.email ||
          action.payload.user.loginUsername,
        _id: action.payload.user._id,
        isAdmin: action.payload.user.isAdmin,
        acceptedTerms: action.payload.user.acceptedTerms,
        walletPubkey: action.payload.user.walletPubkey,
        authToken: action.payload.authToken,
        passedKyc: action.payload.user.passedKyc ?? false,
        birthdate: action.payload.user.birthdate,
        kycStatus: action.payload.user.kycStatus,
        cards: action.payload.user.cards,
        payments: action.payload.user.payments,
        email: action.payload.user.email,
        referenceId: action.payload.user.referenceId,
        notificationPreferences: {
          email: action.payload.user.notificationPreferences?.email,
          push: action.payload.user.notificationPreferences?.push,
        },
        notifications: sortNotifications(action.payload.user.notifications),
        update_seen: action.payload.user.update_seen ?? false,
      };
    }

    case SET_AUTH_TOKEN: {
      return {
        ...state,
        authToken: action.payload,
      };
    }

    case SIGN_OUT: {
      return initialState;
    }

    case SET_KEYPAIR:
      return {
        ...state,
        keypair: action.payload,
      };
    case UPDATE_CARDS:
      return {
        ...state,
        cards: action.payload,
      };
    case UPDATE_PAYMENTS:
      return {
        ...state,
        payments: action.payload,
      };
    case UPDATE_EMAIL:
      return {
        ...state,
        email: action.payload,
      };
    case UPDATE_NOTIFICATION_PREFERENCES:
      return {
        ...state,
        notificationPreferences: {
          email: action.payload.email,
          push: action.payload.push,
        },
      };
    case UPDATE_NOTIFICATIONS:
      return {
        ...state,
        notifications: sortNotifications(action.payload),
      };

    case UPDATE_SEEN_NEW:
      return {
        ...state,
        update_seen: true,
      };

    case UPDATE_SPIN_TIMESTAMP:
      return {
        ...state,
        spinTimestamp: action.payload,
      };

    default:
      return state;
  }
};

function sortNotifications(ntxs) {
  if (!ntxs) return [];
  return ntxs.sort((a, b) => {
    return moment(b.createdAt).valueOf() - moment(a.createdAt).valueOf();
  });
}

export default User;
