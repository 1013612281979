import { useMediaQuery } from "@mui/material";
import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";
import {
  BackpackWalletAdapter,
  PhantomWalletAdapter,
  SlopeWalletAdapter,
  SolflareWalletAdapter,
} from "@solana/wallet-adapter-wallets";
import GeolocationContextProvider from "common/src/context/GeolocationContext";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { CoinflowPurchaseProtection } from "@coinflowlabs/react";
import { Toaster } from "react-hot-toast";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { PersistGate } from "redux-persist/lib/integration/react";
import "./App.css";
import { FooterBottomSheet } from "./components/layout/footer/FooterBottomSheet";
import { FooterTrigger } from "./components/layout/footer/FooterTrigger";
import Header from "./components/layout/header/Header";
import { MobileAdModal } from "./components/modals/MobileAdModal";
import Routes from "./Routes";
import { persistor, store } from "./store";
import ReactPixel from "react-facebook-pixel";
import { ThemeContextProvider } from "./utils/ThemeContext";
import { useSolanaNet } from "common/src/hooks/useConnection";
import { WalletModalProvider } from "@solana/wallet-adapter-react-ui";

require("@solana/wallet-adapter-react-ui/styles.css");

function App() {
  const isMobile = useMediaQuery("(max-width:768px)");

  const net = useSolanaNet();

  const network = net === "devnet" ? "devnet" : "mainnet-beta";

  const endpoint = process.env.REACT_APP_RPC_URL;

  const wallets = useMemo(
    () => [
      new PhantomWalletAdapter(),
      new SlopeWalletAdapter(),
      new BackpackWalletAdapter(),
      new SolflareWalletAdapter(),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [network]
  );

  const [showMobileAd, setShowMobileAd] = useState(isMobile && true);
  // const [showMobileAd, setShowMobileAd] = useState(false);

  useEffect(() => {
    const advancedMatching = { em: "some@email.com" };

    const options = {
      autoConfig: true,
      debug: false, // enable logs
    };
    ReactPixel.init("774165736661030", advancedMatching, options);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isMobile) {
      setShowMobileAd(true);
    }
  }, [isMobile]);

  const [bottomSheetAnchor, setBottomSheetAnchor] = useState(null);

  const toggleBottomSheet = (open) => (event) => {
    if (!event) return;
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setBottomSheetAnchor(open);
  };
  const toggleMobileAdBottomSheet = (open) => (event) => {
    if (!event) return;
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setShowMobileAd(open);
  };

  return (
    <Fragment>
      <CoinflowPurchaseProtection
        coinflowEnv={net === "mainnet" ? "prod" : "sandbox"}
        merchantId={"phantasia"}
      />
      <ConnectionProvider endpoint={endpoint}>
        <WalletProvider wallets={wallets} autoConnect>
          <ThemeContextProvider>
            <Provider store={store}>
              <Toaster />
              <WalletModalProvider>
                <GeolocationContextProvider>
                  <PersistGate persistor={persistor}>
                    <Router>
                      <div id="content-wrap">
                        <Header />
                        <Routes />
                      </div>
                      <div className="flex-grow" />
                      <FooterTrigger openFooter={toggleBottomSheet(true)} />
                      <FooterBottomSheet
                        toggleBottomSheet={toggleBottomSheet}
                        bottomSheetAnchor={bottomSheetAnchor}
                      />
                      {isMobile && (
                        <MobileAdModal
                          toggleBottomSheet={toggleMobileAdBottomSheet}
                          bottomSheetAnchor={showMobileAd}
                        />
                      )}
                    </Router>
                  </PersistGate>
                </GeolocationContextProvider>
              </WalletModalProvider>
            </Provider>
          </ThemeContextProvider>
        </WalletProvider>
      </ConnectionProvider>
    </Fragment>
  );
}

export default App;
