import { PropDecision, PropKey } from "@phantasia/model-interfaces";
import React, { ReactNode, useContext, useEffect, useState } from "react";
import { Singletons } from "../../services/Singletons";
import {
  calculateBoostedValue,
  createFreePlay,
  createNewSlip,
  getBetaMaxPayout,
  slipContainsSamePlayer,
  slipContainsTwoSameTeam,
} from "../../services/PicksService";
import {
  PopulatedPickProp,
  PopulatedSlipLeg,
} from "../../services/dtos/ContestDtos";
import { PublicKey, Transaction } from "@solana/web3.js";
import { useBalance } from "../../hooks/useBalance";
import { useUser } from "../../hooks/useUser";
import { useDispatch } from "react-redux";
import { UserTypes } from "../../redux/actions/types";

export const MAX_SLIP_LEGS = 5;

export const MAX_ENTRY_AMOUNT = 100;

export const NFL_QB_YARDS_BLOCKED_COMBOS = [
  PropKey.PASSING_YARDS,
  PropKey.RECEIVING_YARDS,
  PropKey.PASSING_YARDS,
];

export const NFL_BLOCKED_COMBOS = [PropKey.PASSING_YARDS, PropKey.PASSING_TD];

export const NFL_QB_TD_BLOCKED_COMBOS = [PropKey.PASSING_TD, PropKey.TOTAL_TD];
export const MLB_HRR_BLOCKED_COMBOS = [
  PropKey.BASES,
  PropKey.BATTING_RUNS,
  PropKey.BATTING_RBI,
  PropKey.HITS_RUNS_RBI,
];

export const MLB_TB_BLOCKED_COMBOS = [
  PropKey.HITS_RUNS_RBI,
  PropKey.BATTING_RUNS,
  PropKey.BATTING_RBI,
];

export const MLB_RBI_BLOCKED_COMBOS = [
  PropKey.BASES,
  PropKey.BATTING_RUNS,
  PropKey.HITS_RUNS_RBI,
];

export const MLB_R_BLOCKED_COMBOS = [
  PropKey.BASES,
  PropKey.HITS_RUNS_RBI,
  PropKey.BATTING_RBI,
];

interface SlipBuilderContextProps {
  currentSlip: PopulatedSlipLeg[];
  addLegToSlip: (pick: PopulatedPickProp, decision: PropDecision) => void;
  removeLegFromSlip: (id: string) => void;
  updateLegValue: (id: string, value: number) => void;
  entryAmount: number;
  setEntryAmount: (entry: number) => void;
  submitSlip: (
    handleCreditEntry: (tx: Transaction) => void,
    skipKyc?: boolean
  ) => Promise<Transaction | null | undefined>;
  transaction: Transaction | null;
  setTransaction: (tx: Transaction | null) => void;
  clearSlip: () => void;
  buttonLoading: boolean;
  setButtonLoading: (loading: boolean) => void;
  maxPayout: number;
  insurance: boolean;
  setInsurance: (insurance: boolean) => void;
  boost: boolean;
  setBoost: (boost: boolean) => void;
  submitFreePlay: (onSuccess: () => void) => void;
}

export const SlipBuilderContext = React.createContext<SlipBuilderContextProps>({
  submitFreePlay(onSuccess: () => void): void {},
  currentSlip: [],
  addLegToSlip: () => {},
  removeLegFromSlip: () => {},
  updateLegValue: () => {},
  entryAmount: 0,
  setEntryAmount: () => {},
  submitSlip: () => new Promise<null>(() => {}),
  transaction: null,
  setTransaction: () => {},
  clearSlip: () => {},
  buttonLoading: false,
  setButtonLoading: () => {},
  maxPayout: 0,
  insurance: false,
  setInsurance: (insurance: boolean) => {},
  boost: false,
  setBoost: (boost: boolean) => {},
});

export function SlipBuilderContextProvider({
  children,
}: {
  children: ReactNode;
}) {
  const [currentSlip, setCurrentSlip] = useState<PopulatedSlipLeg[]>([]);
  const [entryAmount, setEntryAmount] = useState<number>(0);
  const [transaction, setTransaction] = useState<Transaction | null>(null);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [maxPayout, setMaxPayout] = useState<number>(1000);
  const [insurance, setInsurance] = useState<boolean>(false);
  const [boost, setBoost] = useState<boolean>(false);

  const { usdc, credits } = useBalance();
  const user = useUser();
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      user.freePlays &&
      user.freePlays > 0 &&
      currentSlip.length === MAX_SLIP_LEGS
    ) {
      setEntryAmount(user.freePlays);
    }
  }, [currentSlip, user]);

  useEffect(() => {
    getBetaMaxPayout().then((res) => {
      if (res.data.isClosed) setMaxPayout(-1);
      else setMaxPayout(Math.max(res.data.maxPayout, 0));
    });
  }, []);

  function addLegToSlip(pick: PopulatedPickProp, decision: PropDecision) {
    const tempSlip = [...currentSlip];

    const existingPick = tempSlip.find((leg) => leg.prop._id === pick._id);
    if (existingPick) {
      if (existingPick.prop_decision === decision) {
        removeLegFromSlip(pick._id);
      } else editLegOnSlip(pick._id, decision, pick.value);
      return;
    }
    if (tempSlip.length >= MAX_SLIP_LEGS) {
      Singletons.toastService.error(
        "Max picks",
        `Maximum ${MAX_SLIP_LEGS} picks allowed`
      );
      return;
    }
    tempSlip.push({
      prop: pick,
      bonus_type: pick.bonus_type,
      prop_decision: decision,
      value: calculateBoostedValue(pick),
    });
    setCurrentSlip(tempSlip);
  }

  function removeLegFromSlip(id: string) {
    const tempSlip = [...currentSlip];
    const index = tempSlip.findIndex((leg) => leg.prop._id === id);
    if (index >= 0) {
      tempSlip.splice(index, 1);
      setCurrentSlip(tempSlip);
      if (tempSlip.length <= 2) setInsurance(false);
    }
  }

  function editLegOnSlip(id: string, decision: PropDecision, value: number) {
    const tempSlip = [...currentSlip];
    const index = tempSlip.findIndex((leg) => leg.prop._id === id);
    if (index >= 0) {
      tempSlip[index].prop_decision = decision;
      tempSlip[index].value = value;
      setCurrentSlip(tempSlip);
    }
  }

  function updateLegValue(id: string, value: number) {
    const tempSlip = [...currentSlip];
    const index = tempSlip.findIndex((leg) => leg.prop._id === id);
    if (index >= 0) {
      tempSlip[index].prop.value = value;
      tempSlip[index].value = value;
      setCurrentSlip(tempSlip);
    }
  }

  async function submitFreePlay(onSuccess: () => void) {
    if (!validateEntry()) return;
    setButtonLoading(true);
    try {
      const res = await createFreePlay(currentSlip, boost ? 1 : 0);
      if (res) {
        dispatch({
          type: UserTypes.SET_USER,
          payload: { ...user, freePlays: res.data },
        });
      }
      onSuccess();
      setButtonLoading(false);
    } catch (e) {
      console.error(e);
      // @ts-ignore
      console.error(e?.response?.data?.msg);
      // @ts-ignore
      const msg = e?.response?.data?.msg;
      Singletons.toastService.error("Entry error", msg);
      setButtonLoading(false);
    }
  }

  function validateEntry() {
    if (entryAmount < 1) {
      Singletons.toastService.error("Entry error", "Minimum $1 entry");
      return false;
    }

    if (currentSlip.length < 2) {
      Singletons.toastService.error(
        "Entry error",
        "Must select at least 2 players"
      );
      return false;
    }

    if (slipContainsTwoSameTeam(currentSlip)) {
      Singletons.toastService.error(
        "Entry error",
        "Players must be on at least 2 different teams"
      );
      return false;
    }
    if (slipContainsSamePlayer(currentSlip)) {
      Singletons.toastService.error(
        "Entry error",
        "Cannot use same player more than once in an entry"
      );
      return false;
    }
    return true;
  }

  async function submitSlip(
    handleCreditEntry: (tx: Transaction) => void,
    skipKyc = false
  ) {
    if (!validateEntry()) return;
    setButtonLoading(true);

    let tx: Transaction | null = null;
    try {
      const response = await createNewSlip(
        currentSlip,
        entryAmount,
        skipKyc,
        insurance ? 2 : 1,
        boost ? 1 : 0
      );
      const rawTx = response.data.tx;
      const serializedTx = Buffer.from(rawTx as any);
      tx = Transaction.from(serializedTx);
      if (tx.feePayer) {
        tx.feePayer = new PublicKey(tx.feePayer);
      }
      if (tx && usdc + credits / 100 >= entryAmount && handleCreditEntry) {
        handleCreditEntry(tx);
      } else {
        setTransaction(tx);
      }

      setButtonLoading(false);
    } catch (e) {
      console.error(e);
      // @ts-ignore
      console.error(e?.response?.data?.msg);
      // @ts-ignore
      const msg = e?.response?.data?.msg;
      Singletons.toastService.error("Entry error", msg);
      setButtonLoading(false);
    }

    return tx;
  }

  async function clearSlip() {
    setCurrentSlip([]);
    setEntryAmount(0);
    setTransaction(null);
    setButtonLoading(false);
    setInsurance(false);
    setBoost(false);
  }

  return (
    <SlipBuilderContext.Provider
      value={{
        currentSlip,
        addLegToSlip,
        removeLegFromSlip,
        entryAmount,
        setEntryAmount,
        submitSlip,
        transaction,
        clearSlip,
        buttonLoading,
        setButtonLoading,
        setTransaction,
        maxPayout,
        insurance,
        setInsurance,
        updateLegValue,
        boost,
        setBoost,
        submitFreePlay,
      }}
    >
      {children}
    </SlipBuilderContext.Provider>
  );
}

export const useSlipBuilder = () => useContext(SlipBuilderContext);
