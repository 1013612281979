import { ConnectionService } from "@phantasia/model-interfaces";

export function initializeConnections(net, overrideConnectionString) {
  ConnectionService.setNet(net);

  if (overrideConnectionString)
    ConnectionService.setConfig(overrideConnectionString, {
      commitment: "confirmed",
    });

  return ConnectionService.getConnectionString();
}
