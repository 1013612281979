import React from "react";
import { Option } from "../atoms/Option";
import "../../sass/Molecules.scss";

export const Select = ({
  options,
  setValue,
  value,
  classes,
  label,
  containerClasses,
  showError,
  size,
}) => {
  if (!options?.length) return null;
  return (
    <div
      className={`relative ${showError && "outline-danger-1 outline-1"} ${
        label && "flex-col"
      } ${containerClasses}`}
    >
      {label && (
        <span className="font-sm letter-spacing-sm weight-300 text-neutral-1 uppercase mb-4 pl-4">
          {label}
        </span>
      )}
      <div className="relative">
        <i
          className={`bx bx-chevron-down ${
            !classes && "bx-sm"
          } absolute-middle-horizontal r-4 cursor-pointer no-click text-neutral-1`}
        />
        <select
          className={`${
            size === "sm" ? "font-sm p-5" : "font-md p-6"
          } min-w-20 w-full bg-base-2 rounded-5 pr-10 text-neutral-2 ${classes}`}
          value={value}
          onChange={setValue}
        >
          {options.map((option) => (
            <Option
              key={option.value ? option.value.toString() : option.toString()}
              value={option.value ? option.value : option}
              label={option.label ? option.label : option}
            />
          ))}
        </select>
      </div>
    </div>
  );
};

export function SelectSmall({
  options,
  setValue,
  value,
  classes,
  label,
  containerClasses,
  showError,
}) {
  return (
    <div
      className={`relative ${showError && "outline-danger-1 outline-1"} ${
        label && "flex-col"
      } ${containerClasses}`}
    >
      <i
        className={`bx bx-chevron-down absolute b-4 r-4 font-sm text-neutral-2`}
      ></i>
      {label && (
        <span className="font-xs weight-500 text-neutral-2 mb-3 pl-3">
          {label}
        </span>
      )}

      <select
        className={
          "rounded-15 px-5 pr-8 font-xs text-neutral-2 py-4 " + classes
        }
        value={value}
        onChange={setValue}
      >
        {options.map((option) => (
          <Option
            key={option.value ? option.value.toString() : option.toString()}
            value={option.value ? option.value : option}
            label={option.label ? option.label : option}
          />
        ))}
      </select>
    </div>
  );
}
