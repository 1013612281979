import { PublicKey, Transaction } from "@solana/web3.js";
import { createContext, useContext, useMemo } from "react";
import { Singletons } from "../Singletons";
import { getApi } from "../../util/api";
import { AxiosError } from "axios";
import { ConnectionService } from "@phantasia/model-interfaces";

const MAGIC_TOKEN_LIFESPAN = 604800; // This is 7 days which is also the lifetime of the session

export const getMagicUser = async () => {
  return Singletons.magic.user.getInfo();
};

export const isLoggedIn = async () => {
  const userRes = await getApi()
    .get("/api/auth/isLoggedIn")
    .then(() => true)
    .catch((e) => (e as AxiosError | undefined)?.response?.status !== 401);

  if (!userRes) return false;

  return Singletons.magic.user.isLoggedIn().catch(() => true);
};

export const loginUserViaEmail = async (
  email: string,
  useMagicLink?: boolean
) => {
  try {
    if (useMagicLink) {
      await Singletons.magic.auth.loginWithMagicLink({ email });
    } else {
      await Singletons.magic.auth.loginWithEmailOTP({ email });
    }
  } catch (e) {
    console.error("Email sign in error", { e });
  }
};

export const loginUserViaPhone = async (phoneNumber: string) => {
  try {
    await Singletons.magic.auth.loginWithSMS({ phoneNumber });
  } catch (e) {
    console.error("Phone sign in error", { e });
  }
};

export const getToken = async () => {
  return Singletons.magic.user.getIdToken({ lifespan: MAGIC_TOKEN_LIFESPAN });
};

export const logoutUser = async () => {
  return Singletons.magic.user.logout();
};

export const MAGIC_WALLET = "Magic";

export function useMagicWallet() {
  const publicKey = useMemo(() => {
    return getPublicKey();
  }, []);
  const WalletContext = getMagicWalletContext(publicKey);
  return useContext(WalletContext);
}

function getPublicKey(): PublicKey | null {
  const user = Singletons.store?.getState()?.User;
  if (!user || !user.walletPubkey || !user.authToken) return null;

  if (!user.authToken.includes("=")) return null;

  try {
    return new PublicKey(user.walletPubkey);
  } catch (e) {
    console.error(e);
    return null;
  }
}

function getMagicWalletContext(publicKey: PublicKey | null) {
  const signTransaction = async (tx: Transaction) => {
    const serializeConfig = {
      requireAllSignatures: false,
      verifySignatures: true,
    };
    getApi()
      .post("/api/transaction/alert", {
        wallet: "",
        msg: `Signing Now`,
      })
      .then();
    const { rawTransaction } = await Singletons.magic.solana.signTransaction(
      tx,
      serializeConfig
    );

    const transaction = Transaction.from(rawTransaction);
    const missingSigners = transaction.signatures
      .filter((s) => !s.signature)
      .map((s) => s.publicKey);
    missingSigners.forEach((publicKey) => {
      const signature = tx.signatures.find((s) =>
        publicKey.equals(s.publicKey)
      );
      if (signature?.signature)
        transaction.addSignature(publicKey, signature.signature);
    });

    return transaction;
  };

  const WalletContextState = {
    name: MAGIC_WALLET,
    url: "https://magic.link/",
    icon: "/MagicIcon.png",
    readyState: "Installed",
    publicKey,
    connecting: false,
    connected: true,

    wallets: [],
    autoConnect: true,
    disconnecting: false,
    wallet: {
      name: MAGIC_WALLET,
      url: "https://magic.link/",
      icon: "/MagicIcon.png",
      adapter: {},
    },

    disconnect: () => logoutUser(),

    signTransaction,

    sendTransaction: async (transaction: Transaction) => {
      const tx = await signTransaction(transaction);

      return await ConnectionService.getConnection().sendRawTransaction(
        tx.serialize()
      );
    },

    signMessage: async (message: Uint8Array) => {
      return await Singletons.magic.solana.signMessage(message);
    },
  };

  return createContext(WalletContextState);
}
