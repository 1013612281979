import { Modal } from "@mui/material";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { GradientButton2, OutlineButton3 } from "../atoms/Button";
import { useCommonWallet } from "common/src/services/wallet/UseCommonWallet";
import { useDispatch } from "react-redux";
import { SignOut } from "common/src/redux/actions/UserAction";
import { blockUser } from "common/src/services/AuthService";

export function BlockUserModal() {
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const wallet = useCommonWallet();
  const dispatch = useDispatch();

  async function block() {
    await blockUser().then(logout());
  }

  function logout() {
    if (wallet) wallet.disconnect();
    dispatch(SignOut(history));
    window.Intercom("shutdown");
  }

  return (
    <>
      <OutlineButton3
        color="danger"
        className="mt-8 w-full mb-15"
        onClick={() => setOpen(true)}
      >
        Delete Account
      </OutlineButton3>
      <Modal
        open={Boolean(open)}
        onClose={() => {
          setOpen(false);
        }}
        className="flex flex-col flex-center modal"
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div className="flex-col space-y-6 animation-from-bottom max-h-9/10 p-9 overflow-auto bg-danger-0 rounded-9 w-full max-w-31 outline-none">
          <div className={"flex-col flex-center w-full"}>
            <span className="font-lg weight-300 text-danger-1 letter-spacing-lg flex">
              WARNING!
            </span>
            <span className="font-sm mt-4 weight-100 text-danger-1 letter-spacing-sm">
              You will lose access to Phantasia if you delete your account!
            </span>
            <div className="flex-row space-x-6">
              <GradientButton2
                color="success"
                className="mt-8"
                onClick={() => setOpen(false)}
              >
                Cancel
              </GradientButton2>
              <GradientButton2
                color="danger"
                className="mt-8"
                onClick={() => block()}
              >
                Confirm Account Deletion
              </GradientButton2>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
