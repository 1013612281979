import { GameStatus } from "@phantasia/model-interfaces";
import { getPositionBackgroundColor } from "common/src/util/ColorTranslater";
import PlayerAvatar from "../molecules/PlayerAvatar";
import moment from "moment";

export default function PlayerTableCard({
  player,
  onSelectPlayer,
  openPlayerModal,
  budget,
  index,
  playerDisabled,
  onRemovePlayer,
  rosterIndex,
}) {
  return (
    <DesktopCard
      fantasyPlayer={player}
      onSelectPlayer={onSelectPlayer}
      openPlayerModal={openPlayerModal}
      budget={budget}
      index={index}
      playerDisabled={playerDisabled}
      onRemovePlayer={onRemovePlayer}
      rosterIndex={rosterIndex}
    />
  );
}

function DesktopCard({
  fantasyPlayer,
  onSelectPlayer,
  openPlayerModal,
  budget,
  index,
  playerDisabled,
  rosterIndex,
}) {
  if (!fantasyPlayer) return null;
  const { player, projection, game } = fantasyPlayer;

  if (!projection) return null;

  const isVerified = projection.in_lineup;

  const opponent_rank_null = Boolean(projection?.opponent_rank);

  return (
    <div
      onClick={() => openPlayerModal(fantasyPlayer)}
      className={`animation-fade flex py-4 flex-1 hover:bg-base-2 transition hover-down items-center cursor-pointer px-4 rounded-4 relative max-w-full  ${
        playerDisabled && "opacity-50"
      }`}
    >
      {/* Rank */}
      {index !== undefined ? (
        <div className="flex-center flex h-full min-w-11">
          <span className="font-sm text-neutral-1">{index + 1}</span>
        </div>
      ) : null}
      {/* Position */}
      <div className={`flex flex-center h-full min-w-11 `}>
        <div
          className={`mr-4 w-3 h-3 rounded-full`}
          style={getPositionBackgroundColor(player.position)}
        />
        <span className="font-sm text-neutral-2 weight-400">
          {player.position}
        </span>
      </div>
      {/* Name and Picture */}
      <div className="flex-row ml-3 flex-shrink space-x-5 flex-grow flex-shrink overflow-visible">
        <PlayerAvatar player={fantasyPlayer} />
        <div className="flex-col flex-1 flex-shrink w-1  truncate">
          <div className={"flex-row space-x-3 trunacate"}>
            <span className="weight-400 no-wrap truncate font-md text-neutral-2">
              {player.name}
            </span>
            {isVerified ? (
              <i className="bx bxs-badge-check text-success-1" />
            ) : null}
          </div>

          <div className="flex-row items-baseline">
            <span className="weight-300 font-sm text-neutral-1">
              {`${player.team} •  ${moment(game.date).format("ddd h:mm a")}`}
            </span>
            {game.status === GameStatus.POSTPONED && (
              <div className="flex-row space-x-4 ml-5">
                <i className="bx bx-error text-danger-1 font-xs" />
                <span className="weight-400 font-xs text-danger-1">
                  Game Postponed
                </span>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Projected Points */}
      <div
        className={`flex items-center text-start h-full  text-center min-w-13 `}
      >
        <span className="text-neutral-1 font-sm weight-400">
          {projection?.fantasy_points}
        </span>
      </div>

      {/* Opponent */}
      <div
        className={`flex items-center text-start h-full  text-center min-w-13 `}
      >
        <span className="text-neutral-1 font-sm weight-400">
          {projection?.opponent ? projection?.opponent + " " : "-"}
        </span>
        {opponent_rank_null && (
          <span
            className={`weight-400 font-sm ${
              projection?.opponent_rank < 10
                ? "text-danger-1"
                : projection?.opponent_rank > 20
                ? "text-success-1"
                : "text-neutral-1"
            }`}
          >
            {"(" + projection?.opponent_rank + ")"}
          </span>
        )}
      </div>

      {/* Salary */}
      <div
        className={`flex items-center text-start h-full  text-center min-w-13 `}
      >
        <span
          className={`font-sm weight-400
                    ${
                      projection?.salary > budget
                        ? "text-danger-1"
                        : "text-neutral-1"
                    }
                  `}
        >
          ${projection?.salary}
        </span>
      </div>
    </div>
  );
}
