import React, {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  PicksWinnings,
  PopulatedSlip,
  SlipWithPerformanceAndProjection,
} from "../../services/dtos/ContestDtos";
import {
  getPicksWinnings,
  getSettledSlipsForUser,
} from "../../services/PicksService";

interface SettledSlipsContextProps {
  settledSlips: PopulatedSlip[] | null;
  selectedSlip: SlipWithPerformanceAndProjection | PopulatedSlip | null;
  setSelectedSlip: (slip: PopulatedSlip | null) => void;
  winnings: PicksWinnings;
  refresh: () => void;
  loadNextSet: () => void;
  loadingSlips: boolean;
}

export const SettledSlipsContext =
  React.createContext<SettledSlipsContextProps>({
    settledSlips: null,
    selectedSlip: null,
    setSelectedSlip: () => {},
    winnings: {
      winnings: 0,
      winAmount: 0,
      refunds: 0,
      count: 0,
    },
    refresh: () => {},
    loadNextSet: () => {},
    loadingSlips: true,
  });

export function SettledSlipsContextProvider({
  children,
}: {
  children: ReactNode;
}) {
  const [settledSlips, setSettledSlips] = useState<PopulatedSlip[] | null>(
    null
  );
  const [winnings, setWinnings] = useState<PicksWinnings>({
    winnings: 0,
    refunds: 0,
    count: 0,
    winAmount: 0,
  });
  const [selectedSlip, setSelectedSlip] = useState<
    SlipWithPerformanceAndProjection | PopulatedSlip | null
  >(null);

  const [offset, setOffset] = useState<number>(10);
  const [loadingSlips, setLoadingSlips] = useState<boolean>(true);

  const refresh = useCallback(() => {
    getSettledSlipsForUser(0).then((res) => {
      setSettledSlips(res.data.slips);
      setSelectedSlip(res.data.selected);
      setLoadingSlips(true);
      setOffset(10);
    });

    getPicksWinnings().then((res) => {
      setWinnings(res.data);
    });
  }, []);

  const loadNextSet = useCallback(() => {
    if (!settledSlips || !loadingSlips) return;

    getSettledSlipsForUser(offset).then((res) => {
      if (!res.data.slips || res.data.slips.length === 0)
        setLoadingSlips(false);
      const temp = settledSlips.slice();
      setSettledSlips(temp.concat(res.data.slips));
      setOffset(offset + 10);
    });
  }, [loadingSlips, offset, settledSlips]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  return (
    <SettledSlipsContext.Provider
      value={{
        settledSlips,
        selectedSlip,
        setSelectedSlip,
        winnings,
        refresh,
        loadNextSet,
        loadingSlips,
      }}
    >
      {children}
    </SettledSlipsContext.Provider>
  );
}

export const useSettledSlips = () => useContext(SettledSlipsContext);
