import React, { createContext, useEffect, useState } from "react";
import { getApi } from "../util/api";
import { isMobile } from "../util/isMobile";

export const GeolocationContext = createContext({});

export const NOT_FOUND = "not found";

export const P2E_COUNTRY_BLACKLIST = [
  "by",
  "cf",
  "cd",
  "kp",
  "cu",
  "ir",
  "ly",
  "so",
  "ss",
  "sd",
  "sy",
  "th",
  "ye",
  "zw",
];

export const PAID_STATE_IP_WHITELIST = [
  "alaska",
  "california",
  "florida",
  "georgia",
  "illinois",
  "kansas",
  "kentucky",
  "minnesota",
  "nebraska",
  "new mexico",
  "north carolina",
  "north dakota",
  "oklahoma",
  "oregon",
  "rhode island",
  "south carolina",
  "south dakota",
  "texas",
  "utah",
  "district of columbia",
  "wisconsin",
  "wyoming",
];

export const SINGLE_GAME_BANNED_STATES = ["kansas", "KS"];

export default function GeolocationContextProvider({ children }) {
  const [country, setCountry] = useState(null);
  const [state, setState] = useState(null);
  const [locationPermissionDenied, setLocationPermissionDenied] =
    useState(false);

  useEffect(() => {
    async function initLocationByIP() {
      try {
        const res = await getApi().get("api/auth/location");

        setCountry(res.data.country);
        // setCountry("BR");
        setState(res.data.state);
      } catch (e) {
        // Not good? Still deny? Probs not...
      }
    }

    if (!isMobile()) {
      initLocationByIP();
    }
  }, []);

  function p2eAccessAllowed(countryBlacklist, stateBlackList) {
    if (!country) return true;
    if (!country || country === NOT_FOUND) return true;
    if (countryBlacklist && countryBlacklist.includes(country)) return false;
    else return !(stateBlackList && stateBlackList.includes(state));
  }

  function showVerificationButton(locationState, locationCountry) {
    if (locationCountry && locationCountry !== "us") return false;
    if (!locationState) return true;
    return PAID_STATE_IP_WHITELIST.includes(locationState);
  }

  return (
    <GeolocationContext.Provider
      value={{
        state,
        country,
        setState,
        setCountry,
        locationPermissionDenied,
        setLocationPermissionDenied,
        p2eAccessAllowed,
        showVerificationButton,
      }}
    >
      {children}
    </GeolocationContext.Provider>
  );
}
