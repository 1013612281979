import { Modal } from "@mui/material";
import { useEffect, useState } from "react";
import { PropDecision } from "@phantasia/model-interfaces";
import { calculateBoostedValue } from "common/src/services/PicksService";
import { getPropKeyShortName } from "common/src/util/helpers";
import { PicksArrow } from "../picks/utils/PicksArrow";
import { PopulatedPickProp } from "common/src/services/dtos/ContestDtos";
import { Input } from "../atoms/Input";
import { SolidButton3 } from "../atoms/Button";

export function PicksIntroModal() {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("preview") !== "true") {
      setOpen(true);
    }
  }, []);

  function handleClose() {
    setOpen(false);
    localStorage.setItem("preview", "true");
  }

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        className="flex flex-col flex-center modal outline-none"
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div className="relative bg-base-0 overflow-auto outline-none flex-col  rounded-9 p-9 w-full max-w-31 animation-from-bottom">
          <div
            onClick={handleClose}
            className="flex flex-center bg-base-2 h-9 w-9 absolute t-7 r-7 rounded-full group hover:bg-danger-0 cursor-pointer transition"
          >
            <i className="bx bx-x text-neutral-1 group-hover:text-danger-1 flex" />
          </div>
          <span className={"font-sm weight-400 text-neutral-1"}>
            Welcome to Phantasia Picks!
          </span>
          <span className={"font-xl weight-600 text-neutral-2 mb-8"}>
            How do I play?
          </span>
          <div className={"rounded-7 p-7 bg-base-1 flex-col"}>
            <span className={"font-md weight-400 text-neutral-2"}>
              1 - Choose higher or lower on 2-4 player projections
            </span>
            <div className={"flex-row space-x-8"}>
              <FakeListItem
                // @ts-ignore
                pickProp={fakePicks[0] as PopulatedPickProp}
                currentDecision={PropDecision.UNDER}
              />
              <FakeListItem
                // @ts-ignore
                pickProp={fakePicks[1] as PopulatedPickProp}
                currentDecision={PropDecision.OVER}
              />
            </div>
          </div>
          <div className={"rounded-7 p-7 bg-base-1 flex-col mt-8"}>
            <span className={"font-md weight-400 text-neutral-2 mb-8"}>
              2 - Enter & win when all of your picks are correct
            </span>
            <div className={"flex-row space-x-5"}>
              <div className={"flex-col flex-1"}>
                <div className={"flex-row items-baseline mb-3"}>
                  <span className={"text-neutral-2 weight-500 font-md flex-1"}>
                    Entry
                  </span>
                </div>
                <Input
                  classes={"h-13 font-lg"}
                  value={100}
                  placeholder={"Entry"}
                  icon={<i className={"bx bx-dollar text-neutral-1"} />}
                  onChange={(e) => {}}
                />
              </div>

              <div className={"flex-col flex-1"}>
                <span
                  className={"text-neutral-2 weight-500 font-md flex-1 mb-3"}
                >
                  To Win (12x)
                </span>

                <div
                  className={`outline-primary-1 bg-primary-0 outline-2 rounded-5 flex-col justify-center px-6 max-h-13 h-13`}
                >
                  <span className={"text-neutral-2 weight-600 font-2xl"}>
                    $1200
                  </span>
                </div>
              </div>
            </div>
          </div>
          <SolidButton3
            onClick={handleClose}
            id={undefined}
            className={"mt-8"}
            textStyles={undefined}
            contentRight={undefined}
            contentLeft={undefined}
            disabled={undefined}
            submit={undefined}
            color={undefined}
          >
            Let's Get It!
          </SolidButton3>
        </div>
      </Modal>
    </>
  );
}

function FakeListItem({
  pickProp,
  currentDecision,
}: {
  pickProp: PopulatedPickProp;
  currentDecision: PropDecision;
}) {
  const { prop_key } = pickProp;
  const player = pickProp.player;

  return (
    <div
      className={`${
        currentDecision ? "bg-base-2" : "outline-1"
      } rounded-6 cursor-pointer hover-down transition mt-8`}
    >
      <div className={"flex-row space-x-3 pt-4 px-5 pb-3 w-full"}>
        <div className={"flex-1"} />
        <div className={"flex flex-center relative"}>
          <div
            className={`mt--7 ring-4 ${
              pickProp.boost ? "bg-gradient-fire-sale" : "bg-base-4"
            } ${
              currentDecision ? "ring-base-2" : "ring-base-1"
            }  flex flex-center transition rounded-full h-11 w-11 overflow-hidden relative`}
          >
            {pickProp.boost ? (
              <i className="bx bxs-hot white absolute opacity-50 h-11 w-11 t-0 r-0 l-0 b-0" />
            ) : null}
            <img
              src={player.photo}
              alt={"player"}
              className={"h-11 contain relative"}
            />
          </div>
        </div>
        <div className={"flex-1 flex-row justify-end items-center"}>
          {pickProp.boost ? (
            <div className={"h-9 text-gradient-fire-sale mr-3"}>
              <div className={"flex-row space-x-3 h-9 flex-center"}>
                <i className="bx bxs-hot" />
                <span className={"font-xs weight-400"}>Fire Sale</span>
              </div>
            </div>
          ) : null}
          <div
            className={
              "h-9 w-9 cursor-pointer hover:bg-base-3 transition rounded-full flex flex-center"
            }
          >
            <i className="bx bx-line-chart text-neutral-2" />
          </div>
        </div>
      </div>
      <div className={"flex-row space-x-3 w-full overflow-hidden"}>
        <DecisionButton
          text={"Higher"}
          active={currentDecision === PropDecision.OVER}
        />
        <div className={"flex-1 truncate flex-col px-3"}>
          <span
            className={"font-sm weight-400 text-center text-neutral-2 truncate"}
          >
            {player.name}
          </span>
          <span
            className={"font-xs weight-300 text-center text-neutral-1"}
          >{`${player.position}  •  ${player.team}`}</span>
          <span
            className={`font-xl weight-500 text-center text-neutral-2 mt-3 line-height-md ${
              pickProp.boost ? "text-gradient-fire-sale" : "text-neutral-2"
            }`}
          >
            {calculateBoostedValue(pickProp)}
          </span>
          <span className={"font-xs weight-300 text-center text-neutral-1"}>
            {getPropKeyShortName(prop_key)}
          </span>
        </div>
        <DecisionButton
          text={"Lower"}
          rotate
          active={currentDecision === PropDecision.UNDER}
        />
      </div>
    </div>
  );
}

function DecisionButton({
  text,
  rotate,
  active,
}: {
  text: string;

  rotate?: boolean;
  active: boolean;
}) {
  return (
    <div
      className={`${active ? "bg-base-3" : ""} ${
        rotate ? "rounded-b-r-6" : "rounded-b-l-6"
      } min-w-14 w-14 h-14 flex-col hover:outline-1 flex-center hover:bg-base-1 transition cursor-pointer`}
    >
      <PicksArrow
        className={`w-9 h-9 ${active ? "fill-primary" : "fill-base-3"} ${
          rotate ? "bx-rotate-180" : ""
        }`}
      />

      <span
        className={`font-sm text-neutral-2 weight-400 ${
          active ? "" : "opacity-75"
        }`}
      >
        {text}
      </span>
    </div>
  );
}

const fakePicks = [
  {
    _id: "64547f44d2b1ac18fb0b9de1",
    game: {
      _id: "644c5e0188f9f8356bb11083",
      game_key: "20019500",
      league: "NBA",
      __v: 0,
      away_team: "BOS",
      away_team_ID: {
        _id: "63dc935b608513d1e4edf44f",
        key: "BOS",
        league: "NBA",
        __v: 0,
        current_players: [],
        logo: "https://upload.wikimedia.org/wikipedia/en/8/8f/Boston_Celtics.svg",
        name: "Celtics",
        team_ID: 9,
      },
      canceled: false,
      date: "2023-05-05T23:30:00.000Z",
      game_ID: "19500",
      home_team: "PHI",
      home_team_ID: {
        _id: "63dc935a608513d1e4edf43d",
        key: "PHI",
        league: "NBA",
        __v: 0,
        current_players: [],
        logo: "https://upload.wikimedia.org/wikipedia/en/0/0e/Philadelphia_76ers_logo.svg",
        name: "76ers",
        team_ID: 7,
      },
      season: 2023,
      season_type: 3,
      status: "Scheduled",
      week: 0,
    },
    market_ID: 1255378,
    player: {
      _id: "615df5e618e8ec278a2e5f1a",
      player_ID: 20001829,
      __v: 0,
      active: true,
      current_status: "Active",
      dob: "1998-03-03T00:00:00.000Z",
      experience: 5,
      experience_string: "5",
      height: "80",
      injury_status: "",
      name: "Jayson Tatum",
      number: 0,
      photo:
        "https://s3-us-west-2.amazonaws.com/static.fantasydata.com/headshots/nba/low-res/20001829.png",
      position: "SF",
      position_category: "F",
      salary: 28103500,
      short_name: "Jayson Tatum",
      status: "Active",
      team: "BOS",
      team_ID: 9,
      team_ref: "63dc935b608513d1e4edf44f",
      weight: 210,
    },
    prop_key: "points_rebounds",
    __v: 0,
    is_available: true,
    league: "NBA",
    value: 38.5,
  },
  {
    _id: "64547f45d2b1ac18fb0ba083",
    game: {
      _id: "644c5e0188f9f8356bb11080",
      game_key: "20019486",
      league: "NBA",
      __v: 0,
      away_team: "DEN",
      away_team_ID: {
        _id: "63dc935b608513d1e4edf4bb",
        key: "DEN",
        league: "NBA",
        __v: 0,
        current_players: [],
        logo: "https://upload.wikimedia.org/wikipedia/en/7/76/Denver_Nuggets.svg",
        name: "Nuggets",
        team_ID: 20,
      },
      canceled: false,
      date: "2023-05-06T02:00:00.000Z",
      game_ID: "19486",
      home_team: "PHO",
      home_team_ID: {
        _id: "63dc935b608513d1e4edf55a",
        key: "PHO",
        league: "NBA",
        __v: 0,
        current_players: [],
        logo: "https://upload.wikimedia.org/wikipedia/en/d/dc/Phoenix_Suns_logo.svg",
        name: "Suns",
        team_ID: 29,
      },
      season: 2023,
      season_type: 3,
      status: "Scheduled",
      week: 0,
    },
    market_ID: 1254550,
    player: {
      _id: "615df5e618e8ec278a2e5f43",
      player_ID: 20000884,
      __v: 0,
      active: true,
      current_status: "Active",
      dob: "1988-09-29T00:00:00.000Z",
      experience: 14,
      experience_string: "14",
      height: "82",
      injury_status: "",
      name: "Kevin Durant",
      number: 35,
      photo:
        "https://s3-us-west-2.amazonaws.com/static.fantasydata.com/headshots/nba/low-res/20000884.png",
      position: "PF",
      position_category: "F",
      salary: 42018900,
      short_name: "Kevin Durant",
      status: "Active",
      team: "PHO",
      team_ID: 29,
      team_ref: "63dc935b608513d1e4edf55a",
      weight: 240,
    },
    prop_key: "points_assists_rebounds",
    __v: 0,
    is_available: true,
    league: "NBA",
    value: 43.5,
  },
];
