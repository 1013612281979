export function getFirebaseLink(link: string) {
  if (!link) return "";
  return `https://phantasia.page.link/?apn=com.phantasia&ibi=app.phantasia&link=${link}`;
}

export async function copyLink(link: string, excludeDeepLink?: boolean) {
  if (!link) return;

  let firebaseLink = "";

  if (excludeDeepLink) {
    firebaseLink = link;
  } else {
    firebaseLink = `https://phantasia.page.link/?apn=com.phantasia&ibi=app.phantasia&link=${link}`;
  }

  if ("clipboard" in navigator) {
    await navigator.clipboard.writeText(firebaseLink);
  } else {
    document.execCommand("copy", true, firebaseLink);
  }
}
