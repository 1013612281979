import pattern106 from "../../assets/patterns/pattern_106.png";
import pattern44 from "../../assets/patterns/pattern_44.svg";

import { GradientButton3, LinkButton3 } from "../../components/atoms/Button";
import { getMobileOperatingSystem } from "../../components/modals/MobileAdModal";
import qrCode from "../../assets/qr-code.png";
import { useEffect, useState } from "react";
import PlayerAvatar from "../../components/molecules/PlayerAvatar";
import { getPositionBackgroundColor } from "common/src/util/ColorTranslater";
import { DfsRosterSlot, PropDecision } from "@phantasia/model-interfaces";
import moment from "moment/moment";
import PlayerTableCard from "../../components/layout/PlayerTableCard";
import { IconHeader1 } from "../../components/atoms/PageHeader";
import ProgressBar from "../../components/atoms/ProgressBar";
import ScLogo from "../../assets/salary-cap-logo.png";
import HlLogo from "../../assets/higher-lower.png";

export function LandingSection({ isMobile }) {
  if (isMobile) {
    return (
      <div className="w-full relative">
        <div className="absolute opacity-50 r-0 l-0 t--21 z-6 overflow-hidden flex-col items-center">
          <img
            src={pattern44}
            className="opacity-8 h-full cover min-h-34 z-8"
            alt="pattern44"
          />
        </div>
        <div className="relative z-10 pt-17 px-6 flex-col items-center">
          <span className="weight-300 letter-spacing-md text-neutral-2 font-md flex line-height-sm">
            PHANTASIA
          </span>

          <h1 className="weight-400 text-gradient-light text-center mt-5 mb-0">
            The Future
          </h1>
          <h1 className="weight-400 text-neutral-2 text-center mt-0 mb-0">
            of Fantasy Sports
          </h1>

          <span className="weight-300 letter-spacing-sm text-neutral-2 font-sm text-center mb-8 mt-6">
            The peer-to-peer, lightning-fast fantasy platform
          </span>

          <LinkButton3
            link={getMobileOperatingSystem()}
            color="primary"
            className={"w-full mt-5 rounded-15 mb-8"}
          >
            Download the App
          </LinkButton3>
          <GameAnimation isMobile />
        </div>
      </div>
    );
  }

  return (
    <div className={`w-screen h-screen relative `}>
      <div
        className="bg-primary-1 rounded-full mobile-hide absolute"
        style={{
          height: "24vw",
          width: "24vw",
          bottom: "30%",
          marginLeft: "-7vw",
        }}
      />
      <div
        className="bg-primary-1 rounded-full mobile-hide absolute"
        style={{
          height: "20vw",
          width: "20vw",
          bottom: "10%",
          marginLeft: "-8vw",
        }}
      />
      <div
        className="bg-primary-1 rounded-full mobile-hide absolute"
        style={{
          height: "30vw",
          width: "30vw",
          top: "30%",
          right: 0,
          marginRight: "12vw",
        }}
      />

      <div
        className="blur-only absolute w-full h-full flex-col flex-center"
        style={{ backgroundColor: "rgba(var(--base-rgb), .9)" }}
      >
        <div className={"relative h-screen w-screen"}>
          <div
            className={`overflow-hidden relative w-5/6 max-w-36 h-full grid grid-cols-2 gap-13 m-auto  animation-reveal`}
          >
            <div className={`pt-17 relative z-10`}>
              <div className="flex-col h-full pb-11">
                <h3
                  style={{ fontSize: "calc(12px + 1.1vw)" }}
                  className="weight-500 text-neutral-2 flex-row no-wrap  mt-11 mb-0"
                >
                  The{" "}
                  <span className="text-gradient-4 text-gradient-phantasia mx-5">
                    Future
                  </span>{" "}
                  of Fantasy Sports
                </h3>

                <span className="weight-300 letter-spacing-sm text-neutral-2 font-lg mt-6 pr-12 mb-6">
                  The peer-to-peer, lightning-fast fantasy sports platform. Join
                  daily and season-long contests powered by the blockchain. Cash
                  out in seconds when you win.
                </span>

                <div className={"flex-row space-x-8 mt-4"}>
                  <a
                    style={{ background: "#09090b" }}
                    href={
                      "https://apps.apple.com/us/app/phantasia-sports/id1608431296"
                    }
                    className={
                      "w-18 hover-down outline-1 outline-neutral-2 decoration-none rounded-full p-6 flex flex-center"
                    }
                  >
                    <img
                      alt={"googleplay"}
                      src={
                        "https://uploads-ssl.webflow.com/63e5b034b55a6e011cf48797/640d26deaa9ee1409224afe2_Vectors-Wrapper.svg"
                      }
                      className={"h-7"}
                    />
                  </a>
                  <a
                    style={{ background: "#09090b" }}
                    href={
                      "https://play.google.com/store/apps/details?id=com.phantasia&hl=en_US&gl=US"
                    }
                    className={
                      "w-18 hover-down outline-1 outline-neutral-2 decoration-none rounded-full p-6 flex flex-center"
                    }
                  >
                    <img
                      alt={"googleplay"}
                      src={
                        "https://uploads-ssl.webflow.com/63e5b034b55a6e011cf48797/640d26dfadfe8851017576dc_Vectors-Wrapper.svg"
                      }
                      className={"h-7"}
                    />
                  </a>
                </div>
                <div className={"flex-grow"} />
                <div className="flex-row space-x-8">
                  <div className="flex-row space-x-8 items-center">
                    {!isMobile && (
                      <img src={qrCode} className="w-15" alt="pattern42" />
                    )}
                  </div>
                  <h4
                    className={`letter-spacing-lg text-neutral-2 weight-300 flex-col`}
                  >
                    DOWNLOAD THE{" "}
                    <b className="text-gradient-light weight-400">5-STAR</b>{" "}
                    RATED APP
                  </h4>
                </div>
              </div>
            </div>

            <div className="relative z-9  flex flex-center">
              <GameAnimation />
            </div>
          </div>
          <img
            src={pattern106}
            className="opacity-8 absolute b-0 r-0 l-0 z-4"
            alt="pattern106"
          />
        </div>
      </div>
    </div>
  );
}

export function PhantasiaTestimonials() {
  const testimonials = [
    {
      user: "Ibeguessing",
      quote: "This app actually looks clean",
    },
    {
      user: "BoyfriendBenny",
      quote: "I love the app super clean",
    },
    {
      user: "josema__1984",
      quote:
        "The best daily fantasy application, more than 1000 contests played and I enjoy playing every day",
    },
    {
      user: "elibasket10",
      quote:
        "Yea I love this App already, I was able to withdraw my funds and instantly it was in my Bank!",
    },
    {
      user: "NoHustleSports",
      quote:
        "One of the best crypto interactive apps I've used. Everything is super smooth. Love the idea of transforming fantasy sports with cutting edge tech.",
    },
    {
      user: "elibasket10",
      quote:
        "Don’t like to wait for your payout? Look no further – when you win a DFS contest on Phantasia you can instantly request a payout & it arrives in seconds!",
    },

    {
      user: "Gino_BetOpenly",
      quote:
        "This super clean app has inspired us. I love it! Their UI/UX is top notch!!",
    },
    {
      user: "Zachary Marron",
      quote:
        "Love this app, super fun, easy to navigate, and a lot easier to win being less congested than other apps",
    },
    {
      user: "CMGAuthentic",
      quote:
        "Huge S/O to Phantasia for giving me some free plays on their app!! Thank you for making my day!",
    },
    {
      user: "Jays.dtx",
      quote:
        "Hella fast customer service it’s crazy. payouts are always on point.",
    },
    {
      user: "Truth2x",
      quote:
        "Amazing!!! I really like the style of the app! Let’s hope I can cash tonight 😀",
    },
    {
      user: "Michael Trujillo",
      quote: "Best dev best support!!! I've had a great nfl experience",
    },
    {
      user: "NC_DRIP",
      quote:
        "Gotta shake back on the Phantasia app today! But this app too fun frfr",
    },
    {
      user: "7afa Ri7",
      quote: "Awesome app! Best Web3 betting system!",
    },
    {
      user: "Joseph Rockins",
      quote:
        "Love all the opportunities in the app. Awesome for sports. Keep it up",
    },
    {
      user: "ihatejuliusrandle",
      quote:
        "Withdraw hit! Just deposited some more myself. App is super cool.",
    },
  ];
  return (
    <div className="slider pb-11 w-screen flex-row">
      <div className="slide-track flex-row ">
        {testimonials.map((item, index) => (
          <TestimonialCard name={item.user} text={item.quote} />
        ))}
      </div>
    </div>
  );
}

function TestimonialCard({ text, name }) {
  return (
    <div className={"pr-9"} style={{ minWidth: "6.25%", width: "6.25%" }}>
      <div
        className={`min-h-20 h-20 shadow-3 animation-from-bottom glass-2 relative z-10 rounded-8 p-8 flex-col outline-1`}
      >
        <span className="font-md text-neutral-2 weight-400 mb-6 flex-1">
          "{text}"
        </span>
        <div className="flex-row space-x-5 items-center">
          <img
            src={
              "https://images.unsplash.com/photo-1511367461989-f85a21fda167?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2231&q=80"
            }
            className="w-9 h-9 rounded-full cover"
            alt="pattern42"
          />
          <div className="flex-col flex-1">
            <span className="flex no-wrap font-md weight-400 text-neutral-2">
              {name}
            </span>
            <span className="flex no-wrap font-sm weight-300 text-neutral-1">
              verified review
            </span>
          </div>
          <i className="bx bxs-quote-alt-left text-neutral-0 bx-md" />
        </div>
      </div>
    </div>
  );
}

function GameAnimation({ isMobile }) {
  const [active, setActive] = useState(0);

  if (active === 0)
    return <HlAnimation isMobile={isMobile} setActive={setActive} />;
  return <DfsAnimation isMobile={isMobile} setActive={setActive} />;
}

function DfsAnimation({ setActive, isMobile }) {
  const [step, setStep] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      setStep(1);
    }, 2000);
    setTimeout(() => {
      setStep(2);
    }, 3000);
    setTimeout(() => {
      setStep(3);
    }, 4000);
    setTimeout(() => {
      setStep(4);
    }, 5500);
    setTimeout(() => {
      setStep(5);
    }, 7000);
    setTimeout(() => {
      setActive(0);
    }, 9000);
  }, [setActive]);

  return (
    <div
      className={`${
        isMobile ? "h-29 max-h-29" : ""
      } flex-col w-full space-y-6 items-center animation-from-bottom`}
    >
      <div className={"flex-col space-y-6 w-full items-center"}>
        <img
          src={ScLogo}
          className={"h-8 contain rounded-full bg-base-1 mb-5"}
          alt={"sc"}
        />
        {isMobile ? null : (
          <div className="w-full flex-col outline-1 p-8 rounded-8 w-full max-w-25">
            <div className="flex-center flex-row space-x-2 mb-4">
              <IconHeader1 className={"flex-1"} icon={"bx-wallet"}>
                Budget
              </IconHeader1>

              <span className="font-md text-neutral-2 weight-500">
                $
                {step > 2 ? 20000 : step > 1 ? 30000 : step > 0 ? 40000 : 50000}
              </span>
              <span className="font-sm text-neutral-1 weight-300">/</span>
              <span className="font-sm text-neutral-1 weight-300">$50000</span>
            </div>

            <ProgressBar
              color="primary"
              value={
                ((step > 2
                  ? 20000
                  : step > 1
                  ? 30000
                  : step > 0
                  ? 40000
                  : 50000) /
                  50000) *
                100
              }
            />
          </div>
        )}
        <div
          className={`flex-row items-center py-5 rounded-5 ${
            step > 0
              ? "outline-2 outline-neutral-2 animation-press"
              : "outline-1"
          } relative w-full transition-long`}
        >
          <PlayerTableCard
            player={FakePlayer2}
            onSelectPlayer={true}
            openPlayerModal={() => {}}
            budget={50000}
            index={undefined}
            playerDisabled={false}
            onRemovePlayer={() => {}}
            rosterIndex={undefined}
          />
          <div className="flex flex-center h-10 w-10 absolute r-5 absolute-middle-horizontal">
            <div
              className={`min-h-9 min-w-9 flex flex-center transition-long ${
                step > 0 ? "bg-danger-1" : "bg-success-1"
              } rounded-full`}
            >
              <i
                className={`bx  bx-xs white ${
                  step > 0 ? "bx-minus" : "bx-plus"
                }`}
              />
            </div>
          </div>
        </div>
        <div
          className={`flex-row items-center py-5 rounded-5 ${
            step > 1
              ? "outline-2 outline-neutral-2 animation-press"
              : "outline-1"
          } relative w-full transition-long`}
        >
          <PlayerTableCard
            player={FakePlayer3}
            onSelectPlayer={true}
            openPlayerModal={() => {}}
            budget={50000}
            index={undefined}
            playerDisabled={false}
            onRemovePlayer={() => {}}
            rosterIndex={undefined}
          />
          <div className="flex flex-center h-10 w-10 absolute r-5 absolute-middle-horizontal">
            <div
              className={`min-h-9 min-w-9 flex flex-center transition-long ${
                step > 1 ? "bg-danger-1" : "bg-success-1"
              } rounded-full`}
            >
              <i
                className={`bx  bx-xs white ${
                  step > 1 ? "bx-minus" : "bx-plus"
                }`}
              />
            </div>
          </div>
        </div>
        <div
          className={`flex-row items-center py-5 rounded-5 ${
            step > 2
              ? "outline-2 outline-neutral-2 animation-press"
              : "outline-1"
          } relative w-full transition-long`}
        >
          <PlayerTableCard
            player={FakePlayer4}
            onSelectPlayer={true}
            openPlayerModal={() => {}}
            budget={50000}
            index={undefined}
            playerDisabled={false}
            onRemovePlayer={() => {}}
            rosterIndex={undefined}
          />
          <div className="flex flex-center h-10 w-10 absolute r-5 absolute-middle-horizontal">
            <div
              className={`min-h-9 min-w-9 flex flex-center transition-long ${
                step > 2 ? "bg-danger-1" : "bg-success-1"
              } rounded-full`}
            >
              <i
                className={`bx  bx-xs white ${
                  step > 2 ? "bx-minus" : "bx-plus"
                }`}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={"relative overflow-visible"}>
        <GradientButton3
          className={`${
            step > 3 ? "animation-ping" : null
          } rounded-full absolute t-0 r-0 l-0 b-0 py-8 px-14`}
          color={"primary"}
          onClick={() => {}}
        >
          Finalize Picks
        </GradientButton3>
        <GradientButton3
          className={`rounded-full py-8 px-14`}
          color={"primary"}
          onClick={() => {}}
        >
          Finalize Picks
        </GradientButton3>
        {step > 4 ? (
          <GradientButton3
            contentLeft={<i className={"bx bx-check-double white"} />}
            className={`rounded-full animation-from-bottom absolute t-0 r-0 l-0 b-0 py-8 px-14`}
            color={"success"}
            onClick={() => {}}
          >
            Picks submitted!
          </GradientButton3>
        ) : null}
      </div>
    </div>
  );
}

function HlAnimation({ setActive, isMobile }) {
  const [step, setStep] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      setStep(1);
    }, 2000);
    setTimeout(() => {
      setStep(2);
    }, 2700);
    setTimeout(() => {
      setStep(3);
    }, 3400);
    setTimeout(() => {
      setStep(4);
    }, 4100);
    setTimeout(() => {
      setStep(5);
    }, 6000);
    setTimeout(() => {
      setStep(6);
    }, 7000);
    setTimeout(() => {
      setActive(1);
    }, 9000);
  }, [setActive]);

  return (
    <div
      className={`flex-col w-full items-center animation-from-bottom ${
        isMobile ? "h-29 max-h-29" : ""
      }`}
    >
      <img
        src={HlLogo}
        className={"h-8 rounded-full bg-base-1  mb-5"}
        alt={"sc"}
      />
      {isMobile ? null : (
        <div className={"flex-row w-full"}>
          <div
            className={`flex-1 ${step > 0 ? "animation-press" : null}`}
          ></div>
          <div className={`flex-1 ${step > 1 ? "animation-press" : null}`}>
            <FakePropPlayerCard
              fantasyPlayer={FakePlayer2}
              status={step > 1 ? PropDecision.UNDER : null}
            />
          </div>
        </div>
      )}
      <div className={"flex-row  w-full"}>
        <div className={`flex-1 ${step > 2 ? "animation-press" : null}`}>
          <FakePropPlayerCard
            fantasyPlayer={FakePlayer3}
            status={step > 2 ? PropDecision.UNDER : null}
          />
        </div>
        <div className={`flex-1 ${step > 3 ? "animation-press" : null}`}>
          <FakePropPlayerCard
            fantasyPlayer={FakePlayer4}
            status={step > 3 ? PropDecision.OVER : null}
          />
        </div>
      </div>
      <div className={"relative overflow-visible mt-6"}>
        <GradientButton3
          className={`${
            step > 4 ? "animation-ping" : null
          } rounded-full absolute t-0 r-0 l-0 b-0 py-8 px-14`}
          color={"primary"}
          onClick={() => {}}
        >
          Finalize Picks
        </GradientButton3>
        <GradientButton3
          className={`rounded-full py-8 px-14`}
          color={"primary"}
          onClick={() => {}}
        >
          Finalize Picks
        </GradientButton3>
        {step > 5 ? (
          <GradientButton3
            contentLeft={<i className={"bx bx-check-double white"} />}
            className={`rounded-full animation-from-bottom absolute t-0 r-0 l-0 b-0 py-8 px-14`}
            color={"success"}
            onClick={() => {}}
          >
            Picks submitted!
          </GradientButton3>
        ) : null}
      </div>
    </div>
  );
}

export function FakePropPlayerCard({ fantasyPlayer, status }) {
  function RemoveButton() {
    return (
      <div
        className={
          "hover:bg-base-3 flex flex-center absolute t-4 r-4 group transition-long h-9 w-9 rounded-5"
        }
      >
        <i
          className={"bx bx-trash text-neutral-0 group-hover:text-neutral-2"}
        />
      </div>
    );
  }

  return (
    <div
      style={{ transform: "scale(.85)" }}
      className={`${getCardStyleByDecision(
        status
      )} animation-fade relative flex-col items-center w-full pt-7 p-4 transition-long hover-up cursor-pointer  rounded-7 relative max-w-full`}
    >
      <RemoveButton />

      <PlayerAvatar player={fantasyPlayer} size={13} />
      <span className="weight-400 no-wrap font-md text-neutral-2 mt-4">
        {fantasyPlayer.player.name}
      </span>
      <div className="flex-row items-center">
        <div
          className={`mr-4 w-3 h-3 rounded-full`}
          style={getPositionBackgroundColor(
            fantasyPlayer.player.position || DfsRosterSlot.UTIL
          )}
        />
        <span className="font-md text-neutral-1 weight-300">
          {fantasyPlayer.player.position}
        </span>
        <span className="font-xs text-neutral-1 weight-300 mx-4">|</span>
        <span className="weight-300 font-md text-neutral-1">
          {fantasyPlayer.player.team}
        </span>
      </div>

      {formatPlayerGame(fantasyPlayer.game, fantasyPlayer)}
    </div>
  );
}

function formatPlayerGame(game, fantasyPlayer) {
  const opponent = [game.away_team_ID, game.home_team_ID].find(
    (team) =>
      team?._id?.toString() !== fantasyPlayer.player.team_ref?.toString()
  );
  const isHomeTeam =
    game.home_team_ID?._id?.toString() === fantasyPlayer.player.team_ref;

  if (
    fantasyPlayer.player.team_ref !== game.home_team_ID?._id?.toString() &&
    fantasyPlayer.player.team_ref !== game.away_team_ID?._id?.toString()
  ) {
    return (
      <div className={"flex-col space-y-1 items-center px-3 my-5"}>
        <span className={"font-sm weight-300 text-danger-2"}>
          Player invalid!
        </span>
        <span className={"font-sm weight-300 text-danger-1 text-center"}>
          Player traded & invalid
        </span>
      </div>
    );
  }
  return (
    <div className={"flex-col space-y-1 items-center my-5"}>
      <span className={"font-sm weight-300 text-neutral-2"}>
        {`${isHomeTeam ? "vs." : "@"} ${opponent?.name}`}
      </span>
      <span className={"font-sm weight-300 text-neutral-1"}>
        {moment(game.date).format("ddd MMM DD h:mm a")}
      </span>
    </div>
  );
}

function getCardStyleByDecision(decision) {
  if (decision === PropDecision.OVER) return "outline-2 outline-success-1";
  if (decision === PropDecision.UNDER) return "outline-2 outline-danger-1";
  return "outline-1";
}

export const FakePlayer2 = {
  playerProp: {
    prop_key: "yards",
    value: 291.5,
  },
  player: {
    _id: "615e0f3018e8ec278a3d55b8",
    player_ID: 20000619,
    __v: 0,
    active: true,
    current_status: "Active",
    dob: "1990-07-15T00:00:00.000Z",
    experience: 9,
    experience_string: "9",
    height: "74",
    injury_status: "",
    name: "Jalen Hurts",
    number: 0,
    photo:
      "https://s3-us-west-2.amazonaws.com/static.fantasydata.com/headshots/nfl/low-res/21831.png",
    position: "QB",
    position_category: "G",
    salary: 39344900,
    short_name: "Jalen Hurts",
    status: "Active",
    team: "PHI",
    team_ID: 19,
    team_ref: "615e0f2f18e8ec278a3d4f98",
    weight: 195,
  },
  propId: "640a7a24d9048daa125f0891",
  game: {
    _id: "64052d011b235f73482d874a",
    game_key: "20019186",
    league: "NFL",
    __v: 0,
    away_team: "PHI",
    away_team_ID: {
      _id: "615e0f2f18e8ec278a3d4f98",
      key: "PHI",
      league: "NFL",
      __v: 0,
      current_players: [],
      logo: "https://upload.wikimedia.org/wikipedia/en/2/21/Portland_Trail_Blazers_logo.svg",
      name: "Trail Blazers",
      salary: 0,
      stadium: {
        capacity: 0,
        lat: 0,
        lng: 0,
      },
      team_ID: 19,
    },
    canceled: false,
    date: "2023-03-12T23:00:00.000Z",
    game_ID: "19186",
    home_team: "NO",
    home_team_ID: {
      _id: "615e0f2f18e8ec278a3d4fc8",
      key: "NO",
      league: "NBA",
      __v: 0,
      current_players: [],
      logo: "https://upload.wikimedia.org/wikipedia/en/0/0d/New_Orleans_Pelicans_logo.svg",
      name: "Saints",
      salary: 0,
      stadium: {
        capacity: 0,
        lat: 0,
        lng: 0,
      },
      team_ID: 23,
    },
    season: 2023,
    season_type: 1,
    status: "Scheduled",
    week: 0,
  },
  projection: {
    _id: "640a7a20ffe9f958337de441",
    game: "64052d011b235f73482d874a",
    player: {
      _id: "615e0f3018e8ec278a3d55b8",
      player_ID: 20000619,
      __v: 0,
      active: true,
      current_status: "Active",
      dob: "1990-07-15T00:00:00.000Z",
      experience: 9,
      experience_string: "9",
      height: "74",
      injury_status: "Probable",
      name: "Damian Lillard",
      number: 0,
      photo:
        "https://s3-us-west-2.amazonaws.com/static.fantasydata.com/headshots/nba/low-res/20000619.png",
      position: "PG",
      position_category: "G",
      salary: 39344900,
      short_name: "Damian Lillard",
      status: "Active",
      team: "POR",
      team_ID: 19,
      team_ref: "615e0f2f18e8ec278a3d4f98",
      weight: 195,
    },
    __v: 0,
    fantasy_points: 31.54,
    opponent: "NO",
    opponent_rank: 5,
    position: "PG",
    projected_stats: {
      field_goals_made: 9.7,
      field_goals_attempted: 20.91,
      field_goals_percentage: 47.6,
      effective_field_goals_percentage: 57.1,
      two_pointers_made: 5.4,
      two_pointers_attempted: 9.37,
      two_pointers_percentage: 55.6,
      three_pointers_made: 4.3,
      three_pointers_attempted: 11.54,
      three_pointers_percentage: 33.3,
      free_throws_made: 8.57,
      free_throws_attempted: 9.32,
      free_throws_percentage: 100,
      offensive_rebounds: 0.73,
      defensive_rebounds: 3.96,
      rebounds: 4.69,
      assists: 7.32,
      steals: 1.12,
      blocked_shots: 0.3,
      turnovers: 3.03,
      personal_fouls: 1.96,
      points: 32.27,
      true_shooting_attempts: 25.0108,
      true_shooting_percentage: 64,
    },
    salary: 10500,
  },
};

export const FakePlayer3 = {
  playerProp: {
    prop_key: "yards",
    value: 111.5,
  },
  player: {
    _id: "615e0f3018e8ec278a3d55b8",
    player_ID: 20000619,
    __v: 0,
    active: true,
    current_status: "Active",
    dob: "1990-07-15T00:00:00.000Z",
    experience: 9,
    experience_string: "9",
    height: "74",
    injury_status: "",
    name: "Christian McCaffrey",
    number: 0,
    photo:
      "https://s3-us-west-2.amazonaws.com/static.fantasydata.com/headshots/nfl/low-res/18877.png",
    position: "RB",
    position_category: "G",
    salary: 39344900,
    short_name: "Christian McCaffrey",
    status: "Active",
    team: "SF",
    team_ID: 19,
    team_ref: "615e0f2f18e8ec278a3d4f98",
    weight: 195,
  },
  propId: "640a7a24d9048daa125f0891",
  game: {
    _id: "64052d011b235f73482d874a",
    game_key: "20019186",
    league: "NFL",
    __v: 0,
    away_team: "PHI",
    away_team_ID: {
      _id: "615e0f2f18e8ec278a3d4f98",
      key: "PHI",
      league: "NFL",
      __v: 0,
      current_players: [],
      logo: "https://upload.wikimedia.org/wikipedia/en/2/21/Portland_Trail_Blazers_logo.svg",
      name: "Trail Blazers",
      salary: 0,
      stadium: {
        capacity: 0,
        lat: 0,
        lng: 0,
      },
      team_ID: 19,
    },
    canceled: false,
    date: "2023-03-12T23:00:00.000Z",
    game_ID: "19186",
    home_team: "GB",
    home_team_ID: {
      _id: "615e0f2f18e8ec278a3d4fc8",
      key: "GB",
      league: "NBA",
      __v: 0,
      current_players: [],
      logo: "https://upload.wikimedia.org/wikipedia/en/0/0d/New_Orleans_Pelicans_logo.svg",
      name: "Panthers",
      salary: 0,
      stadium: {
        capacity: 0,
        lat: 0,
        lng: 0,
      },
      team_ID: 23,
    },
    season: 2023,
    season_type: 1,
    status: "Scheduled",
    week: 0,
  },
  projection: {
    _id: "640a7a20ffe9f958337de441",
    game: "64052d011b235f73482d874a",
    player: {
      _id: "615e0f3018e8ec278a3d55b8",
      player_ID: 20000619,
      __v: 0,
      active: true,
      current_status: "Active",
      dob: "1990-07-15T00:00:00.000Z",
      experience: 9,
      experience_string: "9",
      height: "74",
      injury_status: "Probable",
      name: "Damian Lillard",
      number: 0,
      photo:
        "https://s3-us-west-2.amazonaws.com/static.fantasydata.com/headshots/nba/low-res/20000619.png",
      position: "PG",
      position_category: "G",
      salary: 39344900,
      short_name: "Damian Lillard",
      status: "Active",
      team: "POR",
      team_ID: 19,
      team_ref: "615e0f2f18e8ec278a3d4f98",
      weight: 195,
    },
    __v: 0,
    fantasy_points: 26.21,
    opponent: "GB",
    opponent_rank: 1,
    position: "RB",
    projected_stats: {
      field_goals_made: 9.7,
      field_goals_attempted: 20.91,
      field_goals_percentage: 47.6,
      effective_field_goals_percentage: 57.1,
      two_pointers_made: 5.4,
      two_pointers_attempted: 9.37,
      two_pointers_percentage: 55.6,
      three_pointers_made: 4.3,
      three_pointers_attempted: 11.54,
      three_pointers_percentage: 33.3,
      free_throws_made: 8.57,
      free_throws_attempted: 9.32,
      free_throws_percentage: 100,
      offensive_rebounds: 0.73,
      defensive_rebounds: 3.96,
      rebounds: 4.69,
      assists: 7.32,
      steals: 1.12,
      blocked_shots: 0.3,
      turnovers: 3.03,
      personal_fouls: 1.96,
      points: 32.27,
      true_shooting_attempts: 25.0108,
      true_shooting_percentage: 64,
    },
    salary: 8500,
  },
};

export const FakePlayer4 = {
  playerProp: {
    prop_key: "yards",
    value: 94.5,
  },
  player: {
    _id: "615e0f3018e8ec278a3d55b8",
    player_ID: 20000619,
    __v: 0,
    active: true,
    current_status: "Active",
    dob: "1990-07-15T00:00:00.000Z",
    experience: 9,
    experience_string: "9",
    height: "74",
    injury_status: "",
    name: "Justin Jefferson",
    number: 0,
    photo:
      "https://s3-us-west-2.amazonaws.com/static.fantasydata.com/headshots/nfl/low-res/21685.png",
    position: "WR",
    position_category: "G",
    salary: 39344900,
    short_name: "Justin Jefferson",
    status: "Active",
    team: "MIN",
    team_ID: 19,
    team_ref: "615e0f2f18e8ec278a3d4f98",
    weight: 195,
  },
  propId: "640a7a24d9048daa125f0891",
  game: {
    _id: "64052d011b235f73482d874a",
    game_key: "20019186",
    league: "NFL",
    __v: 0,
    away_team: "PHI",
    away_team_ID: {
      _id: "615e0f2f18e8ec278a3d4f98",
      key: "MIN",
      league: "NFL",
      __v: 0,
      current_players: [],
      logo: "https://upload.wikimedia.org/wikipedia/en/2/21/Portland_Trail_Blazers_logo.svg",
      name: "Trail Blazers",
      salary: 0,
      stadium: {
        capacity: 0,
        lat: 0,
        lng: 0,
      },
      team_ID: 19,
    },
    canceled: false,
    date: "2023-03-12T23:00:00.000Z",
    game_ID: "19186",
    home_team: "CHI",
    home_team_ID: {
      _id: "615e0f2f18e8ec278a3d4fc8",
      key: "CHI",
      league: "NBA",
      __v: 0,
      current_players: [],
      logo: "https://upload.wikimedia.org/wikipedia/en/0/0d/New_Orleans_Pelicans_logo.svg",
      name: "Bears",
      salary: 0,
      stadium: {
        capacity: 0,
        lat: 0,
        lng: 0,
      },
      team_ID: 23,
    },
    season: 2023,
    season_type: 1,
    status: "Scheduled",
    week: 0,
  },
  projection: {
    _id: "640a7a20ffe9f958337de441",
    game: "64052d011b235f73482d874a",
    player: {
      _id: "615e0f3018e8ec278a3d55b8",
      player_ID: 20000619,
      __v: 0,
      active: true,
      current_status: "Active",
      dob: "1990-07-15T00:00:00.000Z",
      experience: 9,
      experience_string: "9",
      height: "74",
      injury_status: "Probable",
      name: "Damian Lillard",
      number: 0,
      photo:
        "https://s3-us-west-2.amazonaws.com/static.fantasydata.com/headshots/nba/low-res/20000619.png",
      position: "PG",
      position_category: "G",
      salary: 39344900,
      short_name: "Damian Lillard",
      status: "Active",
      team: "POR",
      team_ID: 19,
      team_ref: "615e0f2f18e8ec278a3d4f98",
      weight: 195,
    },
    __v: 0,
    fantasy_points: 26.21,
    opponent: "CHI",
    opponent_rank: 24,
    position: "RB",
    projected_stats: {
      field_goals_made: 9.7,
      field_goals_attempted: 20.91,
      field_goals_percentage: 47.6,
      effective_field_goals_percentage: 57.1,
      two_pointers_made: 5.4,
      two_pointers_attempted: 9.37,
      two_pointers_percentage: 55.6,
      three_pointers_made: 4.3,
      three_pointers_attempted: 11.54,
      three_pointers_percentage: 33.3,
      free_throws_made: 8.57,
      free_throws_attempted: 9.32,
      free_throws_percentage: 100,
      offensive_rebounds: 0.73,
      defensive_rebounds: 3.96,
      rebounds: 4.69,
      assists: 7.32,
      steals: 1.12,
      blocked_shots: 0.3,
      turnovers: 3.03,
      personal_fouls: 1.96,
      points: 32.27,
      true_shooting_attempts: 25.0108,
      true_shooting_percentage: 64,
    },
    salary: 8500,
  },
};
