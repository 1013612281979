import { updateUserAndNotify } from "common/src/services/AuthService";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { GradientButton3 } from "../components/atoms/Button";
import { Input } from "../components/atoms/Input";
import Switch from "../components/atoms/Switch";
import { useUser } from "common/src/hooks/useUser";
import { BlockUserModal } from "../components/modals/BlockUserModal";
import { NavDrawer } from "../components/layout/header/NavDrawer";

export default function UserSettingsPage() {
  const user = useUser();

  const [newUsername, setNewUsername] = useState(user.username);
  const [newEmail, setNewEmail] = useState(user.email ?? "");
  const [newCode, setNewCode] = useState(user.code ?? "");

  const [newEmailPref, setNewEmailPref] = useState(
    user.notificationPreferences?.email
  );
  const [newPushPref, setNewPushPref] = useState(
    user.notificationPreferences?.push
  );

  const dispatch = useDispatch();

  return (
    <div className="flex-row bg-base-1">
      <NavDrawer />

      <div className={"flex-col flex-1 outline-l-1 pl-1 relative h-screen"}>
        <div className={"p-8 bg-base-1 outline-b-1 flex-row"}>
          <span
            className={"font-lg weight-500 text-neutral-2 flex-1 sticky t-0"}
          >
            Profile Settings
          </span>
        </div>

        <div className="flex-1 overflow-auto space-y-8 justify-center max-w-31 min-w-31 m-auto pt-8">
          <Input
            size="md"
            label={"Username"}
            value={newUsername}
            placeholder="Username"
            onChange={(e) => setNewUsername(e.target.value.replace(/\s/g, ""))}
          />

          <Input
            size="md"
            label={"Referral Code"}
            value={newCode}
            placeholder="Username"
            onChange={(e) =>
              setNewCode(e.target.value.replace(/\s/g, "").toUpperCase())
            }
          />

          <Input
            size="md"
            label={"Notification email"}
            value={newEmail}
            placeholder="Email"
            onChange={(e) => setNewEmail(e.target.value)}
          />
          {!user.email && (
            <span className="text-neutral-1 font-sm weight-300 mt-5 flex">
              Add an email to receive notifications about important events
              related to your contests. We will never spam you with marketing
              content.
            </span>
          )}

          <div className="flex-row w-full mt-8">
            <div className="flex-col flex-1 flex-grow">
              <span className="text-neutral-2 font-md weight-400">
                Email Notifications
              </span>
              <span className="text-neutral-1 font-sm weight-300">
                Receive emails when you win contests and for draft reminders
              </span>
            </div>
            <Switch
              id={"join-after-create-switch"}
              value={newEmailPref}
              setValue={() => setNewEmailPref(!newEmailPref)}
            />
          </div>

          <div className="flex-row w-full mt-8">
            <div className="flex-col flex-1 flex-grow">
              <span className="text-neutral-2 font-md weight-400">
                Push Notifications (Mobile App)
              </span>
              <span className="text-neutral-1 font-sm weight-300">
                Receive mobile push notifications for important reminders
              </span>
            </div>
            <Switch
              id={"join-after-create-switch"}
              value={newPushPref}
              setValue={() => setNewPushPref(!newPushPref)}
            />
          </div>

          <GradientButton3
            color={"primary"}
            className="mt-12 w-full"
            onClick={() => {
              const newUser = {
                notificationPreferences: {
                  email: newEmailPref,
                  push: newPushPref,
                },
                email: newEmail,
                username: newUsername,
                code: newCode,
              };
              updateUserAndNotify(newUser, { ...user, ...newUser }, dispatch);
            }}
          >
            Save Changes
          </GradientButton3>
          <BlockUserModal />
        </div>
      </div>
    </div>
  );
}
