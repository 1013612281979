import { KycContext } from "common/src/context/KycContext";
import { useUser } from "common/src/hooks/useUser";
import { KycFormErrors } from "common/src/services/KycService";
import { COUNTRIES, US_STATES } from "common/src/util/constants";
import React, { useContext, useState } from "react";
import kycGraphic from "../../../assets/kyc-graphic.png";
import { GradientButton3, SolidButton3 } from "../../atoms/Button";
import { DividerWithTextComponent } from "../../atoms/Divider";
import { Input } from "../../atoms/Input";
import { BannerV2 } from "../../molecules/Banner";
import { Select } from "../../molecules/Select";
import { ParticipantEmailForm } from "../circle/ParticipantEmailForm";

export function KycForm({ handleClose, message }) {
  const {
    submit,
    formState,
    setFormState,
    formError,
    emailOptOut,
    setEmailOptOut,
    useSsn,
  } = useContext(KycContext);
  const user = useUser();
  const [loading, setLoading] = useState(false);

  return (
    <div className="outline-none bg-base-1 h-90 rounded-9 w-11/12 max-w-31 max-h-9/10  flex-col relative animation-from-bottom  overflow-hidden">
      <div className="w-full overflow-auto pb-11 p-8">
        <div className="w-full flex flex-center mb-5">
          <div className="w-17 h-17 rounded-full bg-base-2">
            <img src={kycGraphic} className="w-full cover" alt="kyc" />
          </div>
        </div>
        <h3 className="text-neutral-2 m-0">Let's get you verified </h3>
        <span className="text-neutral-1 font-md weight-300">
          {message !== "" && message
            ? message
            : "We need some information from you before you can join a paid contest"}
        </span>
        <br />
        <div className={`flex-col my-8`}>
          <KycInputs />
        </div>
        {!user.email && (
          <div className=" bg-base-2 rounded-8 p-8">
            <ParticipantEmailForm
              showError={formError === KycFormErrors.EMAIL}
              emailOptOut={emailOptOut}
              setEmailOptOut={useSsn ? undefined : setEmailOptOut}
              inputStyle={"bg-base-3"}
              setValue={(e) =>
                setFormState({ ...formState, email: e.target.value })
              }
              value={formState.email}
            />
          </div>
        )}
        {useSsn && (
          <div className="flex-row  mb-6">
            <Input
              containerClasses={"flex  "}
              value={formState.phone}
              type={"number"}
              placeholder="Phone Number"
              label="Phone Number"
              onChange={(e) =>
                setFormState({ ...formState, phone: e.target.value })
              }
              showError={formError === KycFormErrors.DOB} // TODO
            />
          </div>
        )}
        <br />
        <br />
      </div>
      <div className="absolute bg-base-1 b-0 r-0 l-0 p-8 flex-row w-full z-9 p-xl space-x-8">
        <SolidButton3
          className={"flex-1"}
          onClick={handleClose}
          color="neutral"
        >
          Close
        </SolidButton3>
        {loading ? (
          <GradientButton3
            disabled={true}
            className={"flex-1"}
            color="primary"
            onClick={() => {}}
          >
            <i className="bx bx-loader-circle bx-spin bx-md white" />
          </GradientButton3>
        ) : (
          <GradientButton3
            className={"flex-1"}
            color="primary"
            onClick={async () => {
              setLoading(true);
              await submit().finally(() => setLoading(false));
            }}
          >
            Submit
          </GradientButton3>
        )}
      </div>
    </div>
  );
}

function KycInputs() {
  const { formState, setFormState, formError, useSsn } = useContext(KycContext);
  return (
    <>
      <div className="flex-col justify-end">
        <DividerWithTextComponent className={"mb-8"} />

        <div className="flex-row space-x-8 mb-9 w-full">
          <Input
            containerClasses={"flex-1  "}
            value={formState.firstName}
            placeholder="First Name"
            onChange={(e) =>
              setFormState({ ...formState, firstName: e.target.value })
            }
            label="First Name"
            showError={formError === KycFormErrors.FIRST_NAME}
          />
          <Input
            containerClasses={"flex-1"}
            value={formState.surName}
            placeholder="Last Name"
            label="Last Name"
            onChange={(e) =>
              setFormState({ ...formState, surName: e.target.value })
            }
            showError={formError === KycFormErrors.SURNAME}
          />
        </div>
        <BannerV2 color="warning" solid>
          <div className="flex-row space-x-6">
            <i className="bx bx-sm bx-info-circle text-warning-2" />
            <div className="flex-col">
              <span className="font-md weight-400 text-warning-2">
                If this fails:
              </span>
              <span className="font-sm weight-300 text-warning-2">
                Make sure to use your real name on your government ID - no
                nicknames
              </span>
            </div>
          </div>
        </BannerV2>
      </div>

      <div className="flex-col justify-end mt-13">
        <DividerWithTextComponent className={"mb-8"} />

        <div className="flex-row space-x-8 mb-6">
          <Input
            containerClasses={"flex-1"}
            value={formState.physicalAddress}
            placeholder="Address"
            label="Street Address"
            onChange={(e) =>
              setFormState({ ...formState, physicalAddress: e.target.value })
            }
            showError={formError === KycFormErrors.ADDRESS}
          />
          <Input
            containerClasses={"flex-1"}
            value={formState.city}
            placeholder="City"
            label="City"
            onChange={(e) =>
              setFormState({ ...formState, city: e.target.value })
            }
            showError={formError === KycFormErrors.CITY}
          />
        </div>
        <div className="flex-row space-x-8 mb-9 flex-1 flex-grow">
          <Select
            label="State"
            containerClasses={"flex max-w-25"}
            value={formState.state}
            options={US_STATES}
            setValue={(e) =>
              setFormState({ ...formState, state: e.target.value })
            }
            showError={formError === KycFormErrors.STATE}
          />
          <Select
            label="Country"
            containerClasses={"flex flex-grow"}
            value={formState.country}
            options={COUNTRIES}
            setValue={(e) =>
              setFormState({ ...formState, country: e.target.value })
            }
            showError={formError === KycFormErrors.COUNTRY}
          />
          <Input
            containerClasses={"flex max-w-25 flex-shrink"}
            value={formState.zip}
            classes="flex-shrink"
            type="number"
            placeholder="Zip"
            label="Zip Code"
            onChange={(e) =>
              setFormState({ ...formState, zip: e.target.value })
            }
            showError={formError === KycFormErrors.ZIP}
          />
        </div>
        <BannerV2 color="warning" solid>
          <div className="flex-row space-x-6">
            <i className="bx bx-sm bx-info-circle text-warning-2" />
            <div className="flex-col">
              <span className="font-md weight-400 text-warning-2">
                If this fails:
              </span>
              <span className="font-sm weight-300 text-warning-2">
                Try using old addresses - sometimes new places are not
                registered when you move
              </span>
            </div>
          </div>
        </BannerV2>
      </div>

      <div className="flex-col justify-end mt-13 mb-8">
        <DividerWithTextComponent className={"mb-6"} />

        <div className="flex-row  mb-6">
          <Input
            containerClasses={"flex"}
            value={formState.dob}
            type={"date"}
            placeholder="Birthdate"
            label="Date of Birth (mm/dd/yyyy)"
            onChange={(e) =>
              setFormState({ ...formState, dob: e.target.value })
            }
            showError={formError === KycFormErrors.DOB}
          />
        </div>
        <BannerV2 color="warning" solid>
          <div className="flex-row space-x-6">
            <i className="bx bx-sm bx-info-circle text-warning-2" />
            <div className="flex-col">
              <span className="font-md weight-400 text-warning-2">
                If this fails:
              </span>
              <span className="font-sm weight-300 text-warning-2">
                Make sure to triple-check your date of birth. Verification will
                fail if your birthdate is incorrect.
              </span>
            </div>
          </div>
        </BannerV2>
        {useSsn && (
          <>
            <div className="flex-row  mb-6 mt-11">
              <Input
                containerClasses={"flex  "}
                value={formState.ssn}
                inputMode={"number"}
                maxlength={4}
                placeholder="Last 4 SSN"
                label="Last 4 of Social Security Number"
                onChange={(e) =>
                  setFormState({ ...formState, ssn: e.target.value })
                }
                showError={formError === KycFormErrors.SSN}
              />
            </div>
            <BannerV2 color="warning" solid>
              <div className="flex-row space-x-6">
                <i className="bx bx-sm bx-info-circle text-warning-2" />
                <div className="flex-col">
                  <span className="font-md weight-400 text-warning-2">
                    If this fails:
                  </span>
                  <span className="font-sm weight-300 text-warning-2">
                    Make sure the last 4 of your SSN is correct.
                  </span>
                </div>
              </div>
            </BannerV2>
          </>
        )}
      </div>
    </>
  );
}
