const SOLSCAN = "solscan.io";

export function getExplorerTxUrl(signature: string): string {
  let url = `https://${SOLSCAN}/tx/${signature}`;
  // const net = ConnectionService.getNet();
  // if (net === SolanaNet.DEVNET) url = url + "?cluster=devnet";

  return url;
}

export function getExplorerNftUrl(mint: string): string {
  let url = `https://${SOLSCAN}/token/${mint}`;
  // const net = ConnectionService.getNet();
  // if (net === SolanaNet.DEVNET) url = url + "?cluster=devnet#metadata";

  return url;
}

export function getExplorerAccountUrl(publicKey: string): string {
  let url = `https://${SOLSCAN}/account/${publicKey}`;
  // const net = ConnectionService.getNet();
  // if (net === SolanaNet.DEVNET) url = url + "?cluster=devnet#metadata";

  return url;
}
