import React from "react";
import { StakingInputs } from "./StakingInputs";
import { NavDrawer } from "../layout/header/NavDrawer";

export default function StakingComponent() {
  return (
    <div className="h-screen flex-row ">
      <NavDrawer />

      <div className={"flex-1 flex-center"}>
        <div className={"w-1/2"}>
          <StakingInputs />
        </div>
      </div>
    </div>
  );
}
