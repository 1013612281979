import React, { useEffect, useRef, useState } from "react";
import { HorizontalDivider } from "./Divider";

export function VerticalScrollview({
  header,
  footer,
  children,
  className,
  scrollRef,
}) {
  const [showShadowTop, setShowShadowTop] = useState(false);
  const [showShadowBottom, setShowShadowBottom] = useState(false);

  function scrollEvent(e) {
    if (e.target.scrollTop > 0 && !showShadowTop) {
      setShowShadowTop(true);
    } else if (e.target.scrollTop === 0) {
      setShowShadowTop(false);
    }

    if (
      Math.floor(e.target.scrollHeight - e.target.scrollTop) <=
        Math.floor(e.target.clientHeight) &&
      showShadowBottom
    ) {
      setShowShadowBottom(false);
    } else if (!showShadowBottom) {
      setShowShadowBottom(true);
    }
  }

  return (
    <>
      <div className={`overflow-y-auto max-h-full flex flex-col ${className}`}>
        {header ? (
          <div className={"relative"}>
            {header}
            {showShadowTop && <HorizontalDivider className={"opacity-50"} />}
            {showShadowTop && (
              <div className="header-gradient w-full min-h-8 z-8 absolute b--8 no-click animation-from-top" />
            )}
          </div>
        ) : null}
        <div
          ref={scrollRef ? scrollRef : null}
          onScroll={scrollEvent}
          className="flex flex-1 flex-col overflow-y-scroll"
        >
          {children}
        </div>

        {footer ? (
          <div className="relative">
            {footer}
            {showShadowBottom && (
              <div className="footer-gradient w-full min-h-8 z-8 absolute t--8 no-click animation-from-bottom" />
            )}
          </div>
        ) : null}
      </div>
    </>
  );
}

export function HorizontalScrollview({ children, className }) {
  const ref = useRef(null);
  const [arrowsShown, setArrowsShown] = useState({ left: false, right: false });

  function onScroll(scrollOffset) {
    const scrollLeft = ref.current.scrollLeft + scrollOffset;
    ref.current.scrollLeft += scrollOffset;
    if (scrollLeft <= 0 && arrowsShown.left) {
      setArrowsShown({ left: false, right: true });
    } else {
      setArrowsShown({ left: true, right: true });
    }
  }

  useEffect(() => {
    if (ref.current.scrollWidth > ref.current.clientWidth) {
      setArrowsShown({ left: false, right: true });
    } else {
      setArrowsShown({ left: false, right: false });
    }
  }, [children]);

  return (
    <>
      <div className={`flex-row items-center relative`}>
        {arrowsShown.left ? (
          <div
            onClick={() => onScroll(-300)}
            className={
              "z-11 h-full flex w-9 flex flex-center absolute t-0 l-0 b-0 hover:bg-base-2 cursor-pointer transition bg-base-1 rounded-3 hover-left"
            }
          >
            <i className="bx bx-sm bx-chevron-left text-neutral-2" />
          </div>
        ) : null}

        <div
          ref={ref}
          style={{
            overflowX: "auto",
            scrollBehavior: "smooth",
          }}
          className={`flex-row space-x-6 ${className}`}
        >
          {children}
        </div>

        {arrowsShown.right ? (
          <div
            onClick={() => onScroll(300)}
            className={
              "z-11 h-full flex w-9 flex flex-center hover:bg-base-2 cursor-pointer transition bg-base-1 rounded-3 hover-left"
            }
          >
            <i className="bx bx-sm bx-chevron-right text-neutral-2" />
          </div>
        ) : null}
      </div>
    </>
  );
}
