import { User } from "@phantasia/model-interfaces";
import { Singletons } from "../services/Singletons";

export function validateUserEmailForm(
  user: User,
  email: string,
  optOut: boolean,
  setError: (val: boolean) => {}
) {
  if (user.email) {
    return true;
  }
  if (optOut) {
    return true;
  }
  if ((email === "" || !email) && !optOut) {
    setError(true);
    Singletons.toastService.error(
      "Email error",
      "Enter email or choose to opt out"
    );
    return false;
  } else if (!isValidEmail(email)) {
    setError(true);
    return false;
  }
  return true;
}

export function isValidEmail(email: string) {
  if (!email) return false;
  if (!email.includes("@")) {
    return false;
  }
  if (!email.includes(".")) {
    return false;
  }
  return true;
}
