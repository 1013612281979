import { getApi } from "../util/api";
import { UserTypes } from "../redux/actions/types";
import { Dispatch } from "redux";

export async function getAndSetUser(dispatch: Dispatch) {
  try {
    const { data } = await getApi().get("/api/auth");

    dispatch({
      type: UserTypes.SET_USER,
      payload: data,
    });
  } catch (e) {
    console.error(e);
  }
}

export async function getUserProfile(publicKey: string) {
  try {
    const { data: users } = await getApi().post(`/api/user`, {
      walletPubkeys: [publicKey],
    });
    if (!users) {
      throw new Error("No user found");
    }
    return users[0];
  } catch (e) {
    console.error(e);
  }
}

export async function updateSeen() {
  return await getApi().put("/api/user/update_seen");
}

export async function produceDailyRewardJob() {
  try {
    const res = await getApi().post(`/api/user/daily-reward`);
    return res.data;
  } catch (e) {
    console.error(e);
  }
}

export async function updateReferral(username: string) {
  try {
    const res = await getApi().put(`/api/auth/referral`);
    return res.data;
  } catch (e) {
    console.error(e);
  }
}

export async function checkExistingUserInfo(username: string) {
  try {
    const res = await getApi().get(`/api/auth/check/${username}`);
    return res.data;
  } catch (e) {
    console.error(e);
  }
}

export async function checkUsedRafCode() {
  try {
    const res = await getApi().get(`/api/user/get-used-ref-code`);
    return res.data?.code;
  } catch (e) {
    console.error(e);
  }
}
