import { getApi } from "../util/api";
import { NOT_FOUND } from "../context/GeolocationContext";

export const US_COUNTRY_CODE = "US";

export const PAID_CONTEST_COUNTRY_WHITELIST: string[] = [];

export const PAID_CONTEST_STATE_WHITELIST = new Map<string, number>([
  ["AK", 18],
  ["CA", 18],
  ["FL", 18],
  ["GA", 18],
  ["IL", 18],
  ["KS", 18],
  ["KY", 18],
  ["MN", 18],
  ["NE", 19],
  ["NM", 18],
  ["NC", 18],
  ["ND", 18],
  ["OK", 18],
  ["OR", 18],
  ["RI", 18],
  ["SC", 18],
  ["SD", 18],
  ["TX", 18],
  ["UT", 18],
  ["DC", 18],
  ["WV", 18],
  ["WI", 18],
  ["WY", 18],
]);

export const EXTRA_COUNTRIES_FULL = [
  "Brazil",
  "Mexico",
  "Vietnam",
  "Nigeria",
  "Germany",
  "Spain",
  "Portugal",
  "India",
  "Colombia",
  "Paraguay",
  "Turkiye",
  "Malta",
  "Israel",
];

export const ANDROID_COUNTRIES_FULL = [];

export const EXTRA_COUNTRIES = [
  "BR",
  "MX",
  "VN",
  "NG",
  "DE",
  "ES",
  "PT",
  "IN",
  "CO",
  "PY",
  "TR",
  "MT",
  "IL",
];

export const ANDROID_PAID_COUNTRIES = [""];

export const MAGIC_SMS_BLACKLIST = [
  "Belgium",
  "Denmark",
  "Indonesia",
  "Vietnam",
  "Pakistan",
  "Sri Lanka",
  "Iran",
  "Senegal",
  "Liberia",
  "Nigeria",
  "Lithuania",
  "Belarus",
  "Bolivia",
  "Bangladesh",
  "Lebanon",
  "Jordan",
  "Syria",
  "Iraq",
  "Russia",
  "Azerbaijan",
  "Kyrgyzstan",
  "Cambodia",
];

export const GeoPermissionStates = {
  DENIED: "denied",
  GRANTED: "granted",
  PROMPT: "prompt",
};

export async function checkBrowserGeolocationAccess() {
  try {
    if (!navigator.geolocation) return GeoPermissionStates.DENIED;

    const { state } = await navigator.permissions.query({
      name: "geolocation",
    });
    const geoState = Object.keys(GeoPermissionStates).find(
      (key) => GeoPermissionStates[key] === state
    );
    if (!geoState) return GeoPermissionStates.DENIED;

    return geoState.toLowerCase();
  } catch (e) {
    console.error(e);
    return GeoPermissionStates.DENIED;
  }
}

export async function getCountryAndState(): Promise<{
  country: string;
  state: string;
}> {
  const geolocation: any = await new Promise((resolve, reject) =>
    navigator.geolocation.getCurrentPosition(
      (geolocation) => resolve(geolocation),
      (e) => reject(e)
    )
  );
  const {
    coords: { latitude, longitude },
  } = geolocation;
  return getStateAndCountryFromLatAndLong(latitude, longitude);
}

export async function getStateAndCountryFromLatAndLong(
  lat: number,
  long: number
) {
  const response = await getApi().post("/api/auth/v2/exact-location", {
    lat,
    long,
  });
  // return { country: "BR", state: "" };
  return response.data;
}

export async function isRegionRestricted() {
  const { country, state } = await getCountryAndState();
  console.log({ country, state });

  return determineIfRegionIsOnWhitelist(state, country);
}

export function isExpansionRegion(country: string) {
  return EXTRA_COUNTRIES.includes(country);
}

export function isAndroidExpansion(country: string) {
  return ANDROID_PAID_COUNTRIES.includes(country);
}

export function determineIfRegionIsOnWhitelist(
  state: string,
  country: string
): boolean {
  if (!country || country === NOT_FOUND) return false;
  if (!state || state === NOT_FOUND) return false;

  if (
    country === US_COUNTRY_CODE &&
    Array.from(PAID_CONTEST_STATE_WHITELIST.keys()).includes(state)
  )
    return true;

  return PAID_CONTEST_COUNTRY_WHITELIST.includes(country);
}
