import { useMemo } from "react";
import { ConnectionService } from "@phantasia/model-interfaces";

export function useConnection() {
  return useMemo(() => ConnectionService.getConnection(), []);
}

export function useSolanaNet() {
  return ConnectionService.getNet();
}
