import React from "react";
import phanbotAnimated from "../../assets/phanbot-animated.webm";
import { ROUTES } from "../../Routes";
import { GradientButton3 } from "../../components/atoms/Button";
import { useHistory } from "react-router-dom";

export function LeaguesInfoSection({ isMobile }) {
  const history = useHistory();
  const valueProps = [
    // {
    //   icon: "bxs-badge-dollar",
    //   title: "Get $25, on us",
    //   desc: "Phantasia will match your first deposit 100% up to $25",
    // },
    {
      icon: "bx-user-plus",
      title: "Refer friends for cash",
      desc: "Receive a 100% match up to $10 on all of your friend's first deposits.",
    },
    {
      icon: "bx-dice-5",
      title: "Win daily prizes",
      desc: "Phantasia daily spin allows you to earn a randomized, daily prize. Win real cash with no risk!",
    },
  ];

  return (
    <div className="w-full flex-col flex-center relative z-11 bg-base-0 py-15 space-y-12 overflow-hidden">
      <h1
        style={{ fontSize: "calc(20px + 1.1vw)" }}
        className={`no-wrap text-neutral-2 weight-500 mt-0 flex-row`}
      >
        <strong className="mr-5 text-gradient-light weight-500">Earn</strong>a
        Lot
      </h1>
      <div
        className={
          "grid grid-cols-1 md:grid-cols-2 gap-10 w-full max-w-35 relative m-auto"
        }
      >
        {!isMobile ? (
          <div className="relative z-4 flex justify-center items-end">
            <div className="absolute b-0 l-0">
              <video
                width={"500"}
                height={"500"}
                autoplay="autoplay"
                muted
                loop
              >
                <source src={phanbotAnimated} type="video/webm" />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        ) : null}
        <div className={"flex-col space-y-8 w-full px-5 md:px-0"}>
          {valueProps.map((item, index) => (
            <div
              key={index}
              className={`flex-row space-x-5 p-8 rounded-8 outline-1 bg-gradient-base relative `}
            >
              <i
                className={`bx ${item.icon} text-neutral-2 bx-sm pt-3 text-gradient-light`}
              />
              <div className={"flex-col space-y-4"}>
                <span className="font-xl text-neutral-2 weight-400">
                  {item.title}
                </span>
                <span className="font-lg text-neutral-2 opacity-75 line-height-lg weight-300">
                  {item.desc}
                </span>
              </div>
            </div>
          ))}
          <GradientButton3
            color={"primary"}
            className={"rounded-full py-9"}
            onClick={() => history.push(ROUTES.PICKS)}
          >
            Play now
          </GradientButton3>
        </div>
      </div>
    </div>
  );
}
