import { LivePicksComponent } from "../../components/picks/slips/LivePicksComponent";
import { LiveSlipsContextProvider } from "common/src/context/picks/LiveSlipsContext";

export function LivePicksPage() {
  return (
    <LiveSlipsContextProvider>
      <LivePicksComponent />
    </LiveSlipsContextProvider>
  );
}
