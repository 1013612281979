import { usePicksBoard } from "common/src/context/picks/PicksBoardContext";
import { Input } from "../../atoms/Input";
import { PicksRulesModal } from "../../modals/PicksRulesModal";

export function PicksSearch() {
  const { searchQuery, setSearchQuery } = usePicksBoard();
  return (
    <div className={"flex-row items-center space-x-6 px-8 mt-8"}>
      <PicksRulesModal />
      <div className={"flex-1"} />
      <Input
        classes={"font-md max-w-25 min-w-25 rounded-full"}
        size="sm"
        onChange={(e) => setSearchQuery(e.target.value)}
        placeholder={"Search"}
        value={searchQuery}
        icon={<i className={"bx bx-search text-neutral-1"} />}
      />
      <div
        onClick={() => setSearchQuery("")}
        className="flex flex-center bg-base-2 min-h-10 min-w-10 max-h-10 max-w-10 rounded-full cursor-pointer hover-down transition"
      >
        <i className="bx bx-x text-neutral-2" />
      </div>
    </div>
  );
}
