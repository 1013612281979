import { CoinflowWithdraw } from "@coinflowlabs/react";
import { useConnection, useSolanaNet } from "common/src/hooks/useConnection";
import { useCommonWallet } from "common/src/services/wallet/UseCommonWallet";
import { NavDrawer } from "../components/layout/header/NavDrawer";
import { useBalance } from "common/src/hooks/useBalance";

export default function WithdrawPage() {
  const wallet = useCommonWallet();
  const connection = useConnection();
  const solanaNet = useSolanaNet();
  const { usdc } = useBalance();

  const coinflowenv = solanaNet === "devnet" ? "staging" : "prod";

  return (
    <div className="h-screen w-screen flex-row space-x-8 bg-base-1">
      <NavDrawer />
      <div className={"flex-col flex-1 outline-l-1 pl-1"}>
        <div className={"p-8 bg-base-1 outline-b-1 flex-row"}>
          <span className={"font-lg weight-500 text-neutral-2 flex-1"}>
            Withdraw Winnings
          </span>
          <span className={"font-md weight-400 text-neutral-2"}>
            ${usdc.toFixed(2)} Withdrawable Balance
          </span>
        </div>
        <CoinflowWithdraw
          blockchain={"solana"}
          wallet={wallet}
          merchantId={"phantasia"}
          env={coinflowenv}
          connection={connection}
        />
      </div>
    </div>
  );
}
