import { WalletPageContext } from "common/src/context/WalletPageContext";
import {
  calculateValueTrend,
  sendToken,
} from "common/src/services/SendService";
import { colorDanger, colorSuccess } from "common/src/util/constants";
import moment from "moment";
import { useContext, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Area, AreaChart, ResponsiveContainer, YAxis } from "recharts";
import solLogo from "../../../assets/built-img.png";
import fantLogo from "../../../assets/LogoAlt.svg";
import usdcLogo from "../../../assets/USD-Coin-icon.svg";
import creditsLogo from "../../../assets/fant-credit-logo.png";
import {
  GradientButton3,
  SolidButton1,
  SolidButton3,
} from "../../atoms/Button";
import { Input } from "../../atoms/Input";
import { IconHeader1 } from "../../atoms/PageHeader";
import { Loader } from "../../molecules/Loader";
import { loadBalance } from "common/src/redux/actions/WalletAction";
import { useBalance } from "common/src/hooks/useBalance";
import { useUser } from "common/src/hooks/useUser";
import { Tooltip } from "@mui/material";

export function BalanceDisplayComponent() {
  const { priceData } = useContext(WalletPageContext);
  const { fant, usdc, sol, credits } = useBalance();

  if (priceData.fant.length < 1 || priceData.usdc.length < 1) {
    return <Loader />;
  }

  return (
    <div className="w-full h-full animation-from-right p-8 rounded-8 bg-base-1 h-min">
      <div className="flex-row items-baseline">
        <IconHeader1 icon={"bx-trending-up"} className={"flex-grow mb-5"}>
          BALANCES
        </IconHeader1>
      </div>

      <div className="flex-col space-y-5 w-full">
        <BalanceDisplayItem
          ticker="USD"
          name={"Phantasia Credits"}
          balance={`$${(credits / 100).toFixed(2)}`}
          image={<img className="w-8 h-8" src={creditsLogo} alt="usdc" />}
          data={priceData.usdc}
        />
        <BalanceDisplayItem
          ticker="USD"
          name={"US Dollars"}
          balance={`$${usdc.toFixed(2)}`}
          image={<img className="w-8 h-8" src={usdcLogo} alt="usdc" />}
          data={priceData.usdc}
        />
        <BalanceDisplayItem
          ticker="FANT"
          name={"Phantasia"}
          balance={fant}
          image={<img className="w-8 h-8" src={fantLogo} alt="FANT" />}
          data={priceData.fant}
        />
        <BalanceDisplayItem
          ticker="SOL"
          name={"Solana"}
          balance={sol}
          image={<img className="w-6 h-6" src={solLogo} alt="sol" />}
          data={priceData.sol}
        />
      </div>
    </div>
  );
}

export function BalanceDisplayItem({
  image,
  name,
  ticker,
  balance,
  data,
  hideGraph,
}) {
  const trend = calculateValueTrend(data);

  return (
    <div className="grid grid-cols-3 gap-4 my-4 w-full items-center space-x-6 ">
      <div className="flex-row space-x-5 items-center">
        <div className=" outline-1 rounded-full p-5 flex flex-center w-11 h-11">
          {image}
        </div>
        <div className="flex-col flex-1 mr-5">
          <span className="font-md weight-400 text-neutral-2">{name}</span>
          <span className="font-sm weight-300 text-neutral-1">{ticker}</span>
        </div>
      </div>
      <div className="flex-col items-end">
        <span className={`font-md weight-400 text-neutral-2 no-wrap`}>
          {balance}
        </span>
        <div className="flex-row space-x-5">
          <span
            className={`font-sm weight-400 text-${
              trend >= 0 ? "success" : "danger"
            }-600 opacity-75 no-wrap`}
          >
            ${data ? data[0][1]?.toFixed(2) : "0.00"}
          </span>
        </div>
      </div>
      {!hideGraph && (
        <div className="h-full flex-1 flex  relative">
          <PriceTrendGraph data={data} trend={trend} />
        </div>
      )}
    </div>
  );
}

function PriceTrendGraph({ data, trend }) {
  const reversed_data = [...data];
  reversed_data.reverse();
  const graphData = [];
  reversed_data?.forEach((item) => {
    graphData.push({
      date: moment(item[0]).format("MMM DD"),
      price: Math.round(item[1] * 10000) / 10000,
    });
  });

  const color = trend >= 0 ? colorSuccess[500] : colorDanger[500];

  return (
    <div className="flex flex-1 h-full  absolute t-0 b-0 r-0 l--11">
      <div
        className="flex flex-1 text-neutral-2  delay-4 "
        style={{ overflow: "hidden" }}
      >
        <ResponsiveContainer width="100%" height="100%" debounce={1}>
          <AreaChart data={graphData}>
            <defs>
              <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor={color} stopOpacity={0.3} />
                <stop offset="95%" stopColor={color} stopOpacity={0} />
              </linearGradient>
            </defs>

            <YAxis
              dataKey="price"
              axisLine={false}
              domain={[graphData[0], "auto"]}
              allowDataOverflow={true}
              tick={false}
            />

            <Area
              type="monotone"
              dataKey="price"
              stroke={color}
              fillOpacity={1}
              fill="url(#colorUv)"
              strokeWidth={3}
              animationDuration={500}
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}

export function SendCryptoForm({ wallet }) {
  const {
    selectedCurrency,
    setSelectedCurrency,
    receiverWallet,
    setReceiverWallet,
    showError,
    setShowError,
    amountToSend,
    setAmountToSend,
    showSendConfirmation,
    setShowSendConfirmation,
    fant_balance,
    sol_balance,
    usdc_balance,
  } = useContext(WalletPageContext);

  const dispatch = useDispatch();
  const user = useUser();

  useEffect(() => {
    setAmountToSend(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCurrency]);

  useEffect(() => {
    setShowSendConfirmation(false);
    if (selectedCurrency === "FANT" && amountToSend > fant_balance) {
      setShowError(true);
    } else if (selectedCurrency === "SOL" && amountToSend > sol_balance) {
      setShowError(true);
    } else if (selectedCurrency === "USDC" && amountToSend > usdc_balance) {
      setShowError(true);
    } else {
      if (showError) setShowError(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amountToSend, receiverWallet]);

  return (
    <div className="flex-col">
      <span className="text-neutral-1 weight-400 font-sm mb-3 px-3">
        RECIPIENT WALLET
      </span>
      <Input
        id="receiver-wallet-input"
        showError={showError}
        placeholder={`SoLa...1234`}
        value={receiverWallet}
        onChange={(e) => setReceiverWallet(e.target.value)}
        classes={" h-11 mb-6 rounded-5"}
      />
      <div className="flex-row items-baseline w-full">
        <span className="text-neutral-1 weight-400 font-sm mb-3 mr-18">
          Currency
        </span>
        <span className="text-neutral-1 weight-400 font-sm mb-3 px-3 flex-1">
          {`${selectedCurrency} TO SEND`}
        </span>
        <span className="font-sm text-neutral-1 weight-300 px-3">
          {selectedCurrency === "FANT"
            ? fant_balance + " FANT"
            : selectedCurrency === "SOL"
            ? sol_balance + " SOL"
            : usdc_balance + " USDC"}
        </span>
      </div>

      <div className="flex-row space-x-8 relative mb-8">
        <div className="flex-row space-x-5 items-center mb-8">
          <div
            onClick={() => setSelectedCurrency("FANT")}
            className={`w-11 h-11 rounded-6 flex-center flex hover-up transition cursor-pointer ${
              selectedCurrency === "FANT" ? "bg-base-3" : " outline-1"
            }`}
          >
            <img className="w-8 h-8" src={fantLogo} alt="sol" />
          </div>
          <div
            onClick={() => setSelectedCurrency("SOL")}
            className={`w-11 h-11 rounded-6 flex-center flex hover-up transition cursor-pointer ${
              selectedCurrency === "SOL" ? "bg-base-3" : " outline-1"
            }`}
          >
            <img className="w-6 h-6" src={solLogo} alt="sol" />
          </div>
          {user.usdcWithdrawApproved ? (
            <div
              onClick={() => {
                setSelectedCurrency("USDC");
              }}
              className={`w-11 h-11 rounded-6 flex-center flex hover-up transition cursor-pointer ${
                selectedCurrency === "USDC" ? "bg-base-3" : " outline-1"
              }`}
            >
              <img className="w-8 h-8" src={usdcLogo} alt="usdc" />
            </div>
          ) : (
            <Tooltip
              title={
                <span className={"text-neutral-1 weight-300"}>
                  Reach out to support to be approved for USDC withdraw
                </span>
              }
              placement="top"
            >
              <div
                className={`w-11 h-11 opacity-50 rounded-6 flex-center flex hover-up transition cursor-not-allowed`}
              >
                <img className="w-8 h-8" src={usdcLogo} alt="usdc" />
              </div>
            </Tooltip>
          )}
        </div>
        <Input
          id="send-money-input"
          showError={showError}
          placeholder={`0 ${selectedCurrency}`}
          value={amountToSend}
          onChange={(e) => setAmountToSend(e.target.value)}
          type="number"
          classes={" h-11 rounded-5"}
        />
        <SolidButton1
          className="w-min px-6 absolute r-4 bg-base-2 t-4"
          color={""}
          onClick={() =>
            setAmountToSend(
              selectedCurrency === "FANT"
                ? fant_balance
                : selectedCurrency === "SOL"
                ? sol_balance
                : usdc_balance
            )
          }
        >
          Max
        </SolidButton1>
      </div>
      {showError && (
        <span className="font-sm text-danger-1 weight-300 mb-4 w-full text-center">
          You must enter a value lower than or equal to your balance
        </span>
      )}
      {!showSendConfirmation ? (
        <SolidButton3
          color="primary"
          disabled={showError || amountToSend <= 0 || !receiverWallet}
          onClick={() => setShowSendConfirmation(true)}
        >
          Send {amountToSend} {selectedCurrency}
        </SolidButton3>
      ) : (
        <GradientButton3
          color="success"
          onClick={() =>
            submitForm(
              selectedCurrency,
              amountToSend,
              receiverWallet,
              dispatch,
              () => {
                setAmountToSend(0);
                setReceiverWallet("");
                loadBalance(wallet, dispatch);
              },
              wallet
            )
          }
        >
          Confirm Send {amountToSend} {selectedCurrency}
        </GradientButton3>
      )}
    </div>
  );
}

function submitForm(currency, amount, receiver, dispatch, onSuccess, wallet) {
  sendToken(dispatch, amount, wallet, receiver, onSuccess, currency).catch(
    (e) => console.error(e)
  );
}
