import { SwipeableDrawer } from "@mui/material";
import { GeolocationContext } from "common/src/context/GeolocationContext";
import { HeaderContext } from "common/src/context/HeaderContext";
import { SignOut } from "common/src/redux/actions/UserAction";
import { useCommonWallet } from "common/src/services/wallet/UseCommonWallet";
import { MOONPAY_LINK_USDC } from "common/src/util/constants";
import React, { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, matchPath, useHistory, useLocation } from "react-router-dom";
import { default as logoImg } from "../../../assets/LogoAlt.svg";
import { GradientButton3, LinkButton3, SolidButton3 } from "../../atoms/Button";
import { getMobileOperatingSystem } from "../../modals/MobileAdModal";
import { useUser } from "common/src/hooks/useUser";

export function HeaderMobile() {
  const history = useHistory();
  const dispatch = useDispatch();
  const wallet = useCommonWallet();
  const location = useLocation();
  const user = useUser();
  const [bottomSheetAnchor, setBottomSheetAnchor] = useState(null);

  const toggleBottomSheet = (open) => (event) => {
    if (!event) return;
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setBottomSheetAnchor(open);
  };
  const routeIsWaitlist = matchPath(location.pathname, {
    path: "/waitlist",
    exact: true,
    strict: false,
  });

  function logout() {
    if (wallet) wallet.disconnect();
    dispatch(SignOut(history));
  }

  const { setKycModalOpen } = useContext(HeaderContext);

  const { showVerificationButton, state } = useContext(GeolocationContext);

  return (
    <div className="navbar-container flex flex-row z-11">
      <div className="navbar flex-row w-full  glass p-5">
        {!routeIsWaitlist ? (
          <div className="logo flex items-center">
            <Link to="/" className="flex items-center">
              <img src={logoImg} alt="logo" height="40" />
            </Link>
          </div>
        ) : (
          <div className="logo flex-row items-center">
            <img src={logoImg} alt="logo" height="40" />
            <div className="flex-col">
              <span className="font-sm flex weight-300 letter-spacing-lg text-neutral-2">
                PHANTASIA
              </span>
              <span className="font-xs flex weight-300 letter-spacing-md text-neutral-2">
                SPORTS
              </span>
            </div>
          </div>
        )}

        {!routeIsWaitlist && (
          <div className="flex-row w-full">
            <div className="flex-grow" />
            <div className="flex-row items-center space-x-6 mr-8 h-full">
              <span className="flex flex-center">
                <a
                  className="flex flex-center decoration-none"
                  href="https://twitter.com/PhantasiaSports"
                >
                  <i className="bx bxl-twitter bx-sm text-neutral-1 hover-dark transition" />
                </a>
              </span>
              <span className="flex flex-center ">
                <a
                  className="flex flex-center decoration-none"
                  href="https://t.co/Vskz9PkBBC?amp=1"
                >
                  <i className="bx bxl-discord-alt bx-sm text-neutral-1 hover-dark transition" />
                </a>
              </span>
            </div>
            <SolidButton3
              color="neutral"
              onClick={toggleBottomSheet(true)}
              type="w-min"
            >
              <i className="bx bx-menu bx-sm" />
            </SolidButton3>
          </div>
        )}
      </div>

      <SwipeableDrawer
        anchor={"bottom"}
        open={bottomSheetAnchor ?? false}
        onClose={toggleBottomSheet(false)}
        onOpen={toggleBottomSheet(true)}
      >
        <div className="relative p-5">
          <div className="flex-col decoration-none">
            {!user._id ? (
              <>
                <Link className="decoration-none w-full" to="/login">
                  <GradientButton3
                    color="primary"
                    className={"w-full py-9"}
                    onClick={toggleBottomSheet(false)}
                  >
                    Sign in to join contests
                  </GradientButton3>
                </Link>
              </>
            ) : (
              <div className="flex-col space-y-4">
                <span onClick={toggleBottomSheet(false)} className="">
                  <Link
                    to="/shop"
                    color="inherit"
                    className="decoration-none items-center text-neutral-2 bg-base-2 p-5 px-6 rounded-4 w-full flex"
                  >
                    <i className="bx bx-shopping-bag flex mr-5"></i>
                    Shop
                  </Link>
                </span>
                <span onClick={toggleBottomSheet(false)} className="">
                  <Link
                    to="/staking"
                    color="inherit"
                    className="decoration-none items-center text-neutral-2 bg-base-2 p-5 px-6 rounded-4 w-full flex"
                  >
                    <i className="bx bx-lock-alt flex mr-5"></i>
                    Staking
                    <span className="bg-gradient-primary text-neutral-2 rounded-6 px-4 font-xs ml-4">
                      New!
                    </span>
                  </Link>
                </span>
                <span onClick={() => logout()}>
                  <Link
                    to="/login"
                    className="decoration-none text-neutral-2 bg-base-2 p-5 px-6 rounded-4 w-full flex"
                    onClick={toggleBottomSheet(false)}
                  >
                    <i className="bx bx-log-out flex mr-5"></i>
                    Sign Out
                  </Link>
                </span>
              </div>
            )}
          </div>

          {showVerificationButton(state) && !user.passedKyc && user.authToken && (
            <div className="mt-6 w-full">
              <GradientButton3
                className={"flex-grow w-full"}
                color="success"
                onClick={() => setKycModalOpen(true)}
              >
                Verify for Paid Contests
              </GradientButton3>
            </div>
          )}
          {user.passedKyc && (
            <div>
              <GradientButton3
                className={"f px-8 mt-6 w-full"}
                color="primary"
                onClick={() => {
                  let params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,
              width=500,height=1000,left=-1000,top=-1000`;
                  window.open(MOONPAY_LINK_USDC, "", params);
                }}
              >
                Deposit
              </GradientButton3>
            </div>
          )}
          <div className="p-5 mb-5">
            <span className="text-neutral-1 font-sm weight-400 text-danger-1">
              For the best experience on Phantasia, please use the desktop
              website.
            </span>
            <LinkButton3
              link={getMobileOperatingSystem()}
              color="primary"
              className={"w-full mt-5 rounded-15"}
            >
              Download the App
            </LinkButton3>
          </div>
        </div>
      </SwipeableDrawer>
    </div>
  );
}
