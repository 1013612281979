export const Loader = ({
  message,
  large,
}: {
  message?: string;
  large?: boolean;
}) => {
  return (
    <div className="w-full flex my-2 flex-center h-full">
      <div
        className={`rounded-6 p-${
          large ? "8" : "5"
        } bg-base-2 flex-row space-x-6 flex-center flex`}
      >
        <i
          className={`bx bx-loader-circle bx-spin bx-${
            large ? "md" : "sm"
          } text-neutral-2`}
        />
        <span
          className={`font-${large ? "lg" : "sm"} text-neutral-2 weight-400`}
        >
          {message ? message : "Loading..."}
        </span>
      </div>
    </div>
  );
};
