//Redux
//User
export const UserTypes = {
  SET_USER: "SET_USER",
  REGISTER_SUCCESS: "REGISTER_SUCCESS",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  GET_USERS_LIST: "GET_USERS_LIST",
  SIGN_OUT: "SIGN_OUT",
  CLEAR_REGISTER: "CLEAR_REGISTER",
  CLEAR_ADMIN_LOGIN: "CLEAR_ADMIN_LOGIN",
  MODIFY_SUCCESS: "MODIFY_SUCCESS",
  SET_FANCARD: "SET_FANCARD",
  SET_NFT_METADATA: "SET_NFT_METADATA",
  SET_PHANTASIA_NFT_HOLDER: "SET_PHANTASIA_NFT_HOLDER",
  SET_AUTH_TOKEN: "SET_AUTH_TOKEN",
  SET_KEYPAIR: "SET_KEYPAIR",
  UPDATE_CARDS: "UPDATE_CARDS",
  UPDATE_PAYMENTS: "UPDATE_PAYMENTS",
  UPDATE_EMAIL: "UPDATE_EMAIL",
  UPDATE_SEEN_NEW: "UPDATE_SEEN_NEW",
  UPDATE_NOTIFICATION_PREFERENCES: "UPDATE_NOTIFICATION_PREFERENCES",
  UPDATE_NOTIFICATIONS: "UPDATE_NOTIFICATIONS",
  UPDATE_SPIN_TIMESTAMP: "UPDATE_SPIN_TIMESTAMP",
  RECENT_AUTH_METHOD: "RECENT_AUTH_METHOD",
};

// Wallet
export const WalletTypes = {
  SET_CREDIT_BALANCE: "SET_CREDIT_BALANCE",
  SET_FANT_BALANCE: "SET_FANT_BALANCE",
  SET_SOL_BALANCE: "SET_SOL_BALANCE",
  SET_USDC_BALANCE: "SET_USDC_BALANCE",
  SET_SFANT_BALANCE: "SET_SFANT_BALANCE",
  RESET_BALANCES: "RESET_BALANCES",
};

// Roster
export const RosterTypes = {
  SET_ROSTER: "SET_ROSTER",
};

export const ChatTypes = {
  SET_CHAT_VIEW: "SET_CHAT_VIEW",
  CLEAR: "CLEAR",
  SET_SUPPORT_CHANNEL: "SET_SUPPORT_CHANNEL",
};

// Modal
export const ModalTypes = {
  SET_FAN_CARD_MODAL: "SET_FAN_CARD_MODAL",
  SET_NFT_MODAL: "SET_NFT_MODAL",
};

export const LoginUrlTypes = {
  SET_LOGIN_URL: "SET_LOGIN_URL",
  CLEAR_LOGIN_URL: "CLEAR_LOGIN_URL",
};
