import { PicksLeagueFilters } from "../filters/PicksLeagueFilters";
import { PicksGameFilters } from "../filters/PicksGameFilters";
import { PicksPropKeyFilters } from "../filters/PicksPropKeyFilters";
import { BoardAvailableList } from "../lists/BoardAvailableList";
import { PicksRulesModal } from "../../modals/PicksRulesModal";
import { useSlipBuilder } from "common/src/context/picks/SlipBuilderContext";
import { PicksArrow } from "../utils/PicksArrow";
import { PicksSearch } from "../filters/PicksSearch";
import { useUser } from "common/src/hooks/useUser";

export function BoardAvailableComponent() {
  const { maxPayout } = useSlipBuilder();
  if (maxPayout < 0)
    return (
      <div className={"flex-1 flex-col flex-center"}>
        <PicksArrow className={"fill-primary bx-rotate-180 h-16 w-16"} />
        <span className={"font-xl weight-500 mt-5 mb-4 text-neutral-2"}>
          You're too late!
        </span>
        <span
          className={"font-md weight-300 text-neutral-2 text-center max-w-29"}
        >
          Phantasia Picks is in beta and is only offering a certain amount of
          daily picks. Come back tomorrow for more :)
        </span>
      </div>
    );
  return (
    <div className={"flex-col relative h-full overflow-auto"}>
      <PicksGameSummary />
      <PicksFilters />
      <BoardAvailableList />
    </div>
  );
}

function PicksFilters() {
  return (
    <>
      <PicksLeagueFilters />
      <PicksGameFilters />
      <PicksPropKeyFilters />
      <FreePlayBanner />
      <PicksSearch />
    </>
  );
}

function FreePlayBanner() {
  const user = useUser();

  if (!user.freePlays || user.freePlays <= 0) return null;

  return (
    <div
      className={
        "rounded-8 p-6 bg-base-3 flex-row items-start space-x-6 mx-8 mt-8"
      }
    >
      <div className={"bg-primary-1 rounded-full h-10 w-10 flex flex-center"}>
        <i className="bx bxs-gift text-neutral-2" />
      </div>

      <div className={"flex-col space-y-3 flex-1"}>
        <span className={"text-neutral-2 font-md weight-500 flex items-center"}>
          You have a free play!
        </span>

        <span className={"text-neutral-1 font-md weight-300"}>
          Choose 5 players for a chance to win ${user.freePlays * 18} or more
        </span>
      </div>
    </div>
  );
}

function PicksGameSummary() {
  return (
    <div
      className={"rounded-8 p-6 bg-base-2 flex-row items-start space-x-6 m-8"}
    >
      <i className="bx bx-chat text-neutral-2 bx-sm mt-3" />
      <div className={"flex-col space-y-3 flex-1"}>
        <div className={"flex-row flex-1 w-full"}>
          <span
            className={
              "text-neutral-2 font-md weight-500 flex flex-1 items-center"
            }
          >
            Welcome to Phantasia Picks
          </span>
          <PicksRulesModal />
        </div>
        <span className={"text-neutral-1 font-md weight-300"}>
          Pick 2-5 players and predict if they'll perform higher or lower than
          their projection. Make your picks, choose your entry amount, then cash
          out when you're correct!
        </span>
      </div>
    </div>
  );
}
