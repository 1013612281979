import { createContext, useContext } from "react";
import { Keypair, PublicKey, Transaction } from "@solana/web3.js";
import { logoutUser } from "./magic";
import { sign } from "tweetnacl";
import { Singletons } from "../Singletons";
import { ConnectionService } from "@phantasia/model-interfaces";

export const MOCK_WALLET = "mock";
export const COOKIE_NAME = "CRYPRESS_PRIVATE_KEY";

export function useMockWallet() {
  const publicKey = getPublicKey();
  const WalletContext = getMockWalletContext(publicKey);
  return useContext(WalletContext);
}

function getPublicKey(): PublicKey | null {
  return getKeypair()?.publicKey ?? null;
}

function getKeypair(): Keypair | null {
  try {
    return getKeypairFromCookie() ?? getKeypairFromRedux();
  } catch (e) {
    return null;
  }
}

function getKeypairFromRedux(): Keypair | null {
  const state = Singletons.store?.getState();
  if (!state) return null;

  const {
    User: { keypair },
  } = state;
  if (!keypair) return null;

  return getKeypairFromString(keypair);
}

function getKeypairFromCookie(): Keypair | null {
  try {
    if (!document) return null;

    const privKey = getCookie(COOKIE_NAME) as string;
    if (!privKey) return null;

    return getKeypairFromString(privKey);
  } catch (e) {
    return null;
  }
}

function getKeypairFromString(privKey: string): Keypair {
  const adminPrivKey: number[] = privKey.split(",").map((s) => parseInt(s));
  const adminPrivKeyUInt8: Uint8Array = new Uint8Array(adminPrivKey);
  return Keypair.fromSecretKey(adminPrivKeyUInt8, {
    skipValidation: true,
  });
}

// function setCookie(name: string, value: string, days: number) {
//   var expires = "";
//   if (days) {
//     var date = new Date();
//     date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
//     expires = "; expires=" + date.toUTCString();
//   }
//   document.cookie = name + "=" + (value || "") + expires + "; path=/";
// }

function getCookie(name: string) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    // eslint-disable-next-line eqeqeq
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    // eslint-disable-next-line eqeqeq
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

function eraseCookie(name: string) {
  if (!document) return;
  document.cookie = name + "=; Max-Age=-99999999;";
}

function getMockWalletContext(publicKey: PublicKey | null) {
  const WalletContextState = {
    name: MOCK_WALLET,
    url: "https://magic.link/",
    icon: "/MagicIcon.png",
    readyState: "Installed",
    publicKey,
    connecting: false,
    connected: true,

    wallets: [],
    autoConnect: true,
    disconnecting: false,
    wallet: {
      name: MOCK_WALLET,
      url: "https://magic.link/",
      icon: "/MagicIcon.png",
    },

    disconnect: () => {
      eraseCookie(COOKIE_NAME);
      logoutUser();
    },

    signTransaction: async (transaction: Transaction): Promise<Transaction> => {
      const privateKey = getKeypair();
      if (!privateKey) throw new Error("Unable to sign, no private key set");

      transaction.partialSign(privateKey);
      return Promise.resolve(transaction);
    },

    sendTransaction: async (transaction: Transaction): Promise<string> => {
      const privateKey = getKeypair();
      if (!privateKey) throw new Error("Unable to sign, no private key set");

      transaction.partialSign(privateKey);
      return await ConnectionService.getConnection().sendRawTransaction(
        transaction.serialize()
      );
    },

    signMessage: async (message: Uint8Array): Promise<Uint8Array> => {
      const privateKey = getKeypair()?.secretKey;
      if (!privateKey) throw new Error("Unable to sign, no private key set");
      return sign.detached(message, privateKey);
    },
  };

  return createContext(WalletContextState);
}
