import {
  Game,
  League,
  PlayerGamePerformance,
} from "@phantasia/model-interfaces";
import { getPlayerGameLog } from "../services/PlayerStatService";
import { getApi } from "./api";

export type GameLog = PlayerGamePerformance<string> & { game: Game<string> };

export async function getGameLog(playerId: string, league: League) {
  const response = await getPlayerGameLog(playerId, league);
  return response.data.gameLog as GameLog[];
}

export async function getPlayerProjectedValues(
  playerId: string,
  gameId: string
) {
  try {
    return (await getApi().get(`/api/picks/pick-values/${playerId}/${gameId}`))
      .data;
  } catch (e) {
    console.error(e);
  }
}
