import {
  PopulatedSlip,
  PopulatedSlipLeg,
} from "common/src/services/dtos/ContestDtos";
import moment from "moment/moment";
import {
  PickResult,
  PropBonusType,
  PropDecision,
} from "@phantasia/model-interfaces";
import { getPropKeyShortName } from "common/src/util/helpers";
import { VerticalDivider } from "../../atoms/Divider";
import { PicksArrow } from "../utils/PicksArrow";
import { SlipResultIndicator } from "../utils/SlipResultIndicator";
import phantasia from "../../../assets/LogoMain.png";
import { Tooltip } from "@mui/material";
import { slipIsRefundable } from "common/src/services/PicksService";
import { PicksBonusArrow } from "../utils/PicksBonusArrow";

export function SlipListItem({
  slip,
  selectedSlipId,
  setSelectedSlip,
}: {
  slip: PopulatedSlip;
  selectedSlipId: string;
  setSelectedSlip: (slip: PopulatedSlip | null) => void;
}) {
  const isActive = selectedSlipId === slip._id;

  const isRefundable = slipIsRefundable(slip);

  let slipInsured = slip.insurance_type === 2;
  if (slip.downgraded_insurance_type)
    slipInsured = slip.downgraded_insurance_type === 2;

  if (slip.downgrades && slip.legs.length - slip.downgrades <= 2)
    slipInsured = false;

  function TicketDivider() {
    if (isActive)
      return (
        <div className={"flex-row w-full items-center relative h-10 min-h-10 "}>
          <div
            className={
              "absolute-middle-vertical flex-row space-x-3 items-center t--5"
            }
          >
            <img src={phantasia} className="w-8 contain" alt="yahoo" />
            <span
              className={"font-xs weight-300 text-neutral-2 letter-spacing-lg"}
            >
              PHANTASIA
            </span>
          </div>
          <div
            className={
              "min-w-10 rounded-full h-10 absolute-middle-horizontal l--7 bg-base-1"
            }
          />
          <div className={"flex-row items-center space-x-6 w-full"}>
            {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(() => (
              <div className={"bg-base-3 h-1 flex-1"} />
            ))}
          </div>
          <div
            className={
              "min-w-10 rounded-full h-10 absolute-middle-horizontal r--7 bg-base-1"
            }
          />
        </div>
      );
    return (
      <div className={"flex-row w-full items-center relative h-10 min-h-10"}>
        <div
          className={
            "min-w-10 rounded-full h-10 absolute-middle-horizontal l--7 bg-base-1 ring-r-1 ring-base-3"
          }
        />
        <div className={"flex-row items-center space-x-6 w-full"}>
          {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(() => (
            <div className={"bg-base-3 h-1 flex-1"} />
          ))}
        </div>
        <div
          className={
            "min-w-10 rounded-full h-10 absolute-middle-horizontal r--7 bg-base-1 ring-l-1 ring-base-3"
          }
        />
      </div>
    );
  }

  return (
    <div
      onClick={() => setSelectedSlip(slip)}
      className={`${
        isActive ? "bg-base-2" : "outline-1 outline-base-3"
      } rounded-5 flex-col hover-up hover:bg-base-2 transition`}
    >
      <SlipResultIndicator slip={slip} />
      <div className={"flex-row p-7"}>
        <div className={"flex-col flex-1"}>
          <span className={"font-lg weight-500 text-neutral-2"}>
            {slip.legs.length}-Pick Entry
          </span>
          <span className={"font-md weight-400 text-neutral-2"}>
            {slip.entry_amount === 0 ? "🎁" : ""}${slip.entry_amount.toFixed(2)}{" "}
            play
          </span>
          <span className={"font-sm weight-300 text-neutral-1"}>
            Created {moment(slip.createdAt).format("ddd MMM DD h:mm a")}
          </span>
        </div>
        <div className={"flex-col items-end"}>
          <span
            className={`font-xl weight-600 ${
              slip.result === PickResult.LOSS
                ? "text-danger-1"
                : slip.result === PickResult.WIN
                ? "text-success-1"
                : slip.result === PickResult.DNP
                ? "text-neutral-1"
                : "text-primary-1"
            }`}
          >
            ${slip.payout.toFixed(2)}
          </span>
          <span className={"font-sm weight-300 text-neutral-1"}>
            Potential Payout
          </span>
        </div>
      </div>
      <TicketDivider />
      <div className={"w-full flex-row space-x-6 justify-center items-center"}>
        {slip.legs.map(({ result }, index) => (
          <PickResultIcon result={result} key={index} />
        ))}
      </div>
      <div className={"flex-col p-7 space-y-7"}>
        {(slip.legs as PopulatedSlipLeg[]).map((leg) => (
          <LiveLegSummary leg={leg} key={leg.prop._id} />
        ))}
      </div>
      {isRefundable ? (
        <div className={"flex-row items-center space-x-4 flex-center pb-5"}>
          <div
            className={`h-7 w-7 rounded-full bg-gradient-warning flex-center flex transition-long`}
          >
            <i className="bx bxs-info-circle white font-sm" />
          </div>
          <span className={"font-md weight-400 text-neutral-2"}>
            Refundable
          </span>
        </div>
      ) : null}
      <div className={"flex-1"} />
      {slipInsured ? (
        <div className={"flex-row items-center space-x-4 flex-center  pb-5"}>
          <div
            className={`h-7 w-7 rounded-full bg-gradient-primary flex-center flex transition-long`}
          >
            <i className="bx bxs-check-shield white font-sm" />
          </div>
          <span className={"font-md weight-400 text-neutral-2"}>Insured</span>
          <Tooltip
            title={
              "With insurance, get one pick wrong and receive your entry back in full"
            }
            placement="top"
          >
            <span>
              <i className="bx bx-info-circle text-neutral-1" />
            </span>
          </Tooltip>
        </div>
      ) : null}
    </div>
  );
}

export function PickResultIcon({
  result,
  returnNull,
}: {
  result: PickResult | undefined;
  returnNull?: boolean;
}) {
  if (returnNull && !result) return null;
  if (!result)
    return (
      <div className={"rounded-full w-6 flex flex-center h-6 bg-base-3"} />
    );

  if (result === PickResult.DNP)
    return (
      <div className={"rounded-full w-6 flex flex-center h-6 bg-base-4"}>
        <i className="bx bx-user-x white" />
      </div>
    );
  if (result === PickResult.LOSS)
    return (
      <div className={"rounded-full w-6 flex flex-center h-6 bg-danger-1"}>
        <i className="bx bx-x white" />
      </div>
    );
  if (result === PickResult.TIE)
    return (
      <div className={"rounded-full w-6 flex flex-center h-6 bg-primary-1"}>
        <i className="bx bx-reflect-vertical white font-sm" />
      </div>
    );

  return (
    <div className={"rounded-full w-6 flex flex-center h-6 bg-success-1"}>
      <i className="bx bx-check-double white" />
    </div>
  );
}

export function LiveLegSummary({ leg }: { leg: PopulatedSlipLeg }) {
  const { prop, result, prop_decision, bonus_type } = leg;
  const { player, prop_key } = prop;

  return (
    <div className={"flex-row items-center space-x-5"}>
      <div
        className={"w-9 h-9 rounded-full flex flex-center bg-base-4 relative"}
      >
        <img
          src={player.photo}
          alt={"player"}
          className={`contain h-full rounded-full`}
        />
        {bonus_type === PropBonusType.Bonus ? (
          <div
            className={
              "absolute flex flex-center r--3 b--3 rounded-full bg-base-3  w-6 h-6"
            }
          >
            <PicksBonusArrow fillColor={"#ecc440"} className={`w-4 h-4`} />
          </div>
        ) : null}
      </div>
      <div className={"flex-col flex-1 truncate"}>
        <div className={"flex-row items-center space-x-4"}>
          <span className={"font-md weight-500 text-neutral-2"}>
            {player.name}
          </span>
          <PickResultIcon result={result} returnNull />
        </div>

        <span
          className={"font-xs weight-300 text-neutral-1"}
        >{`${player.position}  •  ${player.team}`}</span>
      </div>
      <div className={"flex-col items-end"}>
        <span className={"font-lg weight-600 text-neutral-2"}>{leg.value}</span>
        <span className={"font-xs weight-300 text-neutral-1"}>
          {getPropKeyShortName(prop_key)}
        </span>
      </div>
      <VerticalDivider className={"h-9 max-w-1"} />
      <PicksDecisionIndicator
        decision={prop_decision}
        rotate={prop_decision === PropDecision.UNDER}
        result={result}
      />
    </div>
  );
}

export function PicksDecisionIndicator({
  decision,
  rotate,
  result,
}: {
  decision: string;
  rotate?: boolean;
  result?: PickResult;
}) {
  let fill = "fill-base-4";
  let color = "text-neutral-1";
  switch (result) {
    case PickResult.DNP:
      fill = "fill-base-4";
      color = "text-neutral-1";
      break;
    case PickResult.LOSS:
      fill = "fill-danger";
      color = "text-danger-1";
      break;
    case PickResult.WIN:
      fill = "fill-success";
      color = "text-success-1";
      break;
  }
  if (result) {
    return (
      <div className={`flex-col items-center w-10`}>
        <PicksArrow
          className={`w-6 h-6 ${fill} ${rotate ? "bx-rotate-180" : ""}`}
        />
        <span className={`font-xxs weight-300 ${color}`}>{decision}</span>
        <span className={`font-xs weight-400 ${color}`}>{result}</span>
      </div>
    );
  }
  return (
    <div className={`flex-col items-center w-10`}>
      <PicksArrow
        className={`w-8 h-8 ${fill} ${rotate ? "bx-rotate-180" : ""}`}
      />

      <span className={`font-xs weight-400 ${color}`}>{decision}</span>
    </div>
  );
}
