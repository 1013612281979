// @ts-ignore
import { isValidPhoneNumber } from "react-phone-number-input";
import React, { useContext, useState } from "react";
import { UserTypes } from "../../redux/actions/types";
import {
  getMagicUser,
  getToken,
  loginUserViaEmail,
  loginUserViaPhone,
} from "../../services/wallet/magic";
import { logTransactionError } from "../../redux/actions/TransactionAction";
import { useDispatch } from "react-redux";
import { Singletons } from "../../services/Singletons";
import {
  loginWallet,
  registerWallet,
  signMessage,
} from "../../services/wallet/LoginService";
import bs58 from "bs58";
import { useCommonWallet } from "../../services/wallet/UseCommonWallet";

/* Demo account used for Google & Apple review teams */
const DEMO_EMAIL = "demo@phantasia.app";
const DEMO_PHONE = "+13125551234";

export interface MagicContextProps {
  email: string;
  setEmail: (e: string) => void;
  phone: string;
  setPhone: (p: string) => void;
  loading: boolean;
  handleSubmit: (deviceId: string, useMagicLink?: boolean) => void;
}

export const MagicContext = React.createContext<MagicContextProps>({
  email: "",
  handleSubmit(deviceId: string, useMagicLink: boolean | undefined): void {},
  loading: false,
  phone: "",
  setEmail(e: string): void {},
  setPhone(p: string): void {},
});

export default function MagicContextProvider({
  children,
  onSuccess,
}: {
  children: JSX.Element;
  onSuccess: () => void;
}) {
  const [email, setEmail] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const wallet = useCommonWallet();
  const dispatch = useDispatch();

  function isDemoEmail() {
    return DEMO_EMAIL === email.toLowerCase();
  }

  function isDemoPhone() {
    return DEMO_PHONE === phone;
  }

  function validateEmail(email: string) {
    if (
      String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    )
      return true;
    else
      Singletons.toastService.error("Email invalid", "Email is incorrect...");

    return false;
  }

  function validatePhone(phone: string) {
    if (isValidPhoneNumber(phone)) return true;
    else
      Singletons.toastService.error(
        "Phone # invalid",
        "Phone # is incorrect..."
      );

    return false;
  }

  function handleDemoLogin() {
    const keypair =
      "239,80,76,75,246,24,7,240,138,147,194,174,244,234,247,101,5,5,100,171,25,32,8,90,229,169,131,150,175,204,28,155,80,77,86,135,153,188,102,245,151,101,121,170,11,177,73,246,133,215,80,184,33,208,65,104,17,88,148,164,76,196,211,80";

    dispatch({
      type: UserTypes.SET_KEYPAIR,
      payload: keypair,
    });

    signMessage(wallet).then(async (signedMessage) => {
      loginWallet(
        wallet.publicKey?.toString(),
        bs58.encode(signedMessage),
        dispatch,
        undefined
      )
        .then(() => {
          try {
            onSuccess();
          } catch (e) {
            console.error(e);
          }
        })
        .catch((e) => console.error(e));
    });
  }

  async function getMagicUserData() {
    try {
      let didTokenPromise = getToken(); // Get ID token with lifespan of 7 days (maximum)
      let userPromise = getMagicUser(); // Get user data from magic
      const promises = [didTokenPromise, userPromise];
      return await Promise.all(promises);
    } catch (e) {
      console.error(e);
      logTransactionError(e).catch(() => {});
      return [];
    }
  }

  async function handleSubmit(deviceId: string, useMagicLink?: boolean) {
    try {
      setLoading(true);

      if (isDemoEmail() || isDemoPhone()) {
        handleDemoLogin();
        setLoading(false);
        return;
      }

      if (email && validateEmail(email)) {
        await loginUserViaEmail(email, useMagicLink); // loginWithMagicLink(email)
      } else if (phone && validatePhone(phone)) {
        await loginUserViaPhone(phone); // loginWithSMS(phone)
      } else {
        throw new Error("Email/phone invalid");
      }

      const [didToken, user] = await getMagicUserData();
      if (!didToken || !user || !user.publicAddress)
        throw new Error("Login Failed");

      await handleAuth(user, didToken, deviceId);

      setLoading(false);
      onSuccess();
    } catch (e) {
      handleError(e);
      setLoading(false);
    }
  }

  async function handleAuth(user: any, didToken: string, deviceId: string) {
    try {
      await loginWallet(user.publicAddress, didToken, dispatch, deviceId);
    } catch (e) {
      // @ts-ignore
      if (e?.response?.status === 404) {
        await registerWallet(
          user.publicAddress,
          didToken,
          dispatch,
          deviceId,
          user.issuer,
          email
        );
      } else handleError(e);
    }
  }

  function handleError(e: unknown) {
    console.error(e);
    // @ts-ignore
    Singletons.toastService.error("Error", e.message ?? "Error");
  }

  return (
    <MagicContext.Provider
      value={{
        email,
        setEmail,
        setPhone,
        phone,
        loading,
        handleSubmit,
      }}
    >
      {children}
    </MagicContext.Provider>
  );
}

export const useMagicContext = () => useContext(MagicContext);
