import React from "react";
import { useUser } from "common/src/hooks/useUser";
import { NotificationListItem } from "./NotificationListItem";

export function NotificationsList() {
  return (
    <>
      <div className="flex-col w-full p-5 space-y-5">
        <EmptyState />
        <List />
      </div>
    </>
  );
}

function EmptyState() {
  const user = useUser();
  if (!user.notifications || user.notifications.length === 0) {
    return (
      <div className="flex-col items-center  outline-1 p-8 w-full flex flex-center rounded-5">
        <i className="bx bx-bell-off text-neutral-2" />
        <span className="text-neutral-2 font-md weight-400">
          You're up to date!
        </span>
      </div>
    );
  }
  return null;
}

function List() {
  const user = useUser();
  if (!user.notifications) return null;

  return user.notifications.map((notification, index) => {
    return <NotificationListItem notification={notification} key={index} />;
  });
}
