import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useUser } from "../hooks/useUser";
import { updateUserEmailSetting } from "../services/AuthService";
import { US_COUNTRY_CODE } from "../services/GeolocationService";
import {
  KycData,
  KycFormErrors,
  KycModalState,
  submitKycForm,
} from "../services/KycService";
import { Singletons } from "../services/Singletons";
import { US_STATES } from "../util/constants";

const initialState: KycData = {
  firstName: "",
  surName: "",
  city: "",
  physicalAddress: "",
  state: US_STATES[0].value,
  country: US_COUNTRY_CODE,
  zip: "",
  dob: "",
  ssn: "",
  email: "",
  phone: "",
};

export interface KycContextProps {
  formState: KycData;
  setFormState: (formstate: KycData) => void;
  formError: string | null;
  setFormError: (error: string | null) => void;
  modalState: string;
  setModalState: (modalState: string) => void;
  submit: () => Promise<void>;
  emailOptOut: boolean;
  setEmailOptOut: (choice: boolean) => void;
  failedCount: number;
  setFailedCount: (count: number) => void;
  useSsn: boolean;
  inquiryUrl: string;
}

export const KycContext = React.createContext<KycContextProps>({
  formState: initialState,
  setFormState: () => {},
  formError: null,
  setFormError: () => {},
  modalState: KycModalState.CHECKING_LOCATION,
  setModalState: () => {},
  submit: () => Promise.resolve(),
  emailOptOut: false,
  setEmailOptOut: () => {},
  failedCount: 0,
  setFailedCount: () => {},
  useSsn: false,
  inquiryUrl: "",
});

export function KycContextProvider({
  children,
  onSuccess,
  useSsn = false,
}: {
  children: JSX.Element;
  onSuccess: () => void;
  useSsn?: boolean;
}) {
  const dispatch = useDispatch();
  const user = useUser();

  const [formState, setFormState] = useState<KycData>(initialState);
  const [formError, setFormError] = useState<string | null>(null);
  const [modalState, setModalState] = useState<string>(
    KycModalState.CHECKING_LOCATION
  );
  const [emailOptOut, setEmailOptOut] = useState<boolean>(false);
  const [failedCount, setFailedCount] = useState<number>(0);
  const [inquiryUrl, setInquiryUrl] = useState<string>("");

  useEffect(() => {
    if (user.email && !formState.email) {
      setFormState({
        ...formState,
        email: user.email,
      });
    }
  }, [formState, user.email]);

  useEffect(() => {
    if (user.phone && !formState.phone) {
      setFormState({
        ...formState,
        phone: user.phone,
      });
    }
  }, [formState, user.phone]);

  useEffect(() => {
    if (failedCount > 1 && !useSsn) {
      setModalState(KycModalState.DOCV);
    }
  }, [failedCount, useSsn]);

  async function submit() {
    if (formState.email && !emailOptOut) {
      updateUserEmailSetting(formState.email, user, dispatch);
    }
    if (!user.email && !formState.email && !emailOptOut) {
      Singletons.toastService.error(
        "Email Error",
        "Enter email or choose to opt out"
      );
      setFormError(KycFormErrors.EMAIL);
      throw Error("Email required - or opt out");
    }
    try {
      await submitKycForm(
        formState,
        dispatch,
        setModalState,
        () => {
          if (onSuccess) {
            onSuccess();
          }
          Singletons.toastService.success("Success", "You are verified!");
        },
        setFormError,
        () => setFailedCount(failedCount + 1),
        Boolean(useSsn),
        setInquiryUrl
      );
    } catch (e) {
      throw Error("Something happened...");
    }
  }

  return (
    <KycContext.Provider
      value={{
        formState,
        setFormState,
        formError,
        setFormError,
        modalState,
        setModalState,
        submit,
        emailOptOut,
        setEmailOptOut,
        failedCount,
        setFailedCount,
        useSsn,
        inquiryUrl,
      }}
    >
      {children}
    </KycContext.Provider>
  );
}
