import { usePicksBoard } from "common/src/context/picks/PicksBoardContext";
import { GradientButton2 } from "../../atoms/Button";
import { Loader } from "../../molecules/Loader";
import { BoardAvailableListItem } from "../list-items/BoardAvailableListItem";
import { useSlipBuilder } from "common/src/context/picks/SlipBuilderContext";
import { useCallback } from "react";
import { PopulatedPickProp } from "common/src/services/dtos/ContestDtos";
import {
  calculateIsMlbCorrelation,
  calculateIsNflCorrelation,
} from "common/src/services/PicksService";

export function BoardAvailableList() {
  const { filteredPicks, setSelectedGame, setSelectedPropKey, propKeyFilters } =
    usePicksBoard();
  const { currentSlip } = useSlipBuilder();

  const pickDisabled = useCallback(
    (pickProp: PopulatedPickProp) => {
      return (
        calculateIsMlbCorrelation(currentSlip, pickProp) ||
        calculateIsNflCorrelation(currentSlip, pickProp)
      );
    },
    [currentSlip]
  );

  function EmptyState() {
    return (
      <div
        className={"bg-base-2 rounded-8 p-8 mx-12 mt-8 flex-col flex-center"}
      >
        <i className="bx bx-md text-neutral-2 bx-list-minus" />
        <span className={"font-md weight-500 text-neutral-2"}>
          No picks found...
        </span>
        <span className={"text-neutral-1 weight-300 font-md mb-6"}>
          Try different filters, or check back later
        </span>
        <GradientButton2
          onClick={() => {
            setSelectedGame(null);
            setSelectedPropKey(propKeyFilters[0]);
          }}
          className={undefined}
          textStyles={undefined}
          contentRight={undefined}
          contentLeft={undefined}
          disabled={undefined}
          submit={undefined}
          color={undefined}
        >
          Reset Filters
        </GradientButton2>
      </div>
    );
  }

  if (!filteredPicks) return <Loader />;

  if (filteredPicks.length === 0) return <EmptyState />;

  return (
    <div className={"flex-col"}>
      <div className={"mt-8 px-8 pb-12"}>
        {filteredPicks.map((pick) => (
          <BoardAvailableListItem
            pickProp={pick}
            key={pick._id}
            pickDisabled={pickDisabled}
          />
        ))}
      </div>
    </div>
  );
}
