import { useSlipBuilder } from "common/src/context/picks/SlipBuilderContext";
import { HorizontalDividerInDiv } from "../../atoms/Divider";
import { BoardMyPicksListItem } from "../list-items/BoardMyPicksListItem";

export function BoardMyPicksList() {
  const { currentSlip } = useSlipBuilder();

  return (
    <div className={"flex-1 overflow-auto"}>
      {currentSlip.map((leg, index) => (
        <div className={"flex-col"} key={leg.prop._id}>
          {index !== 0 ? (
            <HorizontalDividerInDiv className={"pl-12 ml-3"} />
          ) : null}
          <BoardMyPicksListItem leg={leg} />
        </div>
      ))}
    </div>
  );
}
