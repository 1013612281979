import React from "react";
import { useUser } from "common/src/hooks/useUser";

export function NotificationsHeader() {
  return (
    <header className="bg-base-1 flex-row p-6 rounded-top-8">
      <div className="flex-row items-center space-x-5 w-full">
        <span className="font-xl weight-400 text-neutral-2 flex-1">
          Notifications
        </span>
        <UnreadText />
      </div>
    </header>
  );
}
function UnreadText() {
  const user = useUser();
  if (!user.notifications) return null;

  return (
    <span className="font-md weight-300 text-neutral-2">
      {user.notifications.filter((n) => !n.read).length} unread
    </span>
  );
}
