import { Dispatch } from "redux";
import { UserTypes } from "../../redux/actions/types";
import { getApi } from "../../util/api";
import { CommonWallet } from "./UseCommonWallet";
// @ts-ignore
import randomUsernameGenerator from "random-username-generator";

const { LOGIN_SUCCESS, REGISTER_SUCCESS } = UserTypes;

export const RAW_MESSAGE = new TextEncoder().encode("Welcome to Phantasia!");

export async function signMessage(wallet: CommonWallet): Promise<Uint8Array> {
  return await wallet.signMessage(RAW_MESSAGE);
}

export async function loginWallet(
  wallet: string,
  signedMessage: string,
  dispatch: Dispatch,
  deviceId: string | undefined
) {
  const res = await (
    await getApi()
  ).post("/api/auth/login", {
    walletPubkey: wallet,
    signedMessage: signedMessage,
    deviceId,
  });

  dispatch({
    type: LOGIN_SUCCESS,
    payload: {
      ...res.data,
      authToken: signedMessage,
    },
  });
}

export async function registerWallet(
  wallet: string,
  signedMessageStr: string,
  dispatch: Dispatch,
  deviceId: string | undefined,
  magicIssuer: string | null,
  email: string | null
) {
  const data = {
    walletPubkey: wallet,
    signedMessage: signedMessageStr,
    username: randomUsernameGenerator.generate(),
    deviceId,
    magicIssuer,
    email,
  };

  const res = await getApi().post("/api/auth/beta", data);

  dispatch({
    type: REGISTER_SUCCESS,
    payload: {
      ...res.data,
      authToken: signedMessageStr,
    },
  });
}
