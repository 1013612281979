import "../../sass/Molecules.scss";
import { PLAYER_INJURIES } from "common/src/util/constants";
import { Player } from "@phantasia/model-interfaces";

export function getInjuryGradient(injury: string) {
  switch (injury) {
    case PLAYER_INJURIES.OUT:
      return "danger";
    case PLAYER_INJURIES.DOUBTFUL:
      return "danger";
    case PLAYER_INJURIES.QUESTIONABLE:
      return "warning";
    case PLAYER_INJURIES.PROBABLE:
      return "success";
    default:
      return "";
  }
}

export default function PlayerAvatar({
  player,
  size = 10,
}: {
  player: Player<string>;
  size?: number;
}) {
  if (!player) return null;
  const sizeString = `w-${size} h-${size} min-h-${size} min-w-${size}`;
  let borderStyles = "";

  return (
    <div
      className={`relative bg-base-4 ${borderStyles} rounded-full ${sizeString}`}
    >
      {player.injury_status &&
        player.injury_status.toString() !== "Healthy" &&
        size > 8 && (
          <div
            className={`absolute bg-gradient-${getInjuryGradient(
              player.injury_status.toString()
            )} t--3 r--3 rounded-full w-6 h-6 flex flex-center ring-2 ring-base-1`}
          >
            <span className="font-xs weight-500 white">
              {player.injury_status.toString().charAt(0)}
            </span>
          </div>
        )}

      <div className={`w-full h-full rounded-full overflow-hidden relative`}>
        <img
          src={player.photo}
          alt={player.name}
          className={`contain  ${sizeString}`}
        />
      </div>
    </div>
  );
}
