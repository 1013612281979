import { Loader } from "../../molecules/Loader";
import moment from "moment/moment";
import {
  getPicksMultiplier,
  populateSlipWithPerformanceData,
  refundSlip,
  slipIsRefundable,
} from "common/src/services/PicksService";
import {
  PopulatedPickProp,
  PopulatedSlip,
  SlipWithPerformanceAndProjection,
} from "common/src/services/dtos/ContestDtos";
import { useEffect, useState } from "react";
import { SlipPlayerList } from "../lists/SlipPlayerList";
import { HorizontalDividerInDiv } from "../../atoms/Divider";
import { useUser } from "common/src/hooks/useUser";
import { TelegramButton, TwitterButton } from "../../atoms/SocialButtons";
import { copyLink, getFirebaseLink } from "common/src/util/copyLink";
import { toastSuccess } from "../../toast/ToastService";
import { PickResultIcon } from "../list-items/SlipListItem";
import { PickResult } from "@phantasia/model-interfaces";
import { PicksRulesModal } from "../../modals/PicksRulesModal";
import phantasia from "../../../assets/LogoMain.png";
import { GradientButton1 } from "../../atoms/Button";
import { Singletons } from "common/src/services/Singletons";
import { loadBalance } from "common/src/redux/actions/WalletAction";
import { useCommonWallet } from "common/src/services/wallet/UseCommonWallet";
import { useDispatch } from "react-redux";

export function SlipPerformanceTracker({
  selectedSlip,
  slips,
  refresh,
}: {
  selectedSlip: PopulatedSlip | null;
  slips: PopulatedSlip[] | null;
  refresh: () => void;
}) {
  const user = useUser();
  const [populatedSlip, setPopulatedSlip] =
    useState<SlipWithPerformanceAndProjection | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setLoading(true);
    if (selectedSlip) {
      populateSlipWithPerformanceData(selectedSlip._id).then((res) => {
        console.log(res);
        setPopulatedSlip(res.data.slip);
        setLoading(false);
      });
    }
  }, [selectedSlip]);

  if (!selectedSlip && !slips) return <Loader />;

  if (slips && slips.length === 0) {
    return (
      <div className={"outline-1 rounded-8 p-8 mt-8 flex-col flex-center mx-8"}>
        <div className={"flex-row items-center space-x-6 mb-6"}>
          <div
            className={"rounded-full w-10 flex flex-center h-10 bg-warning-1"}
          >
            <i className="bx bx-user-x white" />
          </div>
          <div
            className={"rounded-full w-10 flex flex-center h-10 bg-danger-1"}
          >
            <i className="bx bx-x white" />
          </div>
          <div
            className={"rounded-full w-10 flex flex-center h-10 bg-success-1"}
          >
            <i className="bx bx-check-double white" />
          </div>
        </div>
        <span className={"font-md weight-500 text-neutral-2"}>
          View player performance here
        </span>
        <span className={"text-neutral-1 weight-300 font-md mb-6 text-center"}>
          When you have a live entry, view your player's performance against
          their projection in this window
        </span>
      </div>
    );
  }

  if (!selectedSlip || !slips) return <Loader />;

  const copy = () => {
    copyLink(getFirebaseLink("https://phantasia.app/picks"), true).then(() =>
      toastSuccess("Success", "Link copied")
    );
  };

  return (
    <div
      key={selectedSlip?._id ?? 1}
      className={"flex-col animation-from-bottom h-full relative"}
    >
      <div className={"flex-1 overflow-auto pt-8 px-8"}>
        <div className={"flex-row bg-base-1 sticky t-0 z-10 ring-b-1 pb-5"}>
          <div className={"flex-row pb-5 w-full relative"}>
            <div
              className={
                "absolute-middle-vertical flex-row space-x-3 items-center"
              }
            >
              <img src={phantasia} className="w-8 contain" alt="yahoo" />
              <span
                className={
                  "font-xs weight-300 text-neutral-2 letter-spacing-lg"
                }
              >
                PHANTASIA
              </span>
            </div>
            <div className={"flex-col flex-1"}>
              <span className={"font-lg weight-500 text-neutral-2"}>
                {selectedSlip.legs.length}-Pick Entry
              </span>
              <span className={"font-sm weight-300 text-neutral-1"}>
                Created{" "}
                {moment(selectedSlip.createdAt).format("ddd MMM DD h:mm a")}
              </span>
            </div>
            <div className={"flex-col items-end"}>
              <span
                className={
                  "font-md weight-500 text-gradient-light letter-spacing-md"
                }
              >
                {user.code}
              </span>
              <span className={"font-sm weight-300 text-neutral-1"}>
                referral code
              </span>
            </div>
            <div
              className={
                "w-full flex-row space-x-6 absolute b--7 justify-center items-center"
              }
            >
              {selectedSlip.legs.map(({ result }, index) => (
                <PickResultIcon result={result} key={index} />
              ))}
            </div>
          </div>
        </div>

        {!populatedSlip || loading ? (
          <SlipPlayerList slip={selectedSlip} loading={true} />
        ) : (
          <SlipPlayerList slip={populatedSlip} loading={false} />
        )}
      </div>
      <div className={"flex-col px-8 pb-8"}>
        <HorizontalDividerInDiv className={"mb-8"} />
        <RefundBanner slip={selectedSlip} refresh={refresh} />
        <div className={"flex-row space-x-8 mb-5"}>
          <div className={"flex-col outline-1 p-8 rounded-8 flex-1"}>
            <span className={"font-sm text-neutral-1 weight-300"}>Entry</span>
            <span className={"font-xl weight-500 text-neutral-2"}>
              {selectedSlip.entry_amount === 0 ? "🎁" : ""}$
              {selectedSlip.entry_amount.toFixed(2)}
            </span>
          </div>
          <div className={"flex-col bg-base-2 p-8 rounded-8 flex-1"}>
            <span className={"font-sm text-neutral-1 weight-300"}>
              Payout (
              {getPicksMultiplier(
                !selectedSlip ? [] : selectedSlip.legs,
                Boolean(selectedSlip && selectedSlip.insurance_type === 2),
                selectedSlip.boost === 1
              )}
              x)
            </span>
            <span
              className={`font-xl weight-500 ${
                selectedSlip.result === PickResult.LOSS
                  ? "text-danger-1"
                  : selectedSlip.result === PickResult.WIN
                  ? "text-success-1"
                  : selectedSlip.result === PickResult.DNP
                  ? "text-neutral-1"
                  : "text-neutral-2"
              }`}
            >
              ${selectedSlip.payout.toFixed(2)}
            </span>
          </div>
        </div>

        <div className={"flex-row space-x-8"}>
          <div
            className={
              "flex-row flex-center space-x-4 flex-1 bg-base-0 rounded-6 px-4 py-3"
            }
          >
            <span className={"font-sm weight-300 text-neutral-1"}>
              Pick {selectedSlip.legs.length} / {selectedSlip.legs.length} for
            </span>
            <span className={"font-md weight-600 text-primary-1 flex-1"}>
              {getPicksMultiplier(
                selectedSlip.legs,
                Boolean(selectedSlip && selectedSlip.insurance_type === 2),
                selectedSlip.boost === 1
              )}
              x
            </span>
            <PicksRulesModal />
          </div>
          <div
            className={
              "flex-row flex-center space-x-4 flex-1 bg-base-0 rounded-6 px-4 py-3"
            }
          >
            <span className={"font-sm weight-300 text-neutral-1 flex-1"}>
              Share my picks
            </span>
            <TwitterButton
              small={true}
              hideText={true}
              url={getFirebaseLink(`https://phantasia.app/picks`)}
              title={`Just made my picks on Phantasia! Sign up with my code ${user.code} for exclusive, invite-only access to the Picks game with the highest payouts!`}
            />
            <TelegramButton
              hideText={true}
              small={true}
              url={getFirebaseLink(`https://phantasia.app/picks`)}
              title={`Just made my picks on Phantasia! Sign up with my code ${user.code} for exclusive, invite-only access to the Picks game with the highest payouts!`}
            />
            <div
              onClick={copy}
              className={
                "flex hover:bg-base-2 flex-center cursor-pointer h-9 min-w-9 rounded-5 flex-center transition"
              }
            >
              <i className={"bx bx-copy-alt text-neutral-2"} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function RefundBanner({
  slip,
  refresh,
}: {
  slip: PopulatedSlip;
  refresh: () => void;
}) {
  const wallet = useCommonWallet();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState<boolean>(false);

  if (!slip) return null;

  const refundableLeg = slipIsRefundable(slip);

  if (!refundableLeg) return null;

  const { name } = (refundableLeg.prop as PopulatedPickProp).player;

  async function executeRefund() {
    setLoading(true);
    try {
      await refundSlip(slip._id);
      refresh();
      setLoading(false);
      Singletons.toastService.success(
        "Refunded!",
        `$${slip.entry_amount} will hit your account soon`
      );
      setTimeout(() => {
        loadBalance(wallet.publicKey, dispatch);
      }, 10000);
    } catch (e) {
      Singletons.toastService.error("Error", "Something went wrong...");
      setLoading(false);
    }
  }

  return (
    <div className={"rounded-5 p-5 bg-warning-0 flex-row items-center mb-5"}>
      <span
        className={"flex-1 text-warning-2 font-sm weight-400 pl-5"}
      >{`${name} is not active & this slip will be voided`}</span>
      <GradientButton1
        color={"warning"}
        onClick={executeRefund}
        className={"w-15"}
        textStyles={undefined}
        contentRight={undefined}
        contentLeft={undefined}
        disabled={undefined}
        submit={undefined}
      >
        {loading ? <i className={"bx bx-loader white"} /> : "Refund Slip"}
      </GradientButton1>
    </div>
  );
}
