// @ts-ignore
import { useWallet } from "@solana/wallet-adapter-react";
import { useMagicWallet } from "./magic";
import { PublicKey, Transaction } from "@solana/web3.js";
import { useMockWallet } from "./mockWallet";
import { PhantomContext } from "../../context/auth/PhantomContext";
import { useContext } from "react";
import { getApi } from "../../util/api";
import bs58 from "bs58";
import { useSolanaNet } from "../../hooks/useConnection";
import axios from "axios";
import { getSignedTransaction } from "../../redux/actions/TransactionAction";
import { useDispatch } from "react-redux";
import { TransactionActionTypes } from "../../redux/reducers/Transaction";
import { SolanaNet } from "@phantasia/model-interfaces";

export function useCommonWallet(): CommonWallet {
  const phantomWallet = useContext(PhantomContext);
  const mockWallet = useMockWallet();
  const magicWallet = useMagicWallet();
  const regularWallet = useWallet();
  const net = useSolanaNet();
  const dispatch = useDispatch();

  let wallet: CommonWallet;
  if (phantomWallet.publicKey) wallet = phantomWallet as CommonWallet;
  else if (mockWallet.publicKey) wallet = mockWallet as CommonWallet;
  else if (magicWallet.publicKey) {
    wallet = magicWallet as CommonWallet;
  } else {
    wallet = regularWallet;
    wallet.name = "Solana";
  }

  wallet.sendTransaction = async (
    transaction: Transaction
  ): Promise<string> => {
    try {
      const signedTransaction = await getSignedTransaction(transaction, wallet);

      getApi()
        .post("/api/transaction/alert", {
          wallet: wallet.publicKey.toString(),
          msg: `TRANSACTION SIGNED!! ${signedTransaction.signature?.toString()}`,
        })
        .then();
      let buff: Buffer;
      if (Buffer.isBuffer(signedTransaction)) {
        buff = signedTransaction;
      } else {
        buff = signedTransaction.serialize({
          requireAllSignatures: true,
          verifySignatures: true,
        });
      }
      const res = await getApi().post("/api/transaction/send", {
        tx: bs58.encode(buff),
      });

      if (!res) throw new Error("No response in Send Tx");

      const { data } = res;

      if (!data) throw new Error("no data in Send Tx");

      const { jobId, signature } = data;

      for (let i = 0; i < 40; i++) {
        await new Promise((resolve) => setTimeout(resolve, 2000));
        const {
          data: { status },
        } = await getApi().get(`/api/transaction/status/${jobId}`);

        if (status === "completed") break;

        if (status === "failed")
          throw new Error("Unable to confirm transaction");
      }

      return signature;
    } catch (e) {
      console.error(e);
      // @ts-ignore
      const message = e.message;
      const message1 = JSON.stringify(e);

      dispatch({
        type: TransactionActionTypes.FAILURE,
        payload: {
          transactionRetryFunc: () => {},
          onSuccess: () => {},
          errorLog: message + message1,
          type: "failure",
        },
      });

      if (net === SolanaNet.MAINNET)
        axios
          .post("https://api.coinflow.cash/admin/notify", {
            eventName: "Phantasia Error Message",
            errorMessage: message + message1,
            merchant: "phantasia",
            user: wallet.publicKey.toString(),
          })
          .catch(console.error);

      throw e;
    }
  };

  return wallet;
}

export interface CommonWallet {
  name: string;
  url: string;
  icon: string;
  readyState: string;
  publicKey: PublicKey;
  connecting: boolean;
  connected: boolean;

  wallets: string[];
  autoConnect: boolean;
  disconnecting: boolean;
  wallet: {
    name: string;
    url: string;
    icon: string;
  };

  connect?: () => Promise<void>;
  disconnect: () => void;
  sendTransaction: (transaction: Transaction) => Promise<string>;
  signTransaction: (transaction: Transaction) => Promise<Transaction | Buffer>;
  signMessage: (message: Uint8Array) => Promise<Uint8Array>;
}
