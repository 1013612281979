import React, { useEffect } from "react";
import ReactGA from "react-ga";
import { Route, Switch, useLocation } from "react-router-dom";
import PrivateRoute from "./components/route/PrivateRoute";
import PublicRoute from "./components/route/PublicRoute";
import LandingPage from "./pages/LandingPage";
import Login from "./pages/Login";
import { NotFound } from "./pages/NotFound";
import StakingPage from "./pages/StakingPage";
import WalletPage from "./pages/WalletPage";
import PaymentHistoryPage from "./pages/PaymentHistoryPage";
import LoginSuccessPage from "./pages/LoginSuccessPage";
import UserSettingsPage from "./pages/UserSettingsPage";
import WithdrawPage from "./pages/WithdrawPage";
import { WithdrawHistoryPage } from "./pages/WithdrawHistoryPage";
import DailySpinPage from "./pages/DailySpin";
import { PicksPage } from "./pages/Picks/PicksPage";
import { PicksAdminDashboardPage } from "./pages/Picks/PicksAdminDashboardPage";

export const ROUTES = {
  LANDING: "/",
  LOGIN: "/login",
  WITHDRAW: "/withdraw",
  LOGIN_SUCCESS: "/login-success",
  WALLET: "/wallet-view",
  STAKING: "/staking",
  PAYMENT_HISTORY: "/payment-history",
  WITHDRAW_HISTORY: "/withdraw-history",
  SETTINGS: "/settings",
  PICKS_DASHBOARD: "/picks-admin-dashboard",
  DAILY_SPIN: "/daily-spin",
  PICKS: "/picks",
  PICKS_BOARD: "/picks-board",
  PICKS_LIVE: "/picks-live",
  PICKS_RESULTS: "/picks-results",
};

function Routes() {
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS);
  const location = useLocation();

  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  return (
    <Switch>
      <Route exact path={ROUTES.LANDING} component={LandingPage} />
      <PublicRoute
        restricted={true}
        exact
        path={ROUTES.LOGIN}
        component={Login}
      />

      <PrivateRoute exact path={ROUTES.WITHDRAW} component={WithdrawPage} />

      <PrivateRoute
        exact
        path={ROUTES.LOGIN_SUCCESS}
        component={LoginSuccessPage}
      />

      <PrivateRoute exact path={ROUTES.WALLET} component={WalletPage} />

      <PrivateRoute exact path={ROUTES.PICKS_BOARD} component={PicksPage} />
      <PrivateRoute exact path={ROUTES.PICKS} component={PicksPage} />
      <PrivateRoute exact path={ROUTES.PICKS_LIVE} component={PicksPage} />
      <PrivateRoute exact path={ROUTES.PICKS_RESULTS} component={PicksPage} />

      <PrivateRoute exact path={ROUTES.STAKING} component={StakingPage} />

      <Route
        exact
        path={ROUTES.PAYMENT_HISTORY}
        component={PaymentHistoryPage}
      />
      <Route
        exact
        path={ROUTES.WITHDRAW_HISTORY}
        component={WithdrawHistoryPage}
      />

      <PrivateRoute exact path={ROUTES.SETTINGS} component={UserSettingsPage} />

      <PrivateRoute
        exact
        path={ROUTES.PICKS_DASHBOARD}
        component={PicksAdminDashboardPage}
      />

      <PrivateRoute exact path={ROUTES.DAILY_SPIN} component={DailySpinPage} />

      <Route component={NotFound} />
    </Switch>
  );
}

export default Routes;
