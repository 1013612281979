import { GradientButton3 } from "../../components/atoms/Button";
import { PhantasiaTestimonials } from "./LandingSection";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../../Routes";

export function QrCodeSection() {
  const history = useHistory();
  return (
    <div className="w-full  flex-col flex-center relative z-11 py-13 bg-base-1">
      <h1
        style={{ fontSize: "calc(20px + 1.1vw)" }}
        className="weight-500 text-neutral-2 flex-row no-wrap  mt-11 mb-12"
      >
        Users{" "}
        <span className="text-gradient-4 text-gradient-phantasia mx-5">
          Can't Get Enough
        </span>{" "}
      </h1>
      <PhantasiaTestimonials />
      <GradientButton3
        onClick={() => history.push(ROUTES.PICKS)}
        className={"py-9 px-12 rounded-full"}
      >
        Play Fantasy Now
      </GradientButton3>
    </div>
  );
}
