import { Loader } from "../../molecules/Loader";
import { SlipListItem } from "../list-items/SlipListItem";
import { PopulatedSlip } from "common/src/services/dtos/ContestDtos";
import { GradientButton2 } from "../../atoms/Button";
import { PicksArrow } from "../utils/PicksArrow";

export function SlipList({
  slips,
  selectedSlipId,
  setSelectedSlip,
  showUser,
}: {
  slips: PopulatedSlip[] | null;
  selectedSlipId: string;
  setSelectedSlip: (slip: PopulatedSlip | null) => void;
  showUser?: boolean;
}) {
  if (!slips) return <Loader />;

  if (slips.length === 0) {
    return (
      <div className={"outline-1 rounded-8 p-8 mt-8 flex-col flex-center"}>
        <div className={"flex-row items-center mb-6"}>
          <PicksArrow className={"fill-base-4 h-10 w-10"} />
          <PicksArrow className={"fill-base-4 bx-rotate-180 h-10 w-10"} />
        </div>
        <span className={"font-md weight-500 text-neutral-2"}>
          No live entries
        </span>
        <span className={"text-neutral-1 weight-300 font-md mb-6"}>
          View the board to make some new picks!
        </span>
        <GradientButton2
          onClick={() => {}}
          className={undefined}
          textStyles={undefined}
          contentRight={undefined}
          contentLeft={undefined}
          disabled={undefined}
          submit={undefined}
          color={undefined}
        >
          View the board
        </GradientButton2>
      </div>
    );
  }

  return (
    <div className={"flex-1 space-y-8"}>
      {slips.map((slip) => (
        <div key={slip._id} className={"flex-col"}>
          {showUser ? (
            <span className={"text-neutral-1 weight-300 font-md mb-5"}>
              ObjectId("{slip.user}")
            </span>
          ) : null}
          <SlipListItem
            selectedSlipId={selectedSlipId}
            setSelectedSlip={setSelectedSlip}
            slip={slip}
          />
        </div>
      ))}
    </div>
  );
}
