import { Modal } from "@mui/material";
import {
  checkBrowserGeolocationAccess,
  determineIfRegionIsOnWhitelist,
  GeoPermissionStates,
  getCountryAndState,
  isExpansionRegion,
} from "common/src/services/GeolocationService";
import { KycModalState } from "common/src/services/KycService";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { AllowLocationAccess, CheckingLocation } from "./CheckingLocation";
import { DocV } from "./DocV";
import { KycForm } from "./KycForm";
import { LocationAccessDenied } from "./LocationAccessDenied";
import { RegionRestricted } from "./RegionRestricted";
import { KycContext } from "common/src/context/KycContext";
import { GradientButton3, TextButton3 } from "../../atoms/Button";
import { triggerEventKycSecondChance } from "../../../GoogleAnalytics";
import { HorizontalDivider } from "../../atoms/Divider";

export function KycModal({ open, onClose, message, onCatchExpansion }) {
  const { setModalState, modalState, useSsn } = useContext(KycContext);

  const [showSecondChance, setShowSecondChance] = useState(false);

  function handleClose() {
    if (
      modalState === KycModalState.DOCV ||
      modalState === KycModalState.REGION_RESTRICTED
    ) {
      onClose();
    } else {
      setShowSecondChance(true);
      onClose();
    }
  }

  useEffect(() => {
    if (!open) return () => {};

    async function initializeModalState() {
      try {
        const geolocationState = useSsn
          ? GeoPermissionStates.GRANTED
          : await checkBrowserGeolocationAccess();
        if (geolocationState !== GeoPermissionStates.GRANTED) {
          setModalState(KycModalState.ALLOW_LOCATION_ACCESS);
        } else {
          setModalState(KycModalState.CHECKING_LOCATION);
        }
        const { country, state } = await getCountryAndState();
        console.log({ country, state });
        if (isExpansionRegion(country)) {
          onCatchExpansion();
          return;
        }
        const allowed = determineIfRegionIsOnWhitelist(state, country);
        if (!allowed) {
          setModalState(KycModalState.REGION_RESTRICTED);
          return;
        }
        setModalState(KycModalState.KYC_FORM);
      } catch (e) {
        console.error(e);
        setModalState(KycModalState.LOCATION_ACCESS_DENIED);
      }
    }

    initializeModalState().catch((e) =>
      console.error("initializeModalState", e)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const modalContent = useMemo(() => {
    switch (modalState) {
      case KycModalState.KYC_FORM:
        return <KycForm handleClose={handleClose} message={message} />;
      case KycModalState.CHECKING_LOCATION:
        return <CheckingLocation />;
      case KycModalState.LOCATION_ACCESS_DENIED:
        return <LocationAccessDenied handleClose={handleClose} />;
      case KycModalState.REGION_RESTRICTED:
        return <RegionRestricted handleClose={handleClose} />;
      case KycModalState.ALLOW_LOCATION_ACCESS:
        return <AllowLocationAccess />;
      case KycModalState.DOCV:
        return <DocV handleClose={handleClose} />;
      default:
        handleClose();
        return <span>Invalid State</span>;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleClose, modalState, open]);

  function SecondChanceCloseWarning() {
    return (
      <div className={"flex-col bg-base-1 w-30 rounded-8 p-9"}>
        <h1 className={"text-neutral-2"}>Skip verification?</h1>
        <span className={"text-neutral-1 font-md weight-300 mb-9"}>
          Completing verification unlocks many features on Phantasia and only
          takes 30 seconds to complete. Are you sure you want to skip this for
          now?
        </span>
        <HorizontalDivider />
        <div className={"mt-9 flex-row items-center space-x-6"}>
          <TextButton3
            className={"flex-1"}
            onClick={() => {
              setShowSecondChance(false);
              onClose();
            }}
          >
            Skip for now...
          </TextButton3>
          <GradientButton3
            className={"flex-1"}
            onClick={() => {
              setShowSecondChance(false);
              triggerEventKycSecondChance();
            }}
          >
            Complete verification
          </GradientButton3>
        </div>
      </div>
    );
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      className="flex flex-col flex-center modal"
      BackdropProps={{
        timeout: 500,
      }}
    >
      <>
        {showSecondChance ? <SecondChanceCloseWarning /> : <>{modalContent}</>}
      </>
    </Modal>
  );
}
