import React, { useState } from "react";
import { DISCORD_LINK } from "common/src/util/constants";

export function ShopInfoSection({ isMobile }) {
  const links = [
    {
      icon: "bxl-twitter",
      link: "https://twitter.com/PhantasiaSports",
    },
    {
      icon: "bxl-discord",
      link: DISCORD_LINK,
    },
    {
      icon: "bxl-instagram",
      link: "https://www.instagram.com/phantasiasports/",
    },
    {
      icon: "bxl-tiktok",
      link: "https://www.tiktok.com/@phantasia_sports",
    },
  ];
  return (
    <div className="w-full flex-col bg-gradient-base flex-center relative z-11 py-15 overflow-hidden">
      <h2
        className={`no-wrap letter-spacing-lg text-neutral-2 weight-300 mt-0 mb-10 flex`}
      >
        JOIN THE COMMUNITY
      </h2>
      <div className={"flex-row space-x-8 mt-4"}>
        <a
          style={{ background: "#09090b" }}
          href={"https://apps.apple.com/us/app/phantasia-sports/id1608431296"}
          className={
            "w-18 hover-down outline-1 outline-neutral-2 decoration-none rounded-full p-6 flex flex-center"
          }
        >
          <img
            alt={"googleplay"}
            src={
              "https://uploads-ssl.webflow.com/63e5b034b55a6e011cf48797/640d26deaa9ee1409224afe2_Vectors-Wrapper.svg"
            }
            className={"h-7"}
          />
        </a>
        <a
          style={{ background: "#09090b" }}
          href={
            "https://play.google.com/store/apps/details?id=com.phantasia&hl=en_US&gl=US"
          }
          className={
            "w-18 hover-down outline-1 outline-neutral-2 decoration-none rounded-full p-6 flex flex-center"
          }
        >
          <img
            alt={"googleplay"}
            src={
              "https://uploads-ssl.webflow.com/63e5b034b55a6e011cf48797/640d26dfadfe8851017576dc_Vectors-Wrapper.svg"
            }
            className={"h-7"}
          />
        </a>
      </div>
      <div className={"flex-row max-w-30 w-full mt-10 items-center"}>
        {links.map((item, index) => (
          <a
            rel="noreferrer"
            key={index}
            className="flex flex-center decoration-none flex-1"
            href={item.link}
            target={"_blank"}
          >
            <i
              className={`bx ${item.icon} bx-lg text-neutral-1 hover:text-neutral-2 transition`}
            />
          </a>
        ))}
      </div>
    </div>
  );
}

export function LandingPageFaqs({ isMobile }) {
  const [open, setOpen] = useState(null);

  const faqs = [
    {
      q: "What is FANT?",
      a: "FANT is a token invented by Phantasia that can be earned in free entry contests. Use FANT to purchase digital collectibles on the web store, or sell FANT on the open market. ",
    },
    {
      q: "Can I earn money on Phantasia?",
      a: "Yes! Phantasia offers pay-to-play games where you can earn large prize pools for winning a contest or league",
    },
    {
      q: "How can I play?",
      a: "On both this website and the mobile app. The mobile app is available on iPhone and Android.",
    },
    {
      q: "Where can I play in paid contests?",
      a: "Alaska, California, Florida, Georgia, Illinois, Kansas, Kentucky, Minnesota, Nebraska, New Mexico, North Carolina, North Dakota, Oklahoma, Rhode Island, South Carolina, South Dakota, Texas, Utah, Washington D.C., West Virginia, Wisconsin, Wyoming",
    },
    {
      q: "Can I play for free to test out contests?",
      a: "Yes! Phantasia offers free entry contests to everyone in the world. You can also win small prizes in free entry contests just for playing.",
    },
    {
      q: "How do I withdraw money?",
      a: "In the mobile app, go to the 'wallet' tab in the middle. You'll see a large button below your balance that says 'Withdraw'. On web, click the user menu and find 'Withdraw winnings' in the list of options.",
    },
    {
      q: "Do I need to own crypto to use Phantasia",
      a: "You don't need to learn or know ANYTHING about crypto to use Phantasia. Everything is handled for you. Enjoy the benefits of the blockchain without the hassle.",
    },
    {
      q: "What are Phantasia Credits?",
      a: "Credits are locked, dollar equivalent coins that can only be spent and not withdrawn.",
    },
    {
      q: "Can I create a contest?",
      a: "Yes! You can create a contest on both the mobile app and website. You must complete verification before you are allowed to create.",
    },
    {
      q: "Does Phantasia offer betting against the house?",
      a: "No. Phantasia is 100% peer-to-peer, meaning you always play against other people and never against Phantasia. Users always create the prize pool.",
    },
    {
      q: "Is Phantasia more like Draft Kings or Prize Picks?",
      a: "All game modes are in contest format, so you're always playing against other users. Never compete against a house that takes up to 20% fees like other platforms. With Phantasia, enjoy payouts in under a minute, live engineering support, and the lowest fees on the market. Phantasia also has no parlays, so you don't need to hit every pick to win.",
    },
  ];

  return (
    <div className={"bg-base-2 w-full flex-col items-center py-15"}>
      <h1
        className={`no-wrap text-center letter-spacing-lg text-neutral-2 weight-300 mt-0 mb-10 flex`}
      >
        FAQs
      </h1>
      <div
        className={`m-auto grid gap-8 grid-cols-1 md:grid-cols-2 px-5 md:px-0 max-w-35 w-35 w-full`}
      >
        {faqs.map((faq, index) => (
          <FaqItem
            {...faq}
            open={open}
            setOpen={setOpen}
            key={index}
            i={index}
          />
        ))}
      </div>
    </div>
  );
}

function FaqItem({ q, a, open, setOpen, i }) {
  return (
    <div
      onClick={() => {
        if (open === i) setOpen(null);
        else setOpen(i);
      }}
      className={`${
        open === i ? "outline-2 outline-neutral-2" : "outline-1"
      } flex-col  transaction cursor-pointer hover:bg-base-3 rounded-8 p-8`}
    >
      <div className={"flex-row"}>
        <span className={"font-lg text-neutral-2 flex-1 weight-400"}>{q}</span>
        <i
          className={`bx bx-sm bx-chevron-down text-neutral-2 ${
            open === i ? "bx-rotate-180" : ""
          }`}
        />
      </div>
      {open === i ? (
        <span
          className={
            "font-md animation-expand-y text-neutral-2 flex-1 weight-300 mt-4"
          }
        >
          {a}
        </span>
      ) : null}
    </div>
  );
}
