import React from "react";

export function HorizontalDivider({ className }) {
  return (
    <div
      className={`flex-grow h-1 min-h-1 ${className}`}
      style={{ background: "var(--ring-color)" }}
    />
  );
}

export function HorizontalDividerInDiv({ className }) {
  return (
    <div className={className}>
      <HorizontalDivider className={""} override={undefined} />
    </div>
  );
}

export function VerticalDivider({ className }) {
  return (
    <div
      className={`flex-grow w-1 min-w-1 ${className}`}
      style={{ background: "var(--ring-color)" }}
    />
  );
}

export function DividerWithTextComponent({ className, text }) {
  return (
    <div className={`${className} flex-row space-x-5 items-center`}>
      {text}
      <div className={`bg-base-3 flex-grow`} style={{ height: 1 }} />
    </div>
  );
}
