import { getApi, getCoinflowApi } from "../util/api";
import {
  Game,
  League,
  MlbPositions,
  PickPayouts,
  PickProp,
  Player,
  PropBonusType,
  PropDecision,
  PropKey,
} from "@phantasia/model-interfaces";
import {
  BoardDto,
  GameWithTeams,
  LiveSlipDto,
  MaxPayoutDto,
  PicksWinningsDto,
  PopulatedPickProp,
  PopulatedSlip,
  PopulatedSlipDto,
  PopulatedSlipLeg,
  SettledSlipDto,
} from "./dtos/ContestDtos";
import {
  MAX_ENTRY_AMOUNT,
  MLB_HRR_BLOCKED_COMBOS,
  MLB_R_BLOCKED_COMBOS,
  MLB_RBI_BLOCKED_COMBOS,
  MLB_TB_BLOCKED_COMBOS,
  NFL_QB_TD_BLOCKED_COMBOS,
} from "../context/picks/SlipBuilderContext";
import { Transaction } from "@solana/web3.js";
import base58 from "bs58";

export function isMlbPitcher(position: MlbPositions) {
  const pitchers = [MlbPositions.P, MlbPositions.RP, MlbPositions.SP];
  return pitchers.includes(position);
}

const baseUrl = "/api/picks";

export const multipliers = [
  { bonus: 1.801603206, standard: 1.731261426 },
  { bonus: 1.865800866, standard: 1.764818356 },
  { bonus: 1.860955661, standard: 1.778210117 },
  { bonus: 1.855600736, standard: 1.782602458 },
];
export const UninsuredPayouts = [
  0,
  0,
  PickPayouts.Uninsured2,
  PickPayouts.Uninsured3,
  PickPayouts.Uninsured4,
];

export const InsuredPayouts = [
  0,
  0,
  PickPayouts.Uninsured2,
  PickPayouts.Insured3,
  PickPayouts.Insured4,
];

export type PropRiskItem = {
  prop: PopulatedPickProp;
  over: number;
  under: number;
  count: number;
  underPct: number;
  overPct: number;
  entry: number;
  overDollars: number;
  underDollars: number;
};

export type SlipRiskItem = {
  legs: PopulatedSlipLeg[];
  count: number;
  payout: number;
};

export type PropToGrade = {
  _id: string;
  prop: PickProp;
  player: Player;
  game: Game;
};

export async function getBoardForLeague(
  league: League
): Promise<{ data: BoardDto }> {
  return await getApi().get(`${baseUrl}/board/${league}`);
}

export async function getBetaMaxPayout(): Promise<{ data: MaxPayoutDto }> {
  return await getApi().get(`${baseUrl}/max-payout`);
}

export async function getLiveSlipsForUser(): Promise<{ data: LiveSlipDto }> {
  return await getApi().get(`${baseUrl}/live`);
}

export async function getSettledSlipsForUser(offset: number): Promise<{
  data: SettledSlipDto;
}> {
  return await getApi().get(`${baseUrl}/settled/${offset}`);
}

export async function getPicksWinnings(): Promise<{
  data: PicksWinningsDto;
}> {
  return await getApi().get(`${baseUrl}/winnings`);
}

export async function populateSlipWithPerformanceData(
  slipId: string
): Promise<{ data: PopulatedSlipDto }> {
  return await getApi().get(`${baseUrl}/populate/${slipId}`);
}

export async function getAdminSlips(): Promise<{ data: PopulatedSlip[] }> {
  return await getApi().get(`${baseUrl}/admin`);
}

export async function getAdminSlipRisk(): Promise<{ data: SlipRiskItem[] }> {
  return await getApi().get(`${baseUrl}/admin-slip-risk`);
}

export async function getAdminPropRisk(): Promise<{ data: PropRiskItem[] }> {
  return await getApi().get(`${baseUrl}/admin-prop-risk`);
}

export async function getPropsToGrade(
  league: League
): Promise<{ data: PropToGrade[] }> {
  return await getApi().get(`${baseUrl}/admin-prop-grader/${league}`);
}

export async function gradeProp(propId: string, propValue: number) {
  return await getApi().post(`${baseUrl}/grade-prop`, {
    prop: propId,
    value: propValue,
  });
}

export async function createCsgoPerformances(gameId: string) {
  return await getApi().post(`${baseUrl}/csgo-performances`, {
    game: gameId,
  });
}

export async function createLolPerformances(gameId: string) {
  return await getApi().post(`${baseUrl}/lol-performances`, {
    game: gameId,
  });
}

export async function refundSlip(
  slipId: string
): Promise<{ data: PropRiskItem[] }> {
  return await getApi().post(`${baseUrl}/refund-slip`, { slipId });
}

export async function createNewSlip(
  slipLegs: PopulatedSlipLeg[],
  entry: number,
  skipKyc = false,
  insuranceType = 1,
  boost: number = 0
): Promise<{ data: { tx: Transaction } }> {
  return await getApi().post(`${baseUrl}/`, {
    slipLegs,
    entry,
    skipKyc,
    insuranceType,
    boost,
  });
}

export async function createFreePlay(
  slipLegs: PopulatedSlipLeg[],
  boost: number = 0
): Promise<{ data: { tx: Transaction } }> {
  return await getApi().post(`${baseUrl}/free-play`, {
    slipLegs,
    skipKyc: false,
    insuranceType: 1,
    boost,
  });
}

export async function runCoinflowRedeemTransaction(
  creditsToRedeem: number,
  tx: Transaction
): Promise<Transaction> {
  if (creditsToRedeem === 0) return tx;

  const transaction = base58.encode(
    tx.serialize({ requireAllSignatures: false, verifySignatures: false })
  );

  const {
    data: { transaction: redeemTransaction },
  } = await getCoinflowApi().post(`/redeem`, {
    subtotal: { cents: creditsToRedeem },
    merchantId: "phantasia",
    transaction,
  });

  return Transaction.from(base58.decode(redeemTransaction));
}

export function calculatePayoutForEntry(
  entry: number,
  legs: PopulatedSlipLeg[],
  insurance_type: number = 1,
  boost: boolean = false
) {
  if (legs.length < 2 || legs.length > MAX_ENTRY_AMOUNT) return "0.00";

  return (
    entry * getPicksMultiplier(legs, insurance_type === 2, boost)
  ).toFixed(2);
}

export function calculateBoostedValue(prop: PickProp<string>) {
  if (!prop.boost) return prop.value;

  const boost = prop.value * 0.5;

  // We want to encourage people to take the under, so jack up the line
  if (prop.boost === PropDecision.UNDER) return prop.value + boost;

  // We want to encourage people to take the under, so jack up the line
  if (prop.boost === PropDecision.OVER) return prop.value + boost;

  return prop.value;
}

export function slipContainsTwoSameTeam(legs: PopulatedSlipLeg[]) {
  const picks = legs.map((leg) => {
    return leg.prop;
  });

  return picks.every(
    (pick) => pick.player.team_ref === picks[0].player.team_ref
  );
}

export function slipContainsSamePlayer(legs: PopulatedSlipLeg[]) {
  const picks = legs.map((leg) => {
    return leg.prop;
  });

  const playerArray = picks.map((pick) => pick.player._id);

  return playerArray.some((p, index) => playerArray.indexOf(p) !== index);
}

export function getPicksMultiplier(
  legs: PopulatedSlipLeg[],
  insured?: boolean,
  boost?: boolean
) {
  return getPayoutMultiplier(
    legs.map((leg) => leg.bonus_type),
    Boolean(boost),
    Boolean(insured)
  );
}

export function getGameOpponentAndHomeTeam(
  game: GameWithTeams,
  player: Player<string>
) {
  const home_team = game.home_team_ID;
  const away_team = game.away_team_ID;
  const opponent = [away_team, home_team].find(
    (team) =>
      player.team_ref &&
      team &&
      team._id.toString() !== player.team_ref.toString()
  );

  const isHomeTeam =
    home_team && home_team._id.toString() === player.team_ref?.toString();

  return { opponent, isHomeTeam };
}

export function getSlipFromNewEntryLegs(
  legs: PopulatedSlipLeg[],
  entryAmount: number,
  insurance_type: number = 1,
  boost: boolean = false
): PopulatedSlip {
  return {
    _id: "",
    eligible_for_refund: false,
    legs: legs as PopulatedSlipLeg[],
    payout: Number(
      calculatePayoutForEntry(entryAmount, legs, insurance_type, boost)
    ),
    boost: boost ? 1 : 0,
    entry_amount: entryAmount,
    user: "",
    wallet_pubkey: "",
    insurance_type: insurance_type,
    createdAt: new Date(),
    onChain: true,
  };
}

export function slipIsRefundable(slip: PopulatedSlip) {
  const isRefundable = slip.legs.find(
    (leg) => (leg.prop as PopulatedPickProp).refundable
  );
  if (!isRefundable || slip.result) return null;

  const notRefundableLegs = slip.legs.filter(
    (leg) => !(leg.prop as PopulatedPickProp).refundable
  );

  if (notRefundableLegs.length < 2) return isRefundable;

  return null;
}

function isBatterCorrelation(
  existingPick: PopulatedPickProp,
  newPick: PopulatedPickProp
) {
  if (existingPick.prop_key === PropKey.BASES)
    return MLB_TB_BLOCKED_COMBOS.includes(newPick.prop_key);
  if (existingPick.prop_key === PropKey.HITS_RUNS_RBI)
    return MLB_HRR_BLOCKED_COMBOS.includes(newPick.prop_key);
  if (existingPick.prop_key === PropKey.BATTING_RBI)
    return MLB_RBI_BLOCKED_COMBOS.includes(newPick.prop_key);
  if (existingPick.prop_key === PropKey.BATTING_RUNS)
    return MLB_R_BLOCKED_COMBOS.includes(newPick.prop_key);
  return false;
}

export function calculateIsMlbCorrelation(
  currentPicks: PopulatedSlipLeg[],
  newPick: PopulatedPickProp
) {
  if (newPick.league !== League.MLB) return false;

  if (
    currentPicks.some((p) => p.prop._id.toString() === newPick._id.toString())
  )
    return false;

  const sameTeamBatter = currentPicks.find(
    (leg) =>
      leg.prop.league === League.MLB &&
      leg.prop.player.team_ref &&
      leg.prop.player.team_ref.toString() ===
        newPick.player.team_ref?.toString() &&
      !isMlbPitcher(leg.prop.player.position as MlbPositions) &&
      !isMlbPitcher(newPick.player.position as MlbPositions)
  );

  if (sameTeamBatter) return isBatterCorrelation(sameTeamBatter.prop, newPick);

  return currentPicks.some(
    (leg) =>
      leg.prop.league === League.MLB &&
      leg.prop.player.position_category === MlbPositions.P &&
      newPick.player.position_category !== MlbPositions.P &&
      leg.prop.game._id.toString() === newPick.game._id.toString() &&
      leg.prop.player.team_ref?.toString() !==
        newPick.player.team_ref?.toString()
  );
}

function isQbCorrelation(
  existingPick: PickProp<string>,
  newPick: PickProp<string>
) {
  if (NFL_QB_TD_BLOCKED_COMBOS.includes(existingPick.prop_key))
    return newPick.prop_key === PropKey.XP_MADE;
  if (existingPick.prop_key === PropKey.XP_MADE)
    return NFL_QB_TD_BLOCKED_COMBOS.includes(newPick.prop_key);
  return false;
}

export function calculateIsNflCorrelation(
  currentPicks: PopulatedSlipLeg[],
  newPick: PopulatedPickProp
) {
  // check if new pick is in NFL or NCAAF. Check if stat is XP made Passing TDs/total TDs
  if (
    (newPick.league !== League.NFL && newPick.league !== League.CFB) ||
    (!NFL_QB_TD_BLOCKED_COMBOS.includes(newPick.prop_key) &&
      newPick.prop_key !== PropKey.XP_MADE)
  )
    return false;

  // Check if same prop
  if (
    currentPicks.some((p) => p.prop._id.toString() === newPick._id.toString())
  )
    return false;

  // Find receiving yards or receiving tds in same entry
  const qbCorr = currentPicks.find(
    (leg) =>
      (leg.prop.league === League.NFL || leg.prop.league === League.CFB) &&
      leg.prop.player.team_ref &&
      leg.prop.player.team_ref.toString() ===
        newPick.player.team_ref?.toString() &&
      isQbCorrelation(leg.prop as PickProp<string>, newPick)
  );

  return Boolean(qbCorr);
}

function roundPayout(number: number, legs: PropBonusType[]) {
  const roundVal = legs.length > 3 ? 0.5 : 0.05;

  return Number((roundVal * Math.round(number / roundVal)).toFixed(2));
}

function getInsuredMultiplier(multiplier: number, legs: PropBonusType[]) {
  const userWinProb = Math.pow(0.5, legs.length);

  const insuredMultiplier =
    (multiplier * userWinProb - 1 - 1 / legs.length + 1) / userWinProb;

  return roundPayout(insuredMultiplier, legs);
}

function getPayoutMultiplier(
  legs: PropBonusType[],
  boost: boolean,
  insurance: boolean
) {
  if (legs.length < 2) return 0;

  const boostMulti = boost ? 0.1 : 0;

  const multiplier = legs.reduce((accumulator, currentValue) => {
    const decimal = multipliers[legs.length - 2];
    if (currentValue !== PropBonusType.Bonus)
      return decimal.standard * accumulator;
    return decimal.bonus * accumulator;
  }, 1);

  if (insurance)
    return (
      roundPayout(getInsuredMultiplier(multiplier, legs), legs) + boostMulti
    );

  return roundPayout(multiplier, legs) + boostMulti;
}

export function getBoostedIncrease(
  legs: PropBonusType[],
  boost: boolean,
  insurance: boolean
) {
  const cloneStandard = legs.map(() => 1);
  const standardPayout = getPayoutMultiplier(cloneStandard, boost, insurance);
  const bonusPayout = getPayoutMultiplier(legs, boost, insurance);
  const increase =
    (100 * Math.abs(standardPayout - bonusPayout)) /
    ((standardPayout + bonusPayout) / 2);

  if (increase > 0) return increase.toFixed(2);
  return null;
}
