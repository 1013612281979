import { useSelector } from "react-redux";
import {
  selectCreditBalance,
  selectFantBalance,
  selectSolBalance,
  selectStakedFantBalance,
  selectUsdBalance,
} from "../redux/selectors/wallet";
import { lamportsToSol } from "../util/helpers";

// TODO delete this
export function useCreditsBalance(): number {
  return useSelector(selectCreditBalance);
}

export function useUsdcBalance(): number {
  return useSelector(selectUsdBalance);
}

export function useStakedBalance(): number {
  return useSelector(selectStakedFantBalance);
}

export function useSolBalance(): number {
  return lamportsToSol(useSelector(selectSolBalance));
}

export function useFantBalance(): number {
  return useSelector(selectFantBalance);
}

export function useBalance(): {
  sol: number;
  fant: number;
  credits: number;
  usdc: number;
  staked: number;
} {
  return {
    sol: lamportsToSol(useSelector(selectSolBalance)),
    fant: useSelector(selectFantBalance),
    staked: useSelector(selectStakedFantBalance),
    credits: useSelector(selectCreditBalance),
    usdc: useSelector(selectUsdBalance),
  };
}
