import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { GradientButton2 } from "../../atoms/Button";

export function NotLoggedInHeader() {
  const location = useLocation();
  const history = useHistory();
  return (
    <div className="w-full animation-from-top p-4">
      <div className="navbar-links flex-row items-center space-x-6 w-full mx-8">
        <span className="flex-row space-x-4" />
        <div className="flex-grow" />
        {location.pathname !== "/login" && (
          <>
            <GradientButton2
              id="sign-in"
              color="primary"
              onClick={() => history.push("/login")}
              className="w-min px-8 mr-11"
            >
              Play Now
            </GradientButton2>
          </>
        )}
      </div>
    </div>
  );
}
