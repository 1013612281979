import { getApi } from "../util/api";
import {
  CsgoPlayerPerformanceStats,
  EplPositions,
  League,
  MlbPlayerPerformanceStats,
  MlbPositions,
  NbaPlayerPerformanceStats,
  NbaPositions,
  NflPlayerPerformanceStats,
  NflPositions,
  PlayerGamePerformance,
} from "@phantasia/model-interfaces";

export const getPlayerProjections = async (
  gameId: string,
  playerId: string
) => {
  return getApi().get(`/api/contest/playerProjections/${gameId}/${playerId}`);
};

export const getTopContestPerformers = async (contest_id: string) => {
  return getApi().get(`/api/contest/top-performers/${contest_id}`);
};

export const getPlayerGameLog = async (playerId: string, league: League) => {
  return getApi().get(`/api/contest/playerGameLog/${league}/${playerId}`);
};

export function getFormattedGameLog(
  performance: PlayerGamePerformance,
  league: League,
  position?: MlbPositions | NflPositions | EplPositions | NbaPositions
) {
  switch (league) {
    case League.MLB:
      if (
        position === MlbPositions.P ||
        position === MlbPositions.SP ||
        position === MlbPositions.RP
      )
        return getMlbPitcherGameLog(
          performance.player_performance_stats as MlbPlayerPerformanceStats
        );
      return getMlbBatterGameLog(
        performance.player_performance_stats as MlbPlayerPerformanceStats
      );
    case League.NBA:
      return getNbaGameLog(
        performance.player_performance_stats as NbaPlayerPerformanceStats
      );
    case League.WNBA:
      return getNbaGameLog(
        performance.player_performance_stats as NbaPlayerPerformanceStats
      );
    case League.NFL:
      return getNflGameLog(
        performance.player_performance_stats as NflPlayerPerformanceStats,
        position as NflPositions
      );
    case League.CFB:
      return getNflGameLog(
        performance.player_performance_stats as NflPlayerPerformanceStats,
        position as NflPositions
      );
    case League.CSGO:
      return getCsgoGameLog(
        performance.player_performance_stats as CsgoPlayerPerformanceStats
      );
    default:
      return {};
  }
}

export function getMlbPitcherGameLog(performance: MlbPlayerPerformanceStats) {
  return {
    "Innings Pitched": performance.innings_pitched_decimal,
    "Outs Recorded": performance.innings_pitched_outs,
    "Hits Allowed": performance.pitching_hits,
    Strikeouts: performance.pitching_strikeouts,
    "Runs Allowed": performance.pitching_runs,
    "Earned Runs": performance.pitching_earned_runs,
    Walks: performance.pitching_walks,
    Win: performance.wins,
  };
}

export function getMlbBatterGameLog(performance: MlbPlayerPerformanceStats) {
  return {
    "At Bats": performance.at_bats,
    Runs: performance.runs,
    Hits: performance.hits,
    Singles: performance.singles,
    Doubles: performance.doubles,
    Triples: performance.triples,
    "Home Runs": performance.home_runs,
    "Runs Batted In": performance.runs_batted_in,
    Strikeouts: performance.strike_outs,
    Walks: performance.walks,
    "Stolen Bases": performance.stolen_bases,
  };
}

export function getNbaGameLog(performance: NbaPlayerPerformanceStats) {
  return {
    Points: performance.points,
    Rebounds: performance.rebounds,
    Assists: performance.assists,
    Steals: performance.steals,
    Blocks: performance.blocked_shots,
    Turnovers: performance.turnovers,
    "Three Pointers": performance.three_pointers_made,
    "FT Made": performance.free_throws_made,
    "FT Attempted": performance.free_throws_attempted,
  };
}

export function getNflGameLog(
  performance: NflPlayerPerformanceStats,
  position: NflPositions
) {
  if (position === NflPositions.QB)
    return {
      "Pass Attempts": performance.passing_attempts,
      Completions: performance.passing_completions,
      "Pass Yards": performance.passing_yards,
      "Passing TD": performance.passing_touchdowns,
      Interceptions: performance.passing_interceptions,
      "Rush Attempts": performance.rushing_attempts,
      "Rush Yards": performance.rushing_yards,
      "Rushing TD": performance.rushing_touchdowns,
    };
  else if (position === NflPositions.RB)
    return {
      "Rush Attempts": performance.rushing_attempts,
      "Rush Yards": performance.rushing_yards,
      "Rushing TD": performance.rushing_touchdowns,
      Receptions: performance.receptions,
      "Rec Yards": performance.receiving_yards,
      "Rec TD": performance.receiving_yards,
    };
  else if (position === NflPositions.WR || position === NflPositions.TE)
    return {
      "Rushing TD": performance.rushing_touchdowns,
      Receptions: performance.receptions,
      "Rec Yards": performance.receiving_yards,
    };
  else if (position === NflPositions.K)
    return {
      "FG Made": performance.field_goals_made,
      "XP Made": performance.extra_points_made,
    };
  else return {};
}

export function getCsgoGameLog(performance: CsgoPlayerPerformanceStats) {
  if (
    !performance.stat_map_3_kills &&
    !performance.stat_map_3_assists &&
    !performance.stat_map_3_headshots &&
    !performance.stat_map_3_deaths
  )
    return {
      "Total Kills": performance.stat_kills,
      "Total Deaths": performance.stat_deaths,
      "Total Headshots": performance.stat_headshots,
      "Map 1 Kills": performance.stat_map_1_kills,
      "Map 1 Deaths": performance.stat_map_1_deaths,
      "Map 1 Headshots": performance.stat_map_1_headshots,
      "Map 2 Kills": performance.stat_map_2_kills,
      "Map 2 Deaths": performance.stat_map_2_deaths,
      "Map 2 Headshots": performance.stat_map_2_headshots,
    };

  return {
    "Total Kills": performance.stat_kills,
    "Total Deaths": performance.stat_deaths,
    "Total Headshots": performance.stat_headshots,
    "Map 1 Kills": performance.stat_map_1_kills,
    "Map 1 Deaths": performance.stat_map_1_deaths,
    "Map 1 Headshots": performance.stat_map_1_headshots,
    "Map 2 Kills": performance.stat_map_2_kills,
    "Map 2 Deaths": performance.stat_map_2_deaths,
    "Map 2 Headshots": performance.stat_map_2_headshots,
    "Map 3 Kills": performance.stat_map_3_kills,
    "Map 3 Deaths": performance.stat_map_3_deaths,
    "Map 3 Headshots": performance.stat_map_3_headshots,
  };
}
