import moment from "moment";
import { getApi } from "../util/api";
import {
  EplPlayerPerformanceStats,
  EplPositions,
  Game,
  GameScore,
  GameStatus,
  League,
  MlbPlayerPerformanceStats,
  MlbPositions,
  NbaPlayerPerformanceStats,
  NbaPositions,
  NflPlayerPerformanceStats,
  NflPositions,
  NhlPlayerPerformanceStats,
  NhlPositions,
  PlayerGamePerformance,
} from "@phantasia/model-interfaces";
import { GAME_STATUSES } from "../util/constants";
import { formatGameMinutes, getQuarterSuffix } from "../util/quarterBuilder";
import { AnyLeaguePosition } from "./dtos/ContestDtos";
import { isMlbPitcher } from "./PicksService";

export interface Score {
  game_id?: string;
  time_left?: string;
  quarter?: string;
  away_team: string;
  home_team: string;
  away_team_logo: string;
  home_team_logo: string;
  league: League;
  status: GameStatus;
  date: Date;
  away_defense_stats: any;
  home_defense_stats: any;
  away_team_name?: string;
  home_team_name?: string;
}

export type GameWithScore = Game<string> & { game_score?: GameScore };

export async function getGameScoreByGameId(gameId: string): Promise<{
  score: GameScore<string>;
}> {
  return (await getApi().get(`/api/game-score/game-top-performers/${gameId}`))
    .data;
}

export const sortGameScoresByLive = (
  a: Game<string> & { game_score: GameScore<string> },
  b: Game<string> & { game_score: GameScore<string> }
) => {
  let a_status = a.status;
  let b_status = b.status;

  if (
    a_status === GameStatus.IN_PROGRESS &&
    b_status !== GameStatus.IN_PROGRESS
  ) {
    return -1;
  }
  if (
    a_status !== GameStatus.IN_PROGRESS &&
    b_status === GameStatus.IN_PROGRESS
  ) {
    return 1;
  }
  if (a_status !== GameStatus.SCHEDULED && b_status === GameStatus.SCHEDULED) {
    return 1;
  }
  if (a_status === GameStatus.SCHEDULED && b_status !== GameStatus.SCHEDULED) {
    return -1;
  }

  return moment(a.date).valueOf() - moment(b.date).valueOf();
};

export enum GameQuarterOptions {
  One = "1",
  Two = "2",
  Three = "3",
  Four = "4",
  Half = "Half",
  OT = "OT",
  F = "F",
  FOT = "F/OT",
  NULL = "NULL",
}

export async function getGameScoresByDate(start_date: Date, end_date: Date) {
  const res = await (
    await getApi()
  ).get(
    `/api/contest/game-scores?start_date=${moment(start_date)
      .utc()
      .format("YYYYMMDD HH:mm")}&end_date=${moment(end_date)
      .utc()
      .format("YYYYMMDD HH:mm")}`
  );
  return res.data.gameScores;
}

export function getGameScoreTime(game: GameWithScore) {
  if (
    game.status === GAME_STATUSES.IN_PROGRESS &&
    game.game_score?.quarter_description !== "Half"
  ) {
    return (
      getQuarterSuffix(game.game_score?.quarter_description ?? "") +
      " " +
      formatGameMinutes(game.game_score?.time_left ?? "", game.league)
    );
  }
  if (game.status === GameStatus.SCHEDULED) {
    return moment(game.date).format("ddd hh:mm A");
  }
  if (
    game.game_score?.quarter_description === "half" ||
    game.game_score?.quarter === "Half"
  ) {
    return "Half";
  }
  return game.status;
}

export function getPlayerStatHighlights(
  performance: PlayerGamePerformance<string> | undefined,
  position: AnyLeaguePosition,
  league: League
) {
  if (!performance || !performance.player_active) return "Not entered game";

  switch (league) {
    case League.NFL:
      if (position === NflPositions.DST) {
        return getNflPlayerGameHighlights(
          position,
          performance.player_performance_stats as NflPlayerPerformanceStats
        );
      }
      return getNflPlayerGameHighlights(
        position as NflPositions,
        performance.player_performance_stats as NflPlayerPerformanceStats
      );
    case League.NBA:
      return getNbaPlayerGameHighlights(
        position as NbaPositions,
        performance.player_performance_stats as NbaPlayerPerformanceStats
      );
    case League.EPL:
      return getEplPlayerGameHighlights(
        position as EplPositions,
        performance.player_performance_stats as EplPlayerPerformanceStats
      );
    case League.MLB:
      return getMlbPlayerGameHighlights(
        position as MlbPositions,
        performance.player_performance_stats as MlbPlayerPerformanceStats
      );
    case League.NHL:
      return getNhlPlayerGameHighlights(
        position as NhlPositions,
        performance.player_performance_stats as NhlPlayerPerformanceStats
      );
    default:
      return "";
  }
}

function getNbaPlayerGameHighlights(
  position: NbaPositions,
  performance: NbaPlayerPerformanceStats
) {
  if (!performance) return "";
  let str = `${performance.field_goals_made}/${performance.field_goals_attempted}, ${performance.points} PTS`;
  if (performance.rebounds) str += `, ${performance.rebounds} REB`;
  if (performance.assists) str += `, ${performance.assists} AST`;
  if (performance.steals) str += `, ${performance.steals} STL`;
  if (performance.blocked_shots) str += `, ${performance.blocked_shots} BLK`;

  return str;
}

function getMlbPlayerGameHighlights(
  position: MlbPositions,
  performance: MlbPlayerPerformanceStats
) {
  if (isMlbPitcher(position)) {
    let string = `${performance.innings_pitched_decimal?.toFixed(2) ?? 0} IP`;
    if (performance.strike_outs) string += `, ${performance.strike_outs} K`;
    if (performance.pitching_hits) string += `, ${performance.pitching_hits} H`;
    if (performance.pitching_earned_runs)
      string += `, ${performance.pitching_earned_runs} ER`;
    if (performance.pitching_walks)
      string += `, ${performance.pitching_walks} BB`;
    if (performance.wins) string += `, ${performance.wins} W`;
    if (performance.pitching_no_hitters)
      string += `, ${performance.pitching_no_hitters} NH`;
    return string;
  } else {
    let string = `${performance.hits} H`;
    if (performance.singles) string += `, ${performance.singles} 1B`;
    if (performance.doubles) string += `, ${performance.doubles} 2B`;
    if (performance.triples) string += `, ${performance.triples} 3B`;
    if (performance.home_runs) string += `, ${performance.home_runs} HR`;
    if (performance.runs_batted_in)
      string += `, ${performance.runs_batted_in} RBI`;
    if (performance.runs) string += `, ${performance.runs} R`;
    if (performance.walks) string += `, ${performance.walks} BB`;
    if (performance.stolen_bases) string += `, ${performance.stolen_bases} SB`;

    return string;
  }
}

function getEplPlayerGameHighlights(
  position: EplPositions,
  performance: EplPlayerPerformanceStats
) {
  if (!performance || !position) return "";

  if (position !== EplPositions.GK) {
    let string = `${performance.shots} Sh`;
    if (performance.shots_on_goal) string += `, ${performance.goals} SoG`;
    if (performance.goals) string += `, ${performance.goals} G`;
    if (performance.assists) string += `, ${performance.assists} Ast`;
    if (performance.tackles_won) string += `, ${performance.tackles_won} Tck`;
    if (performance.crosses) string += `, ${performance.crosses} Crs`;
    if (performance.fouled) string += `, ${performance.fouled} FD`;
    if (performance.fouls) string += `, ${performance.fouls} F`;
    if (performance.yellow_cards) string += `, ${performance.yellow_cards} YC`;
    if (performance.red_cards || performance.yellow_red_cards)
      string += `, ${performance.red_cards + performance.yellow_red_cards} RC`;
    return string;
  } else {
    let string = `${performance.goalkeeper_saves} Save`;
    if (performance.goalkeeper_goals_against)
      string += `, ${performance.goalkeeper_goals_against} Goals allowed`;
    return string;
  }
}

function getNhlPlayerGameHighlights(
  position: NhlPositions,
  performance: NhlPlayerPerformanceStats
) {
  if (!performance || !position) return "";

  if (position !== NhlPositions.G) {
    let string = `${performance.minutes} M`;
    if (performance.shots_on_goal) string += `, ${performance.goals} SoG`;
    if (performance.goals) string += `, ${performance.goals} G`;
    if (performance.assists) string += `, ${performance.assists} Ast`;
    if (performance.blocks) string += `, ${performance.blocks} Blk`;
    if (performance.hits) string += `, ${performance.hits} Hits`;
    if (performance.points) string += `, ${performance.points} Pts`;
    return string;
  } else {
    let string = `${performance.goaltending_saves} Saves`;
    if (performance.goaltending_goals_against)
      string += `, ${performance.goaltending_goals_against} GA`;
    if (performance.goaltending_wins)
      string += `, ${performance.goaltending_wins} W`;
    return string;
  }
}

export function getNflPlayerGameHighlights(
  position: NflPositions,
  performance: NflPlayerPerformanceStats
) {
  if (!performance) return "";
  switch (position) {
    case NflPositions.QB:
      return buildQbPerformanceSummary(
        performance as NflPlayerPerformanceStats
      );
    case NflPositions.RB:
      return buildRbPerformanceSummary(
        performance as NflPlayerPerformanceStats
      );
    case NflPositions.WR:
      return buildWrTePerformanceSummary(
        performance as NflPlayerPerformanceStats
      );
    case NflPositions.TE:
      return buildWrTePerformanceSummary(
        performance as NflPlayerPerformanceStats
      );
    case NflPositions.K:
      return buildKickerPerformanceSummary(
        performance as NflPlayerPerformanceStats
      );
    default:
      return "";
  }
}

function buildQbPerformanceSummary(performance: NflPlayerPerformanceStats) {
  let string = getPassingString(performance);
  if (performance.passing_touchdowns) {
    string += `, ${getPassingTouchdownString(performance)}`;
  }
  if (performance.passing_interceptions) {
    string += `, ${getPassingInterceptionString(performance)}`;
  }
  if (performance.rushing_yards) {
    string += `, ${getRushingString(performance)}`;
  }
  if (performance.rushing_touchdowns) {
    string += `, ${getRushingTouchdownString(performance)}`;
  }
  return string;
}

function buildRbPerformanceSummary(performance: NflPlayerPerformanceStats) {
  let string = getRushingString(performance);
  if (performance.rushing_touchdowns) {
    string += `, ${getRushingTouchdownString(performance)}`;
  }
  // @ts-ignore
  if (performance.receptions) {
    string += `, ${getReceivingString(performance)}`;
  }
  // @ts-ignore
  if (performance.receiving_touchdowns || performance.recieving_yards) {
    string += `, ${getReceivingTouchdownString(performance)}`;
  }
  return string;
}

function buildWrTePerformanceSummary(performance: NflPlayerPerformanceStats) {
  let string = getReceivingString(performance);
  if (performance.receiving_touchdowns) {
    string += `, ${getReceivingTouchdownString(performance)}`;
  }
  return string;
}

function buildKickerPerformanceSummary(performance: NflPlayerPerformanceStats) {
  return getKickingString(performance);
}

function getPassingString(performance: NflPlayerPerformanceStats) {
  return `${performance.passing_completions}/${performance.passing_attempts} ${performance.passing_yards} Yds`;
}

function getPassingTouchdownString(performance: NflPlayerPerformanceStats) {
  return `${performance.passing_touchdowns} TD`;
}

function getPassingInterceptionString(performance: NflPlayerPerformanceStats) {
  return `${performance.passing_interceptions} TD`;
}

function getRushingString(performance: NflPlayerPerformanceStats) {
  return `${performance.rushing_attempts} CAR, ${performance.rushing_yards} Yds`;
}

function getRushingTouchdownString(performance: NflPlayerPerformanceStats) {
  return `${performance.rushing_touchdowns} TD`;
}

function getReceivingString(performance: NflPlayerPerformanceStats) {
  return `${performance.receptions}/${
    // @ts-ignore
    performance.receiving_targets ?? performance?.recieving_targets ?? 0
  } REC, ${
    // @ts-ignore
    performance.receiving_yards ?? performance?.recieving_yards ?? 0
  } Yds`;
}

function getReceivingTouchdownString(performance: NflPlayerPerformanceStats) {
  return `${
    // @ts-ignore
    performance.receiving_touchdowns ?? performance.recieving_touchdowns ?? 0
  } TD`;
}

function getKickingString(performance: NflPlayerPerformanceStats) {
  let string = `${performance.field_goals_made}/${performance.field_goals_attempted} FG`;
  if (performance.field_goals_made0to19) {
    string += `, ${performance.field_goals_made0to19} 0-19`;
  }
  if (performance.field_goals_made20to29) {
    string += `, ${performance.field_goals_made20to29} 20-29`;
  }
  if (performance.field_goals_made30to39) {
    string += `, ${performance.field_goals_made30to39} 30-39`;
  }
  if (performance.field_goals_made40to49) {
    string += `, ${performance.field_goals_made40to49} 40-49`;
  }
  if (performance.field_goals_made50_plus) {
    string += `, ${performance.field_goals_made50_plus} 50+`;
  }
  if (performance.extra_points_attempted) {
    string += `, ${performance.extra_points_attempted}/${performance.extra_points_made} XP`;
  }
  return string;
}
