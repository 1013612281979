import { useState } from "react";
import {
  EplPositions,
  League,
  MlbPositions,
  NbaPositions,
  NflPositions,
  NhlPositions,
  PropKey,
} from "@phantasia/model-interfaces";
import { GameGraph } from "./GameGraph";
import { HorizontalDividerInDiv } from "../../atoms/Divider";
import { getPropPerformanceStatus } from "common/src/services/PlayerPropsService";
import { GameLog } from "common/src/util/JoinContestUtil";

export function PlayerLast5Summary({
  gameLog,
  teamColor,
  projectedValues,
  league,
  defaultStat,
  position,
}: {
  gameLog: GameLog[] | null;
  teamColor: string;
  projectedValues: any;
  league: League;
  position:
    | MlbPositions
    | EplPositions
    | NflPositions
    | NbaPositions
    | NhlPositions;
  defaultStat?: PropKey;
}) {
  const [activeStat, setActiveStat] = useState<PropKey>(
    defaultStat ?? PropKey.FANTASY_POINTS
  );

  if (!gameLog) return null;

  const propKeys = Object.values(PropKey);

  const vals = new Map();

  propKeys.forEach((propKey) => {
    const reduction = gameLog.reduce(
      (accumulator, currentValue) =>
        accumulator +
        getPropPerformanceStatus(currentValue, propKey, league, position),
      0
    );

    if (!isNaN(reduction)) vals.set(propKey, reduction / 5);
  });

  return (
    <div className={"flex-col pb-8"}>
      {gameLog.length !== 0 ? (
        <>
          <GameGraph
            gameLog={gameLog}
            league={league}
            position={position}
            teamColor={teamColor}
            activeStat={activeStat}
          />
          <span
            className={"w-full text-neutral-2 weight-400 font-sm text-center"}
          >
            Showing{" "}
            {activeStat
              .replaceAll("_", " ")
              .replace(/^(.)|\s+(.)/g, (c) => c.toUpperCase())}
          </span>
          <div className={`flex-row px-11 py-4 mt-4`}>
            <span className={"text-neutral-1 weight-300 font-sm flex-1"}>
              Stat
            </span>
            <span className={"text-neutral-1 weight-300 font-sm w-14 text-end"}>
              Last 5 avg
            </span>
            <span className={"text-neutral-1 weight-300 font-sm w-14 text-end"}>
              Projection
            </span>
          </div>
          {Array.from(vals).map(([k, v]: [string, number], index) => (
            <StatOptionButton
              index={index}
              k={k}
              v={v}
              activeStat={activeStat}
              setActiveStat={setActiveStat}
              projectedValues={projectedValues}
            />
          ))}
        </>
      ) : (
        <div className="flex-col px-6 py-11 w-full flex flex-center">
          <i className="bx bxs-folder-minus bx-md text-neutral-1 mb-5" />
          <span className=" font-sm weight-300 text-neutral-1 ">
            No Game Logs
          </span>
        </div>
      )}
    </div>
  );
}

function StatOptionButton({
  index,
  k,
  activeStat,
  setActiveStat,
  v,
  projectedValues,
}: {
  index: number;
  k: string;
  v: number;
  activeStat: PropKey;
  setActiveStat: (stat: PropKey) => void;
  projectedValues: any;
}) {
  return (
    <div className={"flex-col px-8"}>
      {index !== 0 && <HorizontalDividerInDiv className={"px-8"} />}
      <div
        onClick={() => setActiveStat(k as PropKey)}
        className={`flex-row p-8 hover:bg-base-2 transition cursor-pointer rounded-8 ${
          activeStat === k ? "bg-base-2" : ""
        }`}
      >
        <span className={"text-neutral-2 weight-400 font-md flex-1"}>
          {k
            .replaceAll("_", " ")
            .replace(/^(.)|\s+(.)/g, (c) => c.toUpperCase())}
        </span>
        <span className={"text-neutral-2 weight-400 font-md w-14 text-end"}>
          {v.toFixed(2)}
        </span>
        <span className={"text-neutral-2 weight-400 font-md w-14 text-end"}>
          {projectedValues ? (
            projectedValues[k] ?? "-"
          ) : (
            <i className="bx bx-loader-circle bx-spin text-neutral-1" />
          )}
        </span>
      </div>
    </div>
  );
}
