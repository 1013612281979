import { Transaction } from "@solana/web3.js";
import axios from "axios";
import { runTransaction } from "../redux/actions/TransactionAction";
import { ThunkDispatchAsync } from "../util/Types";
import { CommonWallet } from "./wallet/UseCommonWallet";
import { Currencies } from "@phantasia/model-interfaces";
import { getApi } from "../util/api";

export async function sendToken(
  dispatch: ThunkDispatchAsync,
  amount: number,
  senderWallet: CommonWallet,
  receiverWalletPublicKeyStr: string,
  onSuccess: () => void,
  token: Currencies
) {
  const createTransaction = async () => {
    const res = await getApi().get(
      `/api/transaction/send-token/${token}?amount=${amount}&destination=${receiverWalletPublicKeyStr}`
    );
    const rawTx = res.data.tx;
    const serializedTx = Buffer.from(rawTx as any);
    return Transaction.from(serializedTx);
  };

  dispatch(runTransaction(createTransaction, senderWallet, onSuccess));
}

export async function getPhantasiaPriceData(): Promise<number[]> {
  return getCoinPriceData("phantasia");
}

export async function getSolanaPriceData(): Promise<number[]> {
  return getCoinPriceData("solana");
}

export async function getUsdcPriceData(): Promise<number[]> {
  return getCoinPriceData("usd-coin");
}

async function getCoinPriceData(coin: string): Promise<number[]> {
  const response = await axios.get(
    `https://api.coingecko.com/api/v3/coins/${coin}/market_chart?vs_currency=usd&days=3&interval=daily`
  );

  return response.data.prices;
}

export function getWalletsDollarValue(
  priceData: { fant: number[]; sol: number[]; usdc: number[] },
  fant_balance: number,
  sol_balance: number,
  usdc_balance: number
): number {
  return getCoinGeckoPriceDollarValue(
    priceData,
    fant_balance,
    sol_balance,
    usdc_balance,
    0
  );
}

export function calculateValueTrend(data: number[][]): number {
  if (data.length > 1) {
    const todays_value = data[0][1];
    const yesterday_value = data[1][1];

    const trend = ((todays_value - yesterday_value) / yesterday_value) * 100;

    return Math.round(trend * 100) / 100;
  }
  return 0;
}

export function getCoinGeckoPriceDollarValue(
  priceData: { fant: number[]; sol: number[]; usdc: number[] },
  fant_balance: number,
  sol_balance: number,
  usdc_balance: number,
  index: number
): number {
  if (
    priceData.fant.length > 0 &&
    priceData.sol.length > 0 &&
    priceData.usdc.length > 0
  ) {
    const fant_value = fant_balance * priceData.fant[index][1];
    const sol_value = sol_balance * priceData.sol[index][1];
    const usdc_value = usdc_balance * priceData.usdc[index][1];

    return Math.round((fant_value + sol_value + usdc_value) * 100) / 100;
  }
  return 0;
}
