import { PicksBoardContextProvider } from "common/src/context/picks/PicksBoardContext";
import { SlipBuilderContextProvider } from "common/src/context/picks/SlipBuilderContext";
import { BoardMyPicksSection } from "../../components/picks/board/BoardMyPicksSection";
import { BoardAvailableComponent } from "../../components/picks/board/BoardAvailableComponent";

export function PicksBoardPage() {
  return (
    <SlipBuilderContextProvider>
      <div className={"flex-1 h-full overflow-auto pb-6 relative"}>
        <div className={"grid grid-cols-3 h-full max-h-full"}>
          <div
            className={
              "col-span-2 flex-col outline-r-1 h-full max-h-full overflow-auto"
            }
          >
            <PicksBoardContextProvider>
              <BoardAvailableComponent />
            </PicksBoardContextProvider>
          </div>
          <div className={"flex-col h-full max-h-full overflow-auto"}>
            <BoardMyPicksSection />
          </div>
        </div>
      </div>
    </SlipBuilderContextProvider>
  );
}
