import { Modal } from "@mui/material";
import React from "react";
import { KycForm } from "./KycForm";

export function SsnKycModal({ open, setOpen }) {
  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      className="flex flex-col flex-center modal"
      BackdropProps={{
        timeout: 500,
      }}
    >
      <div className="outline-none">
        <KycForm
          handleClose={() => setOpen(false)}
          setOpen={setOpen}
          setModalState={() => {}}
          useSsn
        />
      </div>
    </Modal>
  );
}
