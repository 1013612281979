import React, { useEffect } from "react";
import { WalletPageComponent } from "../components/wallet/wallet-page/WalletPageComponent";
import { WalletPageContextProvider } from "common/src/context/WalletPageContext";
import { triggerEventWalletNav } from "../GoogleAnalytics";
import { NavDrawer } from "../components/layout/header/NavDrawer";
const WalletPage = () => {
  useEffect(() => {
    triggerEventWalletNav();
  }, []);
  return (
    <WalletPageContextProvider>
      <div className={"flex-row"}>
        <NavDrawer />
        <WalletPageComponent />
      </div>
    </WalletPageContextProvider>
  );
};

export default WalletPage;
