import {
  PopulatedSlip,
  SlipLegWithPerformanceAndProjection,
  SlipWithPerformanceAndProjection,
} from "common/src/services/dtos/ContestDtos";
import { HorizontalDividerInDiv } from "../../atoms/Divider";
import { SlipLegListItem } from "../list-items/SlipLegListItem";

export function SlipPlayerList({
  slip,
  loading,
}: {
  slip: SlipWithPerformanceAndProjection | PopulatedSlip | null;
  loading: boolean;
}) {
  if (!slip) return null;

  return (
    <div>
      {(slip.legs as SlipLegWithPerformanceAndProjection[]).map(
        (leg, index) => (
          <div className={"flex-col"} key={index}>
            {index !== 0 && <HorizontalDividerInDiv className={"pl-12"} />}
            <SlipLegListItem loading={loading} leg={leg} />
          </div>
        )
      )}
    </div>
  );
}
