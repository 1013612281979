import HeaderContextProvider from "common/src/context/HeaderContext";
import HeaderDesktop from "./HeaderDesktop";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { loadBalance } from "common/src/redux/actions/WalletAction";
import { useUser } from "common/src/hooks/useUser";
import { stringToPubkey } from "common/src/util/helpers";
import { useMediaQuery } from "@mui/material";
import { HeaderMobile } from "./HeaderMobile";

export default function Header() {
  const isMobile = useMediaQuery("(max-width:768px)");

  const dispatch = useDispatch();
  const user = useUser();
  useEffect(() => {
    if (user.walletPubkey) {
      loadBalance(stringToPubkey(user.walletPubkey), dispatch).catch((e) =>
        console.error(e)
      );

      window.Intercom("boot", {
        api_base: "https://api-iam.intercom.io",
        app_id: "s73hozdw",
        email: user.email,
        user_id: user.username,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.walletPubkey]);

  return (
    <HeaderContextProvider>
      <>
        {isMobile ? (
          <div className="mobile-navbar">
            <HeaderMobile />
          </div>
        ) : (
          <div className="desktop-navbar">
            <HeaderDesktop />
          </div>
        )}
      </>
    </HeaderContextProvider>
  );
}
