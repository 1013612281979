import { usePicksBoard } from "common/src/context/picks/PicksBoardContext";
import { LeagueFilterOptions } from "common/src/util/constants";
import {
  HomePageScoresContextProvider,
  useTodaysScores,
} from "common/src/context/DashboardContext";
import { useEffect, useMemo } from "react";
import { League } from "@phantasia/model-interfaces";

export function PicksLeagueFilters() {
  return (
    <HomePageScoresContextProvider>
      <Filters />
    </HomePageScoresContextProvider>
  );
}

function Filters() {
  const {
    selectedLeague,
    setSelectedLeague,
    setSearchQuery,
    setSelectedPropKey,
    setBoostFilter,
  } = usePicksBoard();
  const { matches, getUpcomingGames } = useTodaysScores();

  useEffect(() => {
    getUpcomingGames(8);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const disabledLeagues = useMemo(() => {
    if (!matches) return [];
    const leagues = Object.keys(League);
    const disabled: string[] = [];
    leagues.forEach((league) => {
      if (matches.every((match) => match.league !== league))
        disabled.push(league);
    });
    return disabled;
  }, [matches]);

  return (
    <div className={"flex-row space-x-6 px-8 mb-8"}>
      {LeagueFilterOptions.map(({ label, icon, value }) => (
        <button
          key={value}
          disabled={disabledLeagues.includes(value)}
          onClick={() => {
            if (selectedLeague !== value) setSelectedLeague(value);
            setSearchQuery("");
            setSelectedPropKey(null);
            setBoostFilter(true);
          }}
          className={`${
            disabledLeagues.includes(value)
              ? "opacity-50 cursor-not-allowed"
              : "hover-down transition"
          } flex-row text-neutral-2 space-x-4 outline-none border-none flex flex-center rounded-5 flex-1 p-5  ${
            selectedLeague === value
              ? "bg-primary-0 ring-2 ring-primary-1 ring-offset-4 ring-offset-base-1"
              : " bg-base-2 hover:bg-base-3"
          }`}
        >
          <i className={`bx bx-${icon}`} />
          <span className={"weight-500 font-md"}>{label}</span>
        </button>
      ))}
    </div>
  );
}
