import ReactGA from "react-ga";

const GA_CATEGORIES = {
  Login: "LOGIN_USER",
  Register: "REGISTER_NEW_USER",
  AaronJones: "JOIN_AJ_WAITLIST",
  JoinPaid: "JOIN_PAID_CONTEST",
  JoinFree: "JOIN_FREE_CONTEST",
  NavToContest: "NAV_TO_JOIN",
  ViewLeaguePage: "VIEW_LEAGUE_PAGE",
  CreateLeague: "CREATE_LEAGUE",
  JoinLeague: "JOIN_LEAGUE",
  KycOnboardingOpen: "KYC_ONBOARDING_OPEN",
  KycOnboardingAccept: "KYC_ONBOARDING_ACCEPT",
  KycOnboardingSkip: "KYC_ONBOARDING_SKIP",
  DepositOnboarding: "COMPLETE_DEPOSIT_ONBOARDING",
  DepositOnboardingSkip: "SKIP_DEPOSIT_ONBOARDING",
  JoinPaidWithDeposit: "JOIN_PAID_WITH_DEPOSIT",
  CreateRosterNoDeposit: "SKIP_DEPOSIT_ON_JOIN",
  AcceptTermsOnboarding: "ACCEPT_TERMS_ONBOARDING",
  ClickClaimFreeContest: "CLICK_CLAIM_CONTEST",
  KycSecondChance: "KYC_SECOND_CHANCE_CLICK",
  RafClickBanner: "RAF_CLICK_BANNER",
  RafNavBar: "RAF_CLICK_NAV_BAR",
  RafDropDownMenu: "RAF_CLICK_DROP_DOWN",
  WalletNav: "WALLET_NAV",
  RafClickCopy1: "RAF_CLICK_COPY_1",
  RafClickCopy2: "RAF_CLICK_COPY_2",
  RafClose: "RAF_CLOSE",
  RafShareTg: "RAF_SHARE_TG",
  RafShareFb: "RAF_SHARE_FB",
  RafShareTw: "RAF_SHARE_TW",
  RafExit: "RAF_EXIT",
  RafLeaderboardCopy: "RAF_LEADERBOARD_COPY",
  RafShareTg2: "RAF_SHARE_TG_2",
  RafShareFb2: "RAF_SHARE_FB_2",
  RafShareTw2: "RAF_SHARE_TW_2",
  RafNav: "RAF_NAV",
  RafAppOpen: "RAF_APP_OPEN",
  RafRegister: "RAF_REGISTER",
  WheelDollar: "WHEEL_DOLLAR",
  WheelFant: "WHEEL_FANT",
  WheelSpin: "WHEEL_SPIN"
};

/**
 * REFER A FRIEND EVENTS
 */

export function triggerEventRafRegister() {
  const val = GA_CATEGORIES.RafRegister;
  ReactGA.event({
    category: val,
    action: val,
    nonInteraction: true,
  });
}

export function triggerEventRafAppOpen() {
  const val = GA_CATEGORIES.RafAppOpen;
  ReactGA.event({
    category: val,
    action: val,
    nonInteraction: true,
  });
}

export function triggerEventRafNav() {
  const val = GA_CATEGORIES.RafNav;
  ReactGA.event({
    category: val,
    action: val,
    nonInteraction: true,
  });
}

export function triggerEventRafShareTw2() {
  const val = GA_CATEGORIES.RafShareTw2;
  ReactGA.event({
    category: val,
    action: val,
    nonInteraction: true,
  });
}

export function triggerEventRafShareFb2() {
  const val = GA_CATEGORIES.RafShareFb2;
  ReactGA.event({
    category: val,
    action: val,
    nonInteraction: true,
  });
}

export function triggerEventRafShareTg2() {
  const val = GA_CATEGORIES.RafShareTg2;
  ReactGA.event({
    category: val,
    action: val,
    nonInteraction: true,
  });
}

export function triggerEventRafLeaderboardCopy() {
  const val = GA_CATEGORIES.RafLeaderboardCopy;
  ReactGA.event({
    category: val,
    action: val,
    nonInteraction: true,
  });
}

export function triggerEventRafExit() {
  const val = GA_CATEGORIES.RafExit;
  ReactGA.event({
    category: val,
    action: val,
    nonInteraction: true,
  });
}

export function triggerEventRafShareTw() {
  const val = GA_CATEGORIES.RafShareTw;
  ReactGA.event({
    category: val,
    action: val,
    nonInteraction: true,
  });
}

export function triggerEventRafShareFb() {
  const val = GA_CATEGORIES.RafShareFb;
  ReactGA.event({
    category: val,
    action: val,
    nonInteraction: true,
  });
}

export function triggerEventRafShareTg() {
  const val = GA_CATEGORIES.RafShareTg;
  ReactGA.event({
    category: val,
    action: val,
    nonInteraction: true,
  });
}

export function triggerEventRafClose() {
  const val = GA_CATEGORIES.RafClose;
  ReactGA.event({
    category: val,
    action: val,
    nonInteraction: true,
  });
}
export function triggerEventRafClickCopy2() {
  const val = GA_CATEGORIES.RafClickCopy2;
  ReactGA.event({
    category: val,
    action: val,
    nonInteraction: true,
  });
}

export function triggerEventRafClickCopy1() {
  const val = GA_CATEGORIES.RafClickCopy1;
  ReactGA.event({
    category: val,
    action: val,
    nonInteraction: true,
  });
}

export function triggerEventWalletNav() {
  const val = GA_CATEGORIES.WalletNav;
  ReactGA.event({
    category: val,
    action: val,
    nonInteraction: true,
  });
}

export function triggerEventRafClickDropDown() {
  const val = GA_CATEGORIES.RafDropDownMenu;
  ReactGA.event({
    category: val,
    action: val,
    nonInteraction: true,
  });
}

export function triggerEventRafClickNavBar() {
  const val = GA_CATEGORIES.RafNavBar;
  ReactGA.event({
    category: val,
    action: val,
    nonInteraction: true,
  });
}

export function triggerEventRafClickBanner() {
  const val = GA_CATEGORIES.RafClickBanner;
  ReactGA.event({
    category: val,
    action: val,
    nonInteraction: true,
  });
}

/**************************************************************************/

export function triggerEventClickClaimContest() {
  ReactGA.event({
    category: GA_CATEGORIES.ClickClaimFreeContest,
    action: "ClickClaimFreeContest",
    nonInteraction: true,
  });
}
export function triggerEventCreateRosterNoDeposit() {
  ReactGA.event({
    category: GA_CATEGORIES.CreateRosterNoDeposit,
    action: "CreateRosterNoDeposit",
    nonInteraction: true,
  });
}
export function triggerEventJoinPaidWithDeposit() {
  ReactGA.event({
    category: GA_CATEGORIES.JoinPaidWithDeposit,
    action: "JoinPaidWithDeposit",
    nonInteraction: true,
  });
}
export function triggerEventDepositOnboardingSkip() {
  ReactGA.event({
    category: GA_CATEGORIES.DepositOnboardingSkip,
    action: "DepositOnboardingSkip",
    nonInteraction: true,
  });
}
export function triggerEventDepositOnboarding() {
  ReactGA.event({
    category: GA_CATEGORIES.DepositOnboarding,
    action: "DepositOnboarding",
    nonInteraction: true,
  });
}
export function triggerEventAcceptTermsOnboarding() {
  ReactGA.event({
    category: GA_CATEGORIES.AcceptTermsOnboarding,
    action: "AcceptedTermsFromOnboarding",
    nonInteraction: true,
  });
}
export function triggerEventKycOnboardingSkip() {
  ReactGA.event({
    category: GA_CATEGORIES.KycOnboardingSkip,
    action: "KycOnboardingSkip",
    nonInteraction: true,
  });
}
export function triggerEventKycOnboardingOpen() {
  ReactGA.event({
    category: GA_CATEGORIES.KycOnboardingOpen,
    action: "KycOnboardingOpen",
    nonInteraction: true,
  });
}
export function triggerEventKycOnboardingAccept() {
  ReactGA.event({
    category: GA_CATEGORIES.KycOnboardingAccept,
    action: "KycOnboardingAccept",
    nonInteraction: true,
  });
}

export function triggerEventJoinLeague() {
  ReactGA.event({
    category: GA_CATEGORIES.JoinLeague,
    action: "Web Login User",
    nonInteraction: true,
  });
}

export function triggerEventViewLeague() {
  ReactGA.event({
    category: GA_CATEGORIES.ViewLeaguePage,
    action: "Web Login User",
    nonInteraction: true,
  });
}

export function triggerEventCreateLeague() {
  ReactGA.event({
    category: GA_CATEGORIES.CreateLeague,
    action: "Web Login User",
    nonInteraction: true,
  });
}

export function triggerEventLogin() {
  ReactGA.event({
    category: GA_CATEGORIES.Login,
    action: "Web Login User",
    nonInteraction: true,
  });
}

export function triggerEventRegister() {
  ReactGA.event({
    category: GA_CATEGORIES.Register,
    action: "Web Register New User",
    nonInteraction: true,
  });
}

export function triggerEventAaronJones() {
  ReactGA.event({
    category: GA_CATEGORIES.AaronJones,
    action: "Web Join Waitlist",
    nonInteraction: true,
  });
}

export function triggerEventNavToJoin() {
  ReactGA.event({
    category: GA_CATEGORIES.NavToContest,
    action: "Navigate to join contest",
    nonInteraction: true,
  });
}

export function triggerEventJoinPaidContest() {
  ReactGA.event({
    category: GA_CATEGORIES.JoinPaid,
    action: "Join Paid Contest",
    nonInteraction: true,
  });
}

export function triggerEventJoinFreeContest() {
  ReactGA.event({
    category: GA_CATEGORIES.JoinFree,
    action: "Join Free Contest",
    nonInteraction: true,
  });
}

export function triggerEventKycSecondChance() {
  ReactGA.event({
    category: GA_CATEGORIES.KycSecondChance,
    action: "Kyc click second chance button",
    nonInteraction: true,
  });
}

export function triggerEventWheelSpinDollar(reward) {
  const val = GA_CATEGORIES.WheelDollar + reward;
  ReactGA.event({
    category: val,
    action: "Dollar reward from daily spin",
    nonInteraction: true,
  });
}

export function triggerEventWheelSpinFant() {
  const val = GA_CATEGORIES.WheelFant;
  ReactGA.event({
    category: val,
    action: "Fant reward from daily spin",
    nonInteraction: true,
  });
}
export function triggerEventWheelSpin() {
  const val = GA_CATEGORIES.WheelSpin;
  ReactGA.event({
    category: val,
    action: "No reward from daily spin",
    nonInteraction: true,
  });
}
