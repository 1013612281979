import {
  RunTransactionType,
  TransactionActionTypes,
} from "../reducers/Transaction";
import {
  PublicKey,
  SignaturePubkeyPair,
  Signer,
  Transaction,
} from "@solana/web3.js";
import { Dispatch } from "redux";
import { CommonWallet } from "../../services/wallet/UseCommonWallet";
import { Singletons } from "../../services/Singletons";
import { ThunkDispatchAsync } from "../../util/Types";
import { getApi } from "../../util/api";
import bs58 from "bs58";
import {
  ConnectionService,
  NotEnoughSolError,
  sendTransactionWithRetries,
  TxNotConfirmedError,
  TxSimulationFailedError,
} from "@phantasia/model-interfaces";

export interface TransactionWithSigners {
  transaction: Transaction;
  signers: Signer[];
}

export interface TransactionState {
  pending: string;
  transactionRetryFunc: () =>
    | Promise<TransactionWithSigners | Transaction>
    | Promise<(TransactionWithSigners | Transaction)[]>;
  transactionSignature: string;
  onSuccess: () => void;
  errorLog: string[];
  type: string;
  message: string | undefined;
}

export class TxAlreadyPendingError extends Error {
  constructor() {
    super("Another Transaction is pending please wait for it to complete");
  }
}

export const retryTransaction =
  (transactionState: TransactionState, wallet: CommonWallet) =>
  (dispatch: ThunkDispatchAsync) => {
    switch (transactionState.type) {
      case RunTransactionType.SINGLE:
        dispatch(
          runTransaction(
            transactionState.transactionRetryFunc as () => Promise<
              TransactionWithSigners | Transaction
            >,
            wallet,
            transactionState.onSuccess
          )
        );
        break;
      case RunTransactionType.ASYNC:
        dispatch(
          runAsyncTransactions(
            transactionState.transactionRetryFunc as () => Promise<
              (TransactionWithSigners | Transaction)[]
            >,
            wallet,
            transactionState.onSuccess
          )
        );
        break;
      case RunTransactionType.SYNC:
        dispatch(
          runSyncTransactions(
            transactionState.transactionRetryFunc as () => Promise<
              (TransactionWithSigners | Transaction)[]
            >,
            wallet,
            transactionState.onSuccess
          )
        );
        break;
      default:
        throw new Error("Invalid Retry Transaction Call");
    }
  };

export const runAsyncTransactions =
  (
    getTransactionsAsyncFunc: () => Promise<
      (Transaction | TransactionWithSigners)[]
    >,
    wallet: CommonWallet,
    onSuccess: (signatures: string[]) => void,
    onError?: (err: any) => void,
    message?: string
  ) =>
  async (dispatch: Dispatch) => {
    try {
      setPending(dispatch, message);
    } catch (e) {
      console.error(e);
      Singletons.toastService.error(
        "Another Transaction Pending",
        "Another Transaction is pending please wait for it to complete"
      );
      return;
    }

    try {
      let transactions = await getTransactionsAsyncFunc();

      const signedTransactions: (Transaction | Buffer)[] = [];
      for (const transaction of transactions) {
        signedTransactions.push(
          await getSignedTransaction(transaction, wallet)
        );
        await new Promise((r) => setTimeout(r, 500));
      }

      const txResults = await Promise.allSettled(
        signedTransactions.map((tx) => sendTransactionWithRetries(tx))
      );
      const successfulResults = txResults.filter(
        (result) => result.status === "fulfilled"
      ) as PromiseFulfilledResult<string>[];
      const failedResults = txResults.filter(
        (result) => result.status === "rejected"
      ) as PromiseRejectedResult[];

      if (failedResults.length > 0) {
        const errors = failedResults.map((res) => res.reason);
        throw errors[0];
      }

      const txSignatures = successfulResults.map(
        (result) => (result as PromiseFulfilledResult<string>).value
      );

      dispatch({
        type: TransactionActionTypes.SUCCESS,
        payload: {
          transactionSignature: txSignatures,
        },
      });

      if (onSuccess) await onSuccess(txSignatures);
    } catch (err: any) {
      const runTransactionType = RunTransactionType.ASYNC;
      handleTxError({
        runTransactionType,
        dispatch,
        err,
        getTransactionAsyncFunc: getTransactionsAsyncFunc,
        onSuccess,
        onError,
      });
      logTransactionError(err).catch(() => {});
    }
  };

export const runSyncTransactions =
  (
    getTransactionsAsyncFunc: () => Promise<
      (Transaction | TransactionWithSigners)[]
    >,
    wallet: CommonWallet,
    onSuccess: (signatures: string[]) => void,
    onError?: (err: any) => void,
    message?: string
  ) =>
  async (dispatch: Dispatch) => {
    try {
      setPending(dispatch, message);
    } catch (e) {
      console.error(e);
      Singletons.toastService.error(
        "Another Transaction Pending",
        "Another Transaction is pending please wait for it to complete"
      );
      return;
    }

    try {
      let transactions = await getTransactionsAsyncFunc();

      const signAndSendTx = async (
        transaction: Transaction | TransactionWithSigners
      ) => {
        const signedTransaction = await getSignedTransaction(
          transaction,
          wallet
        );
        return sendTransactionWithRetries(signedTransaction);
      };

      const txSignatures: string[] = [];
      for (const transaction of transactions) {
        txSignatures.push(await signAndSendTx(transaction));
      }

      dispatch({
        type: TransactionActionTypes.SUCCESS,
        payload: {
          transactionSignature: txSignatures,
        },
      });

      if (onSuccess) await onSuccess(txSignatures);
    } catch (err: any) {
      const runTransactionType = RunTransactionType.SYNC;
      handleTxError({
        runTransactionType,
        dispatch,
        err,
        getTransactionAsyncFunc: getTransactionsAsyncFunc,
        onSuccess,
        onError,
      });
      logTransactionError(err).catch(() => {});
    }
  };

/**
 * @param getTransactionAsyncFunc - The function that creates the transaction, should return Promise<Transaction>
 * @param wallet - The wallet which will sign the transaction
 * @param onSuccess - A function that will be ran on success, can be undefined
 * @param onError - A function that will be ran on failure, can be undefined
 * @param message
 * @param assignFeePayer
 */
export const runTransaction =
  (
    getTransactionAsyncFunc: () => Promise<
      Transaction | TransactionWithSigners | null
    >,
    wallet: CommonWallet,
    onSuccess: (signature: string) => void,
    onError?: (err: any) => void,
    message?: string,
    assignFeePayer?: boolean
  ) =>
  async (dispatch: Dispatch) => {
    try {
      setPending(dispatch, message);
    } catch (e) {
      console.error(e);
      Singletons.toastService.error(
        "Another Transaction Pending",
        "Another Transaction is pending please wait for it to complete"
      );
      return;
    }

    try {
      let transaction = await getTransactionAsyncFunc();
      if (assignFeePayer) {
        (transaction as Transaction).feePayer = new PublicKey(
          "H8zqWnBLqJMwYDwpqUQawWLQpaW8dE6B6NHrsJH9Zufv"
        );
      }

      if (transaction) {
        if (await userIsFeePayerAndNoSol(transaction, wallet, dispatch)) {
          return;
        }
      }

      let transactionSignature = "";
      if (transaction) {
        transactionSignature = await wallet.sendTransaction(
          transaction as Transaction
        );
      }

      dispatch({
        type: TransactionActionTypes.SUCCESS,
        payload: {
          transactionSignature: transactionSignature,
        },
      });

      if (onSuccess) await onSuccess(transactionSignature);
    } catch (err: any) {
      console.error("Error Running Transaction", err);
      const runTransactionType = RunTransactionType.SINGLE;
      handleTxError({
        runTransactionType,
        dispatch,
        err,
        getTransactionAsyncFunc,
        onSuccess,
        onError,
      });
      logTransactionError(err).catch(() => {});
    }
  };

async function userIsFeePayerAndNoSol(
  transaction: Transaction | TransactionWithSigners,
  wallet: CommonWallet,
  dispatch: Dispatch
) {
  if (
    // @ts-ignore
    transaction?.feePayer?.toString() === wallet.publicKey.toString() || // @ts-ignore
    transaction?.transaction?.feePayer?.toString() ===
      wallet.publicKey.toString()
  ) {
    const balance = await ConnectionService.getConnection().getBalance(
      wallet.publicKey
    );
    if (balance === 0) {
      dispatch({
        type: TransactionActionTypes.NOT_ENOUGH_SOL,
        payload: {
          errorLog:
            "Not enough to SOL to complete this transaction. Please fund your wallet with more SOL to pay transaction fees.",
        },
      });
      return true;
    }
  }
  return false;
}
function setPending(dispatch: Dispatch, message?: string): void {
  if (
    Singletons.store?.getState().Transaction.pending ===
    TransactionActionTypes.PENDING
  ) {
    throw new TxAlreadyPendingError();
  }

  dispatch({
    type: TransactionActionTypes.PENDING,
    message: message,
  });
}

export async function getSignedTransaction(
  transaction: Transaction | TransactionWithSigners,
  wallet: CommonWallet
): Promise<Transaction> {
  let partialSigners;
  if (
    (transaction as TransactionWithSigners).transaction &&
    (transaction as TransactionWithSigners).signers
  ) {
    partialSigners = (transaction as TransactionWithSigners).signers;
    transaction = (transaction as TransactionWithSigners).transaction;
  }

  let signedTransaction: Transaction | Buffer;
  if (shouldSignTransaction(transaction as Transaction, wallet)) {
    if ((transaction as Transaction).feePayer)
      (transaction as Transaction).feePayer = new PublicKey(
        (transaction as Transaction).feePayer?.toString() ?? ""
      );
    getApi()
      .post("/api/transaction/alert", {
        wallet: wallet.publicKey.toString(),
        msg: `STARTING SIGN TX, Partial: ${Boolean(partialSigners)}`,
      })
      .then();
    signedTransaction = await wallet.signTransaction(
      transaction as Transaction
    );
  } else {
    signedTransaction = transaction as Transaction;
  }

  let tx: Transaction =
    signedTransaction instanceof Uint8Array
      ? Transaction.from(signedTransaction)
      : signedTransaction;

  if (partialSigners) {
    tx = partialSignTx(tx, partialSigners);
  }

  if (transactionNeedsToBeSignedByFeePayer(tx, wallet)) {
    tx = await signWithFeePayer(tx);
  }

  return tx;
}

function shouldSignTransaction(tx: Transaction, wallet: CommonWallet): boolean {
  if (tx.feePayer?.toString() === wallet.publicKey.toString()) return true;

  const keys = Array.from(new Set(tx.instructions.map((ix) => ix.keys).flat()));
  const signerKeys = keys.filter((key) => key.isSigner);
  return !!signerKeys.find(
    (key) => key.pubkey.toString() === wallet.publicKey.toString()
  );
}

function transactionNeedsToBeSignedByFeePayer(
  tx: Transaction,
  wallet: CommonWallet
): boolean {
  if (
    !tx.feePayer ||
    tx.feePayer.toString() === "C5yzt5w9hbFdU4TBt4jFN2yGSY9VVRp7cdejvL82t3Vn" ||
    tx.feePayer.toString() === "GigARaWnLsKrTUq8FNveo3rn18Dp4sV8ic6Y6i5yJ7Z5" ||
    tx.feePayer.toString() === "ADQJrs6Kp7vRJ8LwM5buVq6LPmWeQXyHFLuFccUWgBsB"
  )
    return false;

  return (
    tx.feePayer.toString() === "H8zqWnBLqJMwYDwpqUQawWLQpaW8dE6B6NHrsJH9Zufv" ||
    tx.feePayer.toString() === "857Tm9dNi6Ypur9zCcJ9oAhqYd3bE6J6s2ww77PKCSa"
  );
  // return tx.feePayer.toString() !== wallet.publicKey.toString();
}

async function signWithFeePayer(tx: Transaction): Promise<Transaction> {
  const { data } = await getApi().post("/api/transaction/sign", {
    serialized_tx: tx.serialize({ requireAllSignatures: false }),
  });
  return Transaction.from(bs58.decode(data));
}

export async function getFeePayer(): Promise<PublicKey> {
  const { data } = await getApi().get("/api/transaction/sign");
  return new PublicKey(data);
}

function handleTxError({
  runTransactionType,
  dispatch,
  getTransactionAsyncFunc,
  err,
  onSuccess,
  onError,
}: {
  runTransactionType: string;
  dispatch: Dispatch;
  err: any;
  getTransactionAsyncFunc: () => Promise<
    | (TransactionWithSigners | Transaction | null)
    | (TransactionWithSigners | Transaction | null)[]
  >;
  onSuccess: (sig: string | string[]) => void;
  onError?: (err: any) => void;
}) {
  if (err instanceof TxSimulationFailedError) {
    console.error(err.getLogs());
    if (err.getLogs()?.toLowerCase()?.includes("blockhashnot")) {
      dispatch({
        type: TransactionActionTypes.WARNING,
        payload: {
          transactionRetryFunc: getTransactionAsyncFunc,
          onSuccess: onSuccess,
          errorLog:
            "Transaction confirmation has failed. This can happen due to network congestion or other common issues. Please retry.",
          type: runTransactionType,
        },
      });
    } else if (
      err.getLogs()?.toLowerCase()?.includes("duplicateparticipantpubkey")
    ) {
      dispatch({
        type: TransactionActionTypes.DUPLICATE,
        payload: {
          transactionRetryFunc: () => {},
          onSuccess: () => {},
          errorLog:
            "You already have a lineup entered in this contest and the limit is 1 entry per user. Please go to the contest page to view or edit your lineup.",
          type: runTransactionType,
        },
      });
    } else {
      dispatch({
        type: TransactionActionTypes.FAILURE,
        payload: {
          transactionRetryFunc: getTransactionAsyncFunc,
          onSuccess: onSuccess,
          errorLog: err.getLogs() ?? "Transaction Simulation Failed",
          type: runTransactionType,
        },
      });
    }
  } else if (err instanceof TxNotConfirmedError) {
    console.error(err);
    dispatch({
      type: TransactionActionTypes.WARNING,
      payload: {
        transactionRetryFunc: getTransactionAsyncFunc,
        onSuccess: onSuccess,
        errorLog:
          "Transaction confirmation has failed. This can happen due to network congestion or other common issues. Please retry.",
        type: runTransactionType,
      },
    });
  } else if (err instanceof NotEnoughSolError) {
    console.error(err.message);
    dispatch({
      type: TransactionActionTypes.NOT_ENOUGH_SOL,
      payload: {
        errorLog:
          err.message ??
          "Not enough to SOL to complete this transaction. Please fund your wallet with more SOL to pay transaction fees.",
      },
    });
  } else {
    console.error(err.response?.data?.msg);
    console.error(err.message);

    dispatch({
      type: TransactionActionTypes.FAILURE,
      payload: {
        transactionRetryFunc: getTransactionAsyncFunc,
        onSuccess: onSuccess,
        errorLog: err.response?.data?.msg ?? err.message,
        type: runTransactionType,
      },
    });
  }

  if (onError) onError(err);
}

/**
 * Deserialized txs cant partial sign after so we need this method to do some trickery
 */
function partialSignTx(
  transaction: Transaction,
  partialSignersArr: Signer[]
): Transaction {
  // make sure we have immutable array
  const originalSignatures: SignaturePubkeyPair[] = Object.assign(
    [],
    transaction.signatures
  );
  const filteredSignatures = originalSignatures.filter(
    (item) => item.signature != null
  );

  transaction.partialSign(...partialSignersArr);

  filteredSignatures.forEach((sign) => {
    if (sign.signature)
      transaction.addSignature(sign.publicKey, sign.signature);
  });

  return transaction;
}

export async function logTransactionError(err: any) {
  await getApi().put("api/transaction/error", {
    error: err,
  });
  return;
}
