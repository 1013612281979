import { LegacyRef, ReactNode } from "react";
import "../../sass/Atoms.scss";

export const Input = ({
  id,
  type = "text",
  value,
  placeholder = "Placeholder",
  icon,
  onChange,
  min = 0,
  max = Number.MAX_SAFE_INTEGER,
  onBlur,
  size,
  classes,
  containerClasses,
  label,
  showError,
  disabled,
  inputMode = "text",
  onFocus,
  maxlength,
  pattern,
  reference,
  autoFocus,
}: {
  id?: string;
  type?:
    | "search"
    | "text"
    | "none"
    | "tel"
    | "url"
    | "email"
    | "numeric"
    | "decimal"
    | undefined;
  value: string | number | undefined;
  placeholder?: string;
  icon?: ReactNode;
  onChange: (e?: any) => void;
  min?: number;
  max?: number;
  onBlur?: () => void;
  size?: string;
  classes?: string;
  containerClasses?: string;
  label?: string;
  showError?: boolean;
  disabled?: boolean;
  inputMode?:
    | "search"
    | "text"
    | "none"
    | "tel"
    | "url"
    | "email"
    | "numeric"
    | "decimal"
    | undefined;
  onFocus?: () => void;
  maxlength?: number;
  pattern?: string;
  reference?: LegacyRef<HTMLInputElement>;
  autoFocus?: boolean;
}) => {
  return (
    <div className={`relative ${containerClasses} ${label && "flex-col"}`}>
      {label && (
        <span className="font-sm weight-400 uppercase letter-spacing-sm text-neutral-1 mb-4 pl-4">
          {label}
        </span>
      )}
      <div className={"relative"}>
        <input
          ref={reference}
          maxLength={maxlength}
          inputMode={inputMode}
          id={id}
          onChange={onChange}
          className={`${disabled ? "opacity-75 cursor-not-allowed" : ""} ${
            size === "sm" ? "p-5" : "p-6"
          } ${icon ? "pl-10" : null} ${
            showError && "outline-danger-1 outline-1"
          } font-${
            size ? size : "md"
          } flex w-full text-neutral-2 outline-none border-none focus:outline-primary-1 outline-0 focus:outline-1 rounded-5  bg-base-2 ${classes}`}
          value={value}
          type={type}
          placeholder={placeholder}
          min={min}
          max={max}
          onBlur={onBlur}
          onFocus={onFocus}
          disabled={disabled}
          pattern={pattern}
          autoFocus={autoFocus}
          onKeyDown={(e) => e.stopPropagation()}
        />
        <div className={"absolute-middle-horizontal l-6 "}> {icon}</div>
      </div>
    </div>
  );
};
