import React from "react";
import { SolidButton3 } from "../../atoms/Button";

export function LocationAccessDenied({ handleClose }) {
  return (
    <div className="flex-col bg-base-1 rounded-9 p-8 animation-from-bottom w-11/12 max-w-31 overflow-auto max-h-9/10">
      <div className="w-full flex-col">
        <div className="w-full flex flex-center mb-8">
          <div className="bg-danger-0 outline-danger min-w-15 rounded-full min-h-15 flex flex-center">
            <i className="bx bx-location-plus text-danger-2 bx-md" />
          </div>
        </div>
        <h3 className="text-neutral-2 m-0 mb-4 text-center">
          Please Enable Location Access to enter paid contests
        </h3>
        <span className="text-neutral-1 font-md weight-300 mb-5 text-center">
          Please enable location services in your browser. Read below for
          browser-specific instructions.
        </span>
        <br />
        <span className="font-lg weight-600 text-neutral-2 mb-5">Chrome</span>
        <span className="text-neutral-1 font-sm weight-300 mb-4 items-center flex-row">
          <i className="bx bxs-bullseye mr-4" /> At first you'll see a prompt
          asking you to share your location with Chrome. Simply click 'Allow'.
          If you do not see this, complete the following steps.
        </span>
        <span className="text-neutral-1 font-sm weight-300 mb-4 items-center flex-row">
          <i className="bx bxs-bullseye mr-4" /> At the top right, click More{" "}
          <i className="bx bx-dots-vertical-rounded" /> and then Settings.
        </span>
        <span className="text-neutral-1 font-sm weight-300 mb-4 flex-row">
          <i className="bx bxs-bullseye mr-4" /> Click Privacy and security and
          then Site Settings.
        </span>
        <span className="text-neutral-1 font-sm weight-300 mb-8 flex-row">
          <i className="bx bxs-bullseye mr-4" />
          Click Location. Ensure Phantasia.app is allowed to see your location.
        </span>
        <span className="font-lg weight-600 text-neutral-2 mb-6">Firefox</span>
        <span className="text-neutral-1 font-sm weight-300 mb-4 items-center flex-row">
          <i className="bx bxs-bullseye mr-4" /> Click the "Site Identity
          Button" (globe/padlock) on the location bar
        </span>
        <span className="text-neutral-1 font-sm weight-300 mb-4 flex-row">
          <i className="bx bxs-bullseye mr-4" /> Click "More Information" to
          open "Tools {">"} Page Info" with the Security tab selected
        </span>
        <span className="text-neutral-1 font-sm weight-300 mb-4 flex-row">
          <i className="bx bxs-bullseye mr-4" />
          Go to the Permissions tab (Tools {">"} Page Info {">"} Permissions) to
          check the permissions for Phantasia.app.
        </span>
        <span className="font-lg weight-600 text-neutral-2 mb-4">Brave</span>
        <span className="text-neutral-1 font-sm weight-300 mb-4 items-center flex-row">
          <i className="bx bxs-bullseye mr-4" /> At the top right, click Menu
          icon <i className="bx bx-menu" />, then select Settings.
        </span>
        <span className="text-neutral-1 font-sm weight-300 mb-4 flex-row">
          <i className="bx bxs-bullseye mr-4" /> At the bottom, click Advanced.
        </span>
        <span className="text-neutral-1 font-sm weight-300 mb-4 flex-row">
          <i className="bx bxs-bullseye mr-4" />
          Under Privacy and security, click Site settings.
        </span>
        <span className="text-neutral-1 font-sm weight-300 mb-8 flex-row">
          <i className="bx bxs-bullseye mr-4" />
          Click the permission you want to update. Ensure Phantasia.app has
          access to your location services.
        </span>
        <div>
          <SolidButton3
            onClick={handleClose}
            color="primary"
            className={"w-full"}
          >
            Leave Contest Page
          </SolidButton3>
        </div>
      </div>
    </div>
  );
}
