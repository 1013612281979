import React, { useState } from "react";
import { GradientButton2 } from "../atoms/Button";
import { KycContextProvider } from "common/src/context/KycContext";
import { KycModal } from "../modals/kyc/KycModal";
import { useUser } from "common/src/hooks/useUser";
import { DepositModal } from "../modals/purchase/DepositModal";
import { Tooltip } from "@mui/material";

export function DepositMenu({ small }) {
  const [paymentModalOpen, setPaymentModalOpen] = useState(false);
  const [kycOpen, setKycOpen] = useState(false);
  const user = useUser();

  function handleOpen() {
    if (user.passedKyc) setPaymentModalOpen(!paymentModalOpen);
    else setKycOpen(true);
  }

  return (
    <div>
      {small ? (
        <div
          onClick={() => handleOpen()}
          className=" outline-1 rounded-full mb-6 h-10 w-10 bg-gradient-primary flex flex-center transition hover-up cursor-pointer flex-row"
        >
          <Tooltip title={"Deposit funds"} placement="right">
            <span>
              <i className="bx bx-plus white bx-sm" />
            </span>
          </Tooltip>
        </div>
      ) : (
        <GradientButton2
          color="primary"
          className={"flex-grow px-8"}
          onClick={() => handleOpen()}
        >
          Deposit
        </GradientButton2>
      )}
      <DepositModal
        open={paymentModalOpen}
        onClose={() => {
          setPaymentModalOpen(false);
        }}
      />
      <KycContextProvider
        onSuccess={() => {
          setKycOpen(false);
          setPaymentModalOpen(true);
        }}
      >
        <KycModal
          onCatchExpansion={() => {
            setKycOpen(false);
            setPaymentModalOpen(true);
          }}
          open={kycOpen}
          onClose={() => {
            setKycOpen(false);
          }}
        />
      </KycContextProvider>
      <></>
    </div>
  );
}
