import React from "react";
import { useDispatch } from "react-redux";
import { UserTypes } from "common/src/redux/actions/types";
import {
  deleteNotification,
  updateNotification,
} from "common/src/services/NotificationService";
import { useUser } from "common/src/hooks/useUser";
import { useHistory } from "react-router-dom";
import moment from "moment";

export function NotificationListItem({ notification }) {
  const user = useUser();
  const history = useHistory();
  const dispatch = useDispatch();

  const readUserNotification = () => {
    const updatedNotifications = user.notifications.map((notif) => {
      if (notif._id === notification._id) {
        return { ...notif, read: true };
      } else {
        return notif;
      }
    });
    dispatch({
      type: UserTypes.UPDATE_NOTIFICATIONS,
      payload: updatedNotifications,
    });
  };

  const deleteUserNotification = () => {
    const updatedNotifications = user.notifications.filter(
      (notif) => notif._id !== notification._id
    );
    dispatch({
      type: UserTypes.UPDATE_NOTIFICATIONS,
      payload: updatedNotifications,
    });
  };

  return (
    <div
      onClick={() => {
        history.replace(notification.link);
        updateNotification(notification._id).then(() => readUserNotification());
      }}
      className={`rounded-6 w-full w-full space-x-5 items-center p-6  outline-1 animation-from-bottom hover:bg-base-3 transition cursor-pointer flex-row`}
    >
      <div
        className={"w-11 h-11 rounded-full flex flex-center bg-base-3 relative"}
      >
        {!notification.read && (
          <div className="absolute t-0 r-0 bg-danger-1 flex flex-center min-h-5 max-h-5 w-5 h-5 rounded-full" />
        )}
        {!notification.read ? (
          <i className="bx bx-bell text-neutral-2" />
        ) : (
          <i className="bx bx-check-double text-neutral-2" />
        )}
      </div>
      <div className="flex-col flex-1 overflow-hidden ellipsis no-wrap">
        <span className="text-neutral-1 weight-300 font-xs">
          {moment(notification.createdAt).fromNow()}
        </span>
        <span className="text-neutral-2 weight-400 font-md">
          {notification.title}
        </span>
        <span className="text-neutral-1 weight-300 font-sm overflow-hidden ellipsis no-wrap">
          {notification.body}
        </span>
      </div>

      <div className="flex-row items-center space-x-5">
        {!notification.read && (
          <div
            className={
              "hover:bg-base-3 p-5 transition rounded-full cursor-pointer"
            }
            onClick={(e) => {
              e.stopPropagation();
              updateNotification(notification._id);
              readUserNotification();
            }}
          >
            <i className="bx bx-low-vision text-neutral-2"></i>
          </div>
        )}
        <div
          className={
            "hover:bg-danger-0 p-5 transition rounded-full cursor-pointer"
          }
          onClick={(e) => {
            e.stopPropagation();
            deleteNotification(notification._id);
            deleteUserNotification();
          }}
        >
          <i className="bx bx-trash bx-xs text-danger-1"></i>
        </div>
      </div>
    </div>
  );
}
