import { RosterTypes } from "../actions/types";
import { RosterPlayer } from "@phantasia/model-interfaces";

export type ReduxRoster = {
  roster: RosterPlayer[];
  contest: string;
  timestamp: Date;
};

const initialState: ReduxRoster = {
  roster: [],
  contest: "",
  timestamp: new Date(0),
};

const Roster = (state = initialState, action: any) => {
  switch (action.type) {
    case RosterTypes.SET_ROSTER:
      return {
        ...state,
        roster: action.payload.roster,
        contest: action.payload.contest,
        timestamp: action.payload.timestamp,
      };
    default:
      return state;
  }
};

export default Roster;
