import { SettledSlipsContextProvider } from "common/src/context/picks/SettledSlipsContext";
import { SettledPicksComponent } from "../../components/picks/slips/SettledPicksComponent";

export function PicksResultsPage() {
  return (
    <SettledSlipsContextProvider>
      <SettledPicksComponent />
    </SettledSlipsContextProvider>
  );
}
