import { getApi } from "../util/api";
import { Notification } from "@phantasia/model-interfaces";
import { UserTypes } from "../redux/actions/types";
import { Dispatch } from "redux";

export async function deleteNotification(notificationId: string) {
  return getApi().post(`/api/notifications/delete`, {
    notificationId,
  });
}

export async function updateNotification(notificationId: string) {
  return getApi().put(`/api/notifications/read`, {
    notificationId,
  });
}

export async function clearNotifications() {
  return getApi().post(`/api/notifications/delete-all`);
}

export function updateReduxUserNotifications(
  notifications: Notification,
  dispatch: Dispatch
) {
  dispatch({
    type: UserTypes.UPDATE_NOTIFICATIONS,
    payload: notifications,
  });
}
