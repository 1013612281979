export const selectStakedAmount = (state) => state.Wallet.currentStakeAmount;
export const selectStakedRewards = (state) => state.Wallet.currentStakeRewards;

export const selectWallet = (state) => state.Wallet;
export const selectFantBalance = (state) => state.Wallet.fantBalance;
export const selectStakedFantBalance = (state) =>
  state.Wallet.stakedFantBalance;
export const selectSolBalance = (state) => state.Wallet.solBalance;
export const selectUsdBalance = (state) => state.Wallet.usdcBalance;
export const selectCreditBalance = (state) => state.Wallet.creditBalance;
