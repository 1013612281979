import { useHover } from "common/src/hooks/useHover";
import React, { useMemo, useState } from "react";
import { SolidButton2 } from "../atoms/Button";

export function MagicWallet({ publicKey }) {
  const [copied, setCopied] = useState(false);
  const [hoverRef, isHovered] = useHover();

  const copyAddress = async () => {
    const base58 = publicKey?.toBase58();
    if (base58) {
      await navigator.clipboard.writeText(base58);
      setCopied(true);
      setTimeout(() => setCopied(false), 400);
    }
  };

  const content = useMemo(() => {
    if (copied) return "Copied!";

    if (isHovered) return "Copy";

    const base58 = publicKey?.toBase58();
    if (!base58) return "";

    return base58.slice(0, 4) + ".." + base58.slice(-4);
  }, [publicKey, copied, isHovered]);

  return (
    <SolidButton2 className="w-min" onClick={copyAddress}>
      <div className="flex-row flex-center" ref={hoverRef}>
        <i className={"bx bx-wallet-alt tx-neutral-2"} />
        <span className="px-4" style={{ width: "7.5em" }}>
          {content}
        </span>
        <i className={"bx bx-copy-alt tx-neutral-2"} />
      </div>
    </SolidButton2>
  );
}
