import { useMediaQuery } from "@mui/material";
import { DfsInfoSection } from "./LandingPage/DfsInfoSection";
import { LandingSection } from "./LandingPage/LandingSection";
import { LeaguesInfoSection } from "./LandingPage/LeaguesInfoSection";
import { QrCodeSection } from "./LandingPage/QrCodeSection";
import {
  LandingPageFaqs,
  ShopInfoSection,
} from "./LandingPage/ShopInfoSection";
import { WaysToPlaySection } from "./LandingPage/WaysToPlaySection";
import Footer from "../components/layout/footer/Footer";

export default function LandingPage() {
  const isMobile = useMediaQuery("(max-width:768px)");

  return (
    <div className={`${isMobile && "overflow-hidden max-w-full"}`}>
      <LandingSection isMobile={isMobile} />
      <QrCodeSection isMobile={isMobile} />
      <WaysToPlaySection isMobile={isMobile} />
      <DfsInfoSection isMobile={isMobile} />
      <LeaguesInfoSection isMobile={isMobile} />
      <ShopInfoSection isMobile={isMobile} />
      <LandingPageFaqs isMobile={isMobile} />
      <Footer />
    </div>
  );
}
