import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  selectFantBalance,
  selectSolBalance,
  selectStakedFantBalance,
  selectUsdBalance,
  selectCreditBalance,
} from "../redux/selectors/wallet";
import {
  getPhantasiaPriceData,
  getSolanaPriceData,
  getUsdcPriceData,
} from "../services/SendService";
import { BALANCE_CURRENCIES } from "../util/constants";
import { lamportsToSol } from "../util/helpers";

export interface WalletPageContextProps {
  selectedCurrency: string;
  setSelectedCurrency: (currency: string) => void;
  receiverWallet: string;
  setReceiverWallet: (wallet: string) => void;
  showError: boolean;
  setShowError: (showError: boolean) => void;
  amountToSend: string | number;
  setAmountToSend: (amount: number) => void;
  showSendConfirmation: boolean;
  setShowSendConfirmation: (showError: boolean) => void;
  selectedNft: any;
  setSelectedNft: (nft: any) => void;
  showNftSendConfirmation: boolean;
  setShowNftSendConfirmation: (showConfirmation: boolean) => void;
  nftReceiverWallet: string;
  setNftReceiverWallet: (wallet: string) => void;
  fant_balance: number;
  sol_balance: number;
  sFant_balance: number;
  usdc_balance: number;
  credit_balance: number;
  priceData: { fant: number[]; sol: number[]; usdc: number[] };
}

export const WalletPageContext = React.createContext<WalletPageContextProps>({
  selectedCurrency: BALANCE_CURRENCIES.FANT,
  setSelectedCurrency: () => {},
  receiverWallet: "",
  setReceiverWallet: () => {},
  showError: false,
  setShowError: () => {},
  amountToSend: "",
  setAmountToSend: () => {},
  showSendConfirmation: false,
  setShowSendConfirmation: () => {},
  selectedNft: undefined,
  setSelectedNft: () => {},
  showNftSendConfirmation: false,
  setShowNftSendConfirmation: () => {},
  nftReceiverWallet: "",
  setNftReceiverWallet: () => {},
  fant_balance: 0,
  sol_balance: 0,
  sFant_balance: 0,
  usdc_balance: 0,
  credit_balance: 0,
  priceData: { fant: [], sol: [], usdc: [] },
});

type Props = {
  children: JSX.Element;
};

export function WalletPageContextProvider({ children }: Props): JSX.Element {
  const [selectedCurrency, setSelectedCurrency] = useState<string>(
    BALANCE_CURRENCIES.FANT
  );
  const [receiverWallet, setReceiverWallet] = useState<string>("");
  const [amountToSend, setAmountToSend] = useState<string | number>("");
  const [showError, setShowError] = useState<boolean>(false);
  const [showSendConfirmation, setShowSendConfirmation] =
    useState<boolean>(false);
  const [selectedNft, setSelectedNft] = useState<any>(null);
  const [nftReceiverWallet, setNftReceiverWallet] = useState<string>("");
  const [showNftSendConfirmation, setShowNftSendConfirmation] =
    useState<boolean>(false);
  const [priceData, setPriceData] = useState<{
    fant: number[];
    sol: number[];
    usdc: number[];
  }>({ fant: [], sol: [], usdc: [] });

  const fant_balance: number = useSelector(selectFantBalance);
  const sol_balance: number = lamportsToSol(useSelector(selectSolBalance));
  const usdc_balance: number = useSelector(selectUsdBalance);
  const sFant_balance: number = lamportsToSol(
    useSelector(selectStakedFantBalance)
  );
  const credit_balance: number = useSelector(selectCreditBalance);

  useEffect(() => {
    getCoinPriceData();
  }, []);

  async function getCoinPriceData(): Promise<void> {
    const fant_price_data = await getPhantasiaPriceData();
    const sol_price_data = await getSolanaPriceData();
    const usdc_price_data = await getUsdcPriceData();

    const prices_object = {
      fant: fant_price_data.reverse(),
      sol: sol_price_data.reverse(),
      usdc: usdc_price_data.reverse(),
    };

    setPriceData(prices_object);
  }

  return (
    <WalletPageContext.Provider
      value={{
        selectedCurrency,
        setSelectedCurrency,
        receiverWallet,
        setReceiverWallet,
        showError,
        setShowError,
        amountToSend,
        setAmountToSend,
        showSendConfirmation,
        setShowSendConfirmation,
        selectedNft,
        setSelectedNft,
        showNftSendConfirmation,
        setShowNftSendConfirmation,
        nftReceiverWallet,
        setNftReceiverWallet,
        fant_balance,
        sol_balance,
        sFant_balance,
        usdc_balance,
        priceData,
        credit_balance,
      }}
    >
      {children}
    </WalletPageContext.Provider>
  );
}
