import {
  GameWithTeams,
  PopulatedPickProp,
} from "common/src/services/dtos/ContestDtos";
import { useSlipBuilder } from "common/src/context/picks/SlipBuilderContext";
import {
  PickProp,
  Player,
  PropBonusType,
  PropDecision,
} from "@phantasia/model-interfaces";
import { getPropKeyShortName } from "common/src/util/helpers";
import { PicksArrow } from "../utils/PicksArrow";
import moment from "moment";
import { useState } from "react";
import PlayerStatModal from "../../modals/PlayerStatModal";
import { PlayerStatType } from "common/src/util/constants";
import { calculateBoostedValue } from "common/src/services/PicksService";
import { PicksBonusArrow } from "../utils/PicksBonusArrow";

export function BoardAvailableListItem({
  pickProp,
  pickDisabled = () => false,
}: {
  pickProp: PopulatedPickProp;
  pickDisabled?: (pick: PopulatedPickProp) => boolean;
}) {
  const { currentSlip, addLegToSlip } = useSlipBuilder();
  const [focusedPlayer, setFocusedPlayer] = useState<PickProp<string> | null>(
    null
  );

  const disabled = pickDisabled(pickProp);

  const currentDecision = currentSlip.find(
    // @ts-ignore
    (leg) => leg.prop._id === pickProp._id
  )?.prop_decision;

  const { game, prop_key } = pickProp;
  const player = pickProp.player;

  return (
    <>
      <PlayerModal
        focusedPlayer={focusedPlayer}
        pickProp={pickProp}
        setFocusedPlayer={setFocusedPlayer}
      />
      <div
        onClick={() => setFocusedPlayer(pickProp)}
        className={`transition cursor-pointer hover:bg-base-0 flex-row space-x-8 py-8 ring-b-1 items-center flex-1`}
      >
        <div className={"flex flex-center relative"}>
          <div
            className={`${
              pickProp.bonus_type === PropBonusType.Bonus
                ? "bg-gradient-boost"
                : pickProp.boost
                ? "bg-gradient-fire-sale"
                : "bg-base-4"
            } ${
              currentDecision ? "ring-base-2" : "ring-base-1"
            }  flex flex-center transition rounded-full h-11 w-11 overflow-hidden relative`}
          >
            {pickProp.boost ? (
              <i className="bx bxs-hot white absolute opacity-50 h-11 w-11 t-0 r-0 l-0 b-0" />
            ) : null}
            {pickProp.bonus_type === PropBonusType.Bonus ? (
              <i className="bx bxs-bolt white absolute opacity-50 h-11 w-11 t-0 r-0 l-0 b-0" />
            ) : null}
            <img
              src={player.photo}
              alt={"player"}
              className={"h-11 contain relative"}
            />
          </div>
        </div>
        <div className={"flex-col flex-1"}>
          <span className={"font-md weight-400 text-neutral-2 truncate"}>
            {player.name}
          </span>
          <span className={"font-xs weight-300 text-neutral-1"}>
            {player.position
              ? `${player.position}  •  ${player.team}`
              : `${player.team}`}
          </span>
          <div className={"flex"}>{formatPlayerGame(game, player)}</div>
        </div>

        <div className={"truncate flex-col w-12 bg-red text-end items-end"}>
          {disabled ? (
            <span className={"text-neutral-1 weight-300 font-sm pt-5"}>
              Combo disabled
            </span>
          ) : (
            <>
              <span
                className={`font-lg weight-500 text-center text-neutral-2 mt-3 line-height-md ${
                  pickProp.boost ? "text-gradient-fire-sale" : "text-neutral-2"
                }`}
              >
                {calculateBoostedValue(pickProp)}
              </span>
              <span className={"font-xs weight-300 text-center text-neutral-1"}>
                {getPropKeyShortName(prop_key)}
              </span>
            </>
          )}
        </div>
        <div
          className={`${
            disabled ? "opacity-50 pointer-events-none" : ""
          } flex items-end`}
        >
          <DecisionButton
            boosted={pickProp.bonus_type === PropBonusType.Bonus}
            text={"Higher"}
            onClick={() => addLegToSlip(pickProp, PropDecision.OVER)}
            active={currentDecision === PropDecision.OVER}
          />
        </div>
        <div
          className={`${
            disabled ? "opacity-50 pointer-events-none" : ""
          } flex items-end`}
        >
          <DecisionButton
            text={"Lower"}
            rotate
            boosted={pickProp.bonus_type === PropBonusType.Bonus}
            onClick={() => addLegToSlip(pickProp, PropDecision.UNDER)}
            active={currentDecision === PropDecision.UNDER}
          />
        </div>
      </div>
    </>
  );
}

function PlayerModal({
  focusedPlayer,
  pickProp,
  setFocusedPlayer,
}: {
  focusedPlayer: PickProp<string> | null;
  pickProp: PopulatedPickProp;
  setFocusedPlayer: (player: PickProp<string> | null) => void;
}) {
  return (
    <PlayerStatModal
      open={Boolean(focusedPlayer)}
      currentGame={pickProp.game}
      statType={PlayerStatType.PickProp}
      player={focusedPlayer ? (focusedPlayer.player as Player<string>) : null}
      league={pickProp.league}
      onClose={() => {
        setFocusedPlayer(null);
      }}
      defaultStat={focusedPlayer?.prop_key}
    />
  );
}

function DecisionButton({
  text,
  onClick,
  rotate,
  active,
  boosted,
}: {
  text: string;
  onClick: () => void;
  rotate?: boolean;
  active: boolean;
  boosted?: boolean;
}) {
  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
        onClick();
      }}
      className={`${
        active ? "bg-base-3" : ""
      }  rounded-6 min-w-14 w-13 h-13 bg-base-2 flex-col hover:outline-1 flex-center hover:bg-base-1 transition cursor-pointer`}
    >
      {!boosted ? (
        <PicksArrow
          className={`w-8 h-8 ${active ? "fill-primary" : "fill-base-4"} ${
            rotate ? "bx-rotate-180" : ""
          }`}
        />
      ) : (
        <PicksBonusArrow
          fillColor={"#ecc440"}
          className={`w-8 h-8  ${rotate ? "bx-rotate-180" : ""}`}
        />
      )}

      <span
        className={`font-sm text-neutral-2 weight-400 ${
          active ? "" : "opacity-75"
        }`}
      >
        {text}
      </span>
    </div>
  );
}

function formatPlayerGame(game: GameWithTeams, player: Player<string>) {
  if (!player || !player.team_ref) return null;
  const home_team = game.home_team_ID;
  const away_team = game.away_team_ID;
  const opponent = [away_team, home_team].find(
    (team) =>
      player.team_ref && team._id.toString() !== player.team_ref.toString()
  );

  if (!opponent) return null;

  const isHomeTeam = home_team._id.toString() === player.team_ref.toString();

  if (
    player.team_ref.toString() !== home_team._id.toString() &&
    player.team_ref.toString() !== away_team._id.toString()
  ) {
    return (
      <div className={"flex-col space-y-1 items-center"}>
        <span className={"font-sm weight-300 text-danger-2"}>
          Player invalid!
        </span>
        <span className={"font-sm weight-300 text-danger-1 text-center"}>
          Player traded & invalid
        </span>
      </div>
    );
  }
  return (
    <div className={"flex-col"}>
      <span className={"font-xs flex weight-300 text-neutral-2"}>
        {`${isHomeTeam ? "vs." : "@"} ${opponent.name}`}
      </span>
      <span className={"font-xs flex weight-300 text-neutral-1"}>
        {moment(game.date).format("ddd h:mm a")}
      </span>
    </div>
  );
}
