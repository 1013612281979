import axios, { AxiosInstance } from "axios";
import { Singletons } from "../services/Singletons";
import { ConnectionService } from "@phantasia/model-interfaces";

function getCoinflowBaseUrl() {
  if (ConnectionService.getNet() === "devnet")
    return "https://api-sandbox.coinflow.cash/api";
  return "https://api.coinflow.cash/api";
}

export function getCoinflowApi() {
  const state = Singletons.store.getState();
  let authToken = state.User.authToken;
  const publicKey = state.User.walletPubkey;
  let headers = {};
  if (authToken && publicKey)
    headers = {
      "x-coinflow-auth-wallet": publicKey,
      "x-coinflow-auth-blockchain": "solana",
    };
  return axios.create({
    baseURL: getCoinflowBaseUrl(),
    headers,
  });
}

export function getApi(): AxiosInstance {
  const state = Singletons.store.getState();
  let authToken = state.User.authToken;
  const publicKey = state.User.walletPubkey;

  let headers = {};
  if (authToken && publicKey)
    headers = {
      "x-auth-token": publicKey,
      "x-wallet-signature": authToken,
    };
  return axios.create({
    baseURL: Singletons.apiUrl,
    headers,
  });
}
