import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useCommonWallet } from "../services/wallet/UseCommonWallet";
import { useFantBalance, useStakedBalance } from "../hooks/useBalance";
import { Singletons } from "../services/Singletons";

import { runTransaction } from "../redux/actions/TransactionAction";
import { loadBalance } from "../redux/actions/WalletAction";
import {
  AccountMeta,
  PublicKey,
  Transaction,
  TransactionInstruction,
} from "@solana/web3.js";
import {
  addBlockhash,
  ConnectionService,
  findAssociatedTokenAddress,
  getIdByNet,
  programIds,
  Pubkeys,
} from "@phantasia/model-interfaces";
import {
  createAssociatedTokenAccountInstruction,
  TOKEN_PROGRAM_ID,
} from "@solana/spl-token";
import BN from "bn.js";

export enum PhantasiaInstructions {
  DepositNFT = 0,
  TransferNFTtoWinner = 1,
  InitializeContestAccount = 2,
  AddOrUpdateParticipantData = 3,
  CreateWinnersAccount = 4,
  ClaimContestPrizes = 5,
  CloseWinnersAccount = 6,
  TransferP2eVaultOwnership = 7,
  WhitelistOrBlacklistUser = 9,
  SetupStakingRequirements = 10,
  StakeFant = 11,
  UnstakeFant = 12,
  CancelPaidContest = 13,
  ClaimMultiContestPrizes = 14,
  ContestIndividualRefund = 25,
  IncreaseComputeUnitsInternalIx = 34,
}

export interface StakingContextProps {
  unstakeAmount: number;
  setUnstakeAmount: (amount: number) => void;
  showError: boolean;
  submitForm: () => void;
}

export const StakingContext = React.createContext<StakingContextProps>({
  unstakeAmount: 0,
  setUnstakeAmount: () => {},
  showError: false,
  submitForm: () => {},
});

type Props = {
  children: JSX.Element;
};

export default function StakingContextProvider({
  children,
}: Props): JSX.Element {
  const [unstakeAmount, setUnstakeAmount] = useState<number>(0);

  const [showError, setShowError] = useState<boolean>(false);

  const fantBalance = useFantBalance();
  const sFantBalance = useStakedBalance();

  const dispatch = useDispatch();
  const wallet = useCommonWallet();

  useEffect(() => {
    if (unstakeAmount > sFantBalance) {
      setShowError(true);
    } else {
      if (showError) setShowError(false);
    }
  }, [fantBalance, showError, unstakeAmount, sFantBalance]);

  const submitForm = async () => {
    await unstake();
  };

  const unstake = async () => {
    if (unstakeAmount === 0 || !unstakeAmount) {
      Singletons.toastService.error(
        "Error",
        `You must unstake at least 1 FANT`
      );
      return;
    }
    if (unstakeAmount > sFantBalance) {
      setShowError(true);
      Singletons.toastService.error(
        "Error",
        `${unstakeAmount} is greater than amount of FANT staked`
      );
      return;
    }

    const onSuccess = () => {
      setUnstakeAmount(0);
      loadBalance(wallet.publicKey, dispatch);
      Singletons.toastService.success(
        "Success",
        `Success! You've unstaked ${unstakeAmount} FANT`
      );
    };
    const getUnstakeTx = async () =>
      createUnstakeFantTx(wallet.publicKey, unstakeAmount);

    dispatch(
      runTransaction(
        getUnstakeTx,
        wallet,
        onSuccess,
        () => {},
        "Unstaking...",
        true
      )
    );
  };

  return (
    <StakingContext.Provider
      value={{
        unstakeAmount,
        setUnstakeAmount,
        showError,
        submitForm,
      }}
    >
      {children}
    </StakingContext.Provider>
  );
}

export async function createUnstakeFantTx(
  userWallet: PublicKey,
  amount: number
) {
  const connection = ConnectionService.getConnection();
  const rawAmount = amount * Math.pow(10, 6);
  const fantAta = await findAssociatedTokenAddress(
    userWallet,
    Pubkeys.getFantMint()
  );

  const sFantAta = await findAssociatedTokenAddress(
    userWallet,
    Pubkeys.getSfantMint()
  );
  const sFantAtaInfo = await connection.getAccountInfo(sFantAta);

  const doesSfantAtaExist = sFantAtaInfo?.owner !== undefined;
  if (!doesSfantAtaExist) {
    throw new Error("User Staking $FANT Balance is zero");
  }

  const [sFantPdaSigner] = await PublicKey.findProgramAddress(
    [Buffer.from("PhantasiaSfantAutho")],
    Pubkeys.getPhantasiaProgramId()
  );

  const [stakingVaultPdaSigner] = await PublicKey.findProgramAddress(
    [Buffer.from("PhantasiaStakeVault")],
    Pubkeys.getPhantasiaProgramId()
  );

  const keys: AccountMeta[] = [
    {
      pubkey: userWallet,
      isSigner: true,
      isWritable: false,
    },
    {
      pubkey: fantAta,
      isSigner: false,
      isWritable: true,
    },
    {
      pubkey: sFantAta,
      isSigner: false,
      isWritable: true,
    },
    {
      pubkey: getIdByNet(stakingVaultIds),
      isSigner: false,
      isWritable: true,
    },
    {
      pubkey: sFantPdaSigner,
      isSigner: false,
      isWritable: false,
    },
    {
      pubkey: stakingVaultPdaSigner,
      isSigner: false,
      isWritable: false,
    },
    {
      pubkey: Pubkeys.getSfantMint(),
      isSigner: false,
      isWritable: true,
    },
    {
      pubkey: TOKEN_PROGRAM_ID,
      isSigner: false,
      isWritable: false,
    },
  ];

  const unstakeFantIx = new TransactionInstruction({
    programId: Pubkeys.getPhantasiaProgramId(),
    keys: keys,
    data: Buffer.from([
      PhantasiaInstructions.UnstakeFant,
      ...new BN(rawAmount).toArray("le", 8),
    ]),
  });

  const unstakeFantIxs: TransactionInstruction[] = [];
  await createAta(userWallet, fantAta, Pubkeys.getFantMint(), unstakeFantIxs);

  unstakeFantIxs.push(unstakeFantIx);

  const transaction = new Transaction().add(...unstakeFantIxs);

  await addBlockhash(transaction);
  transaction.feePayer = userWallet;

  return transaction;
}

export async function createAta(
  walletAddress: PublicKey,
  ata: PublicKey,
  mint: PublicKey,
  Ixs: TransactionInstruction[],
  payer?: PublicKey
): Promise<void> {
  const connection = ConnectionService.getConnection();
  const ataInfo = await connection.getAccountInfo(ata);

  const doesAtaExist = ataInfo?.owner !== undefined;
  if (!doesAtaExist) {
    const createAtaIx = createAssociatedTokenAccountInstruction(
      payer ?? walletAddress,
      ata,
      walletAddress,
      mint
    );
    Ixs.push(createAtaIx);
  }
}

const stakingVaultIds: programIds = {
  mainnet: "stvfiaMhcsCKeCy5WB1tWs1VUq6ncfyb4YHtq6pF3h1",
  devnet: "stv9X1xYG7Ret8f7RGX1NZ9nuJWoppKP7DmHg6a9Z3Y",
  localnet: "stakA1vbNMVstBUWfiKMBkBGtcmFzA9RWPhvvh7vZK4",
};
