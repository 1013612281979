import { Link } from "react-router-dom";
import MissedFieldGoal from "../assets/MissedFieldGoal.svg";
import { SolidButton3 } from "../components/atoms/Button";

const style = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  height: "90vh",
};

export function NotFound() {
  return (
    <div className="white avoid-navbar" style={style}>
      <img className="mb-8" src={MissedFieldGoal} alt="Missed field goal" />
      <h2 className="mb-8 text-neutral-2 weight-300">
        Missed opportunity! Page not found
      </h2>
      <Link to="/" className="decoration-none">
        <SolidButton3 color="primary" className={"px-8"}>
          Take me home
        </SolidButton3>
      </Link>
    </div>
  );
}
