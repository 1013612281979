import React, { useState } from "react";
import { HorizontalDivider } from "../atoms/Divider";
import { FANTASY_SCORING_BY_SPORT } from "common/src/util/ScoringRulesUtil";

export function DfsScoringRules({ league }) {
  const [selectedSport, setSelectedSport] = useState(
    FANTASY_SCORING_BY_SPORT.find((obj) => obj?.leagues?.includes(league))
  );

  return (
    <div className={"flex-col"}>
      <span className="mb-6 text-neutral-2 font-md weight-500">
        {`${selectedSport.title}`}
      </span>

      <div className={"flex-row space-x-5 items-center"}>
        {FANTASY_SCORING_BY_SPORT.map((obj) => (
          <FantasyScoreSelector
            name={obj.name}
            key={obj.name}
            active={selectedSport.name === obj.name}
            onPress={() => setSelectedSport(obj)}
          />
        ))}
      </div>
      {Object.keys(selectedSport.scoring).map((rule, index) => (
        <div key={index}>
          {index !== 0 && <HorizontalDivider />}
          <div key={index} className={`flex-row py-5 items-center flex-1`}>
            <span className="text-neutral-1 weight-400 font-md flex-1">
              {rule}
            </span>
            <span className="text-neutral-1 weight-400 font-md">
              {selectedSport.scoring[rule]}
            </span>
          </div>
        </div>
      ))}
    </div>
  );
}

function FantasyScoreSelector({ onPress, active, name }) {
  return (
    <div
      onClick={onPress}
      className={`flex flex-center rounded-6 h-10 px-6 hover-down transition ${
        active ? "bg-primary-1" : "bg-base-3"
      }`}
    >
      <span className={"text-neutral-2 weight-400 font-md"}>{name}</span>
    </div>
  );
}
