import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useUser } from "../hooks/useUser";
import { updateUser } from "../services/AuthService";
import { Singletons } from "../services/Singletons";
import { getAndSetUser } from "../services/UserService";

export const Theme = {
  Light: "LightTheme",
  Dark: "DarkTheme",
  Midnight: "MidnightTheme",
};

export const ThemeKey = "theme";

export interface HeaderContextProps {
  ssnKycModalOpen: boolean;
  setSnnKycModalOpen: (open: boolean) => void;
  kycModalOpen: boolean;
  setKycModalOpen: (open: boolean) => void;
  termsAccepted: boolean;
  userTermsAccepted: (username: string, referral: string) => void;
}

export const HeaderContext = React.createContext<HeaderContextProps>({
  ssnKycModalOpen: false,
  setSnnKycModalOpen: () => {},
  kycModalOpen: false,
  setKycModalOpen: () => {},
  termsAccepted: true,
  userTermsAccepted: () => {},
});

type Props = {
  children: JSX.Element;
};

export default function HeaderContextProvider({
  children,
}: Props): JSX.Element {
  let dispatch = useDispatch();
  const user = useUser();

  const [termsAccepted, setTermsAccepted] = useState<boolean>(true);
  const [ssnKycModalOpen, setSnnKycModalOpen] = useState<boolean>(false);
  const [kycModalOpen, setKycModalOpen] = useState<boolean>(false);

  async function userTermsAccepted(username: string, email?: string) {
    if (username)
      try {
        await updateUser(
          {
            acceptedTerms: true,
            username: username.trim(),
            code: username.toUpperCase(),
            email: email ? email.trim() : user.email,
          },
          user,
          dispatch
        );

        setTermsAccepted(true);
        setTimeout(() => {
          Singletons.toastService.success("Success", "You're ready to play!");
          getAndSetUser(dispatch);
        }, 1000);
      } catch (e) {
        // @ts-ignore
        Singletons.toastService.error("Form Error", e.response?.data?.msg);
      }
  }

  useEffect(() => {
    if (!user?.acceptedTerms && user?.loginUsername) {
      setTermsAccepted(false);
    }
  }, [user]);

  return (
    <HeaderContext.Provider
      value={{
        ssnKycModalOpen,
        setSnnKycModalOpen,
        kycModalOpen,
        setKycModalOpen,
        termsAccepted,
        userTermsAccepted,
      }}
    >
      {children}
    </HeaderContext.Provider>
  );
}
