import { PopulatedSlipLeg } from "common/src/services/dtos/ContestDtos";
import { useSlipBuilder } from "common/src/context/picks/SlipBuilderContext";
import { PropBonusType, PropDecision } from "@phantasia/model-interfaces";
import { getPropKeyShortName } from "common/src/util/helpers";
import { PicksArrow } from "../utils/PicksArrow";
import { FormattedSmallPlayerGame } from "../board/BoardMyPicksSection";
import { PicksBonusArrow } from "../utils/PicksBonusArrow";

export function BoardMyPicksListItem({ leg }: { leg: PopulatedSlipLeg }) {
  const { removeLegFromSlip, addLegToSlip } = useSlipBuilder();

  const prop = leg.prop;
  const player = prop.player;

  function ValueText() {
    if (prop.boost)
      return (
        <div className={"text-gradient-fire-sale"}>
          <div className={"flex-row items-center space-x-2"}>
            <i className="bx bxs-hot font-sm" />
            <span className={"font-sm weight-500"}>{leg.value}</span>
          </div>
        </div>
      );

    return (
      <span className={"text-neutral-2 font-sm weight-500"}>{leg.value}</span>
    );
  }

  return (
    <div className={"flex-row items-center space-x-5 p-6"}>
      <div
        className={`bg-base-4 flex flex-center rounded-full h-9 w-9 overflow-hidden`}
      >
        <img src={player.photo} alt={"player"} className={"h-9 contain"} />
      </div>
      <div className={"flex-col flex-1 truncate"}>
        <span className={"text-neutral-2 font-sm weight-500 truncate"}>
          {player.name}
        </span>
        <span
          className={"font-xs weight-300 text-neutral-1"}
        > {player.position
          ? `${player.position}  •  ${player.team}`
          : `${player.team}`}
        </span>
        <FormattedSmallPlayerGame game={prop.game} player={player} />
      </div>
      <DecisionButton
        boosted={leg.bonus_type === PropBonusType.Bonus}
        onClick={() => addLegToSlip(leg.prop, PropDecision.OVER)}
        active={leg.prop_decision === PropDecision.OVER}
      />
      <div className={`w-9 flex-col items-center`}>
        <ValueText />

        <span className={"text-neutral-1 font-xxs weight-300"}>
          {getPropKeyShortName(prop.prop_key)}
        </span>
      </div>
      <DecisionButton
        rotate
        boosted={leg.bonus_type === PropBonusType.Bonus}
        onClick={() => addLegToSlip(leg.prop, PropDecision.UNDER)}
        active={leg.prop_decision === PropDecision.UNDER}
      />
      <div
        onClick={(e) => {
          e.stopPropagation();
          removeLegFromSlip(prop._id);
        }}
        className={
          "h-9 w-9 cursor-pointer hover:bg-danger-0 group transition rounded-full flex flex-center"
        }
      >
        <i className="bx bx-trash text-neutral-2 group-hover:text-danger-1" />
      </div>
    </div>
  );
}

function DecisionButton({
  onClick,
  rotate,
  active,
  boosted,
}: {
  onClick: () => void;
  rotate?: boolean;
  active: boolean;
  boosted?: boolean;
}) {
  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
        onClick();
      }}
      className={`${
        boosted && active
          ? "bg-warning-0 hover:bg-warning-0"
          : active
          ? "bg-primary-0 hover:bg-danger-0"
          : "outline-1"
      } group z-1  min-w-9 w-9 h-9 rounded-full flex flex-center flex-center hover:bg-base-2 transition cursor-pointer`}
    >
      {!boosted || !active ? (
        <PicksArrow
          className={`w-6 h-6 transition ${
            active ? "group-hover:fill-danger fill-primary" : "fill-base-3"
          } ${rotate ? "bx-rotate-180" : ""}`}
        />
      ) : (
        <PicksBonusArrow
          fillColor={"#ecc440"}
          className={`w-6 h-6 transition ${
            active ? "group-hover:fill-danger fill-primary" : "fill-base-3"
          } ${rotate ? "bx-rotate-180" : ""}`}
        />
      )}
    </div>
  );
}
