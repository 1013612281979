import { SlipPerformanceTracker } from "./SlipPerformanceTracker";
import { SlipList } from "../lists/SlipList";
import { useLiveSlips } from "common/src/context/picks/LiveSlipsContext";

export function LivePicksComponent() {
  const { liveSlips, selectedSlip, refresh, setSelectedSlip } = useLiveSlips();

  return (
    <div className={"flex-1 overflow-auto"}>
      <div className={"grid grid-cols-2 h-full overflow-auto"}>
        <div
          className={"p-8 ring-r-1 max-h-full h-full overflow-auto space-y-8"}
        >
          <SlipList
            slips={liveSlips}
            selectedSlipId={selectedSlip?._id ?? "1"}
            setSelectedSlip={setSelectedSlip}
          />
        </div>
        <div className={"ring-r-1 max-h-full h-full overflow-auto space-y-12"}>
          <SlipPerformanceTracker
            slips={liveSlips}
            selectedSlip={selectedSlip}
            refresh={refresh}
          />
        </div>
      </div>
    </div>
  );
}
