import { CircularProgress } from "@mui/material";
import { MOCK_WALLET } from "common/src/services/wallet/mockWallet";
import React, { useEffect, useRef, useState } from "react";
import PhoneInput from "react-phone-number-input";
import { GradientButton3, TextButton2 } from "../atoms/Button";
import { Input } from "../atoms/Input";
import Wallet from "../wallet/Wallet";
import {
  triggerEventLogin,
  triggerEventRafAppOpen,
} from "../../GoogleAnalytics";
import { default as logoImg } from "../../assets/LogoAlt.svg";
import { HorizontalDivider } from "../atoms/Divider";
import webSS from "../../assets/web-screenshot.png";
import { useParams } from "react-router-dom";
import { MAGIC_SMS_BLACKLIST } from "common/src/services/GeolocationService";
import { useMagicContext } from "common/src/context/auth/MagicContext";
import { useCommonWallet } from "common/src/services/wallet/UseCommonWallet";
import { useWalletAuthContext } from "common/src/context/auth/WalletAuthContext";

const LoginForm = () => {
  const { refWallet } = useParams();

  useEffect(() => {
    if (refWallet) triggerEventRafAppOpen();
  }, [refWallet]);

  return (
    <div className="bg-base-1 relative overflow-hidden h-screen w-screen grid grid-cols-2">
      <InfoForm />

      <div className={"h-full flex-col bg-base-0 p-12"}>
        <div className={"flex-row items-baseline space-x-5"}>
          <h1
            style={{ fontSize: "calc(20px + 1vw)" }}
            className="weight-400 text-neutral-2 opacity-75 m-0"
          >
            The Future of
          </h1>
          <h1
            style={{ fontSize: "calc(20px + 1vw)" }}
            className="text-neutral-2 weight-500 m-0 text-gradient-light no-wrap w-min"
          >
            Fantasy Sports
          </h1>
        </div>

        <div className={"flex-row items-center space-x-4 mt-12"}>
          <i className="bx bx-sm bxs-badge-check text-gradient-light" />
          <span className={"font-lg weight-300 text-neutral-2"}>
            Instant Payouts
          </span>
        </div>
        <div className={"flex-row items-center space-x-4 mt-6"}>
          <i className="bx bx-sm bxs-badge-check text-gradient-light" />
          <span className={"font-lg weight-300 text-neutral-2"}>
            Play against friends, no house
          </span>
        </div>
        <div className={"flex-row items-center space-x-4 mt-6"}>
          <i className="bx bx-sm bxs-badge-check text-gradient-light" />
          <span className={"font-lg weight-300 text-neutral-2"}>
            Save money with the lowest fees
          </span>
        </div>
        <div className={"relative mt-14"}>
          <img
            src={webSS}
            style={{ width: "70vw" }}
            className={"ring-2 rounded-6 ring-neutral-1 absolute t-0 l-0"}
            alt={"web"}
          />
        </div>
      </div>
    </div>
  );
};

function InfoForm() {
  const wallet = useCommonWallet();
  const { onConnect } = useWalletAuthContext();

  if (wallet?.name === MOCK_WALLET) onConnect();

  return (
    <div className="z-3 w-11/12 max-w-29 flex m-auto">
      <SignInForm />
    </div>
  );
}

function SignInForm() {
  const { onConnect } = useWalletAuthContext();
  const { loading, phone, email, setPhone, setEmail, handleSubmit } =
    useMagicContext();

  const inputRef = useRef(null);
  const [showEmail, setShowEmail] = useState(false);

  useEffect(() => {
    inputRef.current?.focus();
  }, [showEmail]);

  function getLoginButtonContent() {
    if (loading) return <CircularProgress color="inherit" />;
    return "Sign in";
  }

  function PhoneForm() {
    if (showEmail) return null;
    return (
      <div className="flex-row items-baseline mb-5 w-full">
        <span className="text-neutral-2 font-md weight-500 pl-3 mb-6">
          Enter a Phone Number
        </span>

        <span className="flex-grow" />
        <span
          className="text-primary-1 font-sm weight-300 cursor-pointer"
          onClick={() => setShowEmail(true)}
        >
          Use email instead
        </span>
      </div>
    );
  }

  function EmailForm() {
    if (!showEmail) return null;
    return (
      <div className="flex-row items-baseline mb-5 w-full">
        <span className="text-neutral-2 font-md weight-500 pl-3 mb-6">
          Enter an email
        </span>

        <span className="flex-grow" />
        <span
          className="text-primary-1 font-sm weight-300 cursor-pointer"
          onClick={() => {
            setShowEmail(false);
          }}
        >
          Use phone number instead
        </span>
      </div>
    );
  }

  return (
    <div className="flex-col animation-reveal w-full scroll-y">
      <div className={"flex-row  mb-9 w-full flex-center"}>
        <img src={logoImg} alt="logo" className={"w-min"} height="56" />
        <span className="font-xl weight-400 text-neutral-2 letter-spacing-lg flex line-height-md">
          PHANTASIA
        </span>
      </div>

      <PhoneForm />
      <EmailForm />

      {!showEmail ? (
        <PhoneInput
          defaultCountry="US"
          placeholder="Phone Number"
          value={phone}
          onChange={setPhone}
          ref={inputRef}
        />
      ) : (
        <Input
          placeholder={"Email"}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          classes={"px-8"}
        />
      )}
      <SmsBlacklistDropdown showEmail={showEmail} setShowEmail={setShowEmail} />

      <GradientButton3
        className={"mt-8"}
        onClick={() => {
          handleSubmit(undefined, true);
          triggerEventLogin();
        }}
      >
        {getLoginButtonContent()}
      </GradientButton3>
      <div className={"relative"}>
        <div className={"t-0 b-0 l-0 r-0 flex flex-center z-11 absolute"}>
          <span
            className={
              "bg-base-1 text-neutral-2 text-center w-10 font-md weight-300"
            }
          >
            or
          </span>
        </div>
        <HorizontalDivider className={"min-h-1 w-full my-12"} />
      </div>

      <span className="text-neutral-2 font-md weight-500 pl-3 mb-6">
        Connect Solana Wallet
      </span>

      <Wallet onConnect={onConnect} />
    </div>
  );
}

function SmsBlacklistDropdown({ showEmail, setShowEmail }) {
  const [open, setOpen] = useState(false);

  if (showEmail) return null;

  return (
    <div className={"flex-col outline-1 rounded-5 p-6 mt-4"}>
      <div onClick={() => setOpen(!open)} className={"flex-row items-center"}>
        <span className={"font-xs weight-300 text-neutral-2 flex-1"}>
          Some countries cannot use SMS. See countries{" "}
          <span
            className={"text-primary-1 hover:text-primary-2 cursor-pointer"}
            onClick={() => {}}
          >
            here
          </span>
        </span>
        <i
          className={`bx hover:text-neutral-2 text-neutral-1 bx-chevron-down transition cursor-pointer ${
            open ? "bx-rotate-180" : ""
          }`}
        />
      </div>
      {open ? (
        <div className={"animation-expand-y flex-col space-y-5 mt-5"}>
          <span className={"font-sm weight-300 text-neutral-2"}>
            Users in the following countries must use email for their account:
          </span>
          <span className={"font-sm weight-300 text-neutral-2"}>
            {MAGIC_SMS_BLACKLIST.join(", ")}
          </span>
          <TextButton2
            onClick={() => setShowEmail(true)}
            className={"w-min px-0 ml--6 py-0"}
            color={"primary"}
          >
            Use email to sign up
          </TextButton2>
        </div>
      ) : null}
    </div>
  );
}

export default LoginForm;
