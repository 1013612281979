export type PhantasiaTheme = {
  property: string;
  value: string;
};

export const PhantasiaDark = [
  { property: "--base-0", value: "#000000" },
  { property: "--base-1", value: "#09090b" },
  { property: "--base-2", value: "#141519" },
  { property: "--base-3", value: "#202127" },
  { property: "--base-4", value: "#3f3f46" },
  { property: "--base-5", value: "#16171a" },
  { property: "--neutral-0", value: "#605f5f" },
  { property: "--neutral-1", value: "#a1a1a1" },
  { property: "--neutral-2", value: "#fff" },
  { property: "--primary-0", value: "#050534" },
  { property: "--primary-1", value: "#3B82F6" },
  { property: "--primary-2", value: "#d7e2ff" },
  { property: "--secondary-0", value: "#022031" },
  { property: "--secondary-1", value: "#06B6D4" },
  { property: "--secondary-2", value: "#cee8fc" },
  { property: "--success-0", value: "#022309" },
  { property: "--success-1", value: "#34d399" },
  { property: "--success-2", value: "#d6ffe9" },
  { property: "--danger-0", value: "#23020a" },
  { property: "--danger-1", value: "#f87171" },
  { property: "--danger-2", value: "#ffe2e8" },
  { property: "--warning-0", value: "#232002" },
  { property: "--warning-1", value: "#d5c50f" },
  { property: "--warning-2", value: "#fffce9" },
  { property: "--base-rgb", value: "9, 9, 11" },
  { property: "--base-2-rgb", value: "20, 21, 25" },
  { property: "--neutral-rgb", value: "255, 255, 255" },
];

export const PhantasiaLight = [
  { property: "--base-0", value: "#f4f4f5" },
  { property: "--base-1", value: "#fff" },
  { property: "--base-2", value: "#F7F7F8" },
  { property: "--base-3", value: "#e4e4e7" },
  { property: "--base-4", value: "#e2e2e3" },
  { property: "--base-5", value: "#fff" },
  { property: "--neutral-0", value: "#d1d5db" },
  { property: "--neutral-1", value: "#6b7280" },
  { property: "--neutral-2", value: "#18181b" },
  { property: "--primary-2", value: "#0c2056" },
  { property: "--primary-1", value: "#1e71f5" },
  { property: "--primary-0", value: "#dbeafe" },
  { property: "--secondary-2", value: "#022031" },
  { property: "--secondary-1", value: "#00d4f5" },
  { property: "--secondary-0", value: "#cee8fc" },
  { property: "--success-2", value: "#022309" },
  { property: "--success-1", value: "#10b981" },
  { property: "--success-0", value: "#ecfdf5" },
  { property: "--danger-2", value: "#23020a" },
  { property: "--danger-1", value: "#dc2626" },
  { property: "--danger-0", value: "#fef2f2" },
  { property: "--warning-2", value: "#1e1b02" },
  { property: "--warning-1", value: "#facc15" },
  { property: "--warning-0", value: "#fffce9" },
  { property: "--base-rgb", value: "255, 255, 255" },
  { property: "--base-2-rgb", value: "244, 244, 245" },
  { property: "--neutral-rgb", value: "0, 0, 0" },
];

export const Midnight = [
  { property: "--base-0", value: "#00080F" },
  { property: "--base-1", value: "#030C19" },
  { property: "--base-2", value: "#071321" },
  { property: "--base-3", value: "#0C1D31" },
  { property: "--base-4", value: "#0f233b" },
  { property: "--base-5", value: "#071321" },
  { property: "--neutral-0", value: "#364555" },
  { property: "--neutral-1", value: "#364555" },
  { property: "--neutral-2", value: "#fff" },
  { property: "--primary-0", value: "#010128" },
  { property: "--primary-1", value: "#3450ff" },
  { property: "--primary-2", value: "#d7e2ff" },
  { property: "--secondary-0", value: "#022031" },
  { property: "--secondary-1", value: "#23dff8" },
  { property: "--secondary-2", value: "#cee8fc" },
  { property: "--success-0", value: "#022309" },
  { property: "--success-1", value: "#10b981" },
  { property: "--success-2", value: "#d6ffe9" },
  { property: "--danger-0", value: "#23020a" },
  { property: "--danger-1", value: "#f1022a" },
  { property: "--danger-2", value: "#ffe2e8" },
  { property: "--warning-0", value: "#232002" },
  { property: "--warning-1", value: "#d5c50f" },
  { property: "--warning-2", value: "#fffce9" },
  { property: "--base-rgb", value: "3, 12, 25" },
  { property: "--base-2-rgb", value: "7, 19, 33" },
  { property: "--neutral-rgb", value: "255, 255, 255" },
];

export const PhantasiaThemes = {
  DarkTheme: PhantasiaDark,
  LightTheme: PhantasiaLight,
  Midnight: Midnight,
};
