export class Singletons {
  static magic: any;
  static toastService: ToastService;
  static store: any;
  static apiUrl: string;

  static set(args: {
    magic: any;
    toastService: ToastService;
    store: any;
    apiUrl: string;
  }) {
    Singletons.magic = args.magic;
    Singletons.toastService = args.toastService;
    Singletons.store = args.store;
    Singletons.apiUrl = args.apiUrl;
  }
}

export interface ToastService {
  error: (header: string, message: string) => void;
  success: (header: string, message: string) => void;
}
