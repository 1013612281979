import { Modal } from "@mui/material";
import {
  getInjuryColor,
  getTeamColorHex,
} from "common/src/util/ColorTranslater";
import { getGradientPairForHexColor } from "common/src/util/gradientGenerator";
import {
  League,
  Player,
  PlayerGamePerformance,
  PropKey,
} from "@phantasia/model-interfaces";
import { ReactNode, useEffect, useState } from "react";
import { GameLogComponent } from "./player-stat-components/GameLogComponent";
import { GameWithTeams } from "common/src/services/dtos/ContestDtos";
import { PlayerStatType } from "common/src/util/constants";
import {
  GameLog,
  getGameLog,
  getPlayerProjectedValues,
} from "common/src/util/JoinContestUtil";
import { VerticalDivider } from "../atoms/Divider";
import { PlayerLast5Summary } from "./player-stat-components/PlayerLast5Summary";

const TABS = {
  LOGS: "logs",
  CURRENT: "current",
  NEWS: "news",
};

export default function PlayerStatModal({
  open,
  currentGame,
  statType,
  player,
  league,
  ControlsComponent,
  onClose,
  salary,
  defaultStat,
  opposingPitcher,
}: {
  open: boolean;
  currentGame: GameWithTeams;
  statType: PlayerStatType;
  player: Player<string> | null;
  league: League;
  onClose: () => void;
  performance?: PlayerGamePerformance<string>;
  ControlsComponent?: ReactNode;
  salary?: number;
  defaultStat?: PropKey;
  opposingPitcher?: Player<string>;
}) {
  const [selectedTab, setSelectedTab] = useState(TABS.CURRENT);
  const [playerGameLog, setPlayerGameLog] = useState<GameLog[] | null>(null);

  const [projectedValues, setProjectedValues] = useState<any | null>(null);

  useEffect(() => {
    if (open) {
      getLogs().catch((e) => console.error(e));
      getProjectedValues().catch((e) => console.error(e));
      getProjectedValues().catch((e) => console.error(e));
    } else {
      setPlayerGameLog(null);
      setProjectedValues(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  if (!open || !player) return null;

  const team_color = getTeamColorHex(player.team ?? "", league);

  async function getProjectedValues() {
    if (!player) return;
    const values = await getPlayerProjectedValues(player._id, currentGame._id);
    setProjectedValues(values);
  }

  async function getLogs() {
    if (!player) return;
    const logs = await getGameLog(player._id, league);
    setPlayerGameLog(logs);
  }

  let avgFp = 0;

  playerGameLog?.forEach((log) => {
    if (log.fantasy_points) {
      if (league === League.NFL) avgFp += log.fantasy_points;
      else avgFp += log.fantasy_points;
    }
  });

  return (
    <Modal
      open={open}
      className="flex-col outline-none modal flex-center"
      onClose={() => {
        onClose();
      }}
      closeAfterTransition
      BackdropProps={{
        timeout: 500,
      }}
    >
      <div
        className={
          "relative flex-col flex-center outline-none max-h-11/12 h-11/12 w-30"
        }
      >
        <div
          className={`flex-col m-auto flex-1 rounded-9 w-full max-w-30 w-30 h-full bg-base-1 animation-from-bottom outline-none overflow-auto`}
        >
          <div className="flex-col flex-1 relative">
            <div className={"sticky t-0 flex-col w-full glass z-10"}>
              <PlayerHeader
                player={player}
                team_color={team_color}
                avgFp={avgFp / 5}
                salary={salary}
                projectedValues={projectedValues}
                onClose={onClose}
                opposingPitcher={opposingPitcher}
              />
              <Tabs selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
            </div>
            <div className={"flex-1 overflow-auto"}>
              <div className="h-full max-h-full flex-1">
                <div className="flex-col flex-1 h-full overflow-auto pb-12">
                  {selectedTab === TABS.CURRENT ? (
                    <PlayerLast5Summary
                      teamColor={team_color}
                      gameLog={playerGameLog}
                      projectedValues={projectedValues}
                      defaultStat={defaultStat}
                      league={league}
                      position={player.position}
                    />
                  ) : selectedTab === TABS.LOGS ? (
                    <div className="flex pb-8 flex-col">
                      <GameLogComponent
                        playerGameLog={playerGameLog as GameLog[]}
                        player={player}
                        league={league}
                      />
                      <br />
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={
            "flex absolute b-0 r-0 l-0 z-10 rounded-b-r-9 rounded-b-l-9 overflow-hidden"
          }
        >
          {ControlsComponent}
        </div>
      </div>
    </Modal>
  );
}

function PlayerHeader({
  player,
  team_color,
  avgFp,
  salary,
  projectedValues,
  onClose,
  opposingPitcher,
}: {
  player: Player<string>;
  team_color: string;
  avgFp: number;
  onClose: () => void;
  salary?: number;
  projectedValues?: any;
  opposingPitcher?: Player<string>;
}) {
  if (!player) return null;

  let projStat = `$${salary}`;
  let statLabel = "Salary";
  if (!salary && projectedValues) {
    projStat = Object.values(projectedValues)?.length?.toString();
    statLabel = "Picks available";
  }

  return (
    <div className="flex-row space-x-8 mb-8 items-center">
      <div className={"flex-col items-center w-full"}>
        <div className={"flex-row items-center pt-8 w-full px-8"}>
          <div className={"w-12"}>
            <i
              className={
                "bx bx-left-arrow-alt bx-sm text-neutral-2 cursor-pointer hover-left transition"
              }
              onClick={onClose}
            />
          </div>

          <div className={"flex-center flex-1"}>
            <div
              className="rounded-full"
              style={{
                background: `linear-gradient(to right, ${team_color}, ${getGradientPairForHexColor(
                  team_color
                )}`,
                padding: 10,
              }}
            >
              <div className="min-h-14 min-w-14 h-14 w-14 ring-base-1 ring-8 bg-base-1 overflow-hidden rounded-full  flex flex-center ">
                <img
                  src={player.photo}
                  alt="player"
                  style={{ height: 90, width: 90 }}
                  className="contain rounded-full bg-base-3 "
                />
              </div>
            </div>
          </div>

          <span className={"white w-12 align-end font-xl flex-row"}>
            #{player.number} <i className="bx bxs-t-shirt ml-3" />
          </span>
        </div>

        <div className={"flex-row items-end space-x-6 mt-4"}>
          <h3 className="m-0 font-xl text-neutral-2 weight-500 mb-0 mt-0 flex">
            {player.name}
          </h3>
        </div>
        <div className="flex-row space-x-3 items-center">
          <span className={"font-md weight-400 text-neutral-1"}>
            {player.position}
          </span>
          <span className={"font-sm weight-400 text-neutral-1"}>•</span>
          <span className={"font-md weight-400 text-neutral-1"}>
            {player.team}
          </span>
        </div>
        <InjuryTag player={player} />
        <div className={"flex-row space-x-7 mt-5 items-center"}>
          <div className="flex-col flex-1 items-center">
            <span className="font-xl text-neutral-2 weight-500 flex">
              {avgFp?.toFixed(1)}
            </span>
            <span className="font-xs text-neutral-1 weight-300 flex no-wrap">
              FP in last 5
            </span>
          </div>
          <VerticalDivider className={"max-w-1"} />
          <div className="flex-col flex-1 items-center">
            <span className="font-xl text-neutral-2 weight-500 flex">
              {projStat}
            </span>
            <span className="font-xs text-neutral-1 weight-300 flex no-wrap">
              {statLabel}
            </span>
          </div>
          {opposingPitcher ? (
            <>
              <VerticalDivider className={"max-w-1"} />
              <img
                src={opposingPitcher.photo}
                alt="player"
                className="contain rounded-full bg-base-3 h-9 w-9"
              />
              <div className={"flex-col"}>
                <span className={"font-xs weight-300 text-neutral-1"}>
                  Opponent
                </span>
                <span className={"font-sm weight-400 text-neutral-2"}>
                  {`${opposingPitcher.name}  •  ${opposingPitcher.team}`}
                </span>
              </div>
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
}

function InjuryTag({ player }: { player: Player<string> }) {
  if (!player.injury_status || player.injury_status === "Healthy") return null;
  return (
    <span
      className={`font-sm weight-600 p-0 text-${getInjuryColor(
        player.injury_status
      )}-700`}
    >
      {player.injury_status}
    </span>
  );
}

function Tabs({
  selectedTab,
  setSelectedTab,
}: {
  selectedTab: string;
  setSelectedTab: (tab: string) => void;
}) {
  return (
    <div className="flex-row relative outline-b-1 ">
      <FilterButton
        text="Summary"
        active={selectedTab === TABS.CURRENT}
        onClick={() => setSelectedTab(TABS.CURRENT)}
      />
      <FilterButton
        text="Game Logs"
        active={selectedTab === TABS.LOGS}
        onClick={() => setSelectedTab(TABS.LOGS)}
      />
      <div
        style={{
          left: selectedTab === TABS.CURRENT ? 0 : "50%",
        }}
        className={`h-2 w-1/2 bg-primary-1 absolute transition-long b-0 z-11`}
      />
    </div>
  );
}

function FilterButton({
  active,
  onClick,
  text,
}: {
  active: boolean;
  onClick: () => void;
  text: string;
}) {
  return (
    <div
      onClick={onClick}
      className={`rounded-5 cursor-pointer p-6 hover:bg-base-2 flex-1 transition flex flex-center`}
    >
      <span
        className={`weight-500 ${
          active ? "text-neutral-2" : "text-neutral-1"
        } font-md`}
      >
        {text}
      </span>
    </div>
  );
}
