import React from "react";

export function FooterTrigger({ openFooter }) {
  return (
    <div className="fixed b-0 w-full h-6 bg-base-2 flex-row items-center justify-end z-9">
      <div
        className="h-full px-9 hover-up flex-row items-center cursor-pointer transition duration-200"
        onClick={openFooter}
      >
        <i className="bx bx-chevron-up mr-5 text-neutral-1"></i>
        <span className="font-sm text-neutral-1 weight-300">
          Show important info
        </span>
      </div>
    </div>
  );
}
