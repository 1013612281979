export default function ProgressBar({ value, color, height = "2px" }) {
  return (
    <div
      className="relative w-full overflow-hidden rounded-full"
      style={{ height: height }}
    >
      <div
        className={`w-full absolute rounded-full top-0 right-0 left-0 bg-${color}-0`}
        style={{ height: height }}
      />
      <div
        className={`absolute top-0 left-0 rounded-full z-4 rounded-5 transition-long bg-gradient-${color}`}
        style={{ height: height, width: `${value}%` }}
      />
    </div>
  );
}
