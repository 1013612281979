import React, { ReactNode, useContext, useState } from "react";
import {
  GameWithScore,
  getGameScoresByDate,
} from "../services/GameScoreService";
import { GAME_STATUSES } from "../util/constants";
import moment from "moment/moment";

interface HomePageScoresContextProps {
  matches: GameWithScore[] | null;
  getUpcomingGames: (extraDays?: number) => void;
}

export const HomePageScoresContext =
  React.createContext<HomePageScoresContextProps>({
    matches: null,
    getUpcomingGames: (extraDays?: number) => {},
  });

export function HomePageScoresContextProvider({
  children,
}: {
  children: ReactNode;
}) {
  const [matches, setMatches] = useState<GameWithScore[] | null>(null);

  const getUpcomingGames = async (extraDays: number = 1) => {
    if (
      matches &&
      matches?.length !== 0 &&
      !matches?.some((match) => match.status === GAME_STATUSES.IN_PROGRESS)
    )
      return;

    const start = moment(new Date()).local().startOf("day");
    const end = moment(start).local().add(extraDays, "days");

    const matches_response = await getGameScoresByDate(
      start.utc().toDate(),
      end.utc().toDate()
    );
    let match_scores: GameWithScore[] = [];
    matches_response.forEach((match: GameWithScore) => {
      if (
        match.away_team !== "AL" &&
        match.home_team_ID &&
        match.away_team_ID &&
        !match_scores.includes(match)
      ) {
        match_scores.push(match);
      }
    });
    setMatches(match_scores);
  };

  return (
    <HomePageScoresContext.Provider
      value={{
        matches,
        getUpcomingGames,
      }}
    >
      {children}
    </HomePageScoresContext.Provider>
  );
}

export const useTodaysScores = () => useContext(HomePageScoresContext);
