import StakingContextProvider from "common/src/context/StakingContext";
import React from "react";
import StakingComponent from "../components/staking/StakingComponent";

const Staking = () => {
  return (
    <StakingContextProvider>
      <StakingComponent />
    </StakingContextProvider>
  );
};

export default Staking;
