import {
  CsgoPlayerPerformanceStats,
  EplPositions,
  League,
  MlbPlayerPerformanceStats,
  MlbPositions,
  NbaPlayerPerformanceStats,
  NbaPositions,
  NflPlayerPerformanceStats,
  NflPositions,
  NhlPlayerPerformanceStats,
  NhlPositions,
  PlayerGamePerformance,
  PropDecision,
  PropKey,
} from "@phantasia/model-interfaces";

export function getPropPerformanceStatus(
  performance: PlayerGamePerformance<string>,
  prop_key: PropKey,
  league: League,
  position:
    | NflPositions
    | NhlPositions
    | EplPositions
    | NbaPositions
    | MlbPositions
): number {
  if (!performance) return 0;

  let stats;

  switch (prop_key) {
    case PropKey.FANTASY_POINTS:
      return performance.fantasy_points;
    case PropKey.FANTASY_POINTS_PITCHER:
      return performance.fantasy_points_ppr ?? 0;
    case PropKey.PASSING_YARDS:
      return (performance.player_performance_stats as NflPlayerPerformanceStats)
        .passing_yards;
    case PropKey.PASSING_COMPLETIONS:
      return (performance.player_performance_stats as NflPlayerPerformanceStats)
        .passing_completions;
    case PropKey.RUSHING_YARDS:
      return (performance.player_performance_stats as NflPlayerPerformanceStats)
        .rushing_yards;
    case PropKey.RECEPTIONS:
      return (performance.player_performance_stats as NflPlayerPerformanceStats)
        .receptions;
    case PropKey.RECEIVING_YARDS:
      return (performance.player_performance_stats as NflPlayerPerformanceStats)
        .receiving_yards;
    case PropKey.RUSHING_RECEIVING_YARDS:
      return (
        (performance.player_performance_stats as NflPlayerPerformanceStats)
          .rushing_yards +
        (performance.player_performance_stats as NflPlayerPerformanceStats)
          .receiving_yards
      );
    case PropKey.POINTS:
      return (performance.player_performance_stats as NbaPlayerPerformanceStats)
        .points;
    case PropKey.REBOUNDS:
      return (performance.player_performance_stats as NbaPlayerPerformanceStats)
        .rebounds;
    case PropKey.ASSISTS:
      return (performance.player_performance_stats as NbaPlayerPerformanceStats)
        .assists;
    case PropKey.STEALS:
      return (performance.player_performance_stats as NbaPlayerPerformanceStats)
        .steals;
    case PropKey.THREE_POINTERS:
      return (performance.player_performance_stats as NbaPlayerPerformanceStats)
        .three_pointers_made;
    case PropKey.BLOCKS:
      if (league === League.NHL)
        return (
          performance.player_performance_stats as NhlPlayerPerformanceStats
        ).blocks;
      return (performance.player_performance_stats as NbaPlayerPerformanceStats)
        .blocked_shots;
    case PropKey.POINTS_ASSISTS:
      stats = performance.player_performance_stats as NbaPlayerPerformanceStats;
      return stats.points + stats.assists;
    case PropKey.POINTS_REBOUNDS:
      stats = performance.player_performance_stats as NbaPlayerPerformanceStats;
      return stats.points + stats.rebounds;
    case PropKey.POINTS_ASSISTS_REBOUNDS:
      stats = performance.player_performance_stats as NbaPlayerPerformanceStats;
      return stats.points + stats.rebounds + stats.assists;
    case PropKey.BASES:
      stats = performance.player_performance_stats as MlbPlayerPerformanceStats;
      return (
        stats.singles +
        stats.doubles * 2 +
        stats.triples * 3 +
        stats.home_runs * 4
      );
    case PropKey.HITS:
      return (performance.player_performance_stats as MlbPlayerPerformanceStats)
        .hits;
    case PropKey.STRIKEOUTS:
      return (performance.player_performance_stats as MlbPlayerPerformanceStats)
        .pitching_strikeouts;
    case PropKey.EARNED_RUNS:
      return (performance.player_performance_stats as MlbPlayerPerformanceStats)
        .pitching_earned_runs;
    case PropKey.WALKS_ALLOWED:
      return (performance.player_performance_stats as MlbPlayerPerformanceStats)
        .pitching_walks;
    case PropKey.HITS_ALLOWED:
      return (performance.player_performance_stats as MlbPlayerPerformanceStats)
        .pitching_hits;
    case PropKey.PITCHING_TOTAL_BASES:
      return (performance.player_performance_stats as MlbPlayerPerformanceStats)
        .pitching_total_bases;
    case PropKey.GOALIE_SAVES:
      return (performance.player_performance_stats as NhlPlayerPerformanceStats)
        .goaltending_saves;
    case PropKey.GOALS:
      return (performance.player_performance_stats as NhlPlayerPerformanceStats)
        .goals;
    case PropKey.GOALS_AGAINST:
      return (performance.player_performance_stats as NhlPlayerPerformanceStats)
        .goaltending_goals_against;
    case PropKey.SHOTS_ON_GOAL:
      return (performance.player_performance_stats as NhlPlayerPerformanceStats)
        .shots_on_goal;
    case PropKey.FACEOFFS_WON:
      return (performance.player_performance_stats as NhlPlayerPerformanceStats)
        .faceoffs_won;
    case PropKey.MINUTES_ON_ICE:
      return (performance.player_performance_stats as NhlPlayerPerformanceStats)
        .minutes;
    case PropKey.INNINGS_PITCHED:
      return (performance.player_performance_stats as MlbPlayerPerformanceStats)
        .innings_pitched_full;
    case PropKey.PITCHING_OUTS:
      return (performance.player_performance_stats as MlbPlayerPerformanceStats)
        .outs;
    case PropKey.BATTING_RUNS:
      return (performance.player_performance_stats as MlbPlayerPerformanceStats)
        .runs;
    case PropKey.BATTING_HOME_RUNS:
      return (performance.player_performance_stats as MlbPlayerPerformanceStats)
        .home_runs;
    case PropKey.BATTING_RBI:
      return (performance.player_performance_stats as MlbPlayerPerformanceStats)
        .runs_batted_in;
    case PropKey.STOLEN_BASES:
      return (performance.player_performance_stats as MlbPlayerPerformanceStats)
        .stolen_bases;
    case PropKey.HITS_RUNS_RBI:
      return (
        (performance.player_performance_stats as MlbPlayerPerformanceStats)
          .hits +
        (performance.player_performance_stats as MlbPlayerPerformanceStats)
          .runs +
        (performance.player_performance_stats as MlbPlayerPerformanceStats)
          .runs_batted_in
      );
    case PropKey.BATTING_WALKS:
      return (performance.player_performance_stats as MlbPlayerPerformanceStats)
        .walks;
    case PropKey.BATTING_STRIKEOUTS:
      return (performance.player_performance_stats as MlbPlayerPerformanceStats)
        .strike_outs;
    case PropKey.ASSISTS_REBOUNDS:
      return (
        (performance.player_performance_stats as NbaPlayerPerformanceStats)
          .assists +
        (performance.player_performance_stats as NbaPlayerPerformanceStats)
          .rebounds
      );
    case PropKey.TURNOVERS:
      return (performance.player_performance_stats as NbaPlayerPerformanceStats)
        .turnovers;
    case PropKey.STEALS_BLOCKS:
      return (
        (performance.player_performance_stats as NbaPlayerPerformanceStats)
          .steals +
        (performance.player_performance_stats as NbaPlayerPerformanceStats)
          .blocked_shots
      );
    case PropKey.STEALS_REBOUNDS:
      return (
        (performance.player_performance_stats as NbaPlayerPerformanceStats)
          .steals +
        (performance.player_performance_stats as NbaPlayerPerformanceStats)
          .rebounds
      );
    case PropKey.FREE_THROWS:
      return (performance.player_performance_stats as NbaPlayerPerformanceStats)
        .free_throws_made;
    case PropKey.KILLS_1:
      return (
        performance.player_performance_stats as CsgoPlayerPerformanceStats
      ).stat_map_1_kills;
    case PropKey.KILLS_1_2:
      return (
        Number(
          (performance.player_performance_stats as CsgoPlayerPerformanceStats)
            .stat_map_1_kills
        ) +
        Number(
          (performance.player_performance_stats as CsgoPlayerPerformanceStats)
            .stat_map_2_kills
        )
      );
    case PropKey.KILLS_2:
      return (
        performance.player_performance_stats as CsgoPlayerPerformanceStats
      ).stat_map_2_kills;
    case PropKey.KILL_1_2_3:
      return Number(
        (performance.player_performance_stats as CsgoPlayerPerformanceStats)
          .stat_map_1_kills +
          Number(
            (performance.player_performance_stats as CsgoPlayerPerformanceStats)
              .stat_map_2_kills
          ) +
          Number(
            (performance.player_performance_stats as CsgoPlayerPerformanceStats)
              .stat_map_3_kills
          )
      );
    case PropKey.KILLS_3:
      return (
        performance.player_performance_stats as CsgoPlayerPerformanceStats
      ).stat_map_3_kills;
    case PropKey.KILLS_4:
      return Number(
        (performance.player_performance_stats as CsgoPlayerPerformanceStats)
          .stat_map_4_kills
      );
    case PropKey.KILLS_5:
      return Number(
        (performance.player_performance_stats as CsgoPlayerPerformanceStats)
          .stat_map_5_kills
      );
    case PropKey.HEADSHOTS_1_2:
      return (
        Number(
          (performance.player_performance_stats as CsgoPlayerPerformanceStats)
            .stat_map_1_headshots
        ) +
        Number(
          (performance.player_performance_stats as CsgoPlayerPerformanceStats)
            .stat_map_2_headshots
        )
      );
    case PropKey.HEADSHOTS_3:
      return (
        performance.player_performance_stats as CsgoPlayerPerformanceStats
      ).stat_map_3_headshots;

    case PropKey.PASSING_TD:
      return (performance.player_performance_stats as NflPlayerPerformanceStats)
        .passing_touchdowns;
    case PropKey.RUSHING_ATTEMPTS:
      return (performance.player_performance_stats as NflPlayerPerformanceStats)
        .rushing_attempts;
    case PropKey.LONGEST_RUSH:
      return (performance.player_performance_stats as NflPlayerPerformanceStats)
        .longest_rush;
    case PropKey.RECEIVING_TD:
      return (performance.player_performance_stats as NflPlayerPerformanceStats)
        .receiving_touchdowns;
    case PropKey.INTERCEPTIONS_THROWN:
      return (performance.player_performance_stats as NflPlayerPerformanceStats)
        .passing_interceptions;
    case PropKey.RUSHING_TD:
      return (performance.player_performance_stats as NflPlayerPerformanceStats)
        .rushing_touchdowns;
    case PropKey.TOTAL_TD:
      return (
        (performance.player_performance_stats as NflPlayerPerformanceStats)
          .passing_touchdowns +
        (performance.player_performance_stats as NflPlayerPerformanceStats)
          .rushing_touchdowns +
        (performance.player_performance_stats as NflPlayerPerformanceStats)
          .receiving_touchdowns
      );
    case PropKey.PASSING_ATTEMPTS:
      return (performance.player_performance_stats as NflPlayerPerformanceStats)
        .passing_attempts;
    case PropKey.TACKLES_ASSISTS:
      return Number(
        (performance.player_performance_stats as NflPlayerPerformanceStats)
          .tackles +
          (performance.player_performance_stats as NflPlayerPerformanceStats)
            .assists
      );
    case PropKey.PASSING_RUSHING_YARDS:
      return (
        (performance.player_performance_stats as NflPlayerPerformanceStats)
          .passing_yards +
        (performance.player_performance_stats as NflPlayerPerformanceStats)
          .rushing_yards
      );
    case PropKey.TACKLES:
      return (performance.player_performance_stats as NflPlayerPerformanceStats)
        .tackles;
    case PropKey.INTERCEPTIONS:
      return (performance.player_performance_stats as NflPlayerPerformanceStats)
        .defensive_interceptions;
    case PropKey.LONGEST_RECEPTION:
      return (performance.player_performance_stats as NflPlayerPerformanceStats)
        .longest_reception;
    case PropKey.LONGEST_PASS:
      return (performance.player_performance_stats as NflPlayerPerformanceStats)
        .longest_pass;
    case PropKey.FG_MADE:
      return (performance.player_performance_stats as NflPlayerPerformanceStats)
        .field_goals_made;
    case PropKey.KICKING_POINTS:
      return (
        (performance.player_performance_stats as NflPlayerPerformanceStats)
          .field_goals_made *
          3 +
        (performance.player_performance_stats as NflPlayerPerformanceStats)
          .extra_points_made
      );
    case PropKey.TACKLE_FOR_LOSS:
      return (performance.player_performance_stats as NflPlayerPerformanceStats)
        .tackles_for_loss;
    case PropKey.XP_MADE:
      return (performance.player_performance_stats as NflPlayerPerformanceStats)
        .extra_points_made;
    case PropKey.PUNTS:
      return (performance.player_performance_stats as NflPlayerPerformanceStats)
        .punts;
    default:
      return 0;
  }
}

/**
 * Returns true if the decision was correct
 * @param performance
 * @param prop_key
 * @param position
 * @param propDecision
 * @param league
 * @param propValue
 * @param actualValue
 */
export function getFinalPropResult(
  performance: PlayerGamePerformance<string>,
  prop_key: PropKey,
  position:
    | NflPositions
    | NhlPositions
    | EplPositions
    | NbaPositions
    | MlbPositions,
  propDecision: PropDecision,
  league: League,
  propValue: number,
  actualValue?: number
): boolean | undefined {
  if (!actualValue) {
    actualValue = getPropPerformanceStatus(
      performance,
      prop_key,
      league,
      position
    );
  }

  if (!performance || !performance.player_active) {
    return false;
  }

  if (actualValue === propValue) return undefined;

  if (actualValue > propValue && propDecision === PropDecision.OVER)
    return true;

  return actualValue < propValue && propDecision === PropDecision.UNDER;
}
