import { Modal } from "@mui/material";
import { useState } from "react";
import { PHANTASIA_PICKS_FAQ_RULES } from "common/src/util/constants";
import { PicksArrow } from "../picks/utils/PicksArrow";
import { PicksBonusArrow } from "../picks/utils/PicksBonusArrow";
import { DfsScoringRules } from "./HowToPlayModal";
import { League } from "@phantasia/model-interfaces";

export function PicksRulesModal() {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <>
      <div
        onClick={() => setOpen(true)}
        className={"flex-row space-x-3 items-center group cursor-pointer"}
      >
        <span
          className={
            "text-primary-1 transition group-hover:text-neutral-2 font-md weight-400"
          }
        >
          view rules
        </span>
        <i
          className={
            "bx-chevron-right transition bx text-primary-1 group-hover:text-neutral-2"
          }
        />
      </div>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        className="flex flex-col flex-center modal outline-none"
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div className="relative bg-base-2 w-31 rounded-11 animation-from-bottom max-w-9/10 max-h-9/10 outline-none flex-col items-center">
          <div
            className={"ring-b-1 flex-row pt-8 px-8 pb-5 items-center w-full"}
          >
            <span className={"text-neutral-2 font-lg weight-500 flex-1"}>
              Phantasia Picks Rules & FAQs
            </span>
            <div
              onClick={() => setOpen(false)}
              className={
                "rounded-full w-10 h-10 flex flex-center cursor-pointer hover:bg-base-3 outline-1 transition"
              }
            >
              <i className={"bx-x bx text-neutral-2"} />
            </div>
          </div>
          <div className={"flex-col overflow-auto p-8 h-full"}>
            <RuleHeader icon={"💵"} />
            <span className={"my-4 text-neutral-2 weight-500 font-lg"}>
              What are the payouts?
            </span>
            <div className={"flex-row items-center"}>
              <BulletPoint />
              <span className={"mx-3 text-neutral-1 font-md"}>
                2 picks multiplier =
              </span>
              <span className={"weight-500 text-neutral-2 font-md"}>3x</span>
            </div>
            <div className={"flex-row items-center"}>
              <BulletPoint />
              <span className={"mx-3 text-neutral-1 font-md"}>
                3 picks multiplier =
              </span>
              <span className={"weight-500 text-neutral-2 font-md"}>5.5x</span>
            </div>
            <div className={"flex-row items-center"}>
              <BulletPoint />
              <span className={"mx-3 text-neutral-1 font-md"}>
                4 picks multiplier =
              </span>
              <span className={"weight-500 text-neutral-2 font-md"}>10x</span>
            </div>
            <div className={"mb-9 flex-row items-center"}>
              <BulletPoint />
              <span className={"mx-3 text-neutral-1 font-md"}>
                5 picks multiplier =
              </span>
              <span className={"weight-500 text-neutral-2 font-md"}>18x</span>
            </div>

            <RuleHeader icon={"⚡️"} />
            <span className={"mt-4 text-neutral-2 font-lg weight-500"}>
              Boosted Picks
            </span>

            <span className={"text-neutral-1 mb-5 font-md weight-300"}>
              Boosted picks give you an extra payout when you include them in
              your entry. Choose all boosted players and your payouts will be
            </span>
            <div className={"flex-row items-center"}>
              <BulletPoint boosted />
              <span className={"mx-3 text-neutral-1 font-md"}>
                2 picks multiplier =
              </span>
              <span className={"weight-500 text-neutral-2 font-md"}>3.25x</span>
            </div>
            <div className={"flex-row items-center"}>
              <BulletPoint boosted />
              <span className={"mx-3 text-neutral-1 font-md"}>
                3 picks multiplier =
              </span>
              <span className={"weight-500 text-neutral-2 font-md"}>6.5x</span>
            </div>
            <div className={"flex-row items-center"}>
              <BulletPoint boosted />
              <span className={"mx-3 text-neutral-1 font-md"}>
                4 picks multiplier =
              </span>
              <span className={"weight-500 text-neutral-2 font-md"}>12x</span>
            </div>
            <div className={"mb-9 flex-row items-center"}>
              <BulletPoint boosted />
              <span className={"mx-3 text-neutral-1 font-md"}>
                5 picks multiplier =
              </span>
              <span className={"weight-500 text-neutral-2 font-md"}>22x</span>
            </div>

            <RuleHeader icon={"⛑️"} />
            <span className={"mt-4 text-neutral-2 font-lg weight-500"}>
              What does insurance do?
            </span>
            <span className={"text-neutral-1 mb-5 font-md weight-300"}>
              Using insurance gives you a chance to earn your entry back if
              exactly 1 pick ends in a{" "}
              <span className={"text-danger-1 font-md weight-500"}>LOSS</span>
            </span>
            <span className={"text-neutral-1 mb-5 font-md weight-300"}>
              With insurance the payouts are slightly worse:
            </span>
            <div className={"flex-row items-center"}>
              <BulletPoint />
              <span className={"mx-3 text-neutral-1 font-md"}>
                3 picks insured multiplier =
              </span>
              <span className={"weight-500 text-neutral-2 font-md"}>2.85x</span>
            </div>
            <div className={"flex-row items-center"}>
              <BulletPoint />
              <span className={"mx-3 text-neutral-1 font-md"}>
                4 picks insured multiplier =
              </span>
              <span className={"weight-500 text-neutral-2 font-md"}>6x</span>
            </div>
            <div className={"mb-6 flex-row items-center"}>
              <BulletPoint />
              <span className={"mx-3 text-neutral-1 font-md"}>
                5 picks insured multiplier =
              </span>
              <span className={"weight-500 text-neutral-2 font-md"}>11.5x</span>
            </div>
            <span className={"text-neutral-1 mb-5 font-md weight-300"}>
              But use all boosted players & they increase to:
            </span>
            <div className={"flex-row items-center"}>
              <BulletPoint boosted />
              <span className={"mx-3 text-neutral-1 font-md"}>
                3 picks insured multiplier =
              </span>
              <span className={"weight-500 text-neutral-2 font-md"}>3.85x</span>
            </div>
            <div className={"flex-row items-center"}>
              <BulletPoint boosted />
              <span className={"mx-3 text-neutral-1 font-md"}>
                4 picks insured multiplier =
              </span>
              <span className={"weight-500 text-neutral-2 font-md"}>8x</span>
            </div>
            <div className={"mb-6 flex-row items-center"}>
              <BulletPoint boosted />
              <span className={"mx-3 text-neutral-1 font-md"}>
                5 picks insured multiplier =
              </span>
              <span className={"weight-500 text-neutral-2 font-md"}>15.5x</span>
            </div>
            <span className={"text-neutral-2 mb-9 font-md weight-300"}>
              Note that insurance will not be valid for contests with 2 or less
              valid picks. A pick marked{" "}
              <span className={"text-neutral-1 font-md weight-500"}>DNP</span>{" "}
              is not valid.
            </span>
            <RuleHeader icon={"🚀"} />
            <span className={"mt-4 text-neutral-2 font-lg weight-500"}>
              FANT Boosts
            </span>
            <span className={"text-neutral-1 mb-9 font-md weight-300"}>
              FANT boosts{" "}
              <span className={"text-neutral-1"}>
                increase your multiplier by .1x
              </span>
              . They cost 50 FANT, which can be earned in free contests and the
              daily spin.
            </span>

            <RuleHeader icon={"🤕"} />
            <span className={"mt-4 text-neutral-2 font-lg weight-500"}>
              What happens if a player is DNP?
            </span>
            <span className={"text-neutral-1 mb-9 font-md weight-300"}>
              Your entry will 'downgrade' when players are marked{" "}
              <span className={"text-neutral-1 font-md weight-500"}>DNP</span>{" "}
              and the payout will be reduced. Read more below about how players
              DNP in the detailed rules section at the end of this page.
            </span>

            <RuleHeader icon={"↔️"} />
            <span className={"mt-4 text-neutral-2 font-lg weight-500"}>
              What happens when picks TIE?
            </span>
            <span className={"text-neutral-1 mb-6 font-md weight-300"}>
              Your entry will 'downgrade' when players are marked{" "}
              <span className={"text-primary-1 font-md weight-500"}>TIE</span>{" "}
              and the payout will be reduced.{" "}
              <span className={"text-primary-1 font-md weight-500"}>TIE</span>s
              will not cause refunds, unless paired specifically with{" "}
              <span className={"text-neutral-1 font-md weight-500"}>DNP</span>.
              Read more below about{" "}
              <span className={"text-primary-1 font-md weight-500"}>TIE</span>s
              in the detailed rules section at the end of this page.
            </span>
            <div className={"flex-row items-center"}>
              <BulletPoint />
              <span className={"mx-3 text-neutral-1 font-md"}>
                Entry with 1{" "}
                <span className={"weight-500 text-success-1 font-md"}>WIN</span>{" "}
                + all{" "}
                <span className={"text-primary-1 font-md weight-500"}>TIE</span>
                s =
              </span>
              <span className={"weight-500 text-neutral-2 font-md"}>1.75x</span>
            </div>
            <div className={"mb-9"}>
              <BulletPoint />
              <span className={"mx-3 text-neutral-1 font-md"}>
                Entry with all{" "}
                <span className={"text-primary-1 font-md weight-500"}>TIE</span>
                s =
              </span>
              <span className={"weight-500 text-neutral-2 font-md"}>1x</span>
            </div>

            <DfsScoringRules league={League.NFL} />

            <span className={"weight-600 font-2xl text-neutral-2 mt-10 mb-8"}>
              Detailed Rules
            </span>
            {PHANTASIA_PICKS_FAQ_RULES.map(({ q, a }, index) => (
              <div key={index} className={"flex-col space-y-3 mb-10"}>
                <span className={"text-neutral-2 font-lg weight-500"}>{q}</span>
                <span className={"text-neutral-1 font-md weight-300 flex"}>
                  {a}
                </span>
              </div>
            ))}
          </div>
        </div>
      </Modal>
    </>
  );
}

function BulletPoint({ boosted }: { boosted?: boolean }) {
  if (!boosted)
    return <PicksArrow className={"fill-primary bx-rotate-90 h-6 w-6"} />;
  return (
    <PicksBonusArrow fillColor={"#ecc440"} className={`w-6 h-6 bx-rotate-90`} />
  );
}

function RuleHeader({ icon }: { icon: string }) {
  return (
    <div className={"w-11 min-h-11 rounded-7 flex flex-center bg-base-3"}>
      <span className={"font-lg text-neutral-2"}>{icon}</span>
    </div>
  );
}
