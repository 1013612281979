import { StakingContext } from "common/src/context/StakingContext";
import React, { useContext } from "react";
import { GradientButton3 } from "../atoms/Button";
import { Input } from "../atoms/Input";
import { useStakedBalance } from "common/src/hooks/useBalance";

export function StakingInputs() {
  const { unstakeAmount, setUnstakeAmount, showError, submitForm } =
    useContext(StakingContext);

  const sFantBalance = useStakedBalance();

  return (
    <div className={`w-full flex-col flex-grow`}>
      <div className="w-full flex-center flex-grow flex-col h-full">
        <div className="rounded-9 bg-base-1 p-8 flex-grow relative w-full">
          <UnstakeSection
            showError={showError}
            sFantBalance={sFantBalance}
            unstakeAmount={unstakeAmount}
            setUnstakeAmount={setUnstakeAmount}
            submitForm={submitForm}
          />
        </div>
      </div>
    </div>
  );
}

function UnstakeSection({
  showError,
  sFantBalance,
  unstakeAmount,
  setUnstakeAmount,
  submitForm,
}) {
  return (
    <div className="flex-col animation-reveal">
      <div className=" outline-1 rounded-5 p-5 flex-col mb-11 flex-center w-full">
        <span className="font-lg text-neutral-1 weight-400 mb-5">
          sFANT available to unstake
        </span>
        <span className="font-lg text-neutral-1 weight-400">
          <b className="text-neutral-2 font-xl">{sFantBalance}</b> sFANT
        </span>
      </div>
      <span className="font-lg text-neutral-2 weight-500 mb-5 px-4">
        Unstake
      </span>

      <div className="flex-row space-x-8 flex-1">
        <Input
          id="unstake-input"
          showError={showError}
          containerClasses={"flex-1"}
          placeholder={"10 FANT"}
          value={unstakeAmount}
          onChange={(e) => setUnstakeAmount(e.target.value)}
          type="number"
          classes={" h-13 rounded-6"}
        />
      </div>
      {showError && (
        <span className="text-danger-1 font-sm weight-400 mt-4 animation-from-left">
          The amount you entered is greater than the amount you have staked
        </span>
      )}

      <span className="text-neutral-2 font-md w-full text-center mb-4 mt-8">
        You will receive FANT in exchange for your wallet's sFANT
      </span>
      <GradientButton3
        id="unstake-submit"
        color="danger"
        type="mt-4"
        onClick={() => submitForm()}
      >{`Unstake ${unstakeAmount} FANT`}</GradientButton3>
    </div>
  );
}
