import { usePicksBoard } from "common/src/context/picks/PicksBoardContext";
import { HorizontalScrollview } from "../../atoms/Scrollviews";
import { getPropKeyUiName } from "common/src/util/helpers";
import { HorizontalDividerInDiv } from "../../atoms/Divider";
import { PicksBonusArrow } from "../utils/PicksBonusArrow";

export function PicksPropKeyFilters() {
  const {
    propKeyFilters,
    setSelectedPropKey,
    selectedPropKey,
    setSearchQuery,
    boostFilter,
    setBoostFilter,
  } = usePicksBoard();

  return (
    <div className={"sticky t-0 glass z-11 mr-1 flex-col"}>
      <HorizontalScrollview className={"py-6 pl-8 pr-4"}>
        <button
          onClick={() => {
            if (!boostFilter) {
              setSelectedPropKey(null);
              setBoostFilter(true);
            }
          }}
          className={`flex-row text-neutral-2 space-x-4 outline-none border-none flex flex-center rounded-9 flex-1 px-6 py-5 hover-down  transition ${
            boostFilter
              ? "bg-warning-0 ring-2 ring-warning-1 ring-offset-4 ring-offset-base-1"
              : "bg-base-2 hover:bg-base-3"
          }`}
        >
          <PicksBonusArrow fillColor={"#ecc440"} className={`w-6 h-6`} />
          <span className={"weight-400 text-warning-1 font-sm no-wrap"}>
            Boosts
          </span>
        </button>
        {propKeyFilters.map((propKey) => (
          <button
            key={propKey}
            onClick={() => {
              if (selectedPropKey !== propKey) {
                setBoostFilter(false);
                setSelectedPropKey(propKey);
                setSearchQuery("");
              }
            }}
            className={`flex-row text-neutral-2 space-x-4 outline-none border-none flex flex-center rounded-9 flex-1 px-6 py-5 hover-down  transition ${
              selectedPropKey === propKey
                ? "bg-primary-0 ring-2 ring-primary-1 ring-offset-4 ring-offset-base-1"
                : " bg-base-2 hover:bg-base-3"
            }`}
          >
            <span className={"weight-400 font-sm no-wrap"}>
              {getPropKeyUiName(propKey)}
            </span>
          </button>
        ))}
      </HorizontalScrollview>
      <HorizontalDividerInDiv className={"px-8"} />
    </div>
  );
}
