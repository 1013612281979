import { Modal } from "@mui/material";
import { useEffect, useState } from "react";
import { SolidButton1 } from "../atoms/Button";
import { HorizontalDivider, HorizontalDividerInDiv } from "../atoms/Divider";
import { PopulatedPickProp } from "common/src/services/dtos/ContestDtos";
import { League } from "@phantasia/model-interfaces";
import { calculateBoostedValue } from "common/src/services/PicksService";
import { getPropKeyShortName } from "common/src/util/helpers";
import { usePicksBoard } from "common/src/context/picks/PicksBoardContext";
import { useSlipBuilder } from "common/src/context/picks/SlipBuilderContext";

export function PicksLineChangeModal() {
  const [open, setOpen] = useState(false);
  const [changedProps, setChangedProps] = useState<{
    changed: PopulatedPickProp[];
    removed: PopulatedPickProp[];
  }>({
    changed: [],
    removed: [],
  });

  const { findChangedOrRemovedProps } = usePicksBoard();
  const { currentSlip, removeLegFromSlip, updateLegValue } = useSlipBuilder();

  useEffect(() => {
    const { changed, removed } = findChangedOrRemovedProps(currentSlip);

    if (changed.length > 0 || removed.length > 0) {
      setChangedProps({ changed, removed });
      setOpen(true);
    } else {
      setChangedProps({ changed, removed });
      setOpen(false);
    }
  }, [currentSlip, findChangedOrRemovedProps]);

  function handlePickUpdate(pick: PopulatedPickProp) {
    updateLegValue(pick._id.toString(), pick.value);
  }

  function handleRemove(pick: PopulatedPickProp) {
    removeLegFromSlip(pick._id.toString());
  }

  return (
    <>
      <Modal
        open={Boolean(open)}
        onClose={() => {
          setOpen(false);
        }}
        className="flex flex-col flex-center modal"
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div className="flex-col space-y-6 animation-from-bottom max-h-9/10 p-9 overflow-auto bg-base-1 rounded-9 w-full max-w-31 outline-none">
          <div className={"flex-col flex-center w-full"}>
            <span className={"text-neutral-2 weight-500 font-lg"}>
              Some picks have changed!
            </span>
            <HorizontalDivider className={undefined} />
            {changedProps.changed.length > 0 ? (
              <>
                <span className={"font-sm weight-500 text-neutral-2"}>
                  Updated projections
                </span>
                <PlayerList
                  props={changedProps.changed}
                  onPress={handlePickUpdate}
                />
              </>
            ) : null}

            {changedProps.removed.length > 0 ? (
              <>
                <span className={"font-sm weight-500 text-neutral-2"}>
                  Removed projections
                </span>
                <PlayerList
                  props={changedProps.removed}
                  removed
                  onPress={handleRemove}
                />
              </>
            ) : null}
          </div>
        </div>
      </Modal>
    </>
  );
}

function PlayerList({
  props,
  removed,
  onPress,
}: {
  props: PopulatedPickProp[];
  removed?: boolean;
  onPress: (pick: PopulatedPickProp) => void;
}) {
  return (
    <div className={"outline-1 rounded-6 p-6 mb-6"}>
      {props.map((pick, index) => (
        <div key={index}>
          {index !== 0 ? <HorizontalDividerInDiv className={"pl-8"} /> : null}
          <div className={"flex-row items-center w-full"}>
            <div
              className={`h-10 w-10 bg-base-4 rounded-full flex flex-center overflow-hidden ${
                removed ? "opacity-60" : null
              } mr-4`}
            >
              <img
                src={pick.player.photo}
                alt={"player"}
                className={"contain h-10"}
              />
            </div>
            <div className={"flex-1"}>
              <span
                className={`${
                  removed ? "bx-strikethrough text-neutral-1" : "text-neutral-2"
                } weight-500 font-md`}
              >
                {pick.player.name}
              </span>
              <span className={"text-neutral-1 font-sm weight-300"}>
                {pick.league !== League.CSGO && pick.league !== League.LOL
                  ? `${pick.player.position}  •  ${pick.player.team}`
                  : `${pick.player.team}`}
              </span>
            </div>
            <div className={"flex-col items-end w-14"}>
              <span
                className={`${
                  removed ? "bx-strikethrough text-neutral-1" : "text-neutral-2"
                } font-lg weight-600`}
              >
                {calculateBoostedValue(pick)}
              </span>
              <span className={"font-sm text-neutral-1 weight-300"}>
                {getPropKeyShortName(pick.prop_key)}
              </span>
            </div>
            <SolidButton1
              onClick={() => onPress(pick)}
              className={undefined}
              textStyles={undefined}
              contentRight={undefined}
              contentLeft={undefined}
              disabled={undefined}
              submit={undefined}
              color={undefined}
              id={undefined}
            >
              Update
            </SolidButton1>
          </div>
        </div>
      ))}
    </div>
  );
}
