import { User } from "@phantasia/model-interfaces";
import { UserTypes } from "../redux/actions/types";
import { getApi } from "../util/api";
import { Singletons } from "./Singletons";
import { isValidEmail } from "../util/FormValidator";

export const updateUser = async (
  updatedUser: any,
  user: Partial<Omit<User, "_id">>,
  dispatch: any
) => {
  const { SET_USER } = UserTypes;

  const res = await getApi().put(`/api/auth/`, updatedUser);
  if (res.status === 200) {
    user.acceptedTerms = true;

    dispatch({
      type: SET_USER,
      payload: { ...user, ...updatedUser },
    });
  }
  return res;
};

export async function addUserReferral(referral: string) {
  return await getApi().put(`/api/auth/referral`, { referral });
}

export async function updateUserAndNotify(
  updatedUser: any,
  user: Partial<Omit<User, "_id">>,
  dispatch: any
) {
  const { SET_USER } = UserTypes;
  try {
    await getApi().put(`/api/auth/`, updatedUser);

    dispatch({
      type: SET_USER,
      payload: user,
    });

    Singletons.toastService.success("Success", "Settings updated");
  } catch (e: any) {
    console.error(e.response);
    Singletons.toastService.error(
      "Error",
      e?.response?.data?.msg ?? e.message ?? "Error"
    );
  }
}

export async function blockUser() {
  try {
    await getApi().put(`/api/auth/block`);
  } catch (e: any) {
    console.error(e.response);
    Singletons.toastService.error(
      "Error",
      e?.response?.data?.msg ?? e.message ?? "Error"
    );
  }
}

export function updateUserEmailSetting(
  participantEmail: string,
  user: User,
  dispatch: any
) {
  if (isValidEmail(participantEmail)) {
    const newUser = {
      email: participantEmail,
    };
    updateUserAndNotify(newUser, newUser, dispatch).catch((e) =>
      console.error(e)
    );
  }
}

export async function refreshUserObjectInLocalState(dispatch: any) {
  const { SET_USER } = UserTypes;

  const res = await getApi().get(`/api/auth/`);
  if (res.status === 200) {
    dispatch({
      type: SET_USER,
      payload: res.data,
    });
  }
}

export const updateUserReferralCode = async (code: string) => {
  return getApi().get(`/api/auth/referral/${code}`);
};
