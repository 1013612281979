import React from "react";
import { PaymentHistoryComponent } from "../components/payments/PaymentHistoryComponent";
import { NavDrawer } from "../components/layout/header/NavDrawer";

export default function PaymentHistoryPage() {
  return (
    <div className="flex-row bg-base-1">
      <NavDrawer />
      <div className={"flex-col flex-1 outline-l-1 pl-1"}>
        <div className={"p-8 bg-base-1 outline-b-1 flex-row"}>
          <span className={"font-lg weight-500 text-neutral-2 flex-1"}>
            Deposit History
          </span>
        </div>
        <PaymentHistoryComponent />
      </div>
    </div>
  );
}
