import { ModalTypes } from "../actions/types";
const { SET_FAN_CARD_MODAL, SET_NFT_MODAL } = ModalTypes;

const initialState = {
  nftImage: null,
  publicKey: null,
};

const Modal = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_FAN_CARD_MODAL: {
      return {
        ...state,
        publicKey: action.payload,
      };
    }
    case SET_NFT_MODAL: {
      return {
        ...state,
        nftImage: action.payload,
      };
    }

    default:
      return state;
  }
};

export default Modal;
