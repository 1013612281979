import { colorDanger, colorSuccess } from "common/src/util/constants";
import toast from "react-hot-toast";

export const toastSuccess = (title, message) => {
  toast(
    (t) => (
      <div className="w-full flex-row space-between-md align-center">
        <span className="">{message || title}</span>
        <div className="flex-grow" />
        <span onClick={() => toast.dismiss(t.id)} className="white font-sm">
          Dismiss
        </span>
      </div>
    ),
    {
      icon: <i class="bx bx-sm bx-check-double white" />,
      position: "bottom-center",
      duration: 2000,
      style: {
        padding: "16px",
        fontSize: "14px",
        minWidth: "350px",
        color: "#fff",
        borderRadius: "100px",
        backgroundColor: colorSuccess[1],
        boxShadow:
          "rgba(0, 0, 0, 0.15) 0px 20px 25px -5px, rgba(0, 0, 0, 0.1) 0px 10px 10px -5px",
      },
    }
  );
};

export const toastError = (title, message) => {
  toast(
    (t) => (
      <div className="w-full flex-row space-between-md align-center">
        <span className="">{message || title}</span>
        <div className="flex-grow" />
        <span onClick={() => toast.dismiss(t.id)} className="white font-sm">
          Dismiss
        </span>
      </div>
    ),
    {
      icon: <i class="bx bx-sm bxs-error-circle white" />,
      position: "bottom-center",
      duration: 4000,
      style: {
        padding: "16px",
        fontSize: "14px",
        minWidth: "350px",
        color: "#fff",
        borderRadius: "100px",
        backgroundColor: colorDanger[400],
        boxShadow:
          "rgba(0, 0, 0, 0.15) 0px 20px 25px -5px, rgba(0, 0, 0, 0.1) 0px 10px 10px -5px",
      },
    }
  );
};
