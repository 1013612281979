import { GeolocationContext } from "common/src/context/GeolocationContext";
import { HeaderContext } from "common/src/context/HeaderContext";
import { KycContextProvider } from "common/src/context/KycContext";
import { SignOut } from "common/src/redux/actions/UserAction";
import { useCommonWallet } from "common/src/services/wallet/UseCommonWallet";
import React, { useContext, useRef } from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { persistor } from "../../../store";
import { KycModal } from "../../modals/kyc/KycModal";
import { SsnKycModal } from "../../modals/kyc/SsnKycModal";
import { DepositMenu } from "../../payments/DepositMenu";
import Wallet from "../../wallet/Wallet";
import { DesktopHeaderMenu } from "./DesktopHeaderMenu";
import { useUser } from "common/src/hooks/useUser";
import { triggerEventRafClickNavBar } from "../../../GoogleAnalytics";
import { NotificationsMenu } from "./NotificationsMenu";

export function LoggedInHeader({ screenIsSmall }) {
  const { ssnKycModalOpen, setSnnKycModalOpen, kycModalOpen, setKycModalOpen } =
    useContext(HeaderContext);
  const user = useUser();
  const depositButtonRef = useRef(null);

  const { showVerificationButton } = useContext(GeolocationContext);
  const wallet = useCommonWallet();

  const history = useHistory();
  const dispatch = useDispatch();

  return (
    <div className="flex-row items-center w-full animation-from-top">
      <SsnKycModal open={ssnKycModalOpen} setOpen={setSnnKycModalOpen} />
      <KycContextProvider
        onSuccess={() => {
          setKycModalOpen(false);
        }}
      >
        <KycModal open={kycModalOpen} onClose={() => setKycModalOpen(false)} />
      </KycContextProvider>
      <div className="flex-row space-x-8 items-center px-8">
        {user._id && (
          <>
            <span className="flex-row items-center">
              <Link
                to="/leagues"
                className="text-neutral-1 hover-dark decoration-none transition weight-300 font-md"
                variant="body2"
              >
                Leagues
              </Link>
            </span>
            <span className="flex-row space-x-5">
              <Link
                to="/shop"
                color="inherit"
                className="text-neutral-1 hover-dark decoration-none transition weight-300 font-md"
              >
                Shop
              </Link>
            </span>
            <span>
              <Link
                to="/staking"
                className="text-neutral-1 hover-dark decoration-none transition weight-300 font-md"
                variant="body2"
              >
                Staking
              </Link>
            </span>
            <span onClick={() => triggerEventRafClickNavBar()}>
              <Link
                to="/refer-a-friend"
                className="text-neutral-1 hover-dark decoration-none transition weight-300 font-md"
                variant="body2"
              >
                Refer-A-Friend
              </Link>
            </span>
          </>
        )}
      </div>
      <div className="flex-grow" />

      <div className="flex ml-4 space-x-5 items-center">
        <Wallet
          ghost
          onDisconnect={() => {
            if (wallet) wallet.disconnect();
            persistor.purge();
            window.Intercom("shutdown");
            dispatch(SignOut(history));
          }}
        />

        {showVerificationButton ? (
          <div className="flex-row" ref={depositButtonRef}>
            <DepositMenu />
          </div>
        ) : null}
        <NotificationsMenu />
        {user._id && <DesktopHeaderMenu screenIsSmall={screenIsSmall} />}
      </div>
    </div>
  );
}
