import React, { useState } from "react";
import DfsSs from "../../assets/dfs-ss.png";
import HlSs from "../../assets/hl-ss.png";
import LeagueSs from "../../assets/leagues-ss.png";
import ScLogo from "../../assets/salary-cap-logo.png";
import hlLogo from "../../assets/higher-lower.png";
import { HorizontalDividerInDiv } from "../../components/atoms/Divider";
import { GradientButton2 } from "../../components/atoms/Button";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../../Routes";

export function DfsInfoSection({ isMobile }) {
  const [selected, setSelected] = useState(isMobile ? null : 0);
  const history = useHistory();

  return (
    <div className="w-full flex-col flex-center px-6 relative z-11 space-y-12 py-15 bg-base-1">
      <h1
        style={{ fontSize: "calc(20px + 1.1vw)" }}
        className={`no-wrap text-neutral-2 weight-500 mt-0 flex-row`}
      >
        How To
        <strong className="ml-5 text-gradient-light weight-500">Play</strong>
      </h1>
      <div
        className={
          "grid grid-cols-1 md:grid-cols-2 gap-10 w-full max-w-35 relative m-auto"
        }
      >
        <div className={"flex-col space-y-10"}>
          <div
            onClick={() => setSelected(1)}
            className={`${
              selected < 2 ? "outline-2 outline-neutral-2" : "outline-1"
            } hover:bg-base-2 transition cursor-pointer hover-right outline-1 rounded-8 p-8 w-full grid grid-cols-1 md:grid-cols-2 gap-8`}
          >
            <div className={"flex-col items-start space-y-2"}>
              <img
                style={{ background: "#09090b" }}
                className={"h-9 contain mb-6 ml--3 px-3 rounded-3"}
                src={ScLogo}
                alt={"sc"}
              />
              <HorizontalDividerInDiv className={"w-full mb-6"} />
              <span className={"text-neutral-2 font-lg weight-500"}>
                Salary Cap
              </span>
              <span
                className={"text-neutral-2 opacity-75 font-md weight-300 mb-6"}
              >
                Build a lineup of players while staying within a salary cap.
                Lineups scoring the most fantasy points take home the prize
                pool.
              </span>
              <GradientButton2
                color={selected < 2 ? "primary" : "base"}
                className={"w-full flex-1"}
                onClick={() => history.push(ROUTES.PICKS)}
              >
                Play now
              </GradientButton2>
            </div>
            <div className={"relative flex flex-center"}>
              <div className={"relative"}>
                <div
                  className={
                    "glass-2 absolute shadow-3 t-12 l-12 rounded-6 p-5 flex-row space-x-4 items-center"
                  }
                >
                  <i className="bx bxs-purchase-tag text-neutral-2" />
                  <span className={"font-md weight-400 text-neutral-2"}>
                    $9300
                  </span>
                </div>
                <div
                  className={
                    "bg-success-0 absolute b-11 r-12 rounded-full shadow-3 p-4 flex flex-center"
                  }
                >
                  <i className="bx bx-plus text-success-1 bx-sm" />
                </div>
              </div>
            </div>
          </div>
          <div
            onClick={() => setSelected(2)}
            className={`${
              selected === 2 ? "outline-2 outline-neutral-2" : "outline-1"
            } hover:bg-base-2 transition cursor-pointer hover-right outline-1 rounded-8 p-8 w-full grid grid-cols-1 md:grid-cols-2 gap-8`}
          >
            <div className={"flex-col items-start space-y-2"}>
              <img
                style={{ background: "#09090b" }}
                className={"h-10 contain mb-6 ml--3 px-3 rounded-3"}
                src={hlLogo}
                alt={"sc"}
              />
              <HorizontalDividerInDiv className={"w-full mb-6"} />
              <span className={"text-neutral-2 font-lg weight-500"}>
                Higher Lower
              </span>
              <span
                className={"text-neutral-2 opacity-75 font-md weight-300 mb-6"}
              >
                Choose stat props for 3-10 players, then select 'Higher' or
                'Lower' to guess the player's performance result. Correct
                selections will earn you 1 point each. The participant(s) with
                the most points wins the contest prize pool.
              </span>
              <GradientButton2
                color={selected === 2 ? "danger" : "base"}
                className={"w-full flex-1"}
                onClick={() => history.push(ROUTES.PICKS)}
              >
                Play now
              </GradientButton2>
            </div>
            <div className={"relative flex flex-center"}></div>
          </div>
          <div
            onClick={() => setSelected(3)}
            className={`${
              selected === 3 ? "outline-2 outline-neutral-2" : "outline-1"
            } hover:bg-base-2 transition cursor-pointer hover-right rounded-8 p-8 w-full grid grid-cols-1 md:grid-cols-2 gap-8`}
          >
            <div className={"flex-col items-start space-y-2"}>
              <div className={"flex-row space-x-3 mb-6"}>
                <span
                  className={
                    "text-neutral-2 font-xl weight-400 letter-spacing-md"
                  }
                >
                  PHANTASIA
                </span>
                <span
                  className={
                    "text-gradient-success font-xl weight-400 letter-spacing-md"
                  }
                >
                  LEAGUES
                </span>
              </div>

              <HorizontalDividerInDiv className={"w-full mb-6"} />
              <span className={"text-neutral-2 font-lg weight-500"}>
                Phantasia Leagues
              </span>
              <span
                className={"text-neutral-2 opacity-75 font-md weight-300 mb-6"}
              >
                Play season long fantasy and use Phantasia as your treasurer.
                Deposit money immediately to enter your league, then get paid
                instantly when NFL week 17 ends. All for zero management fees.
              </span>
              <GradientButton2
                color={selected === 3 ? "success" : "base"}
                className={"w-full flex-1"}
                onClick={() => history.push(ROUTES.PICKS)}
              >
                Play now
              </GradientButton2>
            </div>
            <div className={"relative flex flex-center"}>
              <img
                alt={"mahomes"}
                className={"w-15 h-15 rounded-full contain bg-base-3"}
                src={
                  "https://s3-us-west-2.amazonaws.com/static.fantasydata.com/headshots/nfl/low-res/18890.png"
                }
              />
              <div
                className={
                  "bg-success-0 absolute b-12 r-12 rounded-full shadow-3 p-4 flex flex-center"
                }
              >
                <i className="bx bx-plus text-success-1 bx-sm" />
              </div>
              <div
                className={
                  "bg-primary-0 absolute t-12 l-13 rounded-full shadow-3 p-4 flex flex-center"
                }
              >
                <i className="bx bx-transfer-alt text-primary-1 bx-sm" />
              </div>
              <div
                className={
                  "glass-2 absolute shadow-3 t-18 l-10 rounded-6 p-5 flex-row space-x-4 items-center"
                }
              >
                <i className="bx bx-trending-up text-success-1" />
                <span className={"font-md weight-400 text-neutral-2"}>
                  99.9%
                </span>
              </div>
            </div>
          </div>
        </div>
        {isMobile ? null : (
          <div className={"w-full flex justify-center relative"}>
            <img
              key={selected}
              className={`w-1/2 contain h-min rounded-8 ring-4 ring-offset-8 animation-from-right t-15 sticky ${
                selected < 2
                  ? "ring-secondary-1"
                  : selected === 2
                  ? "ring-danger-1"
                  : "ring-success-1"
              }`}
              src={selected === 2 ? HlSs : selected === 3 ? LeagueSs : DfsSs}
              alt={"dfs"}
            />
          </div>
        )}
      </div>
    </div>
  );
}
