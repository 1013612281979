import { useEffect, useMemo, useState } from "react";
import { Singletons } from "common/src/services/Singletons";
import {
  getAdminPropRisk,
  getAdminSlipRisk,
  getAdminSlips,
  PropRiskItem,
  SlipRiskItem,
} from "common/src/services/PicksService";
import { SlipList } from "../../components/picks/lists/SlipList";
import {
  PopulatedSlip,
  SlipWithPerformanceAndProjection,
} from "common/src/services/dtos/ContestDtos";
import { SlipPerformanceTracker } from "../../components/picks/slips/SlipPerformanceTracker";
import { Modal } from "@mui/material";
import { NavDrawer } from "../../components/layout/header/NavDrawer";
import { copyLink } from "common/src/util/copyLink";
import { toastSuccess } from "../../components/toast/ToastService";
import { Loader } from "../../components/molecules/Loader";
import { LiveLegSummary } from "../../components/picks/list-items/SlipListItem";
import { getPropKeyShortName } from "common/src/util/helpers";
import { HorizontalDivider } from "../../components/atoms/Divider";
import { PickResult } from "@phantasia/model-interfaces";
import { GradientButton2, OutlineButton1 } from "../../components/atoms/Button";
import { Input } from "../../components/atoms/Input";
import { PropGrader } from "./PropGrader";

enum PickFilters {
  NONE,
  WINS,
  LOSS,
  DNP,
  CLOSED,
  IN_PLAY,
  INSURED,
}

enum RiskSort {
  COUNT,
  ENTRY,
}

export function PicksAdminDashboardPage() {
  const [showGrader, setShowGrader] = useState<Boolean>(false);
  const [slips, setSlips] = useState<PopulatedSlip[] | null>(null);
  const [slipRisk, setSlipRisk] = useState<SlipRiskItem[] | null>(null);
  const [propRisk, setPropRisk] = useState<PropRiskItem[] | null>(null);
  const [riskSort, setRiskSort] = useState<RiskSort>(RiskSort.COUNT);
  const [filter, setFilter] = useState<PickFilters>(PickFilters.NONE);
  const [amountFilter, setAmountFilter] = useState<string>("");

  const [selectedSlip, setSelectedSlip] = useState<
    SlipWithPerformanceAndProjection | PopulatedSlip | null
  >(null);

  const filteredSlips = useMemo(() => {
    if (!slips || slips.length === 0) return slips;
    if (!amountFilter && !filter) return slips;
    let temp = [...slips];
    if (amountFilter)
      temp = temp.filter((slip) => slip.payout >= Number(amountFilter));
    if (filter === PickFilters.DNP)
      temp = temp.filter((slip) => slip.result === PickResult.DNP);
    if (filter === PickFilters.WINS)
      temp = temp.filter((slip) => slip.result === PickResult.WIN);
    if (filter === PickFilters.LOSS)
      temp = temp.filter((slip) => slip.result === PickResult.LOSS);
    if (filter === PickFilters.CLOSED)
      temp = temp.filter((slip) => slip.result !== undefined);
    if (filter === PickFilters.IN_PLAY)
      temp = temp.filter((slip) => !slip.result);
    if (filter === PickFilters.INSURED)
      temp = temp.filter((slip) => slip.insurance_type === 2);
    return temp;
  }, [filter, amountFilter, slips]);

  useEffect(() => {
    if (!slips)
      getAdminSlips()
        .then((res) => {
          if (res) setSlips(res.data);
        })
        .catch((e) => {
          console.error(e);
          console.error(e.response.data.msg);
          Singletons.toastService.error("Error", e.response.data.msg);
        });

    if (!propRisk)
      getAdminPropRisk().then((res) => {
        setPropRisk(res.data);
      });

    if (!slipRisk) getAdminSlipRisk().then((res) => setSlipRisk(res.data));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sortedPropRisk = useMemo(() => {
    if (!propRisk) return propRisk;
    if (riskSort === RiskSort.ENTRY)
      return propRisk.sort((a, b) => {
        return b.entry - a.entry;
      });
    else
      return propRisk.sort((a, b) => {
        return b.count - a.count;
      });
  }, [propRisk, riskSort]);

  const filters = [
    {
      text: "Show all",
      value: PickFilters.NONE,
    },
    {
      text: "Wins",
      value: PickFilters.WINS,
    },
    {
      text: "Losses",
      value: PickFilters.LOSS,
    },
    {
      text: "Refunds",
      value: PickFilters.DNP,
    },
    {
      text: "In Play",
      value: PickFilters.IN_PLAY,
    },
    {
      text: "Closed",
      value: PickFilters.CLOSED,
    },
  ];

  const sortOptions = [
    {
      text: "Count",
      value: RiskSort.COUNT,
    },
    {
      text: "Entry $",
      value: RiskSort.ENTRY,
    },
  ];

  let barStyle = "0%";

  if (filter === PickFilters.WINS) barStyle = "16.66%";
  else if (filter === PickFilters.LOSS) barStyle = "33.33%";
  else if (filter === PickFilters.DNP) barStyle = "50%";
  else if (filter === PickFilters.IN_PLAY) barStyle = "66.66%";
  else if (filter === PickFilters.CLOSED) barStyle = "83.33%";

  return (
    <div className="h-screen w-screen flex-row bg-base-1">
      <NavDrawer />

      <div className={"flex-col flex-1 outline-l-1 pl-1 h-screen relative"}>
        <div
          className={
            "px-8 h-12 items-center glass outline-b-1 flex-row absolute t-0 r-0 l-0 z-10 space-x-5"
          }
        >
          {showGrader ? null : (
            <>
              <span className={"font-lg weight-500 text-neutral-2 flex-1"}>
                Picks Admin Dashboard ({filteredSlips?.length})
              </span>

              <div className={"bg-base-1 h-12 outline-b-1 flex-row relative"}>
                {filters.map((tab, index) => (
                  <TabButton
                    text={tab.text}
                    active={filter === tab.value}
                    onClick={() => setFilter(tab.value)}
                    key={index}
                  />
                ))}

                <div
                  style={{
                    left: barStyle,
                  }}
                  className={`h-3 w-1/6 flex flex-center absolute transition-long b-0 z-11`}
                >
                  <div
                    className={
                      "rounded-t-l-6 rounded-t-r-6 w-16 h-3 bg-primary-1"
                    }
                  />
                </div>
              </div>
              <Input
                size="sm"
                icon={<i className={"bx bx-search text-neutral-1"} />}
                classes={"min-w-22 rounded-full"}
                value={amountFilter}
                placeholder="Amount lower bound"
                onChange={(e) => setAmountFilter(e.target.value)}
              />
              <GradientButton2
                color={"primary"}
                onClick={() => setShowGrader(!showGrader)}
                className={undefined}
                textStyles={undefined}
                contentRight={undefined}
                contentLeft={undefined}
                disabled={undefined}
                submit={undefined}
              >
                {!showGrader ? "Grade Esports" : "Dashboard"}
              </GradientButton2>
            </>
          )}
        </div>
        {!showGrader ? (
          <div className={"grid grid-cols-2 max-h-full"}>
            <div className={"overflow-auto p-8 py-15"}>
              <SlipList
                slips={filteredSlips}
                selectedSlipId={""}
                setSelectedSlip={setSelectedSlip}
                showUser
              />
            </div>
            <div className={"ring-l-1 flex-col h-screen flex-1 pt-13"}>
              <div className={"flex-col relative h-1/2"}>
                <div
                  className={
                    "sticky t-0 px-5 pb-4 pt-5 glass ring-b-1 flex-row space-x-4 items-center"
                  }
                >
                  <span className={"text-neutral-2 weight-500 font-md flex-1"}>
                    Prop Risk
                  </span>

                  {sortOptions.map((f, index) => (
                    <OutlineButton1
                      key={index}
                      color={f.value === riskSort ? "primary" : "base"}
                      onClick={() => setRiskSort(f.value)}
                      className={undefined}
                      textStyles={undefined}
                      contentRight={undefined}
                      contentLeft={undefined}
                      disabled={undefined}
                      submit={undefined}
                    >
                      {f.text}
                    </OutlineButton1>
                  ))}
                </div>
                <PropRiskTable propRisk={sortedPropRisk} />
              </div>
              <HorizontalDivider className={""} />
              <div className={"flex-col relative h-1/2"}>
                <div className={"sticky t-0 p-5 glass"}>
                  <span className={"text-neutral-2 weight-500 font-md"}>
                    Slip Risk
                  </span>
                </div>
                <SlipRiskTable slipRisk={slipRisk} />
              </div>
            </div>
          </div>
        ) : (
          <PropGrader setShowGrader={setShowGrader} />
        )}
      </div>

      <TrackerModal
        selectedSlip={selectedSlip}
        slips={slips}
        onClose={() => setSelectedSlip(null)}
      />
    </div>
  );
}

function TabButton({
  active,
  onClick,
  text,
}: {
  active: boolean;
  onClick: () => void;
  text: string;
}) {
  return (
    <div
      className={`rounded-5 cursor-pointer w-14 p-6 hover:bg-base-2  transition flex flex-center decoration-none`}
      onClick={onClick}
    >
      <span
        className={`${
          active ? "text-neutral-2 weight-500" : "text-neutral-1 weight-400"
        } font-sm transition no-wrap`}
      >
        {text}
      </span>
    </div>
  );
}

function PropRiskTable({ propRisk }: { propRisk: PropRiskItem[] | null }) {
  function copy(id: string) {
    copyLink(`ObjectId("${id}")`, true).then(() =>
      toastSuccess("Copied", "ID Copied")
    );
  }

  if (!propRisk) return <Loader />;

  return (
    <div className={"h-full overflow-auto ring-b-1"}>
      {propRisk.map((item) => (
        <div key={item.prop._id}>
          <HorizontalDivider className={undefined} />
          <div
            onClick={() => copy(item.prop._id)}
            className={
              "flex-row space-x-3 items-center hover:bg-base-2 cursor-pointer transition p-5"
            }
          >
            <div
              className={`bg-base-4 flex flex-center rounded-full h-8 w-8 overflow-hidden`}
            >
              <img
                src={item.prop.player.photo}
                alt={"player"}
                className={"h-8 contain"}
              />
            </div>
            <div className={"flex-col flex-1"}>
              <div className={"flex-row space-x-4 items-center"}>
                <span className={"text-neutral-2 weight-400 font-sm"}>
                  {item.prop.player.name}
                </span>
                {item.prop.is_locked ? (
                  <i className={"bx bx-lock text-warning-1 font-xs"} />
                ) : null}
                {item.prop.disabled ? (
                  <i className={"bx bx-block text-danger-1 font-xs"} />
                ) : null}
              </div>

              <span className={"text-neutral-1 weight-300 font-xs"}>
                {`${item.prop.player.team}  |  ${getPropKeyShortName(
                  item.prop.prop_key
                )}  |  ${item.prop.value}`}
              </span>
            </div>
            <div className={"flex-row items-center divide-x"}>
              <TableCell
                danger={item.overDollars / item.entry > 0.8 && item.entry > 300}
                title={`$${item.overDollars.toFixed(2)}`}
                desc={"over"}
              />
              <TableCell
                danger={
                  item.underDollars / item.entry > 0.8 && item.entry > 300
                }
                title={`$${item.underDollars.toFixed(2)}`}
                desc={"under"}
              />

              <TableCell
                title={item.over}
                desc={"over"}
                subtitle={`${(item.overPct * 100).toFixed(0)}%`}
              />
              <TableCell
                title={item.under}
                desc={"under"}
                subtitle={`${(item.underPct * 100).toFixed(0)}%`}
              />

              <TableCell title={item.count} desc={"count"} />
              <TableCell title={`$${item.entry.toFixed(2)}`} desc={"entry"} />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

function SlipRiskTable({ slipRisk }: { slipRisk: SlipRiskItem[] | null }) {
  function copy(id: string) {
    copyLink(`ObjectId("${id}")`, true).then(() =>
      toastSuccess("Copied", "ID Copied")
    );
  }

  if (!slipRisk) return <Loader />;

  if (slipRisk.length === 0) {
    return (
      <span className={"text-neutral-2 font-md weight-400 text-center mt-10"}>
        No duplicate slips
      </span>
    );
  }

  return (
    <div className={"h-full overflow-auto px-5 space-y-5 pb-8"}>
      {slipRisk.map((item, index) => (
        <div
          key={index}
          className={"flex-col bg-base-2 rounded-8 p-7 space-y-4"}
        >
          <div className={"flex-row items-center"}>
            <span className={"text-neutral-2 weight-500 font-md flex-1"}>
              {item.count} duplicate slips
            </span>
            <span className={"text-danger-1 weight-500 font-lg"}>
              ${item.payout.toFixed(2)} risk
            </span>
          </div>

          {item.legs.map((leg) => (
            <div onClick={() => copy(leg.prop._id)} key={leg.prop._id}>
              <LiveLegSummary leg={leg} />
            </div>
          ))}
        </div>
      ))}
    </div>
  );
}

function TableCell({
  title,
  desc,
  subtitle,
  danger,
}: {
  title: string | number;
  desc: string;
  subtitle?: string;
  danger?: boolean;
}) {
  return (
    <div className={"flex-col items-end justify-center w-13 pr-4"}>
      <div className={"flex-row space-x-3 items-baseline"}>
        <span
          className={`${
            danger ? "text-danger-1" : "text-neutral-2"
          } weight-400 font-sm`}
        >
          {title}
        </span>
        {subtitle ? (
          <span className={"text-neutral-1 weight-300 font-xs"}>
            {subtitle}
          </span>
        ) : null}
      </div>

      <span className={"text-neutral-1 weight-300 font-xs"}>{desc}</span>
    </div>
  );
}

function TrackerModal({
  selectedSlip,
  slips,
  onClose,
}: {
  selectedSlip: PopulatedSlip | SlipWithPerformanceAndProjection | null;
  slips: PopulatedSlip[] | null;
  onClose: () => void;
}) {
  return (
    <>
      <Modal
        open={Boolean(selectedSlip)}
        onClose={onClose}
        className="flex flex-col flex-center modal outline-none"
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div className="animation-from-bottom glass rounded-9 p-8 w-11/12 h-min max-w-32 outline-none">
          <SlipPerformanceTracker
            selectedSlip={selectedSlip}
            slips={slips}
            refresh={() => {}}
          />
        </div>
      </Modal>
    </>
  );
}
